import { Action, createSlice, Reducer } from '@reduxjs/toolkit';
import { NetworkState } from '../interfaces/network-state-interface';

// Define the initial state using that type
const initialState: NetworkState = {
  code: 0,
  message: '',
  showError: false,
};

export const network = createSlice({
  name: 'state',
  initialState,
  reducers: {
    showNetworkError: (state, action) => {
      state = {
        ...state,
        showError: true,
        code: action.payload.code,
        message: action.payload.message,
      };
      return state;
    },
    hideNetworkError: (state) => {
      state = {
        ...state,
        showError: false,
        code: 0,
        message: '',
      };
      return state;
    },
  },
});

export const { showNetworkError, hideNetworkError } = network.actions;

// Other code such as selectors can use the imported `RootState` type
const networkReducer: Reducer<NetworkState, Action<NetworkState>> =
  network.reducer;
export default networkReducer;
