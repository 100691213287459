import { AxiosPromise, AxiosResponse } from "axios";
import { instance, searchInstance } from "./api-services";
import { ObjectKey } from "../interfaces/common-interface";
import { compressFileList, removeApiCacheSession } from "../utility";

const servicePath = "/directory/partnerNetwork";

export const getJoinedList = (input: {
  page: number;
  pageSize?: number;
}): AxiosPromise => {
  let pageSize = input?.pageSize ?? 10;
  return instance({
    method: "GET",
    id: `getPartnerNetwork-joined-${input.page}-${pageSize}`,
    url: `${servicePath}/joinedList`,
    params: {
      page: input.page,
      pageSize: pageSize,
    },
  });
};

export const getGroupList = (input: {
  page: number;
  pageSize: number;
  isFullList?: boolean;
}): AxiosPromise => {
  return instance({
    method: "GET",
    id: `getPartnerNetwork-group-${input.page}-${input.pageSize}-${
      input.isFullList ? "full" : ""
    }`,
    url: `${servicePath}/list`,
    params: {
      page: input.page,
      pageSize: input.pageSize,
      isFullList: input.isFullList ?? false,
    },
  });
};

export const getGroupDetail = (groupId: string): AxiosPromise => {
  return instance({
    method: "GET",
    id: `getPartnerNetwork-detail-${groupId}`,
    url: `${servicePath}/details/${groupId}`,
  });
};

export const getGroupSearchList = (input: {
  page: number;
  pageSize: number;
  search: string;
}): AxiosPromise => {
  return searchInstance({
    method: "GET",
    cache: false,
    url: `${servicePath}/list`,
    params: {
      page: input.page,
      pageSize: input.pageSize,
      search: input.search,
    },
  });
};

export const getMemberList = (data: {
  id: string;
  page: number;
  pageSize: number;
}): AxiosPromise => {
  return instance({
    method: "GET",
    id: `getPartnerNetwork-memberList-${data.page}-${data.pageSize}-${data.id}`,
    url: `${servicePath}/participant/${data.id}`,
    params: {
      page: data.page,
      pageSize: data.pageSize,
    },
  });
};

export const uploadCoverImage = async (data: {
  image: File;
}): Promise<AxiosResponse> => {
  const formData = new FormData();

  try {
    const fileList = await compressFileList([data.image], 0.5, 1280);
    fileList.forEach((item: File | undefined) => {
      if (item) formData.append("image", item, encodeURIComponent(item.name));
    });
  } catch (error) {
    console.log("compressFileList error:", error);
  }

  return instance({
    method: "POST",
    url: `${servicePath}/image`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const createGroup = (data: ObjectKey): AxiosPromise => {
  removeApiCacheSession("getPartnerNetwork-");
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "POST",
    url: `${servicePath}/partnerNetworkGroup`,
    data,
  });
};

export const updateGroup = (data: {
  id: string;
  request: ObjectKey;
}): AxiosPromise => {
  removeApiCacheSession("getPartnerNetwork-");
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "PUT",
    url: `${servicePath}/partnerNetworkGroup/${data.id}`,
    data: data.request,
  });
};

export const joinGroup = (groupId: string): AxiosPromise => {
  removeApiCacheSession("getPartnerNetwork-");
  removeApiCacheSession("getSocialWallList-");
  removeApiCacheSession("getEventList-");
  return instance({
    method: "POST",
    url: `${servicePath}/join/${groupId}`,
  });
};

export const quitGroup = (groupId: string): AxiosPromise => {
  removeApiCacheSession("getPartnerNetwork-");
  removeApiCacheSession("getSocialWallList-");
  removeApiCacheSession("getEventList-");
  return instance({
    method: "POST",
    url: `${servicePath}/quit/${groupId}`,
  });
};

export const hideGroup = (data: {
  groupId: string;
  isHidden: boolean;
}): AxiosPromise => {
  removeApiCacheSession("getPartnerNetwork-");
  removeApiCacheSession("getSocialWallList-");
  removeApiCacheSession("getEventList-");
  return instance({
    method: "POST",
    url: `${servicePath}/hide/${data.groupId}`,
    params: {
      isHidden: data.isHidden,
    },
  });
};
