import React, { useState, useEffect } from "react";
import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./sections.module.scss";
import theme from "../../theme";
import { CustomSwiper, EventCardSkeleton } from "../../components";
import GroupItemCard from "../../components/group-item-card";
import { getGroupList } from "../../services/partner-network";
import { getApiDataByLang } from "../../utility";

type Props = {};

const PartnerNetworkSection = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    setIsLoading(true);
    const getList = async () => {
      try {
        const groupResponse = await getGroupList({ page: 1, pageSize: 3 });
        if (
          groupResponse.status === 200 &&
          groupResponse.data.result.length > 0
        ) {
          const result = groupResponse.data.result;
          setData(
            result.map((item: any) => {
              return {
                id: item._id,
                name: getApiDataByLang(item.groupName),
                imageUrl: item.coverImageUrl,
                members: item.memberList,
                memberCount: item.memberCount,
                totalPosts: item.postCount,
              };
            })
          );
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };
    getList();
  }, []);

  const getGroupSwiperData = (item: any) => {
    return (
      <GroupItemCard
        data={item}
        onDetailClick={() =>
          navigate(`/partner-network/detail/${item.content.id}`)
        }
      />
    );
  };

  return (
    <Box className={styles.sectionContainer}>
      <Box className={styles.sectionWrapper}>
        <Box className={styles.sectionHeader}>
          <Typography className={styles.sectionTitle} variant="body1">
            {t("partnerNetwork.title")}
          </Typography>
          <img
            src="/assets/images/chevron_right.svg"
            alt=""
            onClick={() => navigate("/partner-network")}
          />
        </Box>
        {data.length > 0 && (
          <Box>
            <CustomSwiper
              data={data}
              swiperId={`event-swiper`}
              content={getGroupSwiperData}
              slidesPerView={1.5}
              slidesPerGroup={1}
              spaceBetween={16}
              isAutoHeight={true}
            />
          </Box>
        )}
        {data.length === 0 && !isLoading && (
          <Box className={styles.noticeContainer}>
            <img src="/assets/images/event_logo.svg" alt="" />
            <Typography
              variant="body2"
              color={theme.vars.palette.black.opacity38}
            >
              No current group Stay tuned
            </Typography>
          </Box>
        )}

        {isLoading && <EventCardSkeleton size="small" />}
      </Box>
    </Box>
  );
};

export default PartnerNetworkSection;
