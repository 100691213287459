import { AxiosPromise } from "axios";
import { instance, searchInstance } from "./api-services";

const servicePath = "/homepage";

// get quick access home list api
export const getQuickAccessHomeList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getQuickAccess-homeList`,
    url: `${servicePath}/QuickAccess/homeList`,
  });

// get quick access list api
export const getQuickAccessList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getQuickAccess-quickAccessList`,
    url: `${servicePath}/QuickAccess/quickAccessList`,
  });

// get carousel banner list api
export const getCarouselBannerList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getCarouselBanner`,
    url: `${servicePath}/CarouselBanner/list`,
  });

// post green box api
export const createGreenBox = (data: {
  functionId: string;
  comment: string;
}): AxiosPromise =>
  instance({
    method: "POST",
    url: `${servicePath}/greenBoxLog/create`,
    data,
  });

export declare type Module =
  | "ART_WALK"
  | "ANNOUNCEMENTS"
  | "BREWING_INSTRUCTIONS"
  | "NEWSLETTERS"
  | "PARTNER_ANNIVERSARY"
  | "PARTNER_SAFETY_AND_GLOBAL_GUARDIAN"
  | "FAQS";

//get content hub summary api
export const getContentHubSummary = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getContentHub-summary`,
    url: `${servicePath}/ContentHub/summary`,
  });

//get content hub list api
export const getContentHubList = (params: {
  module: Module;
  search?: string;
}): AxiosPromise =>
  searchInstance({
    method: "GET",
    id: `getContentHub-${params.module}`,
    cache: params.search !== "" ? false : undefined,
    url: `${servicePath}/ContentHub/list`,
    params: params,
  });

//get content hub detail api
export const getContentHubDetail = (
  id: string,
  params: {
    module: Module;
  }
): AxiosPromise =>
  instance({
    method: "GET",
    id: `getContentHub-detail-${params.module}-${id}`,
    cache: { ttl: 30 * 1000 },
    url: `${servicePath}/ContentHub/${id}`,
    params: params,
  });

//get country list api
export const getCountryList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getStoreAndFinancial-countryList`,
    url: `${servicePath}/StoreAndFinancial/countryList`,
  });

//get store and financial list api
export const getStoreAndFinancialList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getStoreAndFinancial-data`,
    url: `${servicePath}/StoreAndFinancial/storeAndFinancialList`,
  });

//get chat config api
export const getChatConfig = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getChatConfig`,
    cache: { ttl: 30 * 1000 },
    url: `${servicePath}/chatConfig/config`,
  });
