import React, { useEffect, useState } from "react";
import SearchField from "../../components/search-field";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { groupData } from "../partnerNetwork/mockData";
import ListItem from "../../components/content-hub/list-item";
import { NoResults } from "../../components";
import { getGroupSearchList } from "../../services/partner-network";
import { getApiDataByLang } from "../../utility";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from "@mui/material";

type Props = {};

const PartnerNetworkSearch = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResult, setSearchResult] = useState<Array<any>>([]);
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 10,
    }
  );
  const [apiParams, setApiParams] = useState({
    search: searchParams.get("value")
      ? decodeURI(searchParams.get("value") as string)
      : "",
  });

  useEffect(() => {
    setIsLoading(true);
    setSearchResult([]);
    const getList = async () => {
      try {
        // console.log("groupData", groupData);
        const response = await getGroupSearchList({
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
          search: apiParams.search,
        });
        // console.log("response", response);
        if (response.status === 200 && response.data.result.length > 0) {
          const result = response.data.result;
          setSearchResult(
            result.map((item: any) => {
              return {
                id: item._id,
                title: getApiDataByLang(item.groupName),
                image: item.coverImageUrl,
                subtitle: `${item.memberCount}`,
                post: `${item.postCount}`,
              };
            })
          );
          if (
            apiPagination.page >= response.data.totalPage ||
            !response.data.totalPage
          ) {
            setIsEnded(true);
          } else {
            setApiPagination({ page: apiPagination.page + 1 });
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };
    getList();
  }, [apiParams]);

  async function fetchMoreData() {
    try {
      const response = await getGroupSearchList({
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
        search: apiParams.search,
      });
      // console.log("response", response);
      if (response.status === 200 && response.data.result.length > 0) {
        const result = response.data.result;
        const temp = result.map((item: any) => {
          return {
            id: item._id,
            title: getApiDataByLang(item.groupName),
            image: item.coverImageUrl,
            subtitle: `${item.memberCount}`,
            post: `${item.postCount}`,
          };
        });
        setSearchResult((prev: any) => [...prev, ...temp]);
        if (apiPagination.page >= response.data.totalPage) {
          setIsEnded(true);
        } else {
          setApiPagination({ page: apiPagination.page + 1 });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const { value } = e.target;
    // console.log("handleValueChange", value);
    // setSearchValue(value);
    setApiParams({ search: value });
    setApiPagination({ page: 1 });
    setIsEnded(false);
  };
  const handleResetField = () => {
    setIsLoading(true);
    setApiParams({ search: "" });
    setApiPagination({ page: 1 });
    setIsEnded(false);
  };
  const handleCloseChange = () => {
    navigate("/partner-network");
  };

  return (
    <>
      <SearchField
        id="search"
        name="search"
        placeholder={t("general.search")}
        value={apiParams.search}
        onChange={handleValueChange}
        onReset={handleResetField}
        onClose={handleCloseChange}
      />
      <div style={{ padding: "0 16px" }}>
        {searchResult.length === 0 ? (
          <NoResults resultsType="SEARCH" />
        ) : (
          <InfiniteScroll
            dataLength={searchResult.length}
            next={fetchMoreData}
            hasMore={!isEnded}
            loader={<Skeleton height={40} width="100%" />}
            scrollableTarget="scrollableDiv"
          >
            {searchResult.length > 0 &&
              searchResult.map((item, index) => {
                return (
                  <ListItem
                    {...item}
                    isGroup
                    key={`joined-${index}`}
                    onDetailClick={(id: any) => {
                      navigate(`/partner-network/detail/${id}`);
                    }}
                  />
                );
              })}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default PartnerNetworkSearch;
