import { Box, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import styles from "./checkbox.module.scss";
import theme from "../../theme";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";

interface ICheckboxProps {
  type?: string;
  text?: string;
  checked?: boolean;
  setChecked: (checked: boolean) => void;
  isClickable?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function CheckBoxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.5"
          y="2.5"
          width="19"
          height="19"
          rx="9.5"
          stroke="#BDBDBD"
        />
      </svg>
    </SvgIcon>
  );
}
function CheckBoxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="20" height="20" rx="10" fill="#006241" />
        <path
          d="M6.66699 12.4074L9.94904 15.6667L17.3337 8.33337"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

function CheckRadioIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="12" cy="12" r="9.5" stroke="#006241" />
        <circle cx="12" cy="12" r="7" fill="#006241" />
      </svg>
    </SvgIcon>
  );
}
function LikeBtnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 6.94038C10.4506 4.84336 7.44377 4.19528 5.18923 6.11552C2.93468 8.03576 2.61727 11.2463 4.38778 13.5174C5.85984 15.4056 10.3148 19.3881 11.7749 20.6771C11.9382 20.8213 12.0199 20.8934 12.1152 20.9217C12.1983 20.9464 12.2893 20.9464 12.3725 20.9217C12.4678 20.8934 12.5494 20.8213 12.7128 20.6771C14.1729 19.3881 18.6278 15.4056 20.0999 13.5174C21.8704 11.2463 21.5917 8.01556 19.2984 6.11552C17.0051 4.21548 14.0494 4.84336 12.25 6.94038Z"
          stroke="#6B6B6B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
function LikeBtnCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
          fill="#DFC49D"
        />
      </svg>
    </SvgIcon>
  );
}

export default function IconCheckboxes(props: ICheckboxProps) {
  const {
    type,
    text,
    checked,
    setChecked,
    isClickable = true,
    onChange,
  } = props;
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange && onChange(event);
  };
  return (
    <Box
      className={`${styles.checkboxWrapper} ${
        type === "likeBtn" ? styles.likeBtn : ""
      }`}
    >
      <Checkbox
        checked={checked}
        onChange={(value) => handleChange(value)}
        sx={{ padding: 0 }}
        {...(!isClickable && { style: { pointerEvents: "none" } })}
        icon={type === "likeBtn" ? <LikeBtnIcon /> : <CheckBoxIcon />}
        checkedIcon={
          type === "likeBtn" ? (
            <LikeBtnCheckedIcon />
          ) : type === "radioBtn" ? (
            <CheckRadioIcon />
          ) : (
            <CheckBoxCheckedIcon />
          )
        }
      />
      {type === "likeBtn" || text ? (
        <Typography variant="body1" sx={{ marginLeft: "4px" }}>
          {type === "likeBtn" ? t("socialWall.likes") : text}
        </Typography>
      ) : null}
    </Box>
  );
}
