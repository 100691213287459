const MuiTextField = {
  styleOverrides: {
    root: {},
    inputSizeSmall: {},
  },
  defaultProps: {
    fullWidth: true,
  },
};

export default MuiTextField;
