import { FC, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ActionItem, Checkbox, Popup, RoundButton } from '../../components';

import { ObjectKey } from '../../interfaces/common-interface';
import styles from './multi-filter.module.scss';

interface MultiFilterProps {
  id: string;
  open: boolean;
  selected: Array<FilterItemProps>;
  options: Array<OptionsListType>;
  disableResetButton?: boolean;
  onChange: (value: Array<FilterItemProps>) => void;
  onClose: () => void;
}

export interface OptionsListItemType {
  value: string;
  categoryId: string;
  id: string;
}

export interface OptionsListType {
  key: string;
  name: string;
  options: Array<optionItemProps>;
}

export interface optionItemProps {
  value: string;
  categoryId: string;
  displayValue: string;
}

export interface FilterItemProps {
  key: string;
  name?: string;
  selected: Array<optionItemProps>;
}

function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      width="16"
      height="16"
      style={{ width: 'auto', height: 'auto' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M4 4.5L12 12.5M12 4.5L4 12.5"
          stroke="#006241"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

const DialogHeader = (props: {
  id: string;
  title: string;
  buttonType: 'close' | 'arrow';
  disableResetButton?: boolean;
  onClick: () => void;
  onReset?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" className={styles.filterHeader}>
      <Grid item xs="auto">
        <IconButton
          id={props.id + 'CloseButton'}
          disableRipple
          className={styles.button}
          onClick={props.onClick}
        >
          {props.buttonType === 'arrow' ? (
            <img src="/assets/images/arrow_left.svg" alt="" />
          ) : (
            <img src="/assets/images/close_btn.svg" alt="" />
          )}
        </IconButton>
      </Grid>
      <Grid item xs>
        <Typography variant="h3">{props.title}</Typography>
      </Grid>
      {props.onReset && (
        <Grid item xs="auto">
          <Button
            id={props.id + 'ResetButton'}
            disableRipple
            disabled={props.disableResetButton}
            className={styles.button}
            onClick={props.onReset}
          >
            {t('general.reset')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const ApplyBtn = (props: { disabled?: boolean; onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <Box className={styles.buttonContainer}>
      <RoundButton disabled={props.disabled} onClick={props.onClick}>
        {t('staffDirectory.filter.apply')}
      </RoundButton>
    </Box>
  );
};

const MultiFilter: FC<MultiFilterProps> = (props) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] =
    useState<OptionsListType | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<Array<FilterItemProps>>(
    props.selected
  );

  useEffect(() => {
    setSelectedFilter(props.selected);
  }, [props.selected]);

  const handleResetFilter = () => {
    // console.log('filterPopupResetButton', props.selected);
    setSelectedFilter([]);
  };

  const handleAddFilter = (data: optionItemProps) => {
    if (!selectedCategory) return false;
    const { value, categoryId, displayValue } = data;

    const correctFilter = selectedFilter.find(
      (item: FilterItemProps) => item.key === selectedCategory.key
    );

    if (correctFilter) {
      const updateFilter = selectedFilter.filter(
        (item: FilterItemProps) => item.key !== selectedCategory.key
      );
      if (
        correctFilter.selected.find(
          (item: optionItemProps) => item.value === value
        )
      ) {
        const updateFilterItem = correctFilter.selected.filter(
          (item: optionItemProps) => item.value !== value
        );
        setSelectedFilter([
          ...updateFilter,
          {
            key: selectedCategory.key,
            selected: updateFilterItem,
          },
        ]);
      } else {
        setSelectedFilter([
          ...updateFilter,
          {
            key: selectedCategory.key,
            selected: [...correctFilter.selected, data],
          },
        ]);
      }
    } else {
      setSelectedFilter((prev) => [
        ...prev,
        {
          key: selectedCategory.key,
          selected: [data],
        },
      ]);
    }
  };

  const handleDeleteFilter = (data: optionItemProps) => {
    const { value, categoryId, displayValue } = data;

    const correctFilter = selectedFilter.find(
      (item: FilterItemProps) => item.key === categoryId
    );
    const updateFilter = selectedFilter.filter(
      (item: FilterItemProps) => item.key !== categoryId
    );
    const updateFilterItem = correctFilter?.selected.filter(
      (item: optionItemProps) => item.value !== value
    );
    if (updateFilter && updateFilterItem) {
      if (updateFilterItem.length <= 0) {
        setSelectedFilter(updateFilter);
      } else {
        setSelectedFilter([
          ...updateFilter,
          {
            key: categoryId,
            selected: updateFilterItem,
          },
        ]);
      }
    }
  };

  const DisplaySelectedFilter = () => {
    if (selectedFilter.length <= 0) return null;

    let selected: optionItemProps[] = [];

    selectedFilter.forEach((item: FilterItemProps) => {
      if (item.selected.length > 0) {
        item.selected.forEach((option: optionItemProps) => {
          selected.push(option);
        });
      }
    });

    if (selected.length <= 0) return null;

    return (
      <Stack direction="row" className={styles.filterTagContainer}>
        {selected.map((item: optionItemProps, index: number) => (
          <Chip
            key={index}
            label={item.displayValue}
            onDelete={() => handleDeleteFilter(item)}
            deleteIcon={<DeleteIcon />}
            className={styles.filterTag}
          />
        ))}
      </Stack>
    );
  };

  const FilterOption = (props: {
    category: string;
    data: optionItemProps;
    onChange: (data: optionItemProps) => void;
  }) => {
    const correctSelectedCategory =
      selectedFilter.length > 0 &&
      selectedFilter.find((item) => item.key === selectedCategory?.key)
        ? selectedFilter.find((item) => item.key === selectedCategory?.key)
        : null;
    const [isChecked, setIsChecked] = useState<boolean>(
      // filterList.includes(props.value)
      correctSelectedCategory &&
        correctSelectedCategory.selected.find(
          (item) => item.value === props.data.value
        )
        ? true
        : false
    );

    return (
      <ListItem disablePadding>
        <ListItemButton
          className={styles.filterOption}
          onClick={() => {
            setIsChecked((prev) => !prev);
            handleAddFilter(props.data);
          }}
        >
          <ListItemText
            primary={props.data.displayValue}
            className={styles.filterOptionName}
          />
          <ListItemIcon>
            <Checkbox checked={isChecked} setChecked={(checked) => false} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    );
  };

  const handleApplyFilterTag = () => {
    setSelectedCategory(null);
    props.onChange(selectedFilter);
  };

  const handleSelectCategory = (key: string) => {
    const correctSelectedCategory = props.options.find(
      (item) => item.key === key
    );
    if (correctSelectedCategory) {
      setSelectedCategory(correctSelectedCategory);
    }
  };

  return (
    <>
      <Popup
        id={props.id}
        isOpen={props.open}
        title={
          <DialogHeader
            id={props.id}
            title={t('staffDirectory.filter.title')}
            buttonType="close"
            disableResetButton={
              props.selected.length <= 0 && selectedFilter.length <= 0
            }
            onClick={() => {
              setSelectedCategory(null);
              setSelectedFilter(props.selected);
              props.onClose();
            }}
            onReset={props.disableResetButton ? undefined : handleResetFilter}
          />
        }
        content={
          <Stack className={styles.filterContainer}>
            <DisplaySelectedFilter />
            <Grid item xs className={styles.filterList}>
              <List className={styles.listWrapper}>
                {props.options.map((item: OptionsListType, index: number) => {
                  return (
                    <ListItem key={index} sx={{ padding: '0px' }}>
                      <ActionItem
                        text={item.name}
                        endIcon="/assets/images/chevron_right.svg"
                        onClick={() => handleSelectCategory(item.key)}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <ApplyBtn
              disabled={
                props.selected.length <= 0 && selectedFilter.length <= 0
              }
              onClick={handleApplyFilterTag}
            />
          </Stack>
        }
        disableActions
        fullScreen
        setIsOpen={(isClose) => props.onClose()}
      />

      {props.open && (
        <Popup
          id={props.id + '-' + selectedCategory?.key}
          isOpen={Boolean(selectedCategory)}
          title={
            <DialogHeader
              id={props.id}
              title={selectedCategory ? selectedCategory.name : ''}
              buttonType="arrow"
              disableResetButton={
                props.selected.length <= 0 && selectedFilter.length <= 0
              }
              onClick={() => setSelectedCategory(null)}
              onReset={props.disableResetButton ? undefined : handleResetFilter}
            />
          }
          content={
            <Stack className={styles.filterContainer}>
              <DisplaySelectedFilter />
              <Grid item xs className={styles.filterList}>
                {selectedCategory && (
                  <List className={styles.listWrapper}>
                    {selectedCategory.options.map(
                      (item: optionItemProps, index: number) => {
                        return (
                          <FilterOption
                            key={index}
                            category={item.categoryId}
                            data={item}
                            onChange={handleAddFilter}
                          />
                        );
                      }
                    )}
                  </List>
                )}
              </Grid>
              <ApplyBtn onClick={handleApplyFilterTag} />
            </Stack>
          }
          disableActions
          fullScreen
          setIsOpen={(isClose) => setSelectedCategory(null)}
        />
      )}
    </>
  );
};

export default MultiFilter;
