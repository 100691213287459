import React, { FC, useEffect, useState, useRef, useCallback } from "react";
import styles from "./survey.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTab, NoResults } from "../../components";
import { useTranslation } from "react-i18next";
import { convertPeriod, getApiDataByLang } from "../../utility";
import { SurveyListRequestType, getSurveyList } from "../../services/survey";
import InfiniteScroll from "react-infinite-scroll-component";
interface surveyDetailProps {
  lang?: string;
  value: string;
  _id: string;
}
interface questionsProps {
  questionType: string;
  question: string;
  answerChoices: Array<surveyDetailProps>;
  isRequired: boolean;
  isMultipleSelection: boolean;
  multipleSelection: string;
  answerNumbers: NumberConstructor;
  _id: string;
  orderAnswers: Array<surveyDetailProps>;
}
interface displayListType {
  _id: string;
  status: string;
  displayLanguage: string;
  surveyTitle: Array<surveyDetailProps>;
  surveyDescription: Array<surveyDetailProps>;
  respondents: string;
  anonymousResponses: boolean;
  questions: Array<questionsProps>;
  surveyStartDate: number;
  surveyEndDate: number;
  isDeleted: string;
  lastUpdateBy: string;
  lastUpdateTime: number;
}

function SurveyRecentSurvey() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabData = [
    { value: "Active", display: t("survey.active") },
    { value: "Scheduled", display: t("survey.scheduled") },
    { value: "Completed", display: t("survey.completed") },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<string>(tabData[0].value);
  const [surveyList, setSurveyList] = useState<Array<displayListType>>([]);
  const [displayList, setDisplayList] = useState<Array<displayListType>>([]);
  const [isSnackbarShow, setSnackbarShow] = useState(false);
  //for infinite scroll
  const [hasMore, setHasMore] = useState(true);
  const [listParams, setListParams] = useState<SurveyListRequestType>({
    page: 1,
    pageSize: 20,
    tab: activeTab.toLowerCase(),
  });

  // useEffect(() => {
  //   setIsLoading(true);
  //   getSurveyList(listParams).then((response) => {
  //     setSurveyList(response.data.survey);
  //     setActiveTab(tabData[0].value);
  //     setIsLoading(false);
  //   });
  // }, []);

  useEffect(() => {
    setDisplayList([]);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      tab: activeTab.toLowerCase(),
    }));
  }, [activeTab]);

  const getList = useCallback(async () => {
    let active = true;
    setIsLoading(true);
    setHasMore(true);
    try {
      const response = await getSurveyList(listParams);

      // const response = await getReportedPostList(listParams);
      if (response?.status === 200 && active) {
        setDisplayList((prev) => [...prev, ...response.data.survey]);
        setIsLoading(false);

        if (response.data.totalPage === listParams.page) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setIsLoading(false);
        setHasMore(false);
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  useEffect(() => {
    getList();
  }, [getList]);

  function handleChangeTab(active: string) {
    setDisplayList([]);
    setActiveTab(active);
  }

  function copyLink(id: string) {
    setSnackbarShow(true);
    let currentDomain = window.location.origin;
    navigator.clipboard.writeText(`${currentDomain}/survey/form/${id}`);
  }

  function handleCloseMsgBar() {
    setSnackbarShow(false);
  }

  const campaignItem = (item: any) => (
    <Stack direction="row" className={styles.surveyItemContainer}>
      <Stack
        direction="row"
        gap="16px"
        className={styles.surveyItemWrapper}
        onClick={() => navigate(`edit/${item._id}`)}
      >
        <img
          src={item?.thumbUrl
            ?.replace("_thumb.png", ".png")
            ?.replace("_thumb.jpeg", ".jpeg")}
        ></img>
        <Stack gap="4px">
          <Typography variant="body1" className={styles.surveyItemTitle}>
            {getApiDataByLang(item.surveyTitle)}
          </Typography>
          <Typography variant="body2" className={styles.surveyItemDate}>
            {convertPeriod(item.surveyStartDate, item.surveyEndDate)}
          </Typography>
        </Stack>
      </Stack>
      {activeTab === "Active" && (
        <Typography
          variant="body2"
          className={styles.copyLinkBtn}
          onClick={() => copyLink(item._id)}
        >
          {t("survey.copyLink")}
        </Typography>
      )}{" "}
    </Stack>
  );
  return (
    <Stack gap="8px" className={styles.sectionContainer}>
      <Typography variant="h5" className={styles.sectionHeader}>
        {t("survey.recent")}
      </Typography>
      <Box className={styles.tabWrapper}>
        <CustomTab
          data={tabData}
          activeTab={activeTab}
          onTabClick={handleChangeTab}
        />
      </Box>

      <Box className={styles.surveyContainer}>
        {!isLoading && displayList.length === 0 && <NoResults />}
        {displayList.length > 0 && (
          <InfiniteScroll
            dataLength={displayList.length} //This is important field to render the next data
            next={nextPage}
            hasMore={hasMore}
            loader={
              <Box sx={{ padding: 2 }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height={40}
                />
              </Box>
            }
            scrollableTarget="surveyContainer"
          >
            {displayList.map((item, index) => {
              // console.log("campaignItem", item);
              return (
                <React.Fragment key={index}>
                  {campaignItem(item)}
                </React.Fragment>
              );
            })}
          </InfiniteScroll>
        )}
      </Box>
      {isSnackbarShow && (
        <Snackbar
          open={isSnackbarShow}
          autoHideDuration={6000}
          onClose={handleCloseMsgBar}
        >
          <Alert severity="success">{t("survey.copyLinkMsg")}</Alert>
        </Snackbar>
      )}
    </Stack>
  );
}

export default SurveyRecentSurvey;
