import moment from "moment";
import { useState, useEffect } from "react";
import {
  GreenApronCardDetailProps,
  SendCardRequestType,
} from "../../interfaces/green-apron-card-interface";

interface ReturnFunctionType {
  isDirty: boolean;
  formValue: FormValueType;
  // eslint-disable-next-line
  handleInputFieldChange: (name: string, value: any) => void;
  // eslint-disable-next-line
  handleTextareaFieldChange: (name: string, value: any, limit?: number) => void;
  handleSendDateChange: (value: string) => void;
  handleToggleButtonChange: (name: string, checked: boolean) => void;
  checkValidation: () => boolean;
  convertResponseToFormValue: (
    data: GreenApronCardDetailProps,
    callback?: (value: FormValueType) => void
  ) => void;
  convertFormValueToRequest: () => SendCardRequestType;
  setInitFormValue: (data: FormValueType, isRecoverData?: boolean) => void;
  cleanFormValue: () => void;
}

interface FormValueSettingType {
  // eslint-disable-next-line
  value: any;
  isError: boolean;
  isRequired: boolean;
}

export interface FormValueType {
  [key: string]: FormValueSettingType;
}

export const useGreenCardFormHook = (): ReturnFunctionType => {
  const nowDate = moment();
  const roundTime = 30 - (nowDate.minute() % 30);
  const roundedStartDate = nowDate.add(roundTime, "minutes").format();
  const [isDirty, setIsDirty] = useState(false);
  const [formValue, setFormValue] = useState<FormValueType>({
    id: {
      value: "",
      isError: false,
      isRequired: false,
    },
    card: {
      value: "",
      isError: false,
      isRequired: true,
    },
    content: {
      value: "",
      isError: false,
      isRequired: true,
    },
    receiver: {
      value: null,
      isError: false,
      isRequired: true,
    },
    sender: {
      value: null,
      isError: false,
      isRequired: true,
    },
    sendDate: {
      value: "",
      isError: false,
      isRequired: true,
    },
    sendDateSchedule: {
      value: roundedStartDate,
      isError: false,
      isRequired: false,
    },
    sendViaEmail: {
      value: false,
      isError: false,
      isRequired: false,
    },
    email: {
      value: "",
      isError: false,
      isRequired: false,
    },
  });

  // useEffect(() => {
  //   console.log("formValue", formValue);
  //   console.log("isDirty", isDirty);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formValue]);

  // eslint-disable-next-line
  const handleInputFieldChange = (name: string, value: any) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
      },
    };

    if (formValue[formName].isRequired) {
      let inValid = value === "" || !value;

      updateFormValue = {
        [formName]: {
          ...updateFormValue[formName],
          isError: inValid,
        },
      };
    }

    if (formName === "sendDate") {
      updateFormValue = {
        ...updateFormValue,
        ["sendDateSchedule" as keyof typeof formValue]: {
          ...formValue.sendDateSchedule,
          isRequired: value.value === "NOT_NOW",
        },
      };
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  // eslint-disable-next-line
  const handleTextareaFieldChange = (
    name: string,
    value: any,
    limit?: number
  ) => {
    const formName = name as keyof typeof formValue;
    let inValid = formValue[formName].isRequired
      ? value === "" || !value
      : false;

    if (!inValid && limit) {
      inValid = value.length > limit;
    }

    const updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
        isError: inValid,
      },
    };

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleSendDateChange = (value: string) => {
    setFormValue((prev) => ({
      ...prev,
      sendDateSchedule: { ...prev.sendDateSchedule, value: value },
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleToggleButtonChange = (name: string, checked: boolean) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: checked,
      },
    };

    if (formName === "sendViaEmail") {
      updateFormValue = {
        ...updateFormValue,
        ["email" as keyof typeof formValue]: {
          ...formValue.email,
          isRequired: checked,
        },
      };
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const checkValidation = () => {
    const requiredValue = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].isRequired)
    );

    let errorField: Array<string> = [];
    for (const [key, value] of Object.entries(requiredValue)) {
      if (!value.value) {
        errorField.push(key);
      } else if (Array.isArray(value.value)) {
        if (value.value.length <= 0) {
          errorField.push(key);
        }
      } else if (typeof value.value === "object") {
        if (!value.value || Object.keys(value.value).length <= 0) {
          errorField.push(key);
        }
      }
    }

    if (errorField.length > 0) {
      let errorFieldObj: FormValueType = formValue;
      errorField.forEach((key: string) => {
        errorFieldObj = {
          ...errorFieldObj,
          [key]: {
            ...errorFieldObj[key],
            isError: true,
          },
        };
      });
      setFormValue(errorFieldObj);
      return false;
    }

    return true;
  };

  const convertResponseToFormValue = (
    data: GreenApronCardDetailProps,
    callback?: (value: FormValueType) => void
  ) => {
    const currentCardFormValue: FormValueType = {
      id: {
        ...formValue.id,
        value: data.id,
      },
      card: {
        ...formValue.card,
        value: data.cardInfo.id,
      },
      content: {
        ...formValue.content,
        value: data.description,
      },
      receiver: { ...formValue.receiver, value: data.receiver },
      sender: { ...formValue.sender, value: data.sender },
      sendDate: { ...formValue.sendDate, value: data.sendDate },
      sendDateSchedule: {
        ...formValue.sendDateSchedule,
        value: moment.unix(data.sendDate).format(),
      },
      sendViaEmail: {
        ...formValue.sendViaEmail,
        value: data.receiverEmails ? data.receiverEmails.length > 0 : false,
      },
      email: {
        ...formValue.email,
        value: data.receiverEmails,
      },
    };
    setIsDirty(true);

    if (callback) {
      callback(currentCardFormValue);
    } else {
      setInitFormValue(currentCardFormValue);
    }
  };

  const convertFormValueToRequest = (): SendCardRequestType => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;
    // console.log('getFieldValue("receiver")', [
    //   ...getFieldValue("receiver").map((item: any) => item.id),
    // ]);
    const date =
      getFieldValue("sendDate").id === "NOW"
        ? moment().format()
        : getFieldValue("sendDateSchedule");
    return {
      cardLibraryId: getFieldValue("card"),
      senderMessage: getFieldValue("content"),
      receiverIds:
        getFieldValue("receiver").length > 0
          ? [...getFieldValue("receiver").map((item: any) => item.id)]
          : [],
      sendTime: moment(date).unix(),
      isSendNow: getFieldValue("sendDate").id === "NOW",
      receiverEmails: getFieldValue("email") ? [...getFieldValue("email")] : [],
    };
  };

  const setInitFormValue = (data: FormValueType, isRecoverData?: boolean) => {
    setFormValue((prev) => ({
      ...prev,
      ...data,
    }));

    if (isRecoverData !== undefined && !isDirty) {
      setIsDirty(true);
    }
  };

  const cleanFormValue = () => {
    setFormValue({
      id: {
        value: "",
        isError: false,
        isRequired: false,
      },
      card: {
        value: "",
        isError: false,
        isRequired: true,
      },
      content: {
        value: "",
        isError: false,
        isRequired: true,
      },
      receiver: {
        value: null,
        isError: false,
        isRequired: true,
      },
      sender: {
        value: null,
        isError: false,
        isRequired: true,
      },
      sendDate: {
        value: "",
        isError: false,
        isRequired: true,
      },
      sendDateSchedule: {
        value: roundedStartDate,
        isError: false,
        isRequired: false,
      },
      sendViaEmail: {
        value: false,
        isError: false,
        isRequired: false,
      },
      email: {
        value: "",
        isError: false,
        isRequired: false,
      },
    });
  };

  return {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    handleSendDateChange,
    handleToggleButtonChange,
    checkValidation,
    convertResponseToFormValue,
    convertFormValueToRequest,
    setInitFormValue,
    cleanFormValue,
  };
};
