import React from "react";
import { Popup } from "../../../components";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen: (toOpen: boolean) => void;
  onClickConfirm: () => void;
  title: string;
  sub: string;
  confirmBtnText: string;
};

const GroupActionPopup = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Popup
      id={"partner-network-action"}
      isOpen={props.isOpen}
      setIsOpen={(close: boolean) => props.setIsOpen(close)}
      title={<Typography variant="h4">{props.title}</Typography>}
      content={<div style={{ padding: "16px" }}>{props.sub}</div>}
      confirmBtnText={props.confirmBtnText}
      onClickConfirm={() => {
        props.setIsOpen(false);
        props.onClickConfirm();
      }}
    />
  );
};

export default GroupActionPopup;
