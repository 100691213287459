import React, { FC, useContext, useEffect, useRef, useState } from "react";
import styles from "./greenApronCard.module.scss";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import Header from "../../components/header/header";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  GreenApronCardProps,
  CardListType,
} from "../../interfaces/green-apron-card-interface";
import GreenApronCardContext from "./greenApronCardProvider";

const GreenApronCardSelection: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, selectedCategory, setSelectedCategory, setSelectedId } =
    useContext(GreenApronCardContext);
  const listRef = useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const LoadingSkeleton = () => {
    return (
      <Box className={styles.sectionContainer}>
        <Typography variant="h5" className={styles.sectionTitle}>
          <Skeleton variant="text" width={160} />
        </Typography>
        <Grid container className={styles.sectionList}>
          {Array.from(Array(3), (_, index) => (
            <Grid key={index} item xs={4}>
              <Skeleton
                variant="rounded"
                height="auto"
                className={styles.sentItemImageSkeleton}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const ListItem = (props: {
    card: GreenApronCardProps;
    categoryId: string;
  }) => {
    const { card } = props;
    const handleClickCard = (id: string) => {
      if (selectedCategory !== props.categoryId) {
        setSelectedCategory(props.categoryId);
        setActiveSection(props.categoryId);
      }
      setSelectedId(id);
      navigate(`/green-apron-card/send`);
    };

    return (
      <Box
        id={`card-${card.id}`}
        className={styles.receivedItem}
        onClick={() => handleClickCard(card.id)}
      >
        <Box className={styles.receivedItemImage}>
          <img src={card.url} alt={card.name} />
        </Box>
      </Box>
    );
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = event.currentTarget;

    for (let i = 0; i < list.length; i++) {
      const sectionId = list[i].id;
      const section = document.getElementById("category_" + sectionId);
      if (section) {
        const { offsetTop, offsetHeight } = section;
        if (scrollTop < offsetTop + offsetHeight / 2) {
          setActiveSection(sectionId);
          return false;
        }
      }
    }
  };

  const handleTabClick = (sectionId: string) => {
    const listSection = document.getElementById("category_" + sectionId);
    if (listSection) {
      listSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (list.length > 0) {
      let selectedCategoryId = list[0].id;
      if (selectedCategory) {
        selectedCategoryId = selectedCategory;
      }
      setActiveSection(selectedCategoryId);
      setIsLoading(false);

      document
        .getElementById("category_" + selectedCategoryId)
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [list, selectedCategory]);

  return (
    <>
      <Header
        enableCloseButton
        disableBottomBorder
        title={t("greenApronCard.selectCard")}
        closeButtonNavigation="/green-apron-card"
      />
      <Grid container wrap="nowrap" className={styles.tabsList}>
        {list.map((section: CardListType, index: number) => (
          <Grid
            key={index}
            item
            xs="auto"
            id={`${section.id}_Tab`}
            className={`${styles.tabs} ${
              activeSection === section.id ? "active" : ""
            }`}
            onClick={() => handleTabClick(section.id)}
          >
            {section.name}
          </Grid>
        ))}
      </Grid>
      <Grid
        ref={listRef}
        item
        xs
        className={styles.sentAndReceivedContainer}
        onScroll={handleScroll}
      >
        {isLoading && <LoadingSkeleton />}
        {list.map((section: CardListType) => (
          <Box
            key={section.id}
            id={"category_" + section.id}
            className={styles.sectionContainer}
          >
            <Typography variant="h5" className={styles.sectionTitle}>
              {section.name}
            </Typography>
            <Grid container className={styles.sectionList}>
              {section.data.map((card: GreenApronCardProps, index: number) => (
                <Grid key={index} item xs={4}>
                  <ListItem card={card} categoryId={section.id} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default GreenApronCardSelection;
