import { AxiosPromise } from "axios";
import { instance } from "./api-services";
// import { ObjectKey } from "../interfaces/common-interface";

const servicePath = "/cherries-and-ecosystem";

// export const getLeaderboardInfo = (): AxiosPromise =>
//   instance({
//     method: "GET",
//     url: `${servicePath}/info/`,
//   });

export const getPartnerCherriesSummary = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getPartnerCherriesSummary",
    cache: false,
    url: `${servicePath}/summary/`,
  });

export const getCoffeeCherriesMissionsList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getCoffeeCherriesMissionsList",
    url: `${servicePath}/missions/`,
  });
export const getRankingRewardsList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getRankingRewardsList",
    url: `${servicePath}/liveEvent/`,
  });

export const getRankingRewardsDetails = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getRankingRewardsList-details-${id}`,
    url: `${servicePath}/liveEvent/${id}`,
  });

export const getBasket = (params?: {
  page?: number;
  pageSize?: number;
}): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    url: `${servicePath}/basket/`,
    params: params,
  });

export const getPastWinnersPeriods = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getPastWinnersPeriods",
    url: `${servicePath}/pastWinner/period/`,
  });

export const getPastWinnersList = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getPastWinnersList-${id}`,
    url: `${servicePath}/pastWinner/${id}`,
  });

export const getMyRewardsList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getMyRewardsList",
    url: `${servicePath}/myRewards/?page=1&pageSize=20&sort=desc&by=lastUpdateBy`,
  });

export const getMyRewardsDetail = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getMyRewardsList-details-${id}`,
    url: `${servicePath}/myReward/${id}`,
  });

export const postCollectMyRewards = (id: string): AxiosPromise =>
  instance({
    method: "POST",
    url: `${servicePath}/myReward/${id}`,
  });

export const getRankingList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getRankingList`,
    cache: { ttl: 10 * 1000 },
    url: `${servicePath}/ranking`,
  });

export const getDistrictRankingList = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getDistrictRankingList-${id}`,
    cache: { ttl: 10 * 1000 },
    url: `${servicePath}/ranking/district/${id}`,
  });

export const getSupportCenterRankingList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getSupportCenterRankingList`,
    cache: { ttl: 10 * 1000 },
    url: `${servicePath}/ranking/supportCentre`,
  });
