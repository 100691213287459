const MuiDateCalendar = {
  styleOverrides: {
    root: {
      width: "100%",
      maxHeight: "none",
      padding: 16,
    },
    viewTransitionContainer: {},
  },
};

export default MuiDateCalendar;
