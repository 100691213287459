import SbChannel from "@sendbird/uikit-react/Channel";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { useNavigateOnBan } from "./hooks/useNavigateOnBan";

type Props = {};

export function Channel() {
  const { channelUrl } = useParams();
  const [searchParams] = useSearchParams();
  const messageId = searchParams.get("messageId");
  const createdAt = searchParams.get("createdAt");
  const numCreatedAt = Number(createdAt);
  const navigate = useNavigate();
  useNavigateOnBan();
  return (
    <SbChannel
      channelUrl={channelUrl as string}
      onBackClick={() => {
        navigate("/chat");
      }}
      onSearchClick={() => {
        navigate(`/chat/${channelUrl}/search`);
      }}
      {...(messageId &&
        typeof numCreatedAt === "number" && {
          startingPoint: numCreatedAt,
          highlightedMessageId: messageId,
          animatedMessageId: messageId,
        })}
      showSearchIcon
      onChatHeaderActionClick={() => {
        navigate(`/chat/${channelUrl}/settings`);
      }}
    />
  );
}
