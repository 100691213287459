// import { createTheme } from "@mui/material/styles";
import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
import overridesComponents from "./overrides";
export interface PaletteBaseColor {
  [id: string]: string;
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground extends PaletteBaseColor {}

  interface Palette {
    gradient: PaletteBaseColor;
    green: PaletteBaseColor;
    gold: PaletteBaseColor;
    black: PaletteBaseColor;
    white: PaletteBaseColor;
    customGrey: PaletteBaseColor;
    msTeam: PaletteBaseColor;
  }
  interface PaletteOptions {
    gradient: PaletteBaseColor;
    green: PaletteBaseColor;
    gold: PaletteBaseColor;
    black: PaletteBaseColor;
    white: PaletteBaseColor;
    customGrey: PaletteBaseColor;
    msTeam?: PaletteBaseColor;
  }
}

const white = "#ffffff";
const black = "#212121";
const theme = extendTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        gradient: {
          green:
            "linear-gradient(105.68deg, #2E6F54 1.95%, #315B4F 26.54%, #1C5D47 49.28%, #0E584A 70.16%, #225047 91.04%)",
        },
        green: {
          house: "#1E3932",
          starbucks: "#006241",
          accent: "#00754A",
          accentOpacity50: "rgba(0, 117, 74, 0.5)", // #00754A
          darkApron: "#008248",
          apron: "#00A862",
          light: "#D4E9E2",
        },
        gold: {
          rewards: "#CBA256",
          rewards90white: "#FAF6EE",
          rewards30black: "#8E713E",
          light: "#DFC49D",
          urobilin: "#E5B319",
        },
        black: {
          opacity06: "#F0F0F0",
          opacity04: "rgba(0, 0, 0, 0.04)",
          opacity05: "rgba(0, 0, 0, 0.05)",
          opacity08: "rgba(0, 0, 0, 0.08)",
          opacity12: "rgba(0, 0, 0, 0.12)",
          opacity20: "rgba(0, 0, 0, 0.20)",
          opacity26: "rgba(0, 0, 0, 0.26)",
          opacity30: "rgba(0, 0, 0, 0.30)",
          opacity38: "rgba(0, 0, 0, 0.38)",
          opacity58: "rgba(0, 0, 0, 0.58)",
          opacity70: "rgba(0, 0, 0, 0.70)",
          opacity87: "rgba(0, 0, 0, 0.87)",
          opacity100: "rgba(0, 0, 0, 1)",
          black026: "#BDBDBD",
          black038: "#9E9E9E",
          black058: "#6B6B6B",
          black087: "#212121",
        },
        white: {
          opacity12: "rgba(255, 255 ,255, 0.12)",
          opacity30: "rgba(255, 255 ,255, 0.30)",
          opacity50: "rgba(255, 255 ,255, 0.50)",
          opacity70: "rgba(255, 255 ,255, 0.70)",
          opacity90: "rgba(255, 255 ,255, 0.90)",
          opacity100: "rgba(255, 255 ,255, 1)",
        },
        customGrey: {
          natural: "#F9F9F9",
          ceramic: "#EDEBE9",
          warm: "#F2F0EB",
          seashell: "#F0F0F0",
          cornsilk: "#FFF6D6",
          champagne: "#F3E4CA",
        },
        msTeam: {
          main: "#7B83EB",
        },
        error: {
          main: "#C82014",
        },
        warning: {
          main: "#FBBC05",
        },
        primary: {
          main: "#006241",
        },
        secondary: {
          main: "#008248",
        },
        background: {
          default: white,
          greenLinearGradient128deg:
            "linear-gradient(128.1deg, #2E6F54 2.43%, #315B4F 28.32%, #1C5D47 52.27%, #0E584A 74.26%, #225047 96.24%)",
          greenLinearGradient106deg:
            "linear-gradient(105.68deg, #2E6F54 1.95%, #3B6B5D 16.79%, #116249 34.09%, #1C5D47 49.28%, #0E584A 70.16%, #225047 91.04%)",
        },
        text: {
          primary: black,
        },
        divider: "#F9F9F9",
        Alert: {
          successColor: "#006241",
          successStandardBg: "#D4E9E2",
          errorColor: "#C82014",
          errorStandardBg: "#FFE6E6",
          warningColor: "#F59300",
          warningStandardBg: "#FFF7E1",
        },
      },
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 5,
  },
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: "43.2px",
    },
    h2: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: "36.26px",
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "24px",
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "22px",
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "18px",
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "15.54px",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "19.2px",
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "19.2px",
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16.8px",
      color: "#212121",
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "15.54px",
    },
    button: {
      fontSize: 15,
      fontWeight: 600,
      lineHeight: "18px",
      textTransform: "none",
    },
    caption: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: "12.95px",
    },
    fontFamily: `"Open Sans", -apple-system, "微軟正黑體", "Microsoft JhengHei", Helvetica, Arial, Sans-Serif`,
    fontSize: 14,
  },
  components: {
    ...overridesComponents,
  },
});

export default theme;
