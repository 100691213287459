import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { ActionItemList, Popup } from "../../../components";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen: (toOpen: boolean) => void;
  option: Array<any>;
  removeTitle?: boolean;
};

const MorePopup = (props: Props) => {
  const { removeTitle = false } = props;
  const { t } = useTranslation();

  return (
    <Popup
      id={"partner-network-more"}
      isOpen={props.isOpen}
      setIsOpen={(close: boolean) => props.setIsOpen(close)}
      title={
        <Grid
          container
          alignItems="center"
          {...(removeTitle && { justifyContent: "flex-end" })}
        >
          {!removeTitle && (
            <Grid item xs>
              <Typography variant="h4" color="text.primary">
                {t("homepage.more")}
              </Typography>
            </Grid>
          )}
          <Grid item xs="auto">
            <IconButton
              disableRipple
              size="small"
              onClick={() => props.setIsOpen(false)}
            >
              <img src="/assets/images/close_btn.svg" alt="" />
            </IconButton>
          </Grid>
        </Grid>
      }
      content={<ActionItemList data={props.option} />}
      disableActions={true}
    />
  );
};

export default MorePopup;
