import React from "react";
import styles from "./content-hub.module.scss";
import { listItemType } from "./interface";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box } from "@mui/material";
import toHtml from "html-react-parser";

type Props = {};

const FaqItem = (props: listItemType) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Accordion
      expanded={expanded === `list-item-${props.id}`}
      onChange={handleChange(`list-item-${props.id}`)}
      className={styles.faqItem}
    >
      <AccordionSummary
        aria-controls={`list-item-${props.id}-content`}
        id={`list-item-${props.id}`}
      >
        <Box>
          <label
            className={styles.categoryLabel}
            style={{ backgroundColor: props.color }}
          >
            {props.tab}
          </label>
          <span>{props.title}</span>
        </Box>
        <img src="/assets/images/chevron_down.svg" alt="faq arrow" />
      </AccordionSummary>
      {props.content && (
        <AccordionDetails>{toHtml(props.content)}</AccordionDetails>
      )}
    </Accordion>
  );
};

export default FaqItem;
