import { FC, memo } from "react";
import styles from "./files-media-popup.module.scss";
import { Popup } from "..";
import { File } from "../../pages/files/makeData";

interface FilesMediaPopupProps {
  open: boolean;
  file: PopupProps;
  onClose: (close?: boolean) => void;
  onClickOptions?: (detail: File) => void;
  disableFileName?: boolean;
}

export type PopupProps = {
  type: string;
  content: any;
  folder?: folderDetailType | null;
};

export type folderDetailType = {
  _id: string;
  name: string;
  parentFolderId: string;
  isDeleted: boolean;
  lastUpdateBy: string;
  lastUpdateTime: Date;
  createBy: string;
  createTime: Date;
  path: string;
};

const FilesMediaPopup: FC<FilesMediaPopupProps> = (props) => {
  // console.log("FilesMediaPopup", props);
  const { disableFileName = false } = props;
  return (
    <Popup
      id={styles.mediaPopup}
      isOpen={props.open}
      setIsOpen={(close: boolean) => props.onClose(close)}
      title={
        <MediaPopupTitle
          file={props.file}
          onClickMediaClose={() => props.onClose()}
          onClickMediaMore={props.onClickOptions}
          disableFileName={disableFileName}
        />
      }
      content={<MediaPopupContent file={props.file} />}
      disableActions={true}
      fullScreen={true}
    />
  );
};

const MediaPopupTitle = memo(
  (props: {
    file: PopupProps;
    onClickMediaClose: () => void;
    onClickMediaMore?: (detail: File) => void;
    disableFileName?: boolean;
  }) => {
    const {
      file,
      onClickMediaClose,
      onClickMediaMore,
      disableFileName = false,
    } = props;
    return (
      <div className={styles.mediaHeader}>
        <div className={styles.mediaTitle}>
          <button type="button" onClick={onClickMediaClose}>
            <img src={`/assets/images/files/media_close.svg`} alt="close" />
          </button>
          {!disableFileName && <h3>{file.content.file.name}</h3>}
        </div>
        {onClickMediaMore && (
          <button type="button" onClick={() => onClickMediaMore(file.content)}>
            <img src={`/assets/images/files/media_more.svg`} alt="more" />
          </button>
        )}
      </div>
    );
  }
);

const MediaPopupContent = (props: { file: PopupProps }) => {
  const { file } = props;
  return (
    <div className={styles.mediaContent}>
      {file.content.file.type === "image" ? (
        <img src={file.content.file.image} alt={file.content.file.name} />
      ) : (
        <video src={`${file.content.file.video}#t=0.001`} controls></video>
      )}
    </div>
  );
};

export default FilesMediaPopup;
