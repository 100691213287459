import React, { useState } from "react";
import { List, ListItem, Typography, ButtonBase, Box } from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./action-item.module.scss";
import { useNavigate } from "react-router";

interface ActionItemProps {
  image?: string;
  text: string;
  description?: string;
  endIcon?: string;
  padding?: string;
  onClick?: () => void;
}

const ActionItem = (props: ActionItemProps) => {
  const { image, text, description, endIcon, padding, onClick } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickAction = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ButtonBase
      sx={{ width: "100%" }}
      onClick={onClickAction}
      className={`${styles.listItemWrapper} ${padding ? padding : ""}`}
    >
      {image && (
        <img
          src={image}
          style={{ width: padding === "small" ? "20px" : "auto" }}
        />
      )}
      <Box>
        <Typography variant="body1" align="left">
          {t(text)}
        </Typography>
        {description && (
          <Typography
            variant="body2"
            align="left"
            className={styles.description}
          >
            {t(description)}
          </Typography>
        )}
      </Box>
      {endIcon && <img className={styles.endIcon} src={endIcon} />}
    </ButtonBase>
  );
};

export default ActionItem;
