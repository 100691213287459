import MuiAccordion from "./MuiAccordion";
import MuiAlert from "./MuiAlert";
import MuiButton from "./MuiButton";
import MuiChip from "./MuiChip";
import MuiDateCalendar from "./MuiDateCalendar";
import MuiDialog from "./MuiDialog";
import MuiDialogActions from "./MuiDialogActions";
import MuiDialogContent from "./MuiDialogContent";
import MuiDialogContentText from "./MuiDialogContentText";
import MuiDialogTitle from "./MuiDialogTitle";
import MuiInputBase from "./MuiInputBase";
import MuiListItemAvatar from "./MuiListItemAvatar";
import MuiListItemButton from "./MuiListItemButton";
import MuiListItemIcon from "./MuiListItemIcon";
import MuiListItemText from "./MuiListItemText";
import MuiPickersDay from "./MuiPickersDay";
import MuiSkeleton from "./MuiSkeleton";
import MuiSnackbar from "./MuiSnackbar";
import MuiTextField from "./MuiTextField";
import MuiDayCalendar from "./MuiDayCalendar";
import MuiPickersCalendarHeader from "./MuiPickersCalendarHeader";
import MuiMonthCalendar from "./MuiMonthCalendar";
import MuiYearCalendar from "./MuiYearCalendar";
import MuiAvatarGroup from "./MuiAvatarGroup";

const overridesComponents = {
  MuiAccordion,
  MuiButton,
  MuiDateCalendar,
  MuiPickersDay,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogActions,
  MuiListItemButton,
  MuiListItemIcon,
  MuiListItemAvatar,
  MuiListItemText,
  MuiTextField,
  MuiInputBase,
  MuiAlert,
  MuiSnackbar,
  MuiDayCalendar,
  MuiPickersCalendarHeader,
  MuiMonthCalendar,
  MuiYearCalendar,
  MuiSkeleton,
  MuiChip,
  MuiAvatarGroup,
};

export default overridesComponents;
