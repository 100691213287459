import { faker } from "@faker-js/faker";

export type File = {
  file: {
    type: string;
    name: string;
    // size: string;
    // location: string;
    image: string;
    webUrl?: string;
    size?: number;
    // video: string;
    extension: string;
  };
  lastUpdateDate: Date;
  lastUpdateBy: string;
  createDate: Date;
  createBy: string;
  id: string;
  // isModified?: boolean;
};

const range = (len: number) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newFile = (): File => {
  const fileType = faker.helpers.shuffle<File["file"]["type"]>([
    "folder",
    "word",
    "spreadsheet",
    "presentation",
    "pdf",
    "text",
    "image",
    "audio",
    "video",
    "compressed",
  ]);
  let fileExtension: any = [];
  fileType.forEach((type) => {
    switch (type) {
      case "folder":
        fileExtension.push("folder");
        break;
      case "word":
        fileExtension.push(
          faker.helpers.shuffle(["doc", "docx", "odt", "rtf"])[0]
        );
        break;
      case "spreadsheet":
        fileExtension.push(
          faker.helpers.shuffle(["xls", "xlsx", "ods", "csv"])[0]
        );
        break;
      case "presentation":
        fileExtension.push(faker.helpers.shuffle(["ppt", "pptx", "odp"])[0]);
        break;
      case "pdf":
        fileExtension.push(faker.helpers.shuffle(["pdf"])[0]);
        break;
      case "text":
        fileExtension.push(faker.helpers.shuffle(["txt"])[0]);
        break;
      case "image":
        fileExtension.push(
          faker.helpers.shuffle(["jpg", "jpeg", "png", "gif", "svg"])[0]
        );
        break;
      case "audio":
        fileExtension.push(faker.helpers.shuffle(["mp3", "wav", "wma"])[0]);
        break;
      case "video":
        fileExtension.push(
          faker.helpers.shuffle(["mp4", "mov", "wmv", "avi"])[0]
        );
        break;
      case "compressed":
        fileExtension.push(faker.helpers.shuffle(["zip", "rar", "7z"])[0]);
        break;
      default:
        fileExtension.push("other");
        break;
    }
  });
  return {
    file: {
      type: fileType[0]!,
      name: `${faker.commerce.product()}${
        fileExtension[0] === "folder" || fileExtension[0] === "other"
          ? ""
          : "." + fileExtension[0]
      }`,
      // size: `${faker.number.float({ min: 10, max: 100, precision: 0.01 })} KB`,
      // location: faker.system.directoryPath(),
      extension: fileExtension[0]!,
      image:
        fileType[0] === "image" || fileType[0] === "video"
          ? faker.image.animals()
          : "",
      // video:
      //   fileType[0] === "video" ? "/assets/demo_videos/demo_video.mp4" : "",
    },
    lastUpdateDate: faker.date.past(),
    lastUpdateBy: faker.name.fullName(),
    createDate: faker.date.past(),
    createBy: faker.name.fullName(),
    id: faker.datatype.uuid(),
    // isModified: faker.datatype.boolean(),
  };
};

export function makeData(...lens: number[]) {
  const makeDataLevel = (depth = 0): File[] => {
    const len = lens[depth]!;
    return range(len).map((d): File => {
      return {
        ...newFile(),
      };
    });
  };

  return makeDataLevel();
}

export function getFileTypeFromExtension(fileExtension: string): string {
  switch (fileExtension.toLowerCase()) {
    case "folder":
      return "folder";
    case "doc":
    case "docx":
    case "odt":
    case "rtf":
      return "word";
    case "xls":
    case "xlsx":
    case "ods":
    case "csv":
      return "spreadsheet";
    case "ppt":
    case "pptx":
    case "odp":
      return "presentation";
    case "pdf":
      return "pdf";
    case "txt":
      return "text";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "svg":
      return "image";
    case "mp3":
    case "wav":
    case "wma":
      return "audio";
    case "mp4":
    case "mov":
    case "wmv":
    case "avi":
      return "video";
    case "zip":
    case "rar":
    case "7z":
      return "compressed";
    default:
      return "other";
  }
}
