import { Action, createSlice, Reducer } from "@reduxjs/toolkit";
import { ObjectKey } from "../interfaces/common-interface";

export interface UserState {
  id: string | null;
  partnerId: string | null;
  email: string;
  name: string;
  firstName: string;
  nickname: string;
  avatar: string | null;
  banner: string | null;
  function: string;
  jobTitle: string;
  status: string;
  contactNo: string;
  pronouns: ObjectKey | null;
  languages: string[];
  profileAttribute: ObjectKey[];
  area: string;
  district: string;
  store: string;
  permission: { [key: string]: boolean } | null;
  chat: Record<string, boolean> | null;
  accessToken: string | null;
  refreshToken: string | null;
  isFirstLogin: boolean;
  isAcceptedTnc: boolean;
  isSkipReminder: boolean;
  msToken: string | null;
}

// Define the initial state using that type
const initialState: UserState = {
  id: null,
  partnerId: null,
  email: "",
  name: "",
  firstName: "",
  nickname: "",
  avatar: null,
  banner: null,
  function: "",
  jobTitle: "",
  status: "",
  contactNo: "",
  pronouns: null,
  languages: [],
  profileAttribute: [],
  area: "",
  district: "",
  store: "",
  permission: null,
  chat: null,
  accessToken: null,
  refreshToken: null,
  isFirstLogin: true,
  isAcceptedTnc: false,
  isSkipReminder: false,
  msToken: null,
};

export const user = createSlice({
  name: "state",
  initialState,
  reducers: {
    setUserState: (state: UserState, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateMsToken: (state: UserState, action) => {
      return {
        ...state,
        msToken: action.payload,
      };
    },
    clearUserState: (state: UserState) => {
      return {
        ...state,
        id: null,
        partnerId: null,
        email: "",
        name: "",
        firstName: "",
        nickname: "",
        avatar: null,
        banner: null,
        function: "",
        jobTitle: "",
        status: "",
        contactNo: "",
        pronouns: null,
        languages: [],
        profileAttribute: [],
        area: "",
        district: "",
        store: "",
        permission: null,
        accessToken: null,
        refreshToken: null,
        isFirstLogin: true,
        isAcceptedTnc: false,
        isSkipReminder: false,
        msToken: null,
      };
    },
  },
});

export const { setUserState, clearUserState, updateMsToken } = user.actions;

// Other code such as selectors can use the imported `RootState` type
const userReducer: Reducer<UserState, Action<UserState>> = user.reducer;
export default userReducer;
