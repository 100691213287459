import { FC } from "react";
import { GreenApronCardProvider } from "./greenApronCardProvider";
import { Outlet } from "react-router-dom";

const greenApronCardContainer: FC = () => {
  return (
    <GreenApronCardProvider>
      <Outlet />
    </GreenApronCardProvider>
  );
};

export default greenApronCardContainer;
