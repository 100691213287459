import React, { useState, useEffect, FC } from "react";
import Header from "../../components/header/header";
import styles from "./files.module.scss";
import { Box, Grid, IconButton, Snackbar } from "@mui/material";
import { BottomNavMenu, Popup } from "../../components";
import { makeData, File } from "./makeData";
import moment from "moment";
import { useTranslation } from "react-i18next";
import HandlePopup from "./handlePopup";
import SearchField from "../../components/search-field";
import {
  getFolder,
  getFolderDetail,
  getRootFolderDetail,
  getFilesDetail,
  createFiles,
  getTeamsFileDirectoryList,
} from "../../services/files";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { formatBytes } from "../../utility/formatBytes";
// import { fa } from "@faker-js/faker";
import { Backdrop, CircularProgress } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { useMsal } from "@azure/msal-react";
import { getMSTokenSilent } from "../../utility";

type Props = {};

type IconProps = {
  char: string;
};

type GroupItemProps = {
  GroupTitle: string;
  driveId?: string;
  children: ItemChild[];
};

type ItemChild = {
  id: string;
  name: string;
  url: string;
  folderId?: string;
  webUrl?: string;
};

const PHFiles = (props: Props) => {
  const { instance, accounts } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState<GroupItemProps[]>([]);

  useEffect(() => {
    const getFileDirectoryList = async () => {
      const msToken = localStorage.getItem("MS_TOKEN");
      try {
        setIsLoading(true);
        if (!msToken) return new Error("No MS Token");
        const res = await getTeamsFileDirectoryList(msToken);
        let tempList = res.data.teams.map((team: any) => {
          return {
            GroupTitle: team.displayName,
            driveId: team.driveId,
            children: team.channels.map((channel: any) => {
              return {
                id: channel.id,
                name: channel.displayName,
                url: channel.webUrl,
                folderId: channel.folderId,
                webUrl: channel.webUrl,
              };
            }),
          };
        });

        tempList = tempList.map((item: any) => {
          return {
            ...item,
            children: item.children.filter((child: any) => {
              return child.folderId;
            }),
          };
        });

        console.log("PH folder list:", tempList);

        setList(tempList);
        setIsLoading(false);
      } catch (error: any) {
        // TODO: remove status code 6000 and 4005 handler after backend fixed
        if (
          error.response.data.status === 6000 ||
          error.response.data.status === 4083 ||
          error.response.data.status === 4005
        ) {
          getMSTokenSilent(accounts, instance, "/files", () => {
            getFileDirectoryList();
          });
        } else {
          setIsLoading(false);
        }
      }
    };
    getFileDirectoryList();
  }, []);

  const GroupIcon: FC<IconProps> = (props: IconProps) => {
    const { char } = props;
    return (
      <div
        style={{
          display: "flex",
          padding: 5,
          borderRadius: 5,
          backgroundColor: "#00A862",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 20,
            height: 20,
            padding: 5,
            color: "#fff",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {char}
        </div>
      </div>
    );
  };

  const GroupItem: FC<GroupItemProps> = (props: GroupItemProps) => {
    const { GroupTitle, driveId, children } = props;
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<img src="/assets/images/chevron_down.svg" alt="" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <GroupIcon char={GroupTitle.charAt(0)} />
            <Typography>{GroupTitle}</Typography>
          </Box>
        </AccordionSummary>
        {children.map((child, index) => (
          <AccordionDetails
            key={index}
            onClick={() => {
              navigate(`/files/${driveId}/${child.folderId}`);
              // navigate(`/files/${driveId}/`);
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img src="/assets/images/structure_icon.svg" alt="" />
              <Typography>{child.name}</Typography>
            </Box>
          </AccordionDetails>
        ))}
      </Accordion>
    );
  };

  if (isLoading)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  // if (list.length === 0)
  //   return (

  //   );

  return (
    <div className={styles.phListIndex}>
      <Header title={t("general.files")} />
      {/* <GroupItem /> */}
      {list.length > 0 ? (
        list.map((item, index) => (
          <GroupItem
            key={index}
            GroupTitle={item.GroupTitle}
            driveId={item.driveId}
            children={item.children}
          />
        ))
      ) : (
        <div
          className={styles.noData}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className={styles.noDataContainer}>
            <img src="/assets/images/files/Empty.svg" alt="no data" />
            <h3>{t("files.noChannels")}</h3>
            <span>{t("files.noFilesDescription")}</span>
          </div>
        </div>
      )}
      <BottomNavMenu />
    </div>
  );
};

export default PHFiles;
