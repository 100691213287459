import { Box, Link, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./menu.module.scss";
import { SectionProps } from "../../interfaces/homepage-interface";
import Header from "../../components/header/header";
import { useNavigate } from "react-router-dom";
// import { UserState } from "../../reducers/user-slice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getQuickAccessList } from "../../services/homepage";
import { getApiDataByLang } from "../../utility";

function Menu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuData, setMenuData] = React.useState<any>([]);

  useEffect(() => {
    const getShortcutMenuList = async () => {
      try {
        const response: any = await getQuickAccessList();
        // console.log("getShortcutMenuList", response);
        if (response.data && response.data.quickAccessData.length > 0) {
          response.data.quickAccessData.forEach((item: any) => {
            const temp = {
              id: item._id,
              title: getApiDataByLang(item.categoryName),
              content: item.content
                .map((contentItem: any) => {
                  return (
                    contentItem.isDisplayInApp && {
                      image: contentItem.displayImage,
                      url: contentItem.linkTo,
                      text: getApiDataByLang(contentItem.quickAccessName),
                      isOpenNewTab: contentItem.isOpenNewTab,
                    }
                  );
                })
                .filter(Boolean),
            };
            // console.log("getShortcutMenuList", temp);
            setMenuData((prev: any) => [...prev, temp]);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getShortcutMenuList();
  }, []);
  return (
    <>
      <Header
        title={t("homepage.more")}
        enableCloseButton
        closeButtonNavigation="/home"
      />
      {menuData && menuData.length > 0 && (
        <Grid item xs className={styles.pageWrapper}>
          {menuData.map((section: any) => {
            return (
              section.content.length > 0 && (
                <Box key={section.id} className={styles.sectionContainer}>
                  <Typography className={styles.sectionTitle}>
                    {t(section.title)}
                  </Typography>
                  <Box className={styles.itemContainer}>
                    {section.content.map((item: any, index: any) => {
                      return (
                        <Link
                          key={index}
                          className={styles.itemWrapper}
                          href={item.url}
                          onClick={() => {
                            sessionStorage.setItem(
                              "prevPage",
                              window.location.href
                            );
                          }}
                          underline="none"
                          {...(item.isOpenNewTab && {
                            target: "_blank",
                            rel: "noopener noreferrer",
                          })}
                        >
                          <img src={item.image} alt="" />
                          <Typography variant="body2">
                            {t(item.text)}
                          </Typography>
                        </Link>
                      );
                    })}
                  </Box>
                </Box>
              )
            );
          })}
        </Grid>
      )}
    </>
  );
}

export default Menu;
