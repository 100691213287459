import { Avatar, Box, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import styles from "./account-item.module.scss";

interface AccountItemListProps {
  name: string;
  icon: string;
  description?: string;
  remarks?: string;
  padding?: string;
  onClick?: () => void;
}

const AccountItem = (props: AccountItemListProps) => {
  const { name, icon, description, remarks, padding, onClick } = props;
  // console.log("icon", icon);

  return (
    <Box
      className={styles.itemWrapper}
      sx={{ padding: padding ? padding : "16px" }}
      onClick={() => onClick && onClick()}
    >
      <Avatar className={styles.profileIcon} alt={name} src={icon} />
      <Box className={styles.menuItemDetail}>
        <Typography variant="body1">{name}</Typography>
        {(description || remarks) && (
          <Stack direction="row">
            {description && (
              <Typography
                className={styles.description}
                variant="body2"
                color={theme.vars.palette.black.opacity58}
              >
                {description}
              </Typography>
            )}
            {remarks && (
              <Typography
                className={styles.remarks}
                variant="body2"
                color={theme.vars.palette.black.opacity58}
              >
                {remarks}
              </Typography>
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default AccountItem;
