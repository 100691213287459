import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Link } from "@mui/material";
import theme from "../../theme";
import styles from "./bottom-nav-menu.module.scss";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState } from "../../reducers/user-slice";

interface IBreadcrumbProps {
  // data: any;
  isUploading?: boolean;
  afterUpload?: (url: string) => void;
}

const BottomNavMenu = (props: IBreadcrumbProps) => {
  const { isUploading, afterUpload } = props;
  // const [activeItem, setActiveItem] = useState(0);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const menuItems = [
    {
      image: "/assets/images/navMenu_home.svg",
      colouredImage: "/assets/images/navMenu_home_coloured.svg",
      text: "general.home",
      url: "/home",
    },
    {
      image: "/assets/images/navMenu_socialWall.svg",
      colouredImage: "/assets/images/navMenu_socialWall_coloured.svg",
      text: "general.socialWall",
      url: "/social-wall",
    },
    {
      image:
        process.env.REACT_APP_LOCATION === "HK"
          ? "/assets/images/shortcutMenu_digitalCoupon.svg"
          : "/assets/images/navMenu_event.svg",
      colouredImage:
        process.env.REACT_APP_LOCATION === "HK"
          ? "/assets/images/shortcutMenu_digitalCoupon.svg"
          : "/assets/images/navMenu_event_coloured.svg",
      text:
        process.env.REACT_APP_LOCATION === "HK"
          ? "general.coupon"
          : "general.event",
      url: process.env.REACT_APP_LOCATION === "HK" ? "/my-coupon" : "/event",
    },
    {
      image: "/assets/images/navMenu_me.svg",
      colouredImage: "/assets/images/navMenu_me_coloured.svg",
      text: "general.me",
      url: "/me",
    },
  ];

  if (userState?.chat?.ChatFunction) {
    menuItems.splice(3, 0, {
      image: "/assets/images/navMenu_chat.svg",
      colouredImage: "/assets/images/navMenu_chat_coloured.svg",
      text: "general.chat",
      url: "/chat",
    });
  }
  // const changePage = (event: React.MouseEvent<HTMLElement>, index: number) => {
  //   setActiveItem(index);
  // };
  const changePage = (url: string) => {
    !isUploading ? navigate(url) : afterUpload && afterUpload(url);
  };

  return (
    <Box className={styles.menuItemContainer}>
      {menuItems.map((item, index) => (
        <Box
          className={`${styles.menuItem} ${
            location.pathname.includes(item.url) ? styles.active : ""
          }`}
          // href={item.url}
          onClick={(event) => changePage(item.url)}
          key={index}
        >
          <img
            src={
              location.pathname.includes(item.url)
                ? item.colouredImage
                : item.image
            }
            alt=""
          />
          <Typography variant="body2">{t(item.text)}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default BottomNavMenu;
