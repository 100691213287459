import { FC, useCallback, useEffect, useState } from "react";
import styles from "./eventTabsSection.module.scss";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  EventListRequestType,
  getEventListSummary,
} from "../../services/event";
import { useParams } from "react-router-dom";

interface EventTabsListProps {
  activeTab: string;
  activeMonth: number;
  activeYear: number;
  isAdmin: boolean;
  onTabClick: (active: string) => void;
}

interface EventTabProps {
  name: string;
  title: string;
  icon: string;
  activeIcon?: string;
  count: number;
  isActive: boolean;
  onClick: () => void;
}

const EventTabsList: FC<EventTabsListProps> = (props) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const [summary, setSummary] = useState({
    upcoming: 0,
    myEvent: 0,
    managing: 0,
  });

  const getEventSummary = useCallback(async () => {
    try {
      let apiRequest: EventListRequestType = {
        month: props.activeMonth,
        year: props.activeYear,
      };
      if (groupId) {
        apiRequest = {
          ...apiRequest,
          partnerNetworkGroupId: groupId,
        };
      }
      const summaryResponse = await getEventListSummary(apiRequest);
      if (summaryResponse && summaryResponse.status === 200) {
        setSummary({
          upcoming: summaryResponse.data.upcomingEventsCount,
          myEvent: summaryResponse.data.myEventsCount,
          managing: summaryResponse.data.managingEventsCount,
        });
      }
    } catch (error) {
      throw error;
    }
  }, [props.activeMonth, props.activeYear, props.activeTab]);

  useEffect(() => {
    getEventSummary();
  }, [getEventSummary]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={styles.eventTabsList}
    >
      <Grid item xs={4} className={styles.eventTab}>
        <EventTab
          name="upcoming"
          title={t("event.tabs.upcoming")}
          icon="/assets/images/eventTabIcon-coffee.svg"
          activeIcon="/assets/images/eventTabIcon-coffee-active.svg"
          count={summary.upcoming}
          isActive={props.activeTab === "UPCOMING"}
          onClick={() => props.onTabClick("UPCOMING")}
        />
      </Grid>
      <Grid item xs={4} className={styles.eventTab}>
        <EventTab
          name="myEvent"
          title={t("event.tabs.myEvent")}
          icon="/assets/images/eventTabIcon-cup.svg"
          activeIcon="/assets/images/eventTabIcon-cup-active.svg"
          count={summary.myEvent}
          isActive={props.activeTab === "MY_EVENT"}
          onClick={() => props.onTabClick("MY_EVENT")}
        />
      </Grid>
      {(props.isAdmin || summary.managing > 0) && (
        <Grid item xs={4} className={styles.eventTab}>
          <EventTab
            name="managing"
            title={t("event.tabs.managing")}
            icon="/assets/images/eventTabIcon-apron.svg"
            activeIcon="/assets/images/eventTabIcon-apron-active.svg"
            count={summary.managing}
            isActive={props.activeTab === "MANAGING"}
            onClick={() => props.onTabClick("MANAGING")}
          />
        </Grid>
      )}
    </Grid>
  );
};

const EventTab: FC<EventTabProps> = (props) => {
  const iconPath =
    props.isActive && props.activeIcon ? props.activeIcon : props.icon;

  return (
    <Button
      id={props.name + "Tab"}
      disableRipple
      className={props.isActive ? styles.active : undefined}
      onClick={props.onClick}
    >
      <Typography variant="body2">{props.title}</Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        columnGap={0.5}
        className={styles.eventCount}
      >
        <img src={iconPath} alt={props.title} />
        <Typography variant="h2" component="span">
          {props.count}
        </Typography>
      </Grid>
    </Button>
  );
};

export default EventTabsList;
