import React, { useState } from "react";
import { List, ListItem, Typography, ButtonBase } from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./action-item-list.module.scss";
import { useNavigate } from "react-router";

export interface ItemProps {
  image: string;
  text: string;
  endIcon?: string;
  url?: string;
  onClick?: () => void;
}

interface ActionItemListProps {
  data: Array<ItemProps>;
}

const ActionItemList = (props: ActionItemListProps) => {
  const { data } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <List className={styles.listWrapper}>
      {data.map((item, index) => (
        <ButtonBase
          key={index}
          sx={{ width: "100%" }}
          onClick={() => {
            if (item.url) {
              navigate(item.url);
            } else {
              item.onClick && item.onClick();
            }
          }}
        >
          <ListItem className={styles.listItemWrapper}>
            <img src={item.image} />
            <Typography variant="body1">{t(item.text)}</Typography>
            {item.endIcon && (
              <img className={styles.endIcon} src={item.endIcon} />
            )}
          </ListItem>{" "}
        </ButtonBase>
      ))}
    </List>
  );
};

export default ActionItemList;
