import React, { useState, useEffect } from "react";
import styles from "./content-hub.module.scss";
import Header from "../header/header";
import { useNavigate } from "react-router-dom";
import { Grid, Box, IconButton } from "@mui/material";
import { CustomTab, NoResults, Popup } from "..";
import SearchField from "../search-field";
import { useTranslation } from "react-i18next";
import { listItemType } from "./interface";
import ListItem from "./list-item";
import ActiveItemDetail from "./item-detail";
import FaqItem from "./faq-item";

type Props = {
  pageTitle: string;
  tabData?: { value: string; display: string }[];
  activeTab?: string;
  handleChangeTab?: (value: string) => void;
  listData?: listItemType[];
  searchValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onReset?: () => void;
  onClose?: () => void;
  isSearch?: boolean;
  isFaq?: boolean;
  onDetailClick?: (id: string) => void;
  activeItem?: listItemType | null;
  isLoading?: boolean;
};

const ContentHubList = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    tabData,
    activeTab,
    listData,
    isSearch = true,
    isFaq = false,
    onDetailClick,
    activeItem,
    isLoading = false,
  } = props;
  const [isDetailOpen, setDetailOpen] = useState<boolean>(false);
  // const [activeItem, setActiveItem] = useState<listItemType | null>(null);
  const [isSearching, setIsSearching] = useState(
    props.searchValue ? true : false
  );
  return (
    <>
      {isSearching && props.onReset ? (
        <SearchField
          id="search"
          name="search"
          placeholder={t("general.search")}
          value={`${props.searchValue}`}
          onChange={props.onChange}
          onReset={props.onReset}
          onClose={props.onClose}
        />
      ) : (
        <Header
          title={props.pageTitle}
          enableBackButton
          closeButtonFunction={() => {
            // console.log("closeButtonFunction");
            const prevPage = sessionStorage.getItem("prevPage") ?? null;
            if (prevPage) {
              window.location.href = prevPage;
            } else {
              navigate("/home");
            }
          }}
        >
          {isSearch && (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
            >
              <Grid item xs="auto">
                <IconButton
                  aria-label="Search"
                  sx={{ padding: "0px" }}
                  onClick={() => {
                    setIsSearching(true);
                  }}
                >
                  <img src="/assets/images/toolbar_search_black.svg" alt="" />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Header>
      )}

      <Box
        className={styles.contentContainer}
        {...(isFaq && { sx: { padding: "0px" } })}
      >
        {tabData &&
          tabData.length > 0 &&
          activeTab &&
          props.handleChangeTab && (
            <Box
              className={styles.tabWrapper}
              {...(isFaq && { sx: { padding: "0 16px" } })}
            >
              <CustomTab
                data={tabData}
                activeTab={activeTab}
                onTabClick={props.handleChangeTab}
              />
            </Box>
          )}
        <Box className={styles.listContainer}>
          {listData && listData.length > 0 ? (
            listData.map((item, index) =>
              isFaq ? (
                <FaqItem {...item} />
              ) : (
                <ListItem
                  {...item}
                  key={`list-item-${index}`}
                  onDetailClick={(id: any) => {
                    setDetailOpen(true);
                    onDetailClick && onDetailClick(id);
                    //   console.log("detail", detail);
                  }}
                />
              )
            )
          ) : !isLoading ? (
            <div className={styles.noData}>
              <div className={styles.noDataContainer}>
                {isSearching ? (
                  <NoResults resultsType="SEARCH" />
                ) : (
                  <>
                    <img src="/assets/images/empty.png" alt="no data" />
                    <h3>{t("general.stayTuned")}</h3>
                    <p>{t("general.stayTunedMsg")}</p>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </Box>
      </Box>
      {activeItem && (
        <Popup
          id={styles.detailPopup}
          isOpen={isDetailOpen}
          setIsOpen={(close: boolean) => setDetailOpen(close)}
          title={
            <>
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => setDetailOpen(false)}
              >
                <img src="/assets/images/arrow_left.svg" alt="detail back" />
              </IconButton>
            </>
          }
          content={
            activeItem && (
              <ActiveItemDetail {...activeItem} isLoading={isLoading} />
            )
          }
          disableActions={true}
          fullScreen={true}
        />
      )}
    </>
  );
};

export default ContentHubList;
