import React, { FC, memo } from "react";
import { Grid, AppBar, Typography, IconButton } from "@mui/material";
import styles from "./header.module.scss";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  title: string;
  enableBackButton?: boolean;
  enableCloseButton?: boolean;
  disableBottomBorder?: boolean;
  children?: React.ReactNode;
  closeButtonNavigation?: string;
  closeButtonFunction?: () => void;
  colouredBackground?: boolean;
}

const Header: FC<HeaderProps> = memo((props) => {
  const navigate = useNavigate();
  const isSubHeader = props.enableBackButton || props.enableCloseButton;
  const headerBtnImage = props.enableCloseButton
    ? "/assets/images/close_btn.svg"
    : props.colouredBackground
    ? "/assets/images/arrow_left_white.svg"
    : "/assets/images/arrow_left.svg";

  return (
    <AppBar
      id={styles.header}
      component="nav"
      position="sticky"
      className={`${props.disableBottomBorder ? styles.noBorder : ""} ${
        props.colouredBackground ? styles.green : ""
      }`}
    >
      <Grid container gap={1.25} alignItems="center">
        {isSubHeader && (
          <Grid item xs="auto">
            <IconButton
              id={styles.backButton}
              disableRipple
              onClick={() =>
                props.closeButtonFunction
                  ? props.closeButtonFunction()
                  : props.closeButtonNavigation
                  ? navigate(props.closeButtonNavigation)
                  : navigate(-1)
              }
            >
              <img src={headerBtnImage} alt="" />
            </IconButton>
          </Grid>
        )}
        <Grid item xs className="headerTitle">
          <Typography
            variant={isSubHeader ? "h3" : "h2"}
            className={`text ${styles.title}`}
            component="div"
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs="auto" className={styles.toolsActions}>
          {props.children}
        </Grid>
      </Grid>
    </AppBar>
  );
});

export default Header;
