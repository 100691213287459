import SbChannelList from "@sendbird/uikit-react/ChannelList";
import styles from "./chat.module.scss";
import { useNavigate } from "react-router-dom";

export function ChannelList() {
  const navigate = useNavigate();
  return (
    <SbChannelList
      isMessageReceiptStatusEnabled={true}
      isTypingIndicatorEnabled={true}
      className={styles.channelList}
      disableAutoSelect
      onChannelSelect={(channel) => {
        navigate(`/chat/${channel.url}`);
      }}
    />
  );
}
