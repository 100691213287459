import { AxiosPromise } from "axios";
import { instance } from "./api-services";
import {
  ReplyCardRequestType,
  SendCardRequestType,
  UpdateSendCardRequestType,
} from "../interfaces/green-apron-card-interface";
import { removeApiCacheSession } from "../utility";

const servicePath = "/green-apron-card";

export const getCardsList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getCard-list",
    cache: { ttl: 15 * 60 * 1000 },
    url: `${servicePath}/list`,
  });

export const getCardDetail = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getCard-detail-${id}`,
    url: `${servicePath}/${id}`.replace("//", "/"),
  });

export const replyCard = (data: ReplyCardRequestType): AxiosPromise => {
  removeApiCacheSession("getCard-");
  return instance({
    method: "POST",
    url: `${servicePath}/reply/${data.cardId}`,
    data: {
      replyMessage: data.replyMessage,
    },
  });
};

export const sendCard = (data: SendCardRequestType): AxiosPromise => {
  removeApiCacheSession("getCard-");
  return instance({
    method: "POST",
    url: `${servicePath}/send`,
    data,
  });
};

export const resendCard = (id: string): AxiosPromise => {
  removeApiCacheSession("getCard-");
  return instance({
    method: "POST",
    url: `${servicePath}/resend/${id}`,
  });
};

export const getReceivedCardsList = (input?: {
  page?: number;
  pageSize?: number;
}): AxiosPromise => {
  let page = input?.page ?? 1;
  let pageSize = input?.pageSize ?? 10;
  return instance({
    method: "GET",
    id: `getCard-received-${page}-${pageSize}`,
    cache: { ttl: 30 * 1000 },
    url: `${servicePath}/received/card/`,
    params: {
      page: page,
      pageSize: pageSize,
    },
  });
};

export const getSentCardsList = (input?: {
  page?: number;
  pageSize?: number;
}): AxiosPromise => {
  let page = input?.page ?? 1;
  let pageSize = input?.pageSize ?? 10;
  return instance({
    method: "GET",
    id: `getCard-send-${page}-${pageSize}`,
    cache: { ttl: 5 * 60 * 1000 },
    url: `${servicePath}/sent/card/`,
    params: {
      page: page,
      pageSize: pageSize,
    },
  });
};

export const updateSendCard = (
  data: UpdateSendCardRequestType
): AxiosPromise => {
  removeApiCacheSession("getCard-");
  const { id, ...requestData } = data;
  return instance({
    method: "POST",
    url: `${servicePath}/${id}`,
    data: requestData,
  });
};

export const getAdminList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getCard-admin",
    url: `/directory/profile/eventAdmins?page=1&pageSize=999`,
  });

export const getReceiverList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getCard-receiver",
    url: `${servicePath}/receiver/list/`,
  });

export const getCardsConfig = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getCardsConfig",
    url: `${servicePath}/config`,
  });

export const getNewStatus = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getCard-newStatus",
    cache: { ttl: 10 * 1000 },
    url: `${servicePath}/newStatus`,
  });

export const changeReplyRead = (id: string): AxiosPromise => {
  removeApiCacheSession("getCard-");
  return instance({
    method: "POST",
    url: `${servicePath}/reply/read/${id}`,
  });
};
