const MuiPickersDay = {
  styleOverrides: {
    root: {
      width: 24,
      height: 24,
      fontWeight: 400,
      fontSize: 15,
      lineHeight: "20px",
      color: "var(--mui-palette-grey-300)",
      "&:focus": {
        backgroundColor: "transparent",
      },
      // "&:not(.Mui-selected)": {
      //   border: "none",
      //   backgroundColor: "var(--mui-palette-primary-main)",
      //   color: "var(--mui-palette-primary-contrastText)",
      //   // "&:hover, &[aria-selected=true]": {
      //   //    willChange: "unset",
      //   //    backgroundColor: "var(--mui-palette-primary-main)",
      //   // }
      // },
      "&.Mui-selected": {
        border: "none",
      },
      "&.Mui-selected:focus": {
        border: "none",
        backgroundColor: "var(--mui-palette-primary-main)",
      },
      // "&:focus": {
      //   border: "none",
      //   backgroundColor: "var(--mui-palette-primary-main)",
      //   color: "var(--mui-palette-primary-contrastText)",
      // },
    },
    dayOutsideMonth: {
      color: "var(--mui-palette-black-black087)",
    },
    // today: {
    //   border: "none",
    //   backgroundColor: "var(--mui-palette-primary-main)",
    //   color: "var(--mui-palette-white-opacity100)",
    // },
  },
};

export default MuiPickersDay;
