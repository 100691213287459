import React from "react";
import sectionStyles from "../../homepage/sections.module.scss";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../components/content-hub/list-item";
import { NoResults } from "../../../components";
import { useTranslation } from "react-i18next";

type Props = {
  data: Array<any>;
};

const JoinedList = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box className={sectionStyles.sectionContainer}>
      <Box className={sectionStyles.sectionWrapper}>
        <Box
          className={sectionStyles.sectionHeader}
          style={{ marginBottom: "4px" }}
        >
          <Typography className={sectionStyles.sectionTitle} variant="body1">
            {t("partnerNetwork.joined")}
          </Typography>
          <img
            src="/assets/images/chevron_right.svg"
            alt=""
            onClick={() => navigate("/partner-network/joined")}
          />
        </Box>
        {props.data.length > 0 &&
          props.data.map((item, index) => {
            return (
              <ListItem
                {...item}
                isGroup
                key={`joined-${index}`}
                onDetailClick={(id: any) => {
                  console.log("list id", id);
                  navigate(`/partner-network/detail/${id}`);
                }}
              />
            );
          })}
        {props.data.length === 0 && <NoResults />}
      </Box>
    </Box>
  );
};

export default JoinedList;
