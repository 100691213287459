import React, { FC, useEffect, useState } from "react";
import styles from "./survey.module.scss";
import { Popup, ActionItemList } from "../../components";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import {
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment, { Moment } from "moment";
import { SocialWallProps } from "../../interfaces/social-wall-interface";
import { convertDateTimeWithDivider } from "../../utility";
import { useNavigate } from "react-router";
import SurveyRecentSurvey from "./surveyRecentSurveySection";
import SurveyTemplate from "./surveyTemplateSection";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState } from "../../reducers/user-slice";

export interface surveyListType {
  _id: string;
  status: "normal" | "media" | "link" | "events" | "poll" | "survey";
  displayLanguage: Boolean;
  surveyTitle: number;
  surveyDescription: Array<any>;
  respondents: string;
  anonymousResponses: string;
  questions: string;
  surveyStartDate: string;
  surveyEndDate: string;
  isDeleted: string;
  lastUpdateBy: string;
  lastUpdateTime: string;
}

function Survey() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getSurveyPostBar = JSON.parse(
    localStorage.getItem("survey-created") as string
  );
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showFullScreenPopup, setShowFullScreenPopup] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [surveyList, setSurveyList] = useState<surveyListType>();
  const [templateList, setTemplateList] = useState<any>();
  const [isSnackbarShow, setSnackbarShow] = useState(
    getSurveyPostBar ? getSurveyPostBar : false
  );
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const isAdmin = Boolean(userState.permission?.SurveyAdmin);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (
      // templateList &&
      surveyList
    ) {
      setIsLoading(false);
    }
  }, [surveyList, templateList]);

  const popupTitle = (
    <Box className={styles.popupTitle}>
      <span>{t("survey.create.createNewSurvey")}</span>
      <img
        src="/assets/images/close_btn.svg"
        onClick={() => {
          setShowPopup(false);
        }}
      />
    </Box>
  );

  const actionItemData = [
    {
      image: "/assets/images/survey_create_icon.svg",
      url: "/survey/create",
      text: "survey.create.createFromScratch",
    },
    {
      image: "/assets/images/survey_template_icon.svg",
      url: "/survey/template",
      text: "survey.create.createFromTemplate",
    },
  ];
  const actionItem = (
    <Box className={styles.detailsContainer}>
      <ActionItemList data={actionItemData} />
    </Box>
  );

  function handleCloseMsgBar() {
    localStorage.setItem("survey-created", "false");
    setSnackbarShow(false);
  }

  return (
    <>
      <Header
        title={t("survey.title")}
        enableBackButton
        disableBottomBorder
        closeButtonFunction={() => {
          // console.log("closeButtonFunction");
          const prevPage = sessionStorage.getItem("prevPage") ?? null;
          if (prevPage) {
            window.location.href = prevPage;
          } else {
            navigate("/menu");
          }
        }}
      >
        <IconButton
          aria-label="Plus"
          sx={{ padding: "0px" }}
          onClick={() => setShowPopup(true)}
        >
          <img src="/assets/images/toolbar_plus_black.svg" alt="" />
        </IconButton>

        {(showPopup || showFullScreenPopup) && (
          <Popup
            isOpen={showPopup || showFullScreenPopup}
            setIsOpen={(close: boolean) => {
              setShowPopup(close);
              setShowFullScreenPopup(close);
            }}
            fullScreen={showFullScreenPopup}
            title={popupTitle}
            content={actionItem}
            disableActions={true}
          />
        )}
      </Header>
      {isAdmin && (
        <Grid item xs className={styles.contentContainer} id="surveyContainer">
          <SurveyTemplate />
          <SurveyRecentSurvey />
        </Grid>
      )}
      {isSnackbarShow && (
        <Snackbar
          open={isSnackbarShow}
          autoHideDuration={6000}
          onClose={handleCloseMsgBar}
        >
          <Alert severity="success">{t("survey.create.successMsg")}</Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Survey;
