import React, { FC, useState, memo } from "react";
import {
  Box,
  Chip,
  Grid,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import styles from "./input-field.module.scss";
import { useTranslation } from "react-i18next";
import { ObjectKey } from "../../interfaces/common-interface";

export interface InputFieldProps {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  type?: React.HTMLInputTypeAttribute;
  min?: number;
  max?: number;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  multiline?: boolean;
  helperText?: React.ReactNode;
  errorHelperText?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  children?: React.ReactNode;
  // eslint-disable-next-line
  onChange: (e: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  selectField?: boolean;
  selectorField?: boolean;
  onChipClick?: () => void;
}

const InputField: FC<InputFieldProps> = (props) => {
  const {
    label,
    error,
    multiline,
    helperText,
    errorHelperText,
    startAdornment,
    endAdornment,
    onFocus,
    onBlur,
    onClick,
    selectField,
    selectorField,
    onChipClick,
    ...inputProps
  } = props;
  const { t } = useTranslation();
  const [focusField, setFocusField] = useState(false);
  const handleFocusField = () => {
    if (!inputProps.readOnly) setFocusField(true);
    onFocus && onFocus();
  };
  const handleBlurField = () => {
    setFocusField(false);
    onFocus && onFocus();
  };
  let fieldProps: ObjectKey = {
    ...inputProps,
    // onFocus: handleFocusField,
    // onBlur: handleBlurField,
  };
  // console.log("fieldProps.value", fieldProps.value);

  if (
    inputProps.type === "number" &&
    inputProps.min !== undefined &&
    inputProps.min >= 0
  ) {
    fieldProps = {
      ...fieldProps,
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "-" || e.key === "+" || e.key === ".") e.preventDefault();
      },
    };
  }

  const inputElement = () => {
    if (multiline)
      return (
        <TextareaAutosize
          {...fieldProps}
          placeholder={displayPlaceholder()}
          onChange={(e) => {
            fieldProps.onChange(e);
          }}
        />
      );

    if (onChipClick && fieldProps.value) {
      return (
        <Chip
          label={fieldProps.value}
          size="small"
          className={styles.chip}
          onDelete={onChipClick}
        />
      );
    } else {
      return (
        <>
          {selectorField && <Typography>{fieldProps.value}</Typography>}
          <input
            {...fieldProps}
            placeholder={displayPlaceholder()}
            style={{
              textAlign: label ? "right" : "left",
            }}
          />
        </>
      );
    }
  };

  const selectValueElement = () => {
    const valueNotEmpty = fieldProps.value && fieldProps.value !== "";

    return (
      <Typography
        align={!valueNotEmpty || label ? "right" : "left"}
        className={`${valueNotEmpty ? styles.haveValue : ""} ${
          fieldProps.disabled || fieldProps.readOnly ? styles.disabled : ""
        }`}
      >
        {valueNotEmpty ? fieldProps.value : fieldProps.placeholder}
      </Typography>
    );
  };

  const displayHelperText = () => {
    let output = helperText;
    if (error && errorHelperText) {
      output = errorHelperText;
    }
    return typeof output === "string" ? (
      <Typography variant="body2">{output}</Typography>
    ) : (
      output
    );
  };

  const displayLabel = () => {
    let output = label;
    if (output && !fieldProps.required) {
      output = `${output ?? ""} ${t("general.optional")}`;
    }
    return output;
  };

  const displayPlaceholder = () => {
    let output = fieldProps.placeholder;
    if (output && !fieldProps.required) {
      output = `${output ?? ""} ${t("general.optional")}`;
    }
    return output;
  };

  return selectorField ? (
    <Stack
      className={`${styles.inputContainer} ${error && styles.error} ${
        styles.selector
      } ${(fieldProps.disabled || fieldProps.readOnly) && styles.disabled}`}
      data-error={error}
    >
      <Box
        className={`inputWrapper ${styles.inputWrapper} ${
          focusField && styles.focus
        } `}
        onFocus={handleFocusField}
        onBlur={handleBlurField}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {startAdornment && (
          <Box className={styles.adornment}>{startAdornment}</Box>
        )}
        <Box className={styles.input}>
          {selectField ? selectValueElement() : inputElement()}
        </Box>
        {endAdornment && <Box className={styles.adornment}>{endAdornment}</Box>}
      </Box>
      {displayHelperText() && (
        <Box className={styles.helperText}>{displayHelperText()}</Box>
      )}
    </Stack>
  ) : (
    <Stack
      className={`${styles.inputContainer} ${error && styles.error} ${
        (fieldProps.disabled || fieldProps.readOnly) && styles.disabled
      }`}
      data-error={error}
    >
      <Grid
        id={fieldProps.id + "InputWrapper"}
        container
        alignItems="center"
        className={`${styles.inputWrapper} ${focusField && styles.focus} `}
        onFocus={handleFocusField}
        onBlur={handleBlurField}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {startAdornment && (
          <Grid item xs="auto" className={styles.adornment}>
            {startAdornment}
          </Grid>
        )}
        {displayLabel() && (
          <Grid item xs="auto" className={styles.label}>
            {displayLabel()}
          </Grid>
        )}
        <Grid item xs className={styles.input}>
          {selectField ? selectValueElement() : inputElement()}
        </Grid>
        {endAdornment && (
          <Grid item xs="auto" className={styles.adornment}>
            {endAdornment}
          </Grid>
        )}
      </Grid>
      {displayHelperText() && (
        <Box className={styles.helperText}>{displayHelperText()}</Box>
      )}
    </Stack>
  );
};

export default memo(InputField);
