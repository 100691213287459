const MuiSkeleton = {
  styleOverrides: {
    root: {},
  },
  defaultProps: {
    // @ts-ignore
    animation: "wave" as typeof defaultProps.animation,
  },
};

export default MuiSkeleton;
