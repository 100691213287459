import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  Slide,
  DialogContent,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./question-field.module.scss";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  NotDraggingStyle,
  DraggingStyle,
} from "react-beautiful-dnd";
// import Popup from ".../../components";
import {
  ToggleButton,
  Popup,
  ActionItem,
  DragAndDrop,
  UploadFileField,
} from "..";
import { TransitionProps } from "@mui/material/transitions";
import InputField from "../input-field";
import { getSurveyQuestionType } from "../../services/survey";
import {
  FormValueType,
  useSurveyFormHook,
} from "../../pages/survey/useSurveyFormHook";
import { formatFileSize } from "../../utility";
import { mcAndRankOrderQuestionTypeId } from "../../pages/survey/surveyCreate";
import { QuestionFooterDragAndDrop } from "./FooterDragAndDrogComponent";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface QuestionFieldProps {
  data: Array<FormValueType>;
  disabled: boolean;
  onChange: (data: any) => void;
  // questionsLength: number;
}
interface QuestionTypeProps {
  _id: string;
  isDeleted: boolean;
  name: string;
}
interface ListItemProps {
  id: string;
  index: number;
  type: string;
  question: string;
  disabled: boolean;
}
interface DragDropItemType extends ListItemProps {
  TempData: Array<FormValueType>;
  // handleChange: (data: Array<ListItemProps>) => void;
  onClickCloseBtn: (id: number) => void;
  onClickQuestion: (index: number) => void;
}
// a little function to help us with reordering the result
const reorder = (
  list: Array<FormValueType>,
  startIndex: any,
  endIndex: any
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
interface radioContentType {
  id: string;
  content: string;
}

const PopupContent = (props: {
  data: Array<radioContentType>;
  value?: string;
  onChangeRadio: (value: string) => void;
}) => {
  return (
    <List disablePadding className={styles.formList}>
      {props.data.map(
        (item: { id: string; content: string }, index: number) => {
          return (
            <ListItem disablePadding className="formListItem" key={index}>
              <input
                type="radio"
                name="value"
                id={item.id}
                value={item.content}
                checked={item.id === props.value}
                onChange={(e) => props.onChangeRadio(e.target.id)}
              />
              <label htmlFor={item.id}>{item.content}</label>
            </ListItem>
          );
        }
      )}
    </List>
  );
};
const getNum = (count: number, min = 1, step = 1) =>
  Array.from({ length: count - (min - 1) }, (v, k) => k).map((k) => ({
    id: `${(k + min) * step}`,
    content: `${(k + min) * step}`,
  }));

const DragDropItem = ({
  id,
  index,
  type,
  question,
  TempData,
  disabled,
  onClickCloseBtn,
  onClickQuestion,
}: DragDropItemType) => {
  return (
    <Draggable
      key={index}
      draggableId={id}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${styles.dragDropItem} ${
            snapshot.isDragging ? "dragging" : ""
          }`}
        >
          <img
            src="/assets/images/grip-dots-vertical.svg"
            alt=""
            draggable={false}
          />
          <Box
            id={id}
            className={styles.questionWrapper}
            onClick={() => onClickQuestion(index)}
          >
            <Typography variant="body1" className={styles.questionsType}>
              {type}
            </Typography>
            <Typography
              variant="body1"
              className={`${styles.questionsContent} ${
                disabled ? styles.disableText : ""
              }`}
            >
              0{index + 1}. {question}
            </Typography>
          </Box>
          <button
            className="closeBtn"
            onClick={() => {
              if (disabled) {
                return false;
              } else {
                onClickCloseBtn(index);
              }
            }}
          >
            <img
              src="/assets/images/scheduledpostActionIcon_delete.svg"
              alt=""
            />
          </button>
        </div>
      )}
    </Draggable>
  );
};

const QuestionField = (props: QuestionFieldProps) => {
  const { t } = useTranslation();
  const id = React.useId();
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [isDragDisabled, setIsDragDisabled] = useState<boolean>(false);
  const { uploadQuestionAnswerImage } = useSurveyFormHook();

  const multipleSelectionList = [
    {
      id: "Exact Number",
      content: t("survey.create.multipleChoice.exactNumber"),
    },
    {
      id: "Unlimited",
      content: t("survey.create.multipleChoice.unlimited"),
    },
  ];
  const defaultValue = {
    questionType: {
      value: "",
      isError: false,
      isRequired: true,
    },
    question: {
      value: "",
      isError: false,
      isRequired: true,
    },
    options: {
      value: [
        {
          id: `question-${activeQuestion}-option-1`,
          index: 0,
          placeholder: `${t("socialWall.poll.option")} 1`,
          value: "",
          isOther: false,
          file: {
            value: null,
            isError: false,
            isRequired: false,
          },
        },
        {
          id: `question-${activeQuestion}-option-2`,
          index: 1,
          placeholder: `${t("socialWall.poll.option")} 2`,
          value: "",
          isOther: false,
          file: {
            value: null,
            isError: false,
            isRequired: false,
          },
        },
      ],

      isError: false,
      isRequired: false,
    },
    required: {
      value: false,
      isError: false,
      isRequired: false,
    },
    allowMultipleSelection: {
      value: false,
      isError: false,
      isRequired: false,
    },
    otherOption: {
      value: false,
      isError: false,
      isRequired: false,
    },
    pictureChoice: {
      value: false,
      isError: false,
      isRequired: false,
    },
    multipleSelection: {
      value: "",
      isError: false,
      isRequired: false,
    },
    answerNumbers: {
      value: "",
      isError: false,
      isRequired: false,
    },
    opinionScale: {
      value: "",
      isError: false,
      isRequired: false,
    },
    highScoreLabel: {
      value: "",
      isError: false,
      isRequired: false,
    },
    lowScoreLabel: {
      value: "",
      isError: false,
      isRequired: false,
    },
    uploadPhotos: {
      value: null,
      isError: false,
      isRequired: false,
    },
  };

  const [items, setItems] = useState(props.data);
  const [tempItems, setTempItems] = useState(props.data);

  let TempData: Array<FormValueType> = items;
  const [formValue, setFormValue] = useState<FormValueType>(defaultValue);
  const [dndErrorNumber, setDndErrorNumber] = useState<Array<number>>([]);
  const [tempPictureChoice, setTempPictureChoice] = useState(false);
  // const [optionCount, setOptionCount] = useState<number>(tempItems.length);
  const [isLoading, setIsLoading] = useState(false);
  // const [addingQuestion, setAddingQuestion] = useState(false);
  const [questionTypeList, setQuestionTypeList] = useState(
    Array<QuestionTypeProps>
  );
  const [popupTitle, setPopupTitle] = useState("");
  const [popupBtnText, setPopupBtnText] = useState("");
  const [openQuestionDropdown, setOpenQuestionDropdown] = useState(false);
  const [openQuestionPopup, setOpenQuestionPopup] = useState(false);
  const [openMultipleSelection, setOpenMultipleSelection] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  // console.log("formValue", formValue);
  useEffect(() => {
    setIsLoading(true);
    getSurveyQuestionType().then((response) => {
      setQuestionTypeList(response.data.surveyQuestionType);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    setItems(props.data);
    setTempItems(props.data);
  }, [props.data]);

  const handleSetQuestionType = (type: any) => {
    let updateFormValue = {
      ["questionType"]: {
        ...formValue.questionType,
        value: type,
      },
      ["options"]: {
        ...formValue.options,
        isRequired: false,
      },
      ["multipleSelection"]: {
        ...formValue.multipleSelection,
        isRequired: false,
      },
      ["answerNumbers"]: {
        ...formValue.answerNumbers,
        isRequired: false,
      },
      ["opinionScale"]: {
        ...formValue.opinionScale,
        isRequired: false,
      },
    };

    //Rank order
    if (type === "6448afcf45a431c8981ff6d5") {
      updateFormValue = {
        ...updateFormValue,
        ["options" as keyof typeof formValue]: {
          ...formValue.options,
          isRequired: true,
        },
      };
    }
    //Multiple choice
    else if (type === "6448afac1dde4cea6034cda0") {
      // console.log(
      //   "formValue.allowMultipleSelection.value",
      //   formValue.allowMultipleSelection.value
      // );
      updateFormValue = {
        ...updateFormValue,
        ["options" as keyof typeof formValue]: {
          ...formValue.options,
          value: formValue.options.value.slice(0, 7),
          isRequired: true,
        },
        ["multipleSelection" as keyof typeof formValue]: {
          ...formValue.multipleSelection,
          isRequired: formValue.allowMultipleSelection.value ? true : false,
        },
        ["answerNumbers" as keyof typeof formValue]: {
          ...formValue.answerNumbers,
          isRequired:
            formValue.allowMultipleSelection.value &&
            formValue.multipleSelection.value?.toLowerCase() === "exact number"
              ? true
              : false,
        },
      };
    }
    //5-Star rating
    // else if (type === "6448afbb939f86fd85f4a02d") {
    // }
    //Opinion scale
    else if (type === "6448af76a5f840a74e23814f") {
      updateFormValue = {
        ...updateFormValue,
        ["opinionScale" as keyof typeof formValue]: {
          ...formValue.opinionScale,
          isRequired: true,
        },
        ["highScoreLabel" as keyof typeof formValue]: {
          ...formValue.highScoreLabel,
          isRequired: true,
        },
        ["lowScoreLabel" as keyof typeof formValue]: {
          ...formValue.lowScoreLabel,
          isRequired: true,
        },
      };
    }
    //Open text
    // else if (type === "6448c5c9062b1934c6e2abe7") {

    // }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!openQuestionPopup) {
      setTempItems((prev) => [...prev, defaultValue]);
      setActiveQuestion(tempItems.length);
    }

    setOpenQuestionPopup(true);
    setOpenQuestionDropdown(false);
  };
  const handleClosePopup = () => {
    // console.log("handleClosePopup", [items, formValue]);
    setActiveQuestion(items.length === 0 ? 0 : items.length - 1);
    setTempItems(items);
    setFormValue(defaultValue);
    setOpenQuestionPopup(false);
  };
  const handleOpenDropdown = () => {
    setOpenQuestionDropdown(true);
  };
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      TempData,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
    props.onChange(newItems);
  };
  const handleOtherOption = (checked: boolean) => {
    if (checked) {
      setFormValue((prev) => ({
        ...prev,
        ["options"]: {
          ...prev.options,
          value: [
            ...prev.options.value,
            {
              id: `question-${activeQuestion}-option-Other`,
              index: prev.options.value.length,
              placeholder: `${t("survey.create.other")}`,
              value: "Others",
              isOther: true,
              file: {
                value: null,
                isError: false,
                isRequired: true,
              },
            },
          ],
        },
      }));
    } else {
      setFormValue((prev) => ({
        ...prev,
        ["options"]: {
          ...prev.options,
          value: prev.options.value.filter(
            (item: any) => item.id !== `question-${activeQuestion}-option-Other`
          ),
        },
      }));
    }
  };

  const handleAddQuestion = () => {
    const optionsIsValidate = checkOptionValid();
    if (questionValidation() && optionsIsValidate) {
      setTempItems((prev) =>
        tempItems.length === 1
          ? [formValue]
          : [
              ...prev.slice(0, activeQuestion),
              formValue,
              ...prev.slice(activeQuestion + 1),
            ]
      );
      const newQuestion = {
        ...defaultValue,
        ["questionType"]: {
          ...defaultValue["questionType"],
          value: questionTypeList[0]._id,
        },
        ["options"]: {
          ...defaultValue["options"],
          value: [
            {
              id: `question-${activeQuestion + 2}-option-1`,
              index: 0,
              placeholder: `${t("socialWall.poll.option")} 1`,
              value: "",
              isOther: false,
              file: {
                value: null,
                isError: false,
                isRequired: false,
              },
            },
            {
              id: `question-${activeQuestion + 2}-option-2`,
              index: 1,
              placeholder: `${t("socialWall.poll.option")} 2`,
              value: "",
              isOther: false,
              file: {
                value: null,
                isError: false,
                isRequired: false,
              },
            },
          ],
        },
      };
      // console.log("🚀 ~ handleAddQuestion ~ newQuestion:", newQuestion);
      setFormValue(newQuestion);
      setTempItems((prev) => [...prev, defaultValue]);
      setActiveQuestion(tempItems.length);
    }
  };
  const handleEditQuestion = (QuestionNum: number) => {
    setFormValue(items[QuestionNum]);
    setActiveQuestion(QuestionNum);
    setPopupTitle(t("survey.create.editQuestions"));
    setPopupBtnText(t("general.save"));
    setOpenQuestionPopup(true);
  };

  const handleDeleteQuestion = () => {
    // setOpenDeletePopup(true);
    const questionToDelete = activeQuestion;
    if (tempItems.length === 1) {
      setFormValue(defaultValue);
    } else {
      if (questionToDelete === tempItems.length - 1) {
        setFormValue(tempItems[activeQuestion - 1]);
        setActiveQuestion(activeQuestion - 1);
      } else {
        setFormValue(tempItems[activeQuestion + 1]);
      }
      setTempItems((prev) =>
        prev.filter(function (value, index) {
          return index !== questionToDelete;
        })
      );
    }
  };

  const handleRemoveItem = (id: number) => {
    // setOpenDeletePopup(true);
    // const newItems = TempData.filter(
    //   (item, index) => `question-${index}` !== id
    // );
    let newItems = [...TempData];
    newItems.splice(id, 1);
    // console.log("🚀 ~ handleRemoveItem ~ newItems:", newItems);
    setActiveQuestion(newItems.length === 0 ? 0 : newItems.length - 1);
    setItems(newItems);
    setTempItems(newItems);
    props.onChange(newItems);
  };

  const handleChangeQuestion = (questionNum: number) => {
    const optionsIsValidate = checkOptionValid();
    if (questionValidation() && optionsIsValidate) {
      setTempItems((prev) =>
        tempItems.length === 1
          ? [formValue]
          : [
              ...prev.slice(0, activeQuestion),
              formValue,
              ...prev.slice(activeQuestion + 1),
            ]
      );
      setActiveQuestion(questionNum);
      setFormValue(tempItems[questionNum]);
    }
  };

  const handleToggleButtonChange = (name: string, checked: boolean) => {
    const formName = name as keyof FormValueType;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: checked,
      },
    };
    // console.log(
    //   "🚀 ~ handleToggleButtonChange ~ updateFormValue:",
    //   updateFormValue
    // );

    if (name === "allowMultipleSelection") {
      updateFormValue = {
        ...updateFormValue,
        ["multipleSelection" as keyof typeof formValue]: {
          ...formValue.multipleSelection,
          isRequired: checked,
        },
        ["answerNumbers" as keyof typeof formValue]: {
          ...formValue.answerNumbers,
          isRequired:
            checked &&
            formValue.multipleSelection.value?.toLowerCase() === "exact number"
              ? true
              : false,
        },
      };
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));
  };
  const handleSelectorChange = (name: string, value: string) => {
    const formName = name as keyof FormValueType;

    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
        isError: false,
      },
    };

    if (name === "multipleSelection") {
      updateFormValue = {
        ...updateFormValue,
        ["answerNumbers" as keyof typeof formValue]: {
          ...formValue.answerNumbers,
          value: value === "Exact Number" ? formValue.answerNumbers.value : "",
          isRequired: value === "Exact Number" ? true : false,
        },
      };
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));
  };
  const handleInputFieldChange = (name: string, value: any) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
      },
    };

    if (formValue[formName].isRequired) {
      let inValid = value === "" || !value;

      updateFormValue = {
        [formName]: {
          ...updateFormValue[formName],
          isError: inValid,
        },
      };
    }
    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));
  };

  const MultipleSelectionPopupTitle = () => {
    return (
      <Grid container alignItems="center" className={styles.popupHeader}>
        <Grid item xs>
          <Typography variant="h4">
            {t("survey.create.multipleChoice.multipleSelection")}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <IconButton
            disableRipple
            size="small"
            className={styles.closeButton}
            onClick={() => setOpenMultipleSelection(false)}
          >
            <img src="/assets/images/close_btn.svg" alt="" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const questionValidation = () => {
    const requiredValue = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].isRequired)
    );
    // console.log("questionValidation", formValue);
    // console.log("questionValidation", requiredValue);

    let errorField: Array<string> = [];
    for (const [key, value] of Object.entries(requiredValue)) {
      if (!value.value) {
        errorField.push(key);
      } else if (Array.isArray(value.value)) {
        if (key === "options") {
          const isNotFill = value.value.some((item) => item.value === "");
          if (isNotFill) errorField.push(key);
        } else if (value.value.length <= 0) {
          errorField.push(key);
        }
      }
    }

    if (errorField.length > 0) {
      let errorFieldObj: FormValueType = formValue;
      errorField.forEach((key: string) => {
        errorFieldObj = {
          ...errorFieldObj,
          [key]: {
            ...errorFieldObj[key],
            isError: true,
          },
        };
      });
      setFormValue(errorFieldObj);
      return false;
    } else {
      return true;
    }
  };

  const [failMsg, setFailMsg] = useState({
    open: false,
    message: "",
  });

  const handleFileFieldChange = async (
    name: string,
    value: File | File[] | null
  ) => {
    if (!value || Array.isArray(value)) return false;

    try {
      const response = await uploadQuestionAnswerImage(value);
      // console.log("response", response);
      // if (response.status === 200) {
      if (response)
        setTimeout(() => {
          handleInputFieldChange(name, response);
        }, 800);
      // }
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response.data) {
        setFailMsg({
          open: true,
          message: error.response.data.message,
        });
      }
      throw error;
    }
  };

  const PopupTitle: FC<{ title: any; onCloseBtn?: () => void }> = (props) => {
    return (
      <Grid container alignItems="center" className={styles.popupHeader}>
        <Grid item xs>
          <Typography variant="h4">{props.title}</Typography>
        </Grid>
        <Grid item xs="auto">
          <IconButton
            disableRipple
            size="small"
            className={styles.closeButton}
            onClick={() => {
              props.onCloseBtn && props.onCloseBtn();
              setOpenPopup(false);
            }}
          >
            <img src="/assets/images/close_btn.svg" alt="" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  function checkOptionValid() {
    // console.log("formValue", formValue);
    if (formValue.question.value === "") return false;
    let valid: boolean = true;
    let errorIndex: Array<number> = [];
    const currentQuestion = formValue;
    if (
      mcAndRankOrderQuestionTypeId.includes(currentQuestion.questionType.value)
    ) {
      currentQuestion.options.value.forEach((option: any, idx: number) => {
        if (
          option.value === "" ||
          (currentQuestion.pictureChoice.value && option.file.value === null)
        ) {
          // console.log(`Option ${idx} is empty`);
          errorIndex.push(idx + 1);
          valid = false;
        }
      });
      setDndErrorNumber(errorIndex);
    }
    return valid;
  }

  useEffect(() => {
    setTempPictureChoice(formValue.pictureChoice.value);
  }, [formValue]);

  // useEffect(() => {
  //   if (questionValidation() && checkOptionValid()) {
  //     setIsDragDisabled(false);
  //   }
  // }, [formValue]);

  function onQuestionDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = dndReorder(
      tempItems,
      result.source.index,
      result.destination.index
    );
    const optionsIsValidate = checkOptionValid();
    if (!questionValidation() || !optionsIsValidate) return;
    setActiveQuestion(result.destination.index);
    setTempItems(newItems);
    // setItems(newItems);
  }

  return (
    <>
      <Stack className={styles.questionTypeSelectField} direction="row">
        <Typography variant="body1">
          {t("survey.create.questions")} ({items.length})
        </Typography>

        <Typography
          variant="body1"
          className={props.disabled ? styles.disabledText : ""}
          onClick={() => {
            if (props.disabled) {
              return false;
            } else {
              setPopupTitle(t("survey.create.addQuestions"));
              setPopupBtnText(t("survey.create.add"));
              handleOpenDropdown();
            }
          }}
        >
          + {t("survey.create.addQuestions")}
        </Typography>
      </Stack>
      <Box className={styles.itemWrapper}>
        <div id="questions" className={styles.dragDrop}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.dragDropContainer}
                >
                  {items.map((item, index) => (
                    <DragDropItem
                      key={index}
                      id={`question-${index}`}
                      index={index}
                      type={t(
                        `survey.create.questionsType.${
                          questionTypeList.find(
                            (type) => type._id === item.questionType.value
                          )?.name
                        }`
                      )}
                      question={item.question.value}
                      TempData={TempData}
                      // handleChange={getChangeValue}
                      disabled={props.disabled}
                      onClickCloseBtn={handleRemoveItem}
                      onClickQuestion={(value) => {
                        if (!props.disabled) {
                          handleEditQuestion(value);
                        }
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Box>

      <Dialog
        id="questionPopup"
        open={openQuestionPopup}
        onClose={handleClosePopup}
        fullScreen
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            overflow: "hidden",
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          className={styles.selectPopupHeader}
        >
          <Grid item xs="auto">
            <IconButton
              id="closeSelectPopupButton"
              className={styles.closeSelectPopupButton}
              disableRipple
              onClick={handleClosePopup}
            >
              <img src="/assets/images/close_btn.svg" alt="" />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography variant="h3">{popupTitle}</Typography>
          </Grid>
          <Grid item xs>
            <Typography
              variant="body1"
              className={styles.addButton}
              onClick={() => {
                const optionsIsValidate = checkOptionValid();
                if (questionValidation() && optionsIsValidate) {
                  const updateItemsValue =
                    tempItems.length === 1
                      ? [formValue]
                      : [
                          ...tempItems.slice(0, activeQuestion),
                          formValue,
                          ...tempItems.slice(activeQuestion + 1),
                        ];

                  setTempItems(updateItemsValue);
                  setItems(updateItemsValue);
                  props.onChange(updateItemsValue);
                  setFormValue(defaultValue);

                  //add on close function
                  setOpenQuestionPopup(false);
                }
              }}
            >
              {popupBtnText}
            </Typography>
          </Grid>
        </Grid>
        {/* {tempItems.length > 0 && ( */}
        <DialogContent className={styles.questionFormWrapper}>
          <Box className={styles.questionWrapper}>
            <Stack direction="row" justifyContent="space-between">
              <Box
                className={styles.questionTypeDropdownField}
                onClick={handleOpenDropdown}
              >
                <Grid item xs>
                  <Typography variant="body1">
                    {t(
                      `survey.create.questionsType.${
                        questionTypeList.find(
                          (item) => item._id === formValue.questionType.value
                        )?.name
                      }`
                    )}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <img src="/assets/images/chevron_down.svg" alt="" />
                </Grid>
              </Box>
              <img
                src="/assets/images/scheduledpostActionIcon_delete.svg"
                alt=""
                onClick={() => setOpenDeletePopup(true)}
              />
            </Stack>
            <Box className={styles.formField}>
              <InputField
                id="question"
                name="question"
                placeholder={t("survey.create.question")}
                value={formValue.question.value}
                error={formValue.question.isError}
                required={formValue.question.isRequired}
                // disabled={editMode}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleInputFieldChange("question", value);
                }}
              />
            </Box>
            <Box className={styles.formField}>
              <UploadFileField
                id="uploadPhotos"
                name="uploadPhotos"
                label={t("survey.create.addQuestionsImage")}
                helperText={t("survey.create.maxSize", {
                  size: formatFileSize(5 * 1000 * 1000),
                })}
                limitSize={5 * 1000 * 1000}
                accept="image/*"
                inputFieldType="question"
                value={formValue.uploadPhotos.value}
                error={formValue.uploadPhotos.isError}
                required={formValue.uploadPhotos.isRequired}
                onChange={(files) => {
                  handleFileFieldChange("uploadPhotos", files);
                }}
                onRemove={(files) => {
                  handleInputFieldChange("uploadPhotos", files);
                }}
                dimension={{ width: 375, height: 211 }}
                enableEdit={true}
                disableDimensionChecking
                optionsTitle={t("myProfile.editProfile.editCoverPhoto")}
              />
            </Box>
            {/* for MC and Rank order */}
            {(formValue.questionType.value === "6448afac1dde4cea6034cda0" ||
              formValue.questionType.value === "6448afcf45a431c8981ff6d5") && (
              <Stack className={styles.dragAndDropWrapper} gap="12px">
                <Typography>{t("survey.create.answerChoices")}</Typography>
                <DragAndDrop
                  key={`activeQuestion-${activeQuestion}-dragAndDrop`}
                  options={formValue.options.value}
                  max={
                    formValue.questionType.value === "6448afac1dde4cea6034cda0"
                      ? 7
                      : undefined
                  }
                  onChange={(options) => {
                    // console.log("options", options);
                    handleInputFieldChange("options", options.options);
                  }}
                  optionName={`question-${activeQuestion}-option`}
                  isPictureChoice={formValue.pictureChoice.value}
                  isOtherOff={(checked) => {
                    setFormValue((prev) => ({
                      ...prev,
                      ["otherOption"]: {
                        ...prev.otherOption,
                        value: checked,
                      },
                    }));
                  }}
                  uploadFn={uploadQuestionAnswerImage}
                />
                {dndErrorNumber.length > 0 ? (
                  <div className={styles.errorMsg}>
                    {`${t(
                      "survey.create.multipleChoice.option"
                    )} ${dndErrorNumber.join(", ")} ${t(
                      "survey.create.required"
                    )}
                      `}
                  </div>
                ) : (
                  <></>
                )}
              </Stack>
            )}

            {/* for Opinion scale order */}
            {formValue.questionType.value === "6448af76a5f840a74e23814f" && (
              <>
                <Box className={styles.formField}>
                  <Box
                    className={`${styles.fieldWrapper} ${
                      formValue.opinionScale.isError ? "error" : ""
                    }`}
                  >
                    <label>
                      {t("survey.create.questionsType.opinionScale")}
                    </label>
                    <button
                      className="selectorField"
                      onClick={() => {
                        setOpenPopup(true);
                      }}
                    >
                      {formValue.opinionScale.value}
                      <img src="/assets/images/chevron-down-grey.svg" alt="" />
                    </button>
                  </Box>
                </Box>
                <Box className={styles.formField}>
                  <InputField
                    id="highScoreLabel"
                    name="highScoreLabel"
                    label={t("survey.create.opinionScale.highScoreLabel")}
                    placeholder={t("survey.create.opinionScale.pleaseInput")}
                    value={formValue.highScoreLabel.value}
                    error={formValue.highScoreLabel.isError}
                    required={formValue.highScoreLabel.isRequired}
                    // disabled={editMode}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleInputFieldChange("highScoreLabel", value);
                    }}
                  />
                </Box>
                <Box className={styles.formField}>
                  <InputField
                    id="lowScoreLabel"
                    name="lowScoreLabel"
                    label={t("survey.create.opinionScale.lowScoreLabel")}
                    placeholder={t("survey.create.opinionScale.pleaseInput")}
                    value={formValue.lowScoreLabel.value}
                    error={formValue.lowScoreLabel.isError}
                    required={formValue.lowScoreLabel.isRequired}
                    // disabled={editMode}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleInputFieldChange("lowScoreLabel", value);
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box className={styles.commonFieldWrapper}>
            <Box className={styles.formToggleField}>
              <ToggleButton
                label={t("survey.create.required")}
                checked={formValue.required.value}
                setChecked={(checked: boolean) => {
                  handleToggleButtonChange("required", checked);
                }}
              />
            </Box>
            {/* for MC and Rank order Picture Choice*/}
            {(formValue.questionType.value === "6448afac1dde4cea6034cda0" ||
              formValue.questionType.value === "6448afcf45a431c8981ff6d5") && (
              <>
                <Box className={styles.formToggleField}>
                  <ToggleButton
                    label={t("survey.create.pictureChoice")}
                    checked={formValue.pictureChoice.value}
                    setChecked={(checked: boolean) => {
                      handleToggleButtonChange("pictureChoice", checked);
                    }}
                  />
                </Box>
              </>
            )}
            {/* for mc */}
            {formValue.questionType.value === "6448afac1dde4cea6034cda0" && (
              <>
                <Box className={styles.formToggleField}>
                  <ToggleButton
                    label={t(
                      "survey.create.multipleChoice.allowMultipleSelection"
                    )}
                    checked={formValue.allowMultipleSelection.value}
                    setChecked={(checked: boolean) => {
                      handleToggleButtonChange(
                        "allowMultipleSelection",
                        checked
                      );
                    }}
                  />
                </Box>
                {formValue.allowMultipleSelection.value && (
                  <>
                    <Box className={styles.formField}>
                      <label>
                        {t("survey.create.multipleChoice.multipleSelection")}
                      </label>
                      <button
                        className={`selectorField ${
                          formValue.multipleSelection.isError ? "error" : ""
                        }`}
                        onClick={() => setOpenMultipleSelection(true)}
                      >
                        {formValue.multipleSelection.value
                          ? multipleSelectionList.find(
                              (item) =>
                                item.id === formValue.multipleSelection.value
                            )?.content
                          : t("survey.create.opinionScale.pleaseSelect")}

                        <img
                          src="/assets/images/chevron-down-grey.svg"
                          alt=""
                        />
                      </button>
                    </Box>
                    {openMultipleSelection && (
                      <Popup
                        isOpen={openMultipleSelection}
                        setIsOpen={(close: boolean) =>
                          setOpenMultipleSelection(close)
                        }
                        title={<MultipleSelectionPopupTitle />}
                        content={
                          <PopupContent
                            data={multipleSelectionList}
                            value={formValue.multipleSelection.value}
                            onChangeRadio={(value) => {
                              handleSelectorChange("multipleSelection", value);
                              setOpenMultipleSelection(false);
                            }}
                          />
                        }
                        disableActions
                        onClickCancel={() => {
                          setOpenMultipleSelection(false);
                        }}
                        onClickConfirm={() => {
                          setOpenMultipleSelection(false);
                        }}
                      />
                    )}
                    {formValue.multipleSelection.value?.toLowerCase() ===
                      "exact number" && (
                      <>
                        <Box className={styles.formField}>
                          <label>
                            {t("survey.create.multipleChoice.answerNumbers")}
                          </label>
                          <button
                            className={`selectorField ${
                              formValue.answerNumbers.isError ? "error" : ""
                            }`}
                            onClick={() => setOpenPopup(true)}
                          >
                            {formValue.answerNumbers.value
                              ? formValue.answerNumbers.value
                              : t("survey.create.opinionScale.pleaseSelect")}
                            <img
                              src="/assets/images/chevron-down-grey.svg"
                              alt=""
                            />
                          </button>
                        </Box>
                      </>
                    )}
                  </>
                )}
                <Box className={styles.formToggleField}>
                  <ToggleButton
                    label={t("survey.create.otherOption")}
                    checked={formValue.otherOption.value}
                    setChecked={(checked: boolean) => {
                      handleOtherOption(checked);
                      handleToggleButtonChange("otherOption", checked);
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box className={styles.questionFormFooter}>
            <Box className={styles.footerItemContainer}>
              <QuestionFooterDragAndDrop
                items={tempItems}
                onOrderChange={(items, result) => {
                  setTempItems(items);
                  if (result.destination)
                    setActiveQuestion(result.destination.index);
                }}
                direction="horizontal"
                droppableClassName={styles.footerDroppableContainer}
                render={(_, index) => {
                  const active = activeQuestion === index;
                  return (
                    <Box
                      key={index}
                      className={`${styles.footerItem} ${
                        active ? "active" : ""
                      }`}
                      onClick={() => !active && handleChangeQuestion(index)}
                    >
                      {index + 1}
                    </Box>
                  );
                }}
              />
              <Box
                className={`${styles.footerItem} ${styles.addQuestionBtn}`}
                onClick={handleAddQuestion}
              >
                <img src="/assets/images/plus_grey.svg" alt="" />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        {/* )} */}
      </Dialog>
      <Popup
        isOpen={openQuestionDropdown}
        title={
          <Stack direction="row" className={styles.questionTypePopupHeader}>
            <Typography variant="h4">
              {t("survey.create.questionsType.title")}
            </Typography>
            <img
              src="/assets/images/close_btn_green.svg"
              onClick={() => {
                setOpenQuestionDropdown(false);
              }}
            />
          </Stack>
        }
        content={
          <List className={styles.listWrapper}>
            {questionTypeList.map((item: any, index: number) => {
              return (
                <ListItem sx={{ padding: "0px" }} key={index}>
                  <ActionItem
                    text={t(`survey.create.questionsType.${item.name}`)}
                    onClick={() => handleSetQuestionType(item._id)}
                  />
                </ListItem>
              );
            })}
          </List>
        }
        setIsOpen={(toOpen: boolean) => setOpenQuestionDropdown(toOpen)}
        disableActions
      />
      {tempItems.length > 0 && openPopup && (
        <Popup
          isOpen={openPopup}
          setIsOpen={(close: boolean) => setOpenPopup(close)}
          title={
            <PopupTitle
              title={
                formValue.questionType.value === "6448af76a5f840a74e23814f" //Optional scale
                  ? t("survey.create.questionsType.opinionScale")
                  : t("survey.create.multipleChoice.answerNumbers")
              }
            />
          }
          content={
            <PopupContent
              data={
                formValue.questionType.value === "6448af76a5f840a74e23814f" //Optional scale
                  ? getNum(2, 1, 5).reverse()
                  : getNum(formValue.options.value.length, 2).reverse()
              }
              value={
                formValue.questionType.value === "6448af76a5f840a74e23814f"
                  ? formValue.opinionScale.value.toString()
                  : formValue.answerNumbers.value.toString()
              }
              onChangeRadio={(value) => {
                formValue.questionType.value === "6448af76a5f840a74e23814f" //Optional scale
                  ? handleSelectorChange("opinionScale", value)
                  : handleSelectorChange("answerNumbers", value);
                setOpenPopup(false);
              }}
            />
          }
          disableActions
          onClickCancel={() => {
            setOpenPopup(false);
          }}
          onClickConfirm={() => {
            setOpenPopup(false);
          }}
        />
      )}
      <Snackbar
        open={failMsg.open}
        autoHideDuration={6000}
        onClose={() => setFailMsg((prev) => ({ ...prev, open: false }))}
      >
        <Alert severity="error">{failMsg.message}</Alert>
      </Snackbar>
      <Popup
        isOpen={openDeletePopup}
        setIsOpen={(close: boolean) => setOpenPopup(close)}
        title={
          <PopupTitle
            title={t("survey.delete.question.title")}
            onCloseBtn={() => setOpenDeletePopup(false)}
          />
        }
        content={t("survey.delete.question.description")}
        confirmBtnText={t("general.delete")}
        buttonColor="#C82014"
        onClickCancel={() => {
          setOpenDeletePopup(false);
        }}
        onClickConfirm={() => {
          handleDeleteQuestion();
          setOpenDeletePopup(false);
        }}
      />
    </>
  );
};

const dndReorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default QuestionField;
