import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./polling.module.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import Popup from ".../../components";
import {
  ToggleButton,
  Popup,
  RoundButton,
  UploadFileField,
} from "../../components";
import moment from "moment";
import { convertDateTime } from "../../utility";
import Picker from "react-mobile-picker";
interface PollingProps {
  data: DataProps;
  options: Array<ListItemProps>;
  disabled: boolean;
  // onChange: (options: ListItemProps | Array<ListItemProps> | null) => void;
  onChange: (data: any) => void;
  uploadFn?: (file: File) => Promise<string | null>;
}
interface DataProps {
  title: string;
  options: Array<ListItemProps>;
  duration: number;
  pollEndTime?: number;
  isAnonymous: boolean;
  isPictureChoice: boolean;
}
interface ListItemProps {
  id: string;
  index: number;
  placeholder: string;
  value: string;
  disabled?: boolean;
  file?: {
    value: File | null;
    isError: boolean;
    isRequired: boolean;
  };
}

interface DragDropItemType extends ListItemProps {
  TempData: Array<ListItemProps>;
  handleChange: (data: Array<ListItemProps>) => void;
  onClickCloseBtn: (id: string) => void;
  isShow?: boolean;
  uploadFn?: (file: File) => Promise<string | null>;
}
// a little function to help us with reordering the result
const reorder = (
  list: Array<ListItemProps>,
  startIndex: any,
  endIndex: any
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface radioContentType {
  id: string;
  content: string;
}

const PopupContent = (props: {
  value: string;
  data: Array<radioContentType>;
  onChangeRadio: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState(props.value);
  // useEffect(() => {
  //   console.log("selected", selectedDay);
  // }, [selectedDay]);

  const day = Array.from(Array(31), (_, index) => ({
    id: index + 1 + " " + t("socialWall.poll.days"),
    value: index + 1 + " " + t("socialWall.poll.days"),
  }));

  const optionGroups = {
    day: day.map((item) => item.value),
  };

  return (
    <Stack className={styles.timePickerContainer}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={styles.timePickerWrapper}
      >
        <Picker
          optionGroups={optionGroups}
          valueGroups={{ day: selectedDay }}
          onChange={(name, value) => {
            setSelectedDay(value);
            // props.onChangeRadio(value);
          }}
          wheel="normal"
          height={153}
          itemHeight={32}
        />
      </Grid>
      <Grid
        item
        xs="auto"
        sx={{
          width: "100%",
        }}
      >
        <RoundButton
          id="confirmSelecteDaysButton"
          // disabled={
          //   !selectedValue || inputProps.disabled || selectedValue.length <= 0
          // }
          // fullWidth={!multiple}
          onClick={() => props.onChangeRadio(selectedDay)}
        >
          {t("general.confirm")}
        </RoundButton>
      </Grid>
    </Stack>
    // <List disablePadding className={styles.formList}>
    //   {props.data.map(
    //     (item: { id: string; content: string }, index: number) => {
    //       return (
    //         <ListItem disablePadding className="formListItem" key={index}>
    //           <input
    //             type="radio"
    //             name="poll-days"
    //             id={item.id}
    //             value={item.content}
    //             checked={item.content === checkedItem}
    //             onChange={(e) => {
    //               setCheckedItem(e.target.value);
    //               props.onChangeRadio(e.target.value);
    //             }}
    //           />
    //           <label htmlFor={item.id}>{item.content}</label>
    //         </ListItem>
    //       );
    //     }
    //   )}
    // </List>
  );
};

const DragDropItem = ({
  id,
  index,
  placeholder,
  value,
  file,
  TempData,
  handleChange,
  onClickCloseBtn,
  disabled,
  isShow = false,
  uploadFn,
}: DragDropItemType) => {
  // console.log(typeof id, id);

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${styles.dragDropItem} ${
            snapshot.isDragging ? "dragging" : ""
          }`}
        >
          <img
            src="/assets/images/grip-dots-vertical.svg"
            alt=""
            draggable={false}
          />
          {file && isShow && (
            <UploadFileField
              key={`answer-${id}`}
              id={`answer-${id}`}
              name={`answer-${id}`}
              limitSize={5 * 1000 * 1000}
              accept="image/*"
              inputFieldType="answer"
              value={file.value}
              error={file.isError}
              required={file.isRequired}
              onChange={async (files) => {
                if (uploadFn && files) {
                  const imageUrl = await uploadFn(files as File);
                  const newItems = TempData.map((item: any) => {
                    if (item.id === id) {
                      return {
                        ...item,
                        file: { ...item.file, value: imageUrl },
                      };
                    }
                    return item;
                  });
                  handleChange(newItems);
                } else {
                  const newItems = TempData.map((item: any) => {
                    if (item.id === id) {
                      return { ...item, file: { ...item.file, value: files } };
                    }
                    return item;
                  });
                  handleChange(newItems);
                }
              }}
              onRemove={(files) => {
                handleChange(
                  TempData.map((item: any) => {
                    if (item.id === id) {
                      return { ...item, file: { ...item.file, value: null } };
                    }
                    return item;
                  })
                );
              }}
              dimension={{ width: 375, height: 281 }}
              enableEdit={true}
              disableDimensionChecking
              viewOnly={disabled}
            />
          )}
          <input
            type="text"
            id={id}
            name={id}
            placeholder={placeholder}
            className={styles.textInput}
            defaultValue={value}
            disabled={disabled}
            onChange={(e) => {
              const newItems = TempData.map((item) => {
                if (item.id === id) {
                  return { ...item, value: e.target.value };
                }
                return item;
              });
              handleChange(newItems);
            }}
          />
          {TempData.length > 2 && !disabled && (
            <button
              className="closeBtn"
              onClick={() => {
                onClickCloseBtn(id);
              }}
            >
              <img src="/assets/images/xmark.svg" alt="" />
            </button>
          )}
        </div>
      )}
    </Draggable>
  );
};

const Polling = (props: PollingProps) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(props.data.options);
  const [open, setOpen] = useState<boolean>(false);
  let TempData: Array<ListItemProps> = items;
  const [optionCount, setOptionCount] = useState<number>(TempData.length);
  const [daysValue, setDaysValue] = useState<string>(
    props.data.duration
      ? `${props.data.duration} ${t("socialWall.poll.days")}`
      : t("socialWall.poll.pleaseSelect")
  );
  const [isAllowVote, setAllowVote] = useState<boolean>(props.data.isAnonymous);
  const [isPictureChoice, setPictureChoice] = useState<boolean>(
    props.data.isPictureChoice
  );
  const [isAllOptionfilled, setAllOptionfilled] = useState<boolean>(false);

  useEffect(() => {
    // console.log("formValue poll", daysValue);
  }, []);

  useEffect(() => {
    props.onChange({ ...props.data, options: items });
  }, [items]);

  useEffect(() => {
    let duration = parseInt(daysValue.split(" Days")[0]);
    props.onChange({ ...props.data, duration: duration });
  }, [daysValue]);

  useEffect(() => {
    props.onChange({ ...props.data, isAnonymous: isAllowVote });
  }, [isAllowVote]);

  useEffect(() => {
    props.onChange({ ...props.data, isPictureChoice: isPictureChoice });
  }, [isPictureChoice]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      TempData,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
  };
  const getChangeValue = (data: Array<ListItemProps>) => {
    TempData = data;
    setItems(TempData);
  };
  const handleAddOption = () => {
    const updateData = [
      ...TempData,
      {
        id: `polling-${optionCount + 1}`,
        index: TempData.length + 1,
        placeholder: `${t("socialWall.poll.option")} ${optionCount + 1}`,
        value: "",
        file: {
          value: null,
          isError: false,
          isRequired: false,
        },
      },
    ];
    setOptionCount(optionCount + 1);
    setItems(updateData);
  };
  const handleRemoveItem = (id: string) => {
    const newItems = TempData.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const PopupTitle = () => {
    return (
      <Grid container alignItems="center" className={styles.popupHeader}>
        <Grid item xs>
          <Typography variant="h4">
            {t("socialWall.poll.pollsEndAt")}
          </Typography>
          <span className="desc">{t("socialWall.poll.pollsEndAtDesc")}</span>
        </Grid>
        <Grid item xs="auto">
          <IconButton
            disableRipple
            size="small"
            className={styles.closeButton}
            onClick={() => setOpen(false)}
          >
            <img src="/assets/images/close_btn.svg" alt="" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const getDays = (count: number) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `days-${k + 1}`,
      content: `${k + 1} Days`,
    }));
  return (
    <>
      <Box className={styles.itemWrapper}>
        <input
          type="text"
          id="PollingTitle"
          name="PollingTitle"
          className={styles.textInput}
          value={props.data.title}
          placeholder={t("socialWall.poll.titlePlaceholder")}
          disabled={props.disabled}
          onChange={(e) => {
            props.onChange({ ...props.data, title: e.target.value });
          }}
        />
        <div id="pollOption" className={styles.dragDrop}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.dragDropContainer}
                >
                  {items.map((item, index) => (
                    <DragDropItem
                      key={index}
                      id={item.id}
                      index={index}
                      placeholder={item.placeholder}
                      value={item.value}
                      file={item.file}
                      TempData={TempData}
                      handleChange={getChangeValue}
                      onClickCloseBtn={handleRemoveItem}
                      disabled={props.disabled}
                      isShow={isPictureChoice}
                      uploadFn={props.uploadFn}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!props.disabled && (
            <button
              className={`${styles.dragDropItem} addButton`}
              onClick={handleAddOption}
            >
              <img src="/assets/images/option_add.svg" alt="" />
              {t("socialWall.poll.addOption")}
            </button>
          )}
        </div>
        <div className={styles.contentRow}>
          <label> {t("socialWall.poll.pollsEndAt")}</label>
          {props.disabled && props.data.pollEndTime ? (
            <label>{convertDateTime(props.data.pollEndTime)}</label>
          ) : (
            <button className="pollingDetail" onClick={() => setOpen(true)}>
              {daysValue}{" "}
              <img src="/assets/images/chevron-down-grey.svg" alt="" />
            </button>
          )}
        </div>
        <div className={styles.contentRow}>
          <Box className={styles.toggleButtonWrapper}>
            <ToggleButton
              label={t("socialWall.poll.allowVoteAnonymously")}
              checked={isAllowVote}
              setChecked={setAllowVote}
              disabled={props.disabled}
            ></ToggleButton>
            {process.env.REACT_APP_LOCATION === "TWN" && (
              <div id="pictureChoice" style={{ marginTop: "10px" }}>
                <ToggleButton
                  label={t("survey.create.pictureChoice")}
                  checked={isPictureChoice}
                  setChecked={setPictureChoice}
                  disabled={props.disabled}
                ></ToggleButton>
              </div>
            )}
          </Box>
        </div>
      </Box>
      <Popup
        isOpen={open}
        setIsOpen={(close: boolean) => setOpen(close)}
        title={<PopupTitle />}
        content={
          <PopupContent
            value={daysValue}
            data={getDays(7).reverse()}
            onChangeRadio={(value) => {
              setDaysValue(value);
              setOpen(false);
            }}
          />
        }
        disableActions
        onClickCancel={() => {
          setOpen(false);
        }}
        onClickConfirm={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default Polling;
