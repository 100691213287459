import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Radio,
  ListItemText,
} from "@mui/material";
import InvitedGroupIcon, { InvitedGroup } from "../invited-group-icon";
import SelectAdminCard from "../select-admin-card";
import styles from "./invitation-scope-field.module.scss";

interface OptionProps {
  option: any;
  id: string;
  isSelected: boolean;
  onClick: () => void;
  forRemove?: boolean;
  isLocked?: boolean;
}

interface OptionContainerProps extends Omit<OptionProps, "option"> {
  children: React.ReactNode;
}

const OptionContainer = (props: OptionContainerProps) => {
  const removeBtnProps =
    props.forRemove && !props.isLocked
      ? {
          secondaryAction: (
            <IconButton
              id={`remove-${props.id}-btn`}
              edge="end"
              className={styles.removeBtn}
              onClick={props.onClick}
            >
              <img src="/assets/images/close_btn.svg" alt="Remove Option" />
            </IconButton>
          ),
        }
      : {};

  const itemButtonClass = props.forRemove
    ? styles.optionItem
    : `${styles.optionItem} ${props.isSelected && styles.optionItem__active}`;

  return (
    <ListItem id={props.id} disablePadding {...removeBtnProps}>
      <ListItemButton
        className={itemButtonClass}
        onClick={(e) =>
          !props.forRemove ? props.onClick() : e.preventDefault()
        }
      >
        {props.children}
      </ListItemButton>
    </ListItem>
  );
};

export const GroupOption = (props: OptionProps) => {
  const { option, ...containerProps } = props;
  let groupLevel: InvitedGroup = "operation";

  if (option.districtValue) {
    groupLevel = "store";
  } else if (option.operationValue) {
    groupLevel = "district";
  }

  return (
    <OptionContainer {...containerProps}>
      {!props.forRemove && (
        <ListItemIcon>
          <Radio
            disableRipple
            checked={props.isSelected}
            value={props.option.id}
            disabled={props.isLocked}
            inputProps={{ "aria-labelledby": props.id }}
            tabIndex={-1}
            edge="end"
            className={styles.optionRadio}
          />
        </ListItemIcon>
      )}
      <ListItemIcon>
        <InvitedGroupIcon type={groupLevel} />
      </ListItemIcon>
      <ListItemText primary={props.option.label} />
    </OptionContainer>
  );
};

export const IndividualsOption = (props: OptionProps) => {
  const { option, ...containerProps } = props;

  return (
    <OptionContainer {...containerProps}>
      {!props.forRemove && (
        <ListItemIcon>
          <Radio
            disableRipple
            checked={props.isSelected}
            value={props.option.id}
            disabled={props.isLocked}
            inputProps={{ "aria-labelledby": props.id }}
            tabIndex={-1}
            edge="end"
            className={styles.optionRadio}
          />
        </ListItemIcon>
      )}
      <SelectAdminCard
        id={props.option.id}
        name={props.option.name}
        avatar={props.option.avatar}
        department={props.option.department}
        jobTitle={props.option.jobTitle}
      />
    </OptionContainer>
  );
};
