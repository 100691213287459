import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SbChannelSettings from "@sendbird/uikit-react/ChannelSettings";
import styles from "./chat.module.scss";
import { useNavigateOnBan } from "./hooks/useNavigateOnBan";
import { useTranslation } from "react-i18next";
import { SendbirdGroupChat } from "@sendbird/chat/groupChannel";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";

export function ChannelSettings() {
  const { t } = useTranslation();
  const { channelUrl } = useParams();
  const navigate = useNavigate();
  useNavigateOnBan();
  const store = useSendbirdStateContext();
  const sb = store?.stores?.sdkStore?.sdk as SendbirdGroupChat;
  const [USER_ID] = React.useState<string>(
    `${localStorage.getItem("SendBirdUserId") ?? ""}`
  );
  // console.log("useSendbirdStateContext", sb);
  const postCreateGroupChannel = async (postParams: any) => {
    // console.log(postParams);
    try {
      const res: any = await sb.groupChannel.createChannel(postParams);
      // console.log("res", res);
      if (res) {
        navigate(`/chat/${res._url}`);
      }
    } catch (err) {
      // console.log("err", err);
      throw err;
    }
  };

  const handleDirectMessage = (targetUserId: string) => {
    // console.log("userId", targetUserId);
    postCreateGroupChannel({
      isDistinct: false,
      invitedUserIds: [targetUserId],
      operatorUserIds: [USER_ID],
    });
  };

  return (
    <div className="sendbird-app__settingspanel-wrap">
      <SbChannelSettings
        channelUrl={channelUrl as string}
        onCloseClick={() => {
          navigate(`/chat/${channelUrl}`);
        }}
        onLeaveChannel={() => {
          navigate("/chat");
        }}
        renderUserProfile={({ user }) => {
          return (
            <div className={`sendbird__user-profile`}>
              <section className={`sendbird__user-profile-avatar`}>
                <div className={`sendbird-avatar ${styles.avatar}`}>
                  <div
                    className={`sendbird-avatar-img sendbird-image-renderer ${styles.renderer}`}
                  >
                    {user.plainProfileUrl ? (
                      <img src={user.plainProfileUrl} alt="user profile" />
                    ) : (
                      <div
                        className={`sendbird-avatar-img--default ${styles.defaultIcon}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                        >
                          <path
                            className="icon-user_svg__fill"
                            d="M42.667 37.333c7.17 0 13.018 5.66 13.32 12.755l.013.579V56a2.667 2.667 0 01-5.315.311L50.667 56v-5.333c0-4.26-3.33-7.743-7.53-7.987l-.47-.013H21.333a8 8 0 00-7.986 7.53l-.014.47V56a2.667 2.667 0 01-5.316.311L8 56v-5.333c0-7.17 5.66-13.019 12.755-13.321l.578-.013h21.334zM32 5.333c7.364 0 13.333 5.97 13.333 13.334C45.333 26.03 39.363 32 32 32c-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.334 13.333-13.334zm0 5.334a8 8 0 100 16 8 8 0 000-16z"
                            fill="#000"
                            fill-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <section className={`sendbird__user-profile-name`}>
                <span className="sendbird-label sendbird-label--h-2 sendbird-label--color-onbackground-1">
                  {user.nickname}
                </span>
              </section>
              {USER_ID !== user.userId && (
                <section className="sendbird__user-profile-message">
                  <button
                    className="sendbird-button sendbird-button--secondary sendbird-button--big"
                    type="button"
                    onClick={() => handleDirectMessage(user.userId)}
                  >
                    <span className="sendbird-button__text sendbird-label sendbird-label--button-1 sendbird-label--color-oncontent-1">
                      {t("chat.USER_PROFILE__MESSAGE")}
                    </span>
                  </button>
                </section>
              )}
              <div className="sendbird__user-profile-separator"></div>
              <section className="sendbird__user-profile-userId">
                <span className="sendbird__user-profile-userId--label sendbird-label sendbird-label--caption-2 sendbird-label--color-onbackground-2">
                  {t("chat.EDIT_PROFILE__USERID_LABEL")}
                </span>
                <span className="sendbird__user-profile-userId--value sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1">
                  {user.userId}
                </span>
              </section>
            </div>
          );
        }}
      />
    </div>
  );
}
