const MuiDialogActions = {
  styleOverrides: {
    root: {
      padding: "24px 16px",
      "& >:not(:first-of-type)": {
        marginLeft: 10,
      },
    },
  },
};

export default MuiDialogActions;
