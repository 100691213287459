const MuiDialogContent = {
  styleOverrides: {
    root: {
      padding: 0,
      color: "var(--mui-palette-grey-500)",
    },
    dividers: {
      borderTop: "none",
    },
  },
};

export default MuiDialogContent;
