import type {} from "@mui/material/themeCssVarsAugmentation";

import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Controller,
  Autoplay,
  Navigation,
  Pagination,
  Zoom,
} from "swiper";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import styles from "./swiper.module.scss";

import { SwiperModule } from "swiper/types";

SwiperCore.use([Autoplay, Navigation, Pagination, Zoom]);
interface ICustomSwiperProps {
  swiperId: string;
  data: Array<any>;
  content: any;
  initialSlide?: number;
  slidesPerView?: number;
  slidesPerGroup?: number;
  spaceBetween?: number;
  isPagination?: boolean;
  // isSlider: boolean;
  // isGroup?: boolean;
  isAutoWidth?: boolean;
  breakpoints?: any;
  onSlideChange?: (params: any) => void;
  isAutoHeight?: boolean;
  isAlignCenter?: boolean;
  isLoop?: boolean;
}

interface SlideProps {
  text: string;
  image: string;
}
const CustomSwiper = (props: ICustomSwiperProps) => {
  const {
    swiperId,
    data,
    content,
    initialSlide,
    slidesPerView,
    slidesPerGroup,
    spaceBetween,
    isPagination = false,
    // isSlider,
    // isGroup,
    isAutoWidth = false,
    breakpoints,
    isAutoHeight = false,
    isAlignCenter = false,
    isLoop = false,
    onSlideChange,
  } = props;

  const slideList =
    data &&
    data.map(
      (slide: SlideProps, index) => (
        // (slide.imageurl !== '' || slide.mobile_imageurl !== '') && (
        <SwiperSlide
          key={index}
          className={isAutoWidth ? styles.autoWidthSlide : undefined}
        >
          {content({ content: slide, index: index })}
        </SwiperSlide>
      )
      // ),
    );

  let swiperModule: Array<SwiperModule> = [Controller, EffectFade, Zoom];
  if (isPagination) {
    swiperModule.push(Pagination);
  }

  return (
    <Box className={styles.swiperContainer}>
      {/* <Box className={`${classes.controlContainer} swiperControl`}>
        <IconButton
          id={`${swiperId}-prev`}
          className={classes.prevButton}
          aria-label={`${ariaName ? ariaName['previousImage'] : 'Previous Banner'}`}
          onKeyDown={(event) => {
            if (!event.shiftKey && event.keyCode === 9) {
              const nextBtn = document.getElementById(`${swiperId}-next`) as HTMLElement;
              nextBtn.focus();
              setTimeout(() => {
                nextBtn.focus();
              }, 100);
            }
          }}
        />
        {isSlider && (
          <IconButton className={classes.pauseButton} onClick={handleOnOff}>
            <img
              src={isPlay ? '/assets/icon/Pause.svg' : '/assets/icon/SliderPlay.svg'}
              alt={
                isPlay
                  ? `${ariaName ? ariaName['pauseImage'] : 'Pause the Banner'}`
                  : `${ariaName ? ariaName['playImage'] : 'Play the Banner'}`
              }
            />
          </IconButton>
        )}
        <IconButton
          id={`${swiperId}-next`}
          className={classes.nextButton}
          aria-label={`${ariaName ? ariaName['nextImage'] : 'Next Banner'}`}
        />
      </Box> */}
      <Swiper
        // style={{ overflow: "visible" }}
        initialSlide={initialSlide}
        modules={swiperModule}
        fadeEffect={{ crossFade: true }}
        // onSwiper={setSwiper}
        // controller={{ control: swiper }}
        slidesPerView={isAutoWidth ? "auto" : slidesPerView}
        slidesPerGroup={slidesPerGroup}
        spaceBetween={spaceBetween ? spaceBetween : 0}
        navigation={{
          prevEl: `#${swiperId}-prev`,
          nextEl: `#${swiperId}-next`,
        }}
        zoom={true}
        autoplay={false}
        pagination={
          isPagination
            ? {
                el: `#${swiperId}-pagination`,
                dynamicBullets: true,
                // bulletClass: styles.paginationBullet,
                // bulletActiveClass: styles.paginationBulletActive,
              }
            : false
        }
        // pagination={isPagination}
        // onInit={onInit}
        className={styles.swiper}
        breakpoints={breakpoints ? breakpoints : {}}
        onSlideChange={onSlideChange}
        autoHeight={isAutoHeight}
        loop={isLoop}
      >
        {slideList}
      </Swiper>
      {isPagination && (
        <Box id={`${swiperId}-pagination`} className={styles.pagination} />
      )}
    </Box>
  );
};

export default CustomSwiper;
