import { Action, createSlice, Reducer } from "@reduxjs/toolkit";
import { ObjectKey } from "../interfaces/common-interface";

export interface DropdownListState {
  location: Array<ObjectKey> | null;
  staff: Array<ObjectKey> | null;
  eventCategory: Array<ObjectKey> | null;
  invitationScopeGroup: Array<ObjectKey> | null;
  invitationScopeIndividual: Array<ObjectKey> | null;
  preferredPronouns: Array<ObjectKey> | null;
  spokenLanguages: Array<ObjectKey> | null;
  profileAttributes: Array<ObjectKey> | null;
  issueType: Array<ObjectKey> | null;
  areaCode: Array<ObjectKey> | null;
}

// Define the initial state using that type
const initialState: DropdownListState = {
  location: null,
  staff: null,
  eventCategory: null,
  invitationScopeGroup: null,
  invitationScopeIndividual: null,
  preferredPronouns: null,
  spokenLanguages: null,
  profileAttributes: null,
  issueType: null,
  areaCode: null,
};

export const dropdownList = createSlice({
  name: "state",
  initialState,
  reducers: {
    setDropdownList: (state, action) => {
      state = {
        ...state,
        [action.payload.key]: action.payload.list,
      };
      return state;
    },
  },
});

export const { setDropdownList } = dropdownList.actions;

// Other code such as selectors can use the imported `RootState` type
const dropdownListReducer: Reducer<
  DropdownListState,
  Action<DropdownListState>
> = dropdownList.reducer;
export default dropdownListReducer;
