import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./drag-and-drop.module.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import Popup from ".../../components";
import { ToggleButton, Popup, UploadFileField } from "..";
interface DragAndDropProps {
  options: Array<ListItemProps>;
  disableInput?: boolean;
  max?: number;
  // onChange: (options: ListItemProps | Array<ListItemProps> | null) => void;
  onChange: (data: any) => void;
  optionName?: string;
  isPictureChoice?: boolean;
  isOtherOff?: (checked: boolean) => void;
  uploadFn?: (file: File) => Promise<string | null>;
  variant?: "default" | "picture";
}
interface DataProps {
  title: string;
  options: Array<ListItemProps>;
  duration: number;
  isAnonymous: boolean;
}
interface ListItemProps {
  id: string;
  index: number;
  placeholder?: string;
  value: string;
  isOther?: boolean;
  file?: {
    value: File | null;
    isError: boolean;
    isRequired: boolean;
  };
}

interface DragDropItemType extends ListItemProps {
  TempData: Array<ListItemProps>;
  handleChange: (data: Array<ListItemProps>) => void;
  onClickCloseBtn: (id: string) => void;
  disableInput?: boolean;
  isShow?: boolean;
  isOther?: boolean;
  uploadFn?: (file: File) => Promise<string | null>;
  variant?: "default" | "picture";
}
// a little function to help us with reordering the result
const reorder = (
  list: Array<ListItemProps>,
  startIndex: any,
  endIndex: any
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface radioContentType {
  id: string;
  content: string;
}

const PopupContent = (props: {
  data: Array<radioContentType>;
  onChangeRadio: (value: string) => void;
}) => {
  return (
    <List disablePadding className={styles.formList}>
      {props.data.map(
        (item: { id: string; content: string }, index: number) => {
          return (
            <ListItem disablePadding className="formListItem" key={index}>
              <input
                type="radio"
                name="poll-days"
                id={item.id}
                value={item.content}
                onChange={(e) => props.onChangeRadio(e.target.value)}
              />
              <label htmlFor={item.id}>{item.content}</label>
            </ListItem>
          );
        }
      )}
    </List>
  );
};

const DragDropItem = ({
  id,
  index,
  placeholder,
  value,
  file,
  TempData,
  handleChange,
  onClickCloseBtn,
  disableInput,
  isShow = false,
  isOther = false,
  uploadFn,
}: DragDropItemType) => {
  const { t } = useTranslation();

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${styles.dragDropItem} ${
            snapshot.isDragging ? "dragging" : ""
          } ${disableInput ? "disableInput" : ""}`}
        >
          <img
            src="/assets/images/grip-dots-vertical.svg"
            alt=""
            draggable={false}
          />
          {file && isShow && (
            <UploadFileField
              key={`answer-${id}`}
              id={`answer-${id}`}
              name={`answer-${id}`}
              limitSize={5 * 1000 * 1000}
              accept="image/*"
              inputFieldType="answer"
              value={file.value}
              error={file.isError}
              required={file.isRequired}
              onChange={async (files) => {
                if (uploadFn && files) {
                  const imageUrl = await uploadFn(files as File);
                  const newItems = TempData.map((item: any) => {
                    if (item.id === id) {
                      return {
                        ...item,
                        file: { ...item.file, value: imageUrl },
                      };
                    }
                    return item;
                  });
                  handleChange(newItems);
                } else {
                  const newItems = TempData.map((item: any) => {
                    if (item.id === id) {
                      return { ...item, file: { ...item.file, value: files } };
                    }
                    return item;
                  });
                  handleChange(newItems);
                }
              }}
              onRemove={(files) => {
                handleChange(
                  TempData.map((item: any) => {
                    if (item.id === id) {
                      return { ...item, file: { ...item.file, value: null } };
                    }
                    return item;
                  })
                );
              }}
              dimension={{ width: 375, height: 375 }}
              enableEdit={true}
              disableDimensionChecking
              viewOnly={disableInput}
              // optionsTitle={t("myProfile.editProfile.editCoverPhoto")}
            />
          )}
          <input
            type="text"
            id={id}
            name={id}
            placeholder={isOther ? t("survey.create.other") : placeholder}
            className={styles.textInput}
            defaultValue={value}
            onChange={(e) => {
              const newItems = TempData.map((item) => {
                if (item.id === id) {
                  return { ...item, value: e.target.value };
                }
                return item;
              });
              handleChange(newItems);
            }}
            disabled={isOther ? isOther : disableInput}
          />

          {TempData.length > 2 && !disableInput && (
            <button
              className="closeBtn"
              onClick={() => {
                onClickCloseBtn(id);
              }}
            >
              <img
                src={`/assets/images/${
                  id.includes("polling")
                    ? "xmark"
                    : "scheduledpostActionIcon_delete"
                }.svg`}
                alt=""
              />
            </button>
          )}
        </div>
      )}
    </Draggable>
  );
};

const DragAndDrop = (props: DragAndDropProps) => {
  const {
    optionName = "polling",
    isPictureChoice = false,
    variant = "default",
  } = props;
  const { t } = useTranslation();
  const [items, setItems] = useState(props.options);
  const [open, setOpen] = useState<boolean>(false);
  let TempData: Array<ListItemProps> = items;
  const [optionCount, setOptionCount] = useState<number>(TempData.length);

  useEffect(() => {
    setItems(props.options);
  }, [props.options]);

  useEffect(() => {
    props.onChange({ ...props, options: items });
  }, [items]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      TempData,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
  };
  const getChangeValue = (data: Array<ListItemProps>) => {
    TempData = data;
    setItems(TempData);
  };
  const handleAddOption = () => {
    const updateData = [
      ...TempData,
      {
        id: `${optionName}-${optionCount + 1}`,
        index: TempData.length + 1,
        placeholder: `Option ${optionCount + 1}`,
        value: "",
        isOther: false,
        file: {
          value: null,
          isError: false,
          isRequired: true,
        },
      },
    ];
    setOptionCount(optionCount + 1);
    setItems(updateData);
  };
  const handleRemoveItem = (id: string) => {
    const newItems = TempData.filter((item) => item.id !== id);
    if (id.includes("Other") && props.isOtherOff) {
      props.isOtherOff(false);
    }
    setItems(newItems);
  };

  return (
    <>
      <Box className={styles.itemWrapper}>
        <div id="pollOption" className={styles.dragDrop}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.dragDropContainer}
                >
                  {items.map((item, index) => {
                    // console.log("DragDropItem", item);
                    return (
                      <DragDropItem
                        key={item.id}
                        id={item.id}
                        index={index}
                        placeholder={
                          t("survey.create.multipleChoice.option") +
                          " " +
                          (index + 1)
                        }
                        value={item.value}
                        file={item.file}
                        TempData={TempData}
                        handleChange={getChangeValue}
                        onClickCloseBtn={handleRemoveItem}
                        disableInput={props.disableInput}
                        isShow={isPictureChoice}
                        isOther={item.isOther}
                        uploadFn={props.uploadFn}
                        variant={variant}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!props.disableInput &&
            (!props.max ||
              (props.max !== undefined && items.length < props.max)) && (
              <button
                className={`${styles.dragDropItem} addButton`}
                onClick={handleAddOption}
              >
                <img src="/assets/images/option_add.svg" alt="" />
                {t("socialWall.poll.addOption")}
              </button>
            )}
        </div>
      </Box>
    </>
  );
};

export default DragAndDrop;
