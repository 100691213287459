const MuiDialogTitle = {
  styleOverrides: {
    root: {
      padding: 16,
      borderBottom: "1px solid var(--mui-palette-divider)",
    },
  },
  defaultProps: {
    typography: "h4",
  },
};

export default MuiDialogTitle;
