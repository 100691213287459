import { FC } from "react";
import styles from "./me.module.scss";
import { Grid, Box, Typography } from "@mui/material";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import toHtml from "html-react-parser";

const MeTermsConditions: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t("myProfile.me.termsConditions")} enableBackButton />
      <Grid item xs className={styles.container}>
        <Box className={styles.content}>
          <Typography variant="h5" color="text.primary">
            {t("myProfile.me.termsConditions")}
          </Typography>
          <br />
          <Typography variant="body1" color="text.primary">
            {toHtml(t("myProfile.me.termsConditionsDetails"))}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default MeTermsConditions;
