import { RefObject, useEffect, useRef, useState } from "react";
import { containsObject } from "../../utility";
import { InputFieldProps } from "../input-field";

interface useSelectHookProps {
  // eslint-disable-next-line
  value: any;
  // eslint-disable-next-line
  getOptionValue?: (value: any) => string;
  // eslint-disable-next-line
  getOptionLabel?: (value: any) => string;
  disableDateTimePicker?: (value: any) => boolean;
  otherFieldValue?: string;
  otherFieldProps?: InputFieldProps;
}

interface ReturnFunctionType {
  selectedValue: any;
  isDisabledDateTimePicker: boolean;
  handleSelectOption: (option: any, isMultiple?: boolean) => void;
  displaySelectedValue: () => string;
}

export const useSelectHook = (
  props: useSelectHookProps
): ReturnFunctionType => {
  const {
    value,
    getOptionValue,
    getOptionLabel,
    disableDateTimePicker,
    otherFieldValue,
    otherFieldProps,
  } = props;

  const handleShowDateTimePicker = (value: any): boolean => {
    return disableDateTimePicker ? disableDateTimePicker(value) : false;
  };

  const [isDisabledDateTimePicker, setIsDisabledDateTimePicker] =
    useState<boolean>(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const otherValid = otherFieldValue ?? "other";

  useEffect(() => {
    setSelectedValue(value);
    setIsDisabledDateTimePicker(handleShowDateTimePicker(value));
  }, [value]);

  const outputValue = (value: any): string => {
    return getOptionValue ? getOptionValue(value) : value;
  };

  const outputLabel = (value: any): string => {
    return getOptionLabel ? getOptionLabel(value) : value;
  };

  const handleSelectOption = (option: any, isMultiple?: boolean) => {
    setSelectedValue(option);
    setIsDisabledDateTimePicker(handleShowDateTimePicker(option));
  };

  const convertArrayValueToString = (value: Array<any>) => {
    const outputValueArray = value.map((item: any) => {
      let output = outputLabel(item);
      if (outputValue(item) === otherValid) {
        output = `${output} - ${
          otherFieldProps ? outputValue(otherFieldProps) : ""
        }`;
      }
      return output;
    });
    return outputValueArray.join(", ");
  };

  const displaySelectedValue = () => {
    let output: string = "";

    if (value && isDisabledDateTimePicker) {
      output = outputLabel(value);
      if (outputValue(value) === otherValid) {
        output = `${output} - ${otherFieldProps ? otherFieldProps.value : ""}`;
      }
    }

    return output;
  };

  return {
    selectedValue,
    isDisabledDateTimePicker,
    handleSelectOption,
    displaySelectedValue,
  };
};
