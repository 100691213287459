import { FC, useState, useEffect, useCallback } from "react";
import styles from "./invitation-scope-field.module.scss";
import { PaginationParamsType } from "../../services/common";
import { ObjectKey } from "../../interfaces/common-interface";
import { Stack, Grid, List, Skeleton, Box } from "@mui/material";
import SearchField from "../search-field";
import { IndividualsOption } from "./option";
import NoResults from "../no-results";
import InfiniteScroll from "react-infinite-scroll-component";
import { convertStaffList } from "../../utility";
import { useTranslation } from "react-i18next";
import MultiFilter, { FilterItemProps, OptionsListType } from "../multi-filter";

interface IndividualsListSectionProps {
  value: Array<ObjectKey>;
  initList: boolean;
  listFetch: (data?: PaginationParamsType) => Promise<ObjectKey>;
  listFetchKey: string;
  filterFetch?: () => Promise<ObjectKey>;
  onClick: (selectedId: Array<ObjectKey>) => void;
  lockSelected?: Array<ObjectKey> | null;
}

const IndividualsListSection: FC<IndividualsListSectionProps> = (props) => {
  const [result, setResult] = useState<Array<ObjectKey>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listParams, setListParams] = useState<PaginationParamsType>({
    page: 1,
    pageSize: 20,
    search: "",
  });
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<Array<OptionsListType>>([]);
  const filterButtonProps = props.filterFetch
    ? {
        onFilter: () => setOpenFilter(true),
        activeFilter: listParams.filter && listParams.filter.length > 0,
      }
    : {};
  const [delaySearchThread, setDelaySearchThread] =
    useState<NodeJS.Timeout | null>(null);
  const getList = useCallback(async () => {
    if (!props.initList) return false;
    let active = true;

    setIsLoading(true);
    setHasMore(true);
    try {
      const { filter, ...request } = listParams;
      const filterRequest = filter
        ? filter.map((item: ObjectKey) => {
            return {
              key: item.key,
              selected: item.selected.map((option: ObjectKey) => option.value),
            };
          })
        : [];

      const response = await props.listFetch({
        ...request,
        filter: filterRequest,
      });
      let responseList: Array<ObjectKey> = [];

      if (props.listFetchKey) {
        if (response.code !== "ERR_CANCELED") {
          responseList = convertStaffList(response.data[props.listFetchKey]);
        }
      } else {
        responseList = convertStaffList(response.data);
      }

      if (responseList.length > 0) {
        if (active) {
          setResult((prev) => [...prev, ...responseList]);
          if (
            listParams.pageSize &&
            responseList.length < listParams.pageSize
          ) {
            setHasMore(false);
          }
        }
      } else {
        setHasMore(false);
      }
      setIsLoading(false);
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code && error.code !== "ERR_CANCELED") {
        setIsLoading(false);
      }
      throw error;
    }

    return () => {
      active = false;
    };
  }, [listParams, props.initList]);

  useEffect(() => {
    if (delaySearchThread) {
      clearTimeout(delaySearchThread);
    }
    const threadId = setTimeout(() => {
      getList();
    }, 2000);
    setDelaySearchThread(threadId);
  }, [getList]);

  const handleResetField = () => {
    setResult([]);
    setListParams((prev) => ({ ...prev, page: 1, search: "" }));
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setListParams((prev) => ({ ...prev, page: 1, search: value }));
    setResult([]);
  };

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  const handleSelectOption = (option: ObjectKey) => {
    let selected = [...props.value];
    const existOption = selected.find(
      (value: ObjectKey) => value.id === option.id
    );

    if (selected.length > 0 && existOption) {
      selected = selected.filter((value: ObjectKey) => value.id !== option.id);
    } else {
      selected.push(option);
    }
    props.onClick(selected);
  };

  useEffect(() => {
    const getFilterList = async () => {
      if (!props.filterFetch) return false;

      const response = await props.filterFetch();
      if (response.status === 200) {
        let convertFilter: Array<OptionsListType> = [];

        Object.keys(response.data).forEach((key: string) => {
          const categoryName =
            key === "department"
              ? t(`staffDirectory.filter.function`)
              : t(`staffDirectory.filter.${key}`);
          convertFilter = [
            ...convertFilter,
            {
              key: key,
              name: categoryName,
              options: response.data[key].map((item: ObjectKey) => {
                const { _id, storeId, ...value } = item;
                const optionKey = Object.keys(value)[0];
                return {
                  categoryId: key,
                  id: _id ?? storeId,
                  value: _id ? _id : storeId,
                  displayValue:
                    key === "store" ? item.storeName : item[optionKey],
                };
              }),
            },
          ];
        });
        setFilterList(convertFilter);
      }
    };

    if (props.filterFetch) {
      getFilterList();
    }
  }, [props.filterFetch, t]);

  const handleApplySelectedFilter = (selected: ObjectKey[]) => {
    setResult([]);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      filter: selected,
    }));
    setOpenFilter(false);
  };

  return (
    <>
      <Stack className={styles.autoCompleteContainer}>
        <SearchField
          id="individualsSearch"
          name="individualsSearch"
          value={listParams.search ?? ""}
          onChange={handleValueChange}
          onReset={handleResetField}
          // onClose={handleResetField}
          {...filterButtonProps}
        />
        {props.filterFetch && (
          <MultiFilter
            id={"individualsFilterPopup"}
            open={openFilter}
            selected={
              listParams.filter
                ? (listParams.filter as FilterItemProps[])
                : ([] as FilterItemProps[])
            }
            disableResetButton
            options={filterList}
            onChange={(value) => handleApplySelectedFilter(value)}
            onClose={() => setOpenFilter(false)}
          />
        )}
        <Grid
          id="individualsListsContainer"
          item
          xs
          className={styles.autoCompleteDropdown}
        >
          {!isLoading && result.length <= 0 && (
            <NoResults resultsType="SEARCH" />
          )}
          <InfiniteScroll
            dataLength={result.length} //This is important field to render the next data
            next={nextPage}
            hasMore={hasMore}
            loader={<LoadingSkeleton />}
            scrollableTarget="individualsListsContainer"
          >
            <List
              className={`${styles.optionsList} ${styles.optionsList__multiple}`}
            >
              {/* eslint-disable-next-line */}
              {result.map((option: any, index: number) => {
                const optionID = `individualsSelectOption_${option.name}`;
                let isSelected = false;
                if (props.value.length > 0) {
                  const selected = props.value.find(
                    (value: ObjectKey) => value.id === option.id
                  );
                  if (selected) isSelected = true;
                }
                let isLocked = false;
                if (props.lockSelected) {
                  const locked = props.lockSelected.find(
                    (value: ObjectKey) => value.id === option.id
                  );
                  if (locked) isLocked = true;
                }
                return (
                  <IndividualsOption
                    key={index}
                    option={option}
                    isSelected={isSelected}
                    isLocked={isLocked}
                    id={optionID}
                    onClick={() => {
                      if (!isLocked) handleSelectOption(option);
                    }}
                  />
                );
              })}
            </List>
          </InfiniteScroll>
        </Grid>
      </Stack>
    </>
  );
};

function LoadingSkeleton() {
  return (
    <Box sx={{ padding: 2 }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={40}
      />
    </Box>
  );
}

export default IndividualsListSection;
