import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./leaderboard.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CustomSwiper, ShortcutMenu } from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import moment from "moment";
import theme from "../../theme";

interface MissionProps {
  data: Array<{
    _id: string;
    earningName: string;
    earningDesc: string;
    pointEarn: number;
  }>;
}

function LeaderboardMission(props: MissionProps) {
  const { data } = props;
  let { id } = useParams();

  const [comment, setComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  const getMissionSwiperData = (data: any) => {
    const item = data.content;
    return (
      <Stack
        sx={{
          backgroundImage:
            'url("/assets/images/leaderboard_missionCardIcon1.svg")',
        }}
        className={styles.missionCardWrapper}
      >
        <Typography variant="body1" className={styles.cardTitle}>
          {t(`leaderboard.missions.${item.earningName}`)}
        </Typography>
        <Stack direction="row" className={styles.cardBottom}>
          <Typography variant="subtitle2" className={styles.cardPoints}>
            +{item.pointEarn}
          </Typography>
          <Typography variant="body2" className={styles.cardUnit}>
            {t("leaderboard.pts")}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <Box className={styles.missionCointainer}>
      <Typography variant="body1" className={styles.title}>
        {t("leaderboard.coffeeCherriesMissions")}
      </Typography>
      <Stack className={styles.missionCardCointainer} direction="row">
        {data.length > 0 && (
          <Box sx={{ width: "100%" }}>
            <CustomSwiper
              data={data}
              swiperId={`mission-swiper`}
              content={getMissionSwiperData}
              slidesPerView={2.3}
              slidesPerGroup={1}
              spaceBetween={8}
            />
          </Box>
        )}

        {/* {data.map((item, index) => (
          <Stack
            sx={{
              backgroundImage:
                'url("/assets/images/leaderboard_missionCardIcon1.svg")',
            }}
            className={styles.missionCardWrapper}
          >
            <Typography variant="body1" className={styles.cardTitle}>
              {item.title}
            </Typography>
            <Stack direction="row" className={styles.cardBottom}>
              <Typography variant="subtitle2" className={styles.cardPoints}>
                +{item.points}
              </Typography>
              <Typography variant="body2" className={styles.cardUnit}>
                {t("leaderboard.pts")}
              </Typography>
            </Stack>
          </Stack>
        ))} */}
      </Stack>
    </Box>
  );
}

export default LeaderboardMission;
