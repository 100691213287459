import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./eventTickets.module.scss";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { getQrCode } from "../../services/event";
import EventDetailContext from "./eventDetailProvider";
import { convertDateWithWeek, convertTime } from "../../utility";

const EventTickets: FC = () => {
  const { detail, isLoading } = useContext(EventDetailContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [qrCodeValue, setQrCodeValue] = useState<string | null>(null);
  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);
  let { id } = useParams();

  useEffect(() => {
    if (!id) {
      navigate("/event");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let active = true;

    const genQrCode = async (eventId: string) => {
      try {
        const response = await getQrCode(eventId);
        if (active) {
          if (response.status === 200) {
            setQrCodeValue(response.data.qrCode);
            if (response.data.isAttended) {
              setIsCheckedIn(true);
            }
          } else {
            navigate(`/event/${eventId}?ticket=${response.status}`);
          }
        }
        // eslint-disable-next-line
      } catch (error: any) {
        // setIsCheckedIn(true);
        navigate(`/event/${eventId}?ticket=${error.response.status}`);
        throw error;
      }
    };

    if (detail.id !== "") {
      genQrCode(detail.id);
    }

    return () => {
      active = false;
    };
  }, [detail]);

  return (
    <>
      <Header title={t("event.myTicket")} enableCloseButton />
      <Grid item xs className={styles.ticketsContainer}>
        <Stack className={styles.ticket}>
          <Box className={styles.ticketHeader}>
            <Typography variant="subtitle1">{detail.name}</Typography>
          </Box>
          <Box className={styles.ticketContent}>
            <Typography component="p" variant="subtitle1" align="center">
              {isLoading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                t("event.ticketContent")
              )}
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={styles.ticketQrCodeContainer}
            >
              <Grid item xs="auto" className={styles.ticketQrCode}>
                {!isLoading && qrCodeValue ? (
                  <QRCode
                    size={178}
                    value={qrCodeValue}
                    viewBox={`0 0 178 178`}
                  />
                ) : (
                  <Skeleton variant="rectangular" width={178} height={178} />
                )}
                {isCheckedIn && (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    className={styles.ticketCheckedIn}
                  >
                    <Typography
                      variant="body2"
                      align="center"
                      component="span"
                      className={styles.ticketCheckedInLabel}
                    >
                      {t("event.checkedIn")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box className={styles.ticketTopLine}></Box>
          <Box className={styles.ticketBottomLine}></Box>
          <Box className={styles.ticketInfo}>
            {detail && (
              <List disablePadding>
                <ListItem disableGutters className={styles.ticketItem}>
                  <ListItemIcon>
                    <img src="/assets/images/eventIcon_calendar.svg" alt="" />
                  </ListItemIcon>
                  {isLoading ? (
                    <Skeleton variant="text" width={200} />
                  ) : (
                    <ListItemText
                      primary={convertDateWithWeek(detail.startsFrom)}
                    />
                  )}
                </ListItem>
                <ListItem disableGutters className={styles.ticketItem}>
                  <ListItemIcon>
                    <img src="/assets/images/eventIcon_clock_two.svg" alt="" />
                  </ListItemIcon>
                  {isLoading ? (
                    <Skeleton variant="text" width={200} />
                  ) : (
                    <ListItemText
                      primary={`${convertTime(
                        detail.startsFrom
                      )} - ${convertTime(detail.endsAt)}`}
                    />
                  )}
                </ListItem>
                <ListItem disableGutters className={styles.ticketItem}>
                  <ListItemIcon>
                    <img
                      src="/assets/images/eventIcon_location_pin.svg"
                      alt=""
                    />
                  </ListItemIcon>
                  {isLoading ? (
                    <Skeleton variant="text" width={200} />
                  ) : (
                    <ListItemText
                      primary={detail.location.name}
                      primaryTypographyProps={{
                        variant: "body1",
                      }}
                      secondary={detail.location.address}
                      secondaryTypographyProps={{
                        variant: "body2",
                      }}
                    />
                  )}
                </ListItem>
              </List>
            )}
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

export default EventTickets;
