const MuiButton = {
  styleOverrides: {
    root: {},
  },
  defaultProps: {
    fullWidth: true,
  },
};

export default MuiButton;
