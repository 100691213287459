import i18n from 'i18next';
import moment from 'moment';

import 'moment/locale/zh-tw';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enHK from './locales/en-hk.json';
import enPH from './locales/en-ph.json';
import enTW from './locales/en-tw.json';
import zhHK from './locales/zh-hk.json';
import zhTW from './locales/zh-tw.json';

enum LocalesKey {
  EN = 'enPH',
  EN_PH = 'enPH',
  EN_TW = 'enTW',
  ZH_TW = 'zhTW',
  EN_HK = 'enHK',
  ZH_HK = 'zhHK',
}
enum PageLang {
  en = 'enPH',
  enPH = 'enPH',
  enTW = 'enTW',
  zhTW = 'zhTW',
  enHK = 'enHK',
  zhHK = 'zhHK',
}

const resources = {
  [LocalesKey.EN_PH]: {
    translation: {
      ...enPH,
    },
  },
  [LocalesKey.EN_TW]: {
    translation: {
      ...enTW,
    },
  },
  [LocalesKey.ZH_TW]: {
    translation: {
      ...zhTW,
    },
  },
  [LocalesKey.EN_HK]: {
    translation: {
      ...enHK,
    },
  },
  [LocalesKey.ZH_HK]: {
    translation: {
      ...zhHK,
    },
  },
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

if (
  process.env.REACT_APP_LOCATION === 'TWN' ||
  process.env.REACT_APP_LOCATION === 'HK'
) {
  const storageLang = sessionStorage.getItem('lang');
  const defaultLang =
    !storageLang || storageLang === 'zhTW' || storageLang === 'zhHK'
      ? 'zh-tw'
      : 'en';
  moment.locale(defaultLang);
} else {
  moment.locale('en');
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      // order and from where user language should be detected
      // order: ['querystring', 'cookie', 'sessionStorage'],
      order: ['querystring', 'sessionStorage'],

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      lookupSessionStorage: 'lang',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['sessionStorage'],
    },
    fallbackLng:
      process.env.REACT_APP_LOCATION === 'TWN'
        ? LocalesKey.ZH_TW
        : process.env.REACT_APP_LOCATION === 'HK'
          ? LocalesKey.ZH_HK
          : LocalesKey.EN,

    resources,
    // lng: 'zhHk', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
  });

export { LocalesKey, PageLang };
export default i18n;
