import React, { FC, useContext, useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  Alert,
  AlertColor,
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Popup, RoundButton } from '../../components';
import CategoryTag from '../../components/category-tag/category-tag';
import Header from '../../components/header/header';
import QrCodeScanner from '../../components/qr-code-scanner';

import { ObjectKey } from '../../interfaces/common-interface';
import {
  AttachmentsType,
  AttendeesType,
} from '../../interfaces/event-interface';
import { UserState } from '../../reducers/user-slice';
import {
  deleteEvent,
  registerEvent,
  RegisterEventRequestType,
  unregisterEvent,
} from '../../services/event';
import { RootState } from '../../store/store';
import {
  convertDate,
  convertDateTime,
  convertDateWithWeek,
  convertTime,
  getMSTokenSilent,
} from '../../utility';
import styles from './eventDetail.module.scss';
import EventDetailContext from './eventDetailProvider';

const EventDetail: FC = () => {
  const { detail, isAssignedEventAdmin, isLoading, setReloadKey } =
    useContext(EventDetailContext);
  const { t } = useTranslation();
  const [readMoreDesc, setReadMoreDesc] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [isRegister, setIsRegister] = useState(false);
  const [registerMsgOpen, setRegisterMsgOpen] = useState({
    open: false,
    status: 'success',
    message: '',
    bottomPosition: 0,
  });
  const navigate = useNavigate();
  let { id, groupId } = useParams();
  const registerRef = React.useRef<HTMLDivElement>(null);
  const [openScanner, setOpenScanner] = useState(false);
  const [openManagePopup, setOpenManagePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openUnregisteredPopup, setOpenUnregisteredPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const [snackbarPosition, setSnackbarPosition] = useState(16);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const { instance, accounts } = useMsal();

  const isCreator = userState.partnerId === detail.createBy;

  const isEditor = groupId
    ? isAssignedEventAdmin ||
      (Boolean(userState.permission?.SocialWallAdmin) && isCreator)
    : isAssignedEventAdmin ||
      (Boolean(userState.permission?.EventAdmin) && isCreator);

  useEffect(() => {
    if (!isLoading && registerRef.current) {
      const newPosition = Number(registerRef.current.offsetHeight) + 16;
      setSnackbarPosition(newPosition);
      setRegisterMsgOpen((prev) => ({
        ...prev,
        bottomPosition: newPosition,
      }));
    }
  }, [isLoading, registerRef]);

  function convertPeriodDate(start: string, end?: string) {
    const startDate = convertDateTime(start);
    const endDate = end ? convertDateTime(end) : null;
    const outputDate = endDate ? `${startDate} - ${endDate}` : startDate;
    return outputDate;
  }

  function handleClickReadMore() {
    setReadMoreDesc(true);
  }

  async function handleClickRegister() {
    if (
      !id ||
      !detail ||
      (detail.status !== 'open' && detail.status !== 'registered')
    )
      return false;

    setIsRegister(true);
    if (detail.status === 'registered') {
      navigate(`/event/${id}/my-tickets`);
    } else {
      // const snackbarPosition = Number(registerRef.current?.offsetHeight) + 16;
      try {
        const request: RegisterEventRequestType = {
          eventId: id,
        };
        // if (process.env.REACT_APP_LOCATION === "PHL" && userState.msToken) {
        //   request = {
        //     ...request,
        //     msAccessToken: userState.msToken,
        //   };
        // }
        const response = await registerEvent(
          request,
          localStorage.getItem('MS_TOKEN') ?? undefined
        );
        if (response.status === 200) {
          setRegisterMsgOpen({
            open: true,
            status: 'success',
            message: t('event.registerSuccessfullyMsg'),
            bottomPosition: snackbarPosition,
          });
          setReloadKey('detail');
        } else {
          setRegisterMsgOpen({
            open: true,
            status: 'error',
            message: (response as ObjectKey).message,
            bottomPosition: snackbarPosition,
          });
        }
        setIsRegister(false);
        // eslint-disable-next-line
      } catch (error: any) {
        // TODO: remove status code 6000 and 4005 handler after backend fixed
        if (
          error.response.data.status === 6000 ||
          error.response.data.status === 4083 ||
          error.response.data.status === 4005
        ) {
          getMSTokenSilent(accounts, instance, `/event/${id}`, () => {
            handleClickRegister();
          });
        } else {
          setRegisterMsgOpen({
            open: true,
            status: 'error',
            message: 'fail',
            bottomPosition: snackbarPosition,
          });

          setIsRegister(false);
          throw error;
        }
      }
    }
  }

  function handleRegisterBarClose() {
    setRegisterMsgOpen((prev) => ({
      ...prev,
      open: false,
    }));
  }

  const handleClickUnregister = async () => {
    if (!id) return false;

    setIsRegister(true);
    try {
      const response = await unregisterEvent(
        id,
        localStorage.getItem('MS_TOKEN') ?? undefined
      );
      if (response.status === 200) {
        setReloadKey('detail');
      }
      setIsRegister(false);
      // eslint-disable-next-line
    } catch (error: any) {
      // TODO: remove status code 6000 and 4005 handler after backend fixed
      if (
        error.response.data.status === 6000 ||
        error.response.data.status === 4083 ||
        error.response.data.status === 4005
      ) {
        getMSTokenSilent(accounts, instance, `/event/${id}`, () => {
          handleClickUnregister();
        });
      } else {
        setRegisterMsgOpen({
          open: true,
          status: 'error',
          message: 'fail',
          bottomPosition: snackbarPosition,
        });

        setIsRegister(false);
        throw error;
      }
    }
  };

  function handleCloseManagePopup(isClose: boolean) {
    setOpenManagePopup(isClose);
  }

  function handleOpenDeletePopup() {
    setOpenManagePopup(false);
    setOpenDeletePopup(true);
  }

  async function handleDeleteEvent() {
    if (!id) return false;

    try {
      const response = await deleteEvent(id);

      if (response.status === 200) {
        setOpenDeletePopup(false);
        navigate('/event');
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (searchParams.get('ticket')) {
      setRegisterMsgOpen({
        ...registerMsgOpen,
        open: true,
        status: 'error',
        message: `get QR code fail - ${searchParams.get('ticket')}`,
      });
      window.history.replaceState(null, '', `/event/${id}`);
    }
  }, [searchParams]);
  return (
    <>
      <Header title={t('event.title')} enableBackButton>
        {isEditor && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            className={styles.toolbar}
          >
            <Grid item xs="auto">
              <IconButton
                aria-label="Scan"
                onClick={() => setOpenScanner(true)}
              >
                <img src="/assets/images/toolbar_scan_black.svg" alt="" />
              </IconButton>
              <QrCodeScanner
                open={openScanner}
                onClose={() => setOpenScanner(false)}
              />
            </Grid>
            <Grid item xs="auto">
              <IconButton
                aria-label="Plus"
                onClick={() => setOpenManagePopup(true)}
              >
                <img src="/assets/images/moreAction_icon.svg" alt="" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Header>
      <Grid container item xs flexWrap="wrap" className={styles.eventContainer}>
        {isLoading ? (
          <Box className={styles.eventMedia}>
            <Skeleton
              width="100%"
              animation="wave"
              variant="rectangular"
              className={styles.eventMediaSkeleton}
            />
          </Box>
        ) : (
          <Box className={styles.eventMedia}>
            <img src={detail.imageUrl} alt="" />
            {detail.category && (
              <Box className={styles.eventCategory}>
                <CategoryTag category={detail.category}></CategoryTag>
              </Box>
            )}
          </Box>
        )}
        {isLoading ? (
          <>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Skeleton animation="wave" height={56} />
              <Skeleton animation="wave" width="60%" height={28} />
              <Skeleton animation="wave" width="60%" height={28} />
              <Skeleton animation="wave" width="80%" height={28} />
              <Skeleton animation="wave" width="80%" height={28} />
              <Skeleton animation="wave" width="40%" height={28} />
            </Stack>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h5">{t('event.about')}</Typography>
              <Skeleton animation="wave" height={60} variant="rectangular" />
            </Stack>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h5">{t('event.attendees')}</Typography>
              <Skeleton animation="wave" height={28} />
            </Stack>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h5">{t('event.attachment')}</Typography>
              <Skeleton animation="wave" height={110} variant="rectangular" />
            </Stack>
          </>
        ) : (
          <>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h3">{detail.name}</Typography>
              <Stack gap={2}>
                <EventDetailItem iconUrl="/assets/images/eventIcon_calendar.svg">
                  <Typography variant="body1">
                    {convertDateWithWeek(detail.startsFrom)}
                  </Typography>
                </EventDetailItem>
                <EventDetailItem iconUrl="/assets/images/eventIcon_clock_two.svg">
                  <Typography variant="body1">{`${convertDateTime(
                    detail.startsFrom
                  )} - ${convertDateTime(detail.endsAt)}`}</Typography>
                </EventDetailItem>
                <EventDetailItem iconUrl="/assets/images/eventIcon_location_pin.svg">
                  <Typography variant="body1">
                    {detail.location.name}
                  </Typography>
                  <Typography variant="body2">
                    {detail.location.address}
                  </Typography>
                </EventDetailItem>
                <EventDetailItem iconUrl="/assets/images/eventIcon_calendar_lines_pen.svg">
                  <Typography variant="body1">
                    {t('event.registrationPeriod')}
                  </Typography>
                  <Typography variant="body2">
                    {convertPeriodDate(
                      detail.registrationPeriod.startsFrom,
                      detail.registrationPeriod.endsAt
                    )}
                  </Typography>
                </EventDetailItem>
                <EventDetailItem iconUrl="/assets/images/eventIcon_users.svg">
                  {detail.maxCapacity !== 0 ? (
                    <Typography variant="body1">
                      {t('event.people', {
                        limit: detail.maxCapacity,
                      })}
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      {t('event.noLimitPeople')}
                    </Typography>
                  )}
                </EventDetailItem>
              </Stack>
            </Stack>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h5">{t('event.about')}</Typography>
              <Box className={styles.eventInformationAbout}>
                <Typography
                  variant="body1"
                  className={readMoreDesc ? styles.more : ''}
                >
                  {detail.description}
                </Typography>
                {detail.description !== '' && (
                  <Link
                    id="readMoreDescriptionBtn"
                    component="button"
                    typography="h5"
                    disabled={readMoreDesc}
                    onClick={handleClickReadMore}
                  >
                    {t('event.readMore')}
                  </Link>
                )}
              </Box>
            </Stack>
            <Stack gap={2} className={styles.eventInformationCard}>
              <AttendeesCheckInSection
                checkInList={detail.checkIn}
                checkedInCount={detail.checkedAttendeesCount}
                maxCount={detail.allAttendeesCount}
              />
            </Stack>
            {(detail.status === 'registered' ||
              detail.status === 'checkedIn') &&
              detail.externalLink &&
              detail.externalLink !== '' && (
                <Stack gap={2} className={styles.eventInformationCard}>
                  <Typography variant="h5">
                    {t('event.eventDetails')}
                  </Typography>
                  <Box>
                    <Typography>
                      {t('event.link')}
                      <Link
                        id="externalLink"
                        href={detail.externalLink}
                        target="_blank"
                      >
                        {detail.externalLink}
                      </Link>
                    </Typography>
                    {detail.externalLinkInformation ?? (
                      <Typography>{detail.externalLinkInformation} </Typography>
                    )}
                  </Box>
                </Stack>
              )}
            {detail.attachments && detail.attachments.length > 0 && (
              <Stack gap={2} className={styles.eventInformationCard}>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="h5">
                      {t('event.attachment')}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Link
                      id="viewAllAttachmentBtn"
                      component="button"
                      typography="h5"
                      className={styles.viewAllAttachmentBtn}
                      onClick={() => navigate(`/event/${id}/attachments`)}
                    >
                      {t('event.viewAll')}
                    </Link>
                  </Grid>
                </Grid>
                <AttachmentsSection
                  attachments={
                    detail.attachments && detail.attachments.length > 0
                      ? detail.attachments
                      : []
                  }
                />
              </Stack>
            )}
          </>
        )}
        <Snackbar
          open={registerMsgOpen.open}
          autoHideDuration={6000}
          className={styles.registerMsgBar}
          onClose={handleRegisterBarClose}
          sx={{ bottom: snackbarPosition }}
        >
          <Alert
            severity={registerMsgOpen.status as AlertColor}
            className={styles.registerMsg}
          >
            {registerMsgOpen.message}
          </Alert>
        </Snackbar>
      </Grid>
      {!isLoading && (
        <RegisterActions
          ref={registerRef}
          status={detail.status}
          loading={isRegister}
          startsFrom={detail.registrationPeriod.startsFrom}
          enabledQrCode={detail.allowAttendanceWithQrCode}
          onClickRegister={handleClickRegister}
          onClickUnregister={handleClickUnregister}
        />
      )}
      {isEditor && (
        <>
          <Popup
            isOpen={openManagePopup}
            title={
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="h4">{t('event.manage')}</Typography>
                </Grid>
                <Grid item xs="auto">
                  <IconButton
                    disableRipple
                    className={styles.manageCloseBtn}
                    onClick={() => handleCloseManagePopup(false)}
                  >
                    <img src="/assets/images/close_btn.svg" alt="" />
                  </IconButton>
                </Grid>
              </Grid>
            }
            content={
              <List disablePadding className={styles.manageList}>
                <ListItem disablePadding>
                  <ListItemButton
                    className={styles.manageOption}
                    onClick={() =>
                      navigate(
                        detail.partnerNetworkGroupId
                          ? `/partner-network/event/${detail.partnerNetworkGroupId}/edit/${id}`
                          : `/event/edit/${id}`
                      )
                    }
                  >
                    <ListItemIcon>
                      <img
                        src="/assets/images/postActionIcon_edit.svg"
                        alt=""
                      />
                    </ListItemIcon>
                    <ListItemText primary={t('event.editEventDetails')} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    className={styles.manageOption}
                    onClick={handleOpenDeletePopup}
                  >
                    <ListItemIcon>
                      <img
                        src="/assets/images/postActionIcon_delete.svg"
                        alt=""
                      />
                    </ListItemIcon>
                    <ListItemText primary={t('event.deleteEvent')} />
                  </ListItemButton>
                </ListItem>
              </List>
            }
            disableActions
            setIsOpen={(isClose) => handleCloseManagePopup(isClose)}
          />
          <Popup
            isOpen={openDeletePopup}
            title={t('event.deleteDialog.title')}
            content={t('event.deleteDialog.content')}
            setIsOpen={(isClose) => setOpenDeletePopup(isClose)}
            confirmBtnText={t('general.delete')}
            buttonColor="#C82014"
            onClickConfirm={handleDeleteEvent}
          />
        </>
      )}
    </>
  );
};

interface AttachmentsSectionProps {
  attachments: Array<AttachmentsType>;
}

const AttachmentsSection: FC<AttachmentsSectionProps> = (props) => {
  const limitCount = 3;
  let list: Array<AttachmentsType> = [];

  props.attachments.forEach((attachment: AttachmentsType, index: number) => {
    if (index >= limitCount) return false;
    list.push(attachment);
  });

  return (
    <Grid container wrap="nowrap" className={styles.attachmentsContainer}>
      {list.map((attachment: AttachmentsType, index: number) => {
        let thumbnail = attachment.url;
        let attachmentType = 'image';
        const imageType = ['image', 'png', 'jpg', 'jpeg', 'gif', 'mp4', 'mov'];
        const fileType = thumbnail.split('.').pop() as string;
        console.log('fileType', fileType);
        if (!imageType.includes(fileType)) {
          attachmentType = 'file';
          if (
            fileType === 'doc' ||
            fileType === 'docx' ||
            fileType === 'document'
          ) {
            thumbnail = '/assets/images/files/file_word.svg';
          } else if (fileType === 'pdf') {
            thumbnail = '/assets/images/files/file_pdf.svg';
          } else if (fileType === 'ppt' || fileType === 'pptx') {
            thumbnail = '/assets/images/files/file_presentation.svg';
          } else if (fileType === 'xls' || fileType === 'xlsx') {
            thumbnail = '/assets/images/files/file_spreadsheet.svg';
          } else {
            thumbnail = '/assets/images/files/file_other.svg';
          }
        }

        return (
          <Grid
            key={index}
            item
            xs="auto"
            className={styles.attachmentsItem}
            data-attachment-type={attachmentType}
          >
            {fileType === 'mp4' || fileType === 'mov' ? (
              <video src={`${thumbnail}#t=0.001`}></video>
            ) : (
              <img src={thumbnail} alt={attachment.name} />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

interface EventDetailItemProps {
  iconUrl: string;
  children: React.ReactNode;
}

const EventDetailItem: FC<EventDetailItemProps> = (props) => {
  return (
    <Grid
      container
      gap={1}
      alignItems="flex-start"
      className={styles.eventInformationItem}
    >
      <Grid item xs="auto" className={styles.itemIcon}>
        <img src={props.iconUrl} alt="" />
      </Grid>
      <Grid item xs className={styles.itemContent}>
        {props.children}
      </Grid>
    </Grid>
  );
};

interface AttendeesCheckInSectionProps {
  checkInList: Array<AttendeesType>;
  checkedInCount: number;
  maxCount: number;
}

const AttendeesCheckInSection: FC<AttendeesCheckInSectionProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id, groupId } = useParams();
  console.log('AttendeesCheckInSection', groupId);
  return (
    <Grid
      container
      justifyContent="space-between"
      className={styles.eventAttendeesContainer}
      onClick={() =>
        navigate(
          groupId
            ? `/partner-network/event/${groupId}/${id}/attendeesList`
            : `/event/${id}/attendeesList`
        )
      }
    >
      <Grid item xs>
        <Typography variant="h5">{t('event.attendees')}</Typography>
        <Grid container justifyContent="flex-start">
          <AvatarGroup max={5} className={styles.list}>
            {props.checkInList.map((user: AttendeesType, index: number) => (
              <Avatar key={index} alt={user.name} src={user.avatar} />
            ))}
          </AvatarGroup>
        </Grid>
      </Grid>
      <Grid item xs="auto">
        <Typography variant="h5">{t('event.checkedIn')}</Typography>
        <Box className={styles.count}>
          <Typography variant="h2" component="span">
            {props.checkedInCount}
          </Typography>
          <Typography
            variant="subtitle2"
            component="span"
          >{`/${props.maxCount}`}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

interface RegisterActionsProps {
  ref?: React.Ref<HTMLDivElement>;
  status: 'upcoming' | 'open' | 'registered' | 'ended' | 'fulled' | 'checkedIn';
  loading: boolean;
  startsFrom: string;
  enabledQrCode: boolean;
  onClickRegister: () => void;
  onClickUnregister: () => void;
}

const RegisterActions: FC<RegisterActionsProps> = React.forwardRef(
  (props, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const RegisterButton = () => {
      const disableButton = props.status !== 'open';
      let buttonLabel = t(`event.registerBtn.${props.status}`);

      if (props.status === 'upcoming') {
        const eventDate = `${convertDate(
          props.startsFrom,
          true
        )}, ${convertTime(props.startsFrom)}`;
        buttonLabel = t(`event.registerBtn.${props.status}`, {
          date: eventDate,
        });
      }

      return (
        <RoundButton
          id={`${props.status}EventBtn`}
          disabled={disableButton}
          onClick={() => !props.loading && props.onClickRegister()}
        >
          {props.loading ? t('general.loading') + '...' : buttonLabel}
        </RoundButton>
      );
    };

    return (
      <>
        <Grid ref={ref} container className={styles.registerActionsContainer}>
          {props.status === 'registered' ? (
            <>
              <Grid container padding={2} paddingLeft={0}>
                <Typography variant="body1">
                  {t('event.youAreGoing')}
                </Typography>
              </Grid>

              <Grid container>
                <Grid item xs={6} paddingRight={1}>
                  <RoundButton
                    id="unregisterEventBtn"
                    className={'reversetype'}
                    onClick={() => setOpen(true)}
                  >
                    {t('event.registerBtn.unregister')}
                  </RoundButton>
                </Grid>
                {props.enabledQrCode && (
                  <Grid item xs={6}>
                    <RoundButton
                      id="eventQrCodeBtn"
                      onClick={() => props.onClickRegister()}
                    >
                      {t('event.registerBtn.eventQrCode')}
                    </RoundButton>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <RegisterButton />
          )}
        </Grid>
        {props.status === 'registered' && (
          <Popup
            isOpen={open}
            setIsOpen={(close: boolean) => setOpen(close)}
            title={t('event.unregisterDialog.title')}
            content={t('event.unregisterDialog.content')}
            onClickConfirm={() => {
              setOpen(false);
              props.onClickUnregister();
            }}
          />
        )}
      </>
    );
  }
);

export default EventDetail;
