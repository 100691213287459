import { Button as MuiButton, Theme } from "@mui/material";
import type {} from "@mui/material/themeCssVarsAugmentation";
import { styled } from "@mui/material/styles";

const RoundButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.vars.palette.green.accent,
  color: theme.vars.palette.white.opacity100,
  padding: "16px",
  borderRadius: "41px",
  textTransform: "none",
  "&.small": {
    padding: "10px",
  },
  "&.notFullWidth": {
    width: "auto",
  },
  "&.reversetype": {
    backgroundColor: "transparent",
    color: theme.vars.palette.green.accent,
    border: `1px solid ${theme.vars.palette.green.accent}`,
  },
  "&.Mui-disabled": {
    color: theme.vars.palette.white.opacity100,
    backgroundColor: theme.vars.palette.black.opacity26,
  },
  "&:hover": {
    "&:not(.reversetype)": {
      backgroundColor: theme.vars.palette.green.accent,
      "&.Mui-disabled": {
        color: theme.vars.palette.white.opacity100,
        backgroundColor: theme.vars.palette.black.opacity26,
      },
    },
  },
}));

export default RoundButton;
