import React from "react";
import styles from "./content-hub.module.scss";
import { listItemType } from "./interface";
import { convertDate } from "../../utility";
import toHtml from "html-react-parser";
import { encode, decode } from "html-entities";
import { Skeleton } from "@mui/material";

const ActiveItemDetail = (props: listItemType) => {
  const { isLoading } = props;
  return (
    <div className={styles.detail}>
      <div className={styles.detailImage}>
        {!isLoading ? (
          <img src={props.image} alt={props.title} />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={210} />
        )}
      </div>
      <div className={styles.detailContent}>
        {!isLoading ? (
          <div className={styles.detailTitle}>{props.title}</div>
        ) : (
          <Skeleton
            variant="rectangular"
            width="50%"
            height={27}
            sx={{ marginBottom: "8px" }}
          />
        )}
        {props.date && (
          <>
            {!isLoading ? (
              <div className={styles.date}>
                {convertDate(props.date, false, true)}
              </div>
            ) : (
              <Skeleton
                variant="rectangular"
                width="80px"
                height={17}
                sx={{ marginBottom: "16px" }}
              />
            )}
          </>
        )}
        {!isLoading ? (
          <>
            {props.content && (
              <div className={styles.detailText}>{toHtml(decode(props.content))}</div>
            )}
          </>
        ) : (
          Array.from(Array(5), (_, index) => (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={10}
              sx={{ marginBottom: "8px" }}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ActiveItemDetail;
