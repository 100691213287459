const MuiSnackbar = {
  styleOverrides: {
    root: {
      left: 16,
      right: 16,
      bottom: 12,
    },
  },
};

export default MuiSnackbar;
