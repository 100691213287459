import React, { useEffect, useState } from 'react';

import { Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AccountItem, ActionItemList, Popup } from '../../../components';
import InfiniteScrollContainer from '../../../components/infinite-scroll-container/InfiniteScrollContainer';

import { getMemberList } from '../../../services/partner-network';

type Props = {
  isOpen: boolean;
  setIsOpen: (toOpen: boolean) => void;
  // memberList: Array<any>;
  groupId: string;
};

const MemberListPopup = (props: Props) => {
  const { t } = useTranslation();
  const [memberList, setMemberList] = useState<Array<any>>([]);
  const navigate = useNavigate();

  const convertData = (response: any, isNext: boolean) => {
    const data = response.partnerNetworkGroup.memberList;
    if (data) {
      if (!isNext) {
        setMemberList(data);
      } else {
        setMemberList((prev: any) => [...prev, ...data]);
      }
    }
  };

  return (
    <Popup
      id={'member-list-popup'}
      isOpen={props.isOpen}
      setIsOpen={(close: boolean) => props.setIsOpen(close)}
      title={
        <Grid container alignItems="center" gap="10px">
          <Grid item xs="auto">
            <IconButton
              sx={{ padding: '0px' }}
              onClick={() => props.setIsOpen(false)}
            >
              <img src="/assets/images/arrow_left.svg" alt="detail back" />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography variant="h4" color="text.primary">
              {t('partnerNetwork.member')}
            </Typography>
          </Grid>
        </Grid>
      }
      content={
        <div>
          <InfiniteScrollContainer
            fetchData={getMemberList}
            pageSize={20}
            returnData={convertData}
            list={memberList}
            apiParams={{ id: props.groupId }}
            scrollableTarget="member-list-popup-content"
          >
            {memberList.map((item, key) => {
              return (
                <AccountItem
                  key={key}
                  name={item.userName}
                  icon={item.userProfilePictureUrl}
                  padding={'8px 16px'}
                  onClick={() =>
                    navigate(`/discover-people/profile/${item.partnerId}`)
                  }
                />
              );
            })}
          </InfiniteScrollContainer>
        </div>
      }
      fullScreen={true}
      disableActions={true}
    />
  );
};

export default MemberListPopup;
