import { FC } from "react";
import { Moment } from "moment";
import Calendar from "../calendar/calendar";
import TimePicker from "./time-picker";
import { ObjectKey } from "../../interfaces/common-interface";

interface RenderCalendarProps {
  mode: string;
  value: string;
  onChange: (date: string, mode: string) => void;
  spacialTimeOptions?: {
    hours?: ObjectKey[];
    minutes?: ObjectKey[];
    meridiem?: ObjectKey[];
  };
}

const RenderCalendar: FC<RenderCalendarProps> = (props) => {
  if (props.mode === "time") {
    return (
      <TimePicker
        value={props.value}
        onChange={(value: string) => {
          props.onChange(value, props.mode);
        }}
        spacialTimeOptions={props.spacialTimeOptions}
      />
    );
  }
  return (
    <Calendar
      value={props.value}
      disableHighlightToday
      disablePast
      onSelectDate={(value: Moment) => {
        props.onChange(value.format(), props.mode);
      }}
    />
  );
};

export default RenderCalendar;
