import React, { FC } from "react";
import {
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@mui/material";

interface SelectAdminCardProps {
  id: string;
  name: string;
  avatar: string;
  department: string;
  jobTitle: string;
  classes?: string;
}

const SelectAdminCard: FC<SelectAdminCardProps> = (props) => {
  return (
    <>
      <ListItemAvatar>
        <Avatar alt={props.name} src={props.avatar} />
      </ListItemAvatar>
      <ListItemText
        id={props.id}
        className={props.classes}
        primary={props.name}
        primaryTypographyProps={{
          sx: {
            wordBreak: "break-all",
          },
        }}
        secondary={
          <>
            <Typography variant="body2">{props.department}</Typography>
            <Typography variant="body2">{props.jobTitle}</Typography>
          </>
        }
        secondaryTypographyProps={{
          component: "div",
        }}
      />
    </>
  );
};

export default SelectAdminCard;
