import { FC, useEffect, useState } from "react";
import { InputFieldProps } from "../input-field";
import {
  Box,
  Stack,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Radio,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "./datetime-picker-field.module.scss";
import SelectFieldContainer from "../select-field-container";
import moment from "moment";
import {
  convertDate,
  convertDateTime,
  convertDateTimeWithDivider,
  convertTime,
  dateTimeFormat,
} from "../../utility";
import DateButton from "./date-button";
import RenderCalendar from "./render-calendar";
import ControlEndDateTimeButton from "./control-end-date-time-button";
import { useTranslation } from "react-i18next";
import { useSelectHook } from "./useSelectHook";
import { ObjectKey } from "../../interfaces/common-interface";

interface DateTimePickerFieldProps extends Omit<InputFieldProps, "value"> {
  popupLabel?: string;
  disableStartDate?: boolean;
  startDate: string;
  startDateLabel?: string;
  endDate?: string | null;
  endDateLabel?: string;
  disableLabel?: boolean;
  disableEndDateControl?: boolean;
  type?: string;
  options?: Array<ObjectKey>;
  value?: ObjectKey;
  getOptionValue?: (value: ObjectKey) => string;
  getOptionLabel?: (value: ObjectKey) => string;
  disableDateTimePicker?: (value: ObjectKey) => boolean;
  spacialTimeOptions?: {
    hours?: ObjectKey[];
    minutes?: ObjectKey[];
    meridiem?: ObjectKey[];
  };
}

const DateTimePickerField: FC<DateTimePickerFieldProps> = (props) => {
  const {
    disableLabel,
    disableEndDateControl = false,
    disableStartDate,
    startDate,
    startDateLabel,
    endDate,
    endDateLabel,
    type,
    options,
    value,
    getOptionValue,
    getOptionLabel,
    disableDateTimePicker,
    spacialTimeOptions,
    ...containerProps
  } = props;

  const {
    selectedValue,
    handleSelectOption,
    isDisabledDateTimePicker,
    displaySelectedValue,
  } = useSelectHook({
    value,
    getOptionValue,
    getOptionLabel,
    disableDateTimePicker,
  });

  const [datetimeMode, setDateTimeMode] = useState(
    disableStartDate ? "end_date" : "start_date"
  );
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate?: string | null;
  }>({
    startDate,
    endDate,
  });
  const [selectedDateTime, setSelectedDateTime] = useState<string>(startDate);
  const [newDateIncorrect, setNewDateIncorrect] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (startDate !== "") {
      setSelectedDateTime(startDate);
      setDateRange((prev) => ({ ...prev, startDate }));
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== "") {
      setDateRange((prev) => ({ ...prev, endDate }));
    }
  }, [endDate]);

  const handleConfirmSelected = (callback: () => void) => {
    if (
      dateRange.endDate &&
      moment(dateRange.endDate).isBefore(dateRange.startDate)
    ) {
      setNewDateIncorrect(true);
    } else {
      containerProps.onChange({ value: { ...selectedValue }, ...dateRange });
      if (newDateIncorrect) setNewDateIncorrect(false);
      handleChangePicker("start_date");
      callback();
    }
  };

  const handleReset = () => {
    setDateTimeMode("start_date");
    setDateRange({
      startDate,
      endDate,
    });
    setSelectedDateTime(startDate);
    setNewDateIncorrect(false);
  };

  const displayValue = () => {
    var outputValue;
    if (displaySelectedValue()) {
      outputValue = displaySelectedValue();
    } else {
      outputValue =
        type === "selector" && dateRange.endDate
          ? convertDate(dateRange.startDate)
          : type === "selector"
          ? convertDateTimeWithDivider(dateRange.startDate)
          : convertDateTime(dateRange.startDate);
      if (dateRange.endDate) {
        outputValue = `${outputValue} - ${
          type === "selector"
            ? convertDate(dateRange.endDate)
            : convertDateTime(dateRange.endDate)
        }`;
      }
    }
    return outputValue;
  };

  const handleChangePicker = (mode: string) => {
    const modeKey = mode.split("_")[0];
    setSelectedDateTime(
      modeKey === "start"
        ? dateRange.startDate
        : dateRange.endDate
        ? dateRange.endDate
        : ""
    );
    setDateTimeMode(mode);
  };

  function handleDateTimeChange(date: string, mode: string, update?: boolean) {
    let nowDateTime = "";
    let combineDateTime = "";

    if (disableStartDate && datetimeMode.split("_")[0] === "start")
      return false;
    // if (!dateRange.endDate) return false;
    if (mode === "date") {
      nowDateTime = moment(selectedDateTime).format("HH:mm");
      // DD MMM, YY
      combineDateTime = moment(date).format();
    } else {
      nowDateTime = moment(selectedDateTime).format("DD MMM, YY");
      combineDateTime = moment(
        `${nowDateTime} ${date}`,
        "DD MMM, YY HH:mm"
      ).format();
    }

    const dateRangeKey =
      datetimeMode.split("_")[0] === "start" ? "startDate" : "endDate";

    setDateRange({
      ...dateRange,
      [dateRangeKey]: combineDateTime,
    });
    setSelectedDateTime(combineDateTime);
  }

  function addEndDateField() {
    setDateRange({
      ...dateRange,
      endDate: moment(dateRange.startDate).add(1, "hour").format(),
    });
    handleChangePicker("end_date");
  }

  function removeEndDateField() {
    setDateRange({
      ...dateRange,
      endDate: null,
    });
    setDateTimeMode("start_date");
    setSelectedDateTime(dateRange.startDate);
    if (newDateIncorrect) setNewDateIncorrect(false);
  }
  const Option = (props: {
    // eslint-disable-next-line
    option: any;
    id: string;
    onClick: () => void;
    forRemove?: boolean;
  }) => {
    let isSelected = false;
    if (selectedValue && getOptionValue) {
      isSelected =
        getOptionValue(selectedValue) === getOptionValue(props.option);
    }

    const removeBtnProps = props.forRemove
      ? {
          secondaryAction: (
            <IconButton
              id={`remove-${props.id}-btn`}
              edge="end"
              className={styles.removeBtn}
              onClick={props.onClick}
            >
              <img src="/assets/images/close_btn.svg" alt="Remove Option" />
            </IconButton>
          ),
        }
      : {};

    return (
      <ListItem id={props.id} disablePadding {...removeBtnProps}>
        <ListItemButton
          className={`${styles.optionItem} ${
            isSelected && styles.optionItem__active
          }`}
          onClick={() => !props.forRemove && props.onClick()}
          // sx={{ flexDirection: "row-reverse" }}
        >
          <>
            <ListItemText
              // className={styles.optionName}
              primary={
                getOptionLabel ? getOptionLabel(props.option) : props.option
              }
            />
            {!props.forRemove && (
              <ListItemIcon>
                <Radio
                  disableRipple
                  checked={isSelected}
                  value={
                    getOptionValue ? getOptionValue(props.option) : props.option
                  }
                  inputProps={{ "aria-labelledby": props.id }}
                  tabIndex={-1}
                  edge="end"
                  className={styles.optionRadio}
                />
              </ListItemIcon>
            )}
          </>
        </ListItemButton>
      </ListItem>
    );
  };
  return (
    <SelectFieldContainer
      {...containerProps}
      disableLabel={disableLabel}
      value={displayValue()}
      selectedValue={dateRange}
      onConfirmSelected={handleConfirmSelected}
      onCancel={handleReset}
      type={type}
    >
      {options && (
        <List className={styles.optionsList}>
          {/* eslint-disable-next-line */}
          {options.map((option: any, index: number) => {
            const optionID = `${containerProps.name}SelectOption_${
              getOptionValue ? getOptionValue(option) : option
            }`;
            return (
              <Option
                key={index}
                option={option}
                id={optionID}
                onClick={() => handleSelectOption(option)}
              />
            );
          })}
        </List>
      )}
      {!isDisabledDateTimePicker && (
        <>
          <Stack className={styles.dateTimeFieldSection}>
            <Box className={styles.dateTimeField}>
              <Grid container gap={1} alignItems="center">
                <Grid item xs>
                  {startDateLabel ? startDateLabel : t("general.start")}
                </Grid>
                <DateButton
                  id="startDateButton"
                  value={convertDate(dateRange.startDate)}
                  active={datetimeMode === "start_date"}
                  disabled={disableStartDate ? true : false}
                  onClick={() => handleChangePicker("start_date")}
                />
                <DateButton
                  id="startTimeButton"
                  value={convertTime(dateRange.startDate)}
                  active={datetimeMode === "start_time"}
                  disabled={disableStartDate ? true : false}
                  onClick={() => handleChangePicker("start_time")}
                />
              </Grid>
            </Box>
            {dateRange.endDate && (
              <Box className={styles.dateTimeField}>
                <Grid container gap={1} alignItems="center">
                  <Grid item xs>
                    {endDateLabel ? endDateLabel : t("general.end")}
                  </Grid>
                  <DateButton
                    id="endDateButton"
                    value={convertDate(dateRange.endDate)}
                    active={datetimeMode === "end_date"}
                    disabled={false}
                    onClick={() => handleChangePicker("end_date")}
                  />
                  <DateButton
                    id="endTimeButton"
                    value={convertTime(dateRange.endDate)}
                    active={datetimeMode === "end_time"}
                    disabled={false}
                    onClick={() => handleChangePicker("end_time")}
                  />
                </Grid>
              </Box>
            )}
            {!disableEndDateControl && (
              <Box textAlign="right" className={styles.dateTimeField}>
                <ControlEndDateTimeButton
                  isAdd={!dateRange.endDate}
                  onAddField={addEndDateField}
                  onRemoveField={removeEndDateField}
                />
              </Box>
            )}
            {newDateIncorrect && (
              <Box className={styles.dateTimeField}>
                <Typography
                  textAlign="right"
                  variant="body2"
                  className={styles.dateTimeError}
                >
                  {t("event.dateIncorrect")}
                </Typography>
              </Box>
            )}
          </Stack>
          <RenderCalendar
            value={selectedDateTime}
            mode={datetimeMode.split("_")[1]}
            spacialTimeOptions={spacialTimeOptions}
            onChange={handleDateTimeChange}
          />
        </>
      )}
    </SelectFieldContainer>
  );
};

export default DateTimePickerField;
