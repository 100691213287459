import { FC, HTMLInputTypeAttribute, useEffect, useState } from "react";
import styles from "./meMyProfile.module.scss";
import { Grid, Box, Typography, Stack, Alert, Snackbar } from "@mui/material";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Popup } from "../../components";
import { FormValueType, useFormHook } from "../../utility/useFormHook";
import UploadFileField from "../../components/upload-file-field";
import {
  getApiDataByLang,
  getBase64FromUrl,
  getDropdownListOnStore,
} from "../../utility";
import InputField from "../../components/input-field";
import SelectField from "../../components/select-field";
import TagSelectField from "../../components/tag-select-field";
import IconCheckboxes from "../../components/checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  createUserCover,
  createUserPicture,
  getProfileAttributes,
  getProfileLanguages,
  getProfilePronouns,
  updateUserProfile,
} from "../../services/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState, setUserState } from "../../reducers/user-slice";
import { ObjectKey } from "../../interfaces/common-interface";

const MeMyProfileEdit: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [reminderPopup, setReminderPopup] = useState(false);
  const [dropdownList, setDropdownList] = useState<{
    [key: string]: Array<any>;
  }>({
    preferredPronounsList: [],
    spokenLanguagesList: [],
  });
  const [attributesList, setAttributesList] = useState<ObjectKey[]>([]);
  const [loadedDropdownList, setLoadedDropdownList] = useState(false);
  const defaultFormValue: FormValueType = {
    banner: {
      value: null,
      isError: false,
      isRequired: false,
    },
    avatar: {
      value: null,
      isError: false,
      isRequired: false,
    },
    nickname: {
      value: "",
      isError: false,
      isRequired: false,
    },
    status: {
      value: "",
      isError: false,
      isRequired: false,
    },
    email: {
      value: "",
      isError: false,
      isRequired: false,
      type: "email" as HTMLInputTypeAttribute,
    },
    contactNo: {
      value: "",
      isError: false,
      isRequired: false,
      type: "tel" as HTMLInputTypeAttribute,
    },
    preferredPronouns: {
      value: null,
      isError: false,
      isRequired: false,
    },
    preferredPronounsCustom: {
      value: "",
      isError: false,
      isRequired: false,
    },
    spokenLanguages: {
      value: [],
      isError: false,
      isRequired: false,
    },
    attributes: {
      value: [],
      isError: false,
      isRequired: false,
    },
    skipReminder: {
      value: false,
      isError: false,
      isRequired: false,
    },
  };
  const [failMsg, setFailMsg] = useState({
    open: false,
    message: "",
  });
  const {
    isDirty,
    formValue,
    handleInputFieldChange,
    setInitFormValue,
    checkValidation,
  } = useFormHook({
    value: defaultFormValue,
  });

  useEffect(() => {
    const getDropdownList = async () => {
      try {
        const preferredPronounsList = await getDropdownListOnStore(
          "preferredPronouns",
          "profilePronouns",
          getProfilePronouns
        );

        const filteredArr = preferredPronounsList.filter((obj: ObjectKey) => {
          return obj.name.find((nameObj: ObjectKey) => {
            return nameObj.value.toLowerCase() === "she" || nameObj.value.toLowerCase() === "he";
          });
        });

        const preferredPronounsListWithCustom = [
          ...filteredArr,
          {
            _id: "custom",
            name: t("myProfile.editProfile.custom"),
          },
        ];
        const spokenLanguagesList = await getDropdownListOnStore(
          "spokenLanguages",
          "profileLanguages",
          getProfileLanguages
        );
        const profileAttributesList = await getDropdownListOnStore(
          "profileAttributes",
          "profileAttributes",
          getProfileAttributes
        );

        const convertAttributesList = [...profileAttributesList]
          .sort((p1: ObjectKey, p2: ObjectKey) =>
            p1.sequence > p2.sequence ? 1 : p1.sequence < p2.sequence ? -1 : 0
          )
          .map((attributes: ObjectKey) => ({
            id: attributes._id,
            name: attributes.attributeName,
            title: getApiDataByLang(attributes.title),
            freeText: attributes.allowFreeText,
            limit: 5,
            category: attributes.attributeLists.map((category: ObjectKey) => ({
              id: category._id,
              name: getApiDataByLang(category.categoryName),
              options: getApiDataByLang(category.options),
            })),
          }));

        setAttributesList(convertAttributesList);
        setDropdownList({
          preferredPronounsList: preferredPronounsListWithCustom.map(
            (pronouns: ObjectKey) => ({
              _id: pronouns._id,
              name: pronouns.name,
            })
          ),
          spokenLanguagesList: spokenLanguagesList.map(
            (languages: ObjectKey) => ({
              _id: languages._id,
              name: languages.name,
            })
          ),
        });
        setLoadedDropdownList(true);
      } catch (error) {
        throw error;
      }
    };

    getDropdownList();
  }, []);

  useEffect(() => {
    const setProfileInfo = async () => {
      let initValue: FormValueType = {
        ...formValue,
        nickname: {
          ...formValue.nickname,
          value: userState.nickname ?? "",
        },
        status: {
          ...formValue.status,
          value: userState.status ?? "",
        },
        email: {
          ...formValue.email,
          value: userState.email ?? "",
        },
        contactNo: {
          ...formValue.contactNo,
          value: userState.contactNo ?? "",
        },
        skipReminder: {
          ...formValue.skipReminder,
          value: userState.isSkipReminder,
        },
        attributes: {
          ...formValue.attributes,
          value: userState.profileAttribute
            ? userState.profileAttribute.map((attribute: ObjectKey) => ({
                id: attribute.profileAttributeId,
                list: attribute.attributes,
              }))
            : [],
        },
      };

      if (userState.languages.length > 0) {
        const selectedLanguage: { name: string; value: string }[] = [];
        userState.languages.forEach((lang: string) => {
          const selected = dropdownList.spokenLanguagesList.find(
            (item) => item._id === lang
          );
          if (selected) {
            selectedLanguage.push(selected);
          }
        });

        initValue = {
          ...initValue,
          spokenLanguages: {
            ...formValue.spokenLanguages,
            value: selectedLanguage,
          },
        };
      }

      if (userState.pronouns) {
        if (userState.pronouns.custom) {
          initValue = {
            ...initValue,
            preferredPronouns: {
              ...formValue.preferredPronouns,
              value: dropdownList.preferredPronounsList.pop(),
            },
            preferredPronounsCustom: {
              ...formValue.preferredPronounsCustom,
              value: userState.pronouns.customValue,
            },
          };
        } else {
          const correctPronouns = dropdownList.preferredPronounsList.find(
            (pronouns: ObjectKey) => pronouns._id === userState.pronouns?.value
          );
          if (correctPronouns) {
            initValue = {
              ...initValue,
              preferredPronouns: {
                ...formValue.preferredPronouns,
                value: correctPronouns,
              },
            };
          }
        }
      }

      if (userState.banner) {
        const withoutDomainPathArray = userState.banner
          .replace("https://", "")
          .replace("http://", "")
          .split("/");
        withoutDomainPathArray.shift();
        let withoutDomainPath = "";
        for (let index = 1; index < withoutDomainPathArray.length; index++) {
          if (index === 1) {
            withoutDomainPath = withoutDomainPathArray[index];
          } else {
            withoutDomainPath =
              withoutDomainPath + "/" + withoutDomainPathArray[index];
          }
        }

        initValue = {
          ...initValue,
          banner: {
            ...formValue.banner,
            value: userState.banner
              ? {
                  // ...coverImageValue,
                  id: "0",
                  data: userState.banner,
                  fileName: userState.banner.split("/").pop(),
                  type: userState.banner.split(".").pop(),
                  size: 0,
                  path: withoutDomainPath,
                }
              : null,
          },
        };
      }

      if (userState.avatar) {
        const withoutDomainPathArray = userState.avatar
          .replace("https://", "")
          .replace("http://", "")
          .split("/");
        withoutDomainPathArray.shift();
        let withoutDomainPath = "";
        for (let index = 1; index < withoutDomainPathArray.length; index++) {
          if (index === 1) {
            withoutDomainPath = withoutDomainPathArray[index];
          } else {
            withoutDomainPath =
              withoutDomainPath + "/" + withoutDomainPathArray[index];
          }
        }
        initValue = {
          ...initValue,
          avatar: {
            ...formValue.avatar,
            value: userState.avatar
              ? {
                  // ...avatarPictureValue,
                  id: "0",
                  data: userState.avatar,
                  fileName: userState.avatar.split("/").pop(),
                  type: userState.avatar.split(".").pop(),
                  size: 0,
                  path: withoutDomainPath,
                }
              : null,
          },
        };
      }

      setInitFormValue(initValue);
    };

    // if (loadedDropdownList) {
    setProfileInfo();
    // }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedDropdownList, userState]);

  const convertFormValueToRequest = () => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;

    const avatarPicture = getFieldValue("avatar");
    const coverImage = getFieldValue("banner");

    let avatarPictureRequest: ObjectKey = {};
    if (avatarPicture) {
      avatarPictureRequest = {
        userProfilePicture: {
          imageName: avatarPicture.fileName.split(".")[0],
          imageExtension: avatarPicture.fileName.split(".").pop(),
          imagePath: avatarPicture.path.replace(/File\//g, ""),
        },
      };
    }

    let coverImageRequest: ObjectKey = {};
    if (coverImage) {
      coverImageRequest = {
        userCoverPhoto: {
          imageName: coverImage.fileName.split(".")[0],
          imageExtension: coverImage.fileName.split(".").pop(),
          imagePath: coverImage.path.replace(/File\//g, ""),
        },
      };
    }

    let selectedPronouns = getFieldValue("preferredPronouns");
    let selectedPronounsRequest: ObjectKey = {};
    if (selectedPronouns) {
      if (selectedPronouns._id === "custom") {
        selectedPronounsRequest = {
          customValue: getFieldValue("preferredPronounsCustom"),
          custom: true,
        };
      } else {
        selectedPronounsRequest = {
          value: selectedPronouns._id,
        };
      }
    }

    return {
      ...avatarPictureRequest,
      ...coverImageRequest,
      nickname: getFieldValue("nickname"),
      status: getFieldValue("status"),
      email: getFieldValue("email"),
      contactNo: getFieldValue("contactNo"),
      pronouns: selectedPronounsRequest,
      languages: getFieldValue("spokenLanguages").map(
        (language: ObjectKey) => language._id
      ),
      profileAttribute: getFieldValue("attributes").map(
        (attributes: ObjectKey) => ({
          profileAttributeId: attributes.id,
          attributes: attributes.list,
        })
      ),
      showReminder: !getFieldValue("skipReminder"),
    };
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const request = convertFormValueToRequest();
    try {
      const response = await updateUserProfile(request);
      if (response.status === 200) {
        const updateAttributes = request.profileAttribute.map(
          (attribute: ObjectKey, index: number) => {
            const findAttributeNameObject = attributesList.find(
              (item: ObjectKey) => item.id === attribute.profileAttributeId
            );
            if (findAttributeNameObject) {
              return {
                ...request.profileAttribute[index],
                attributeName: findAttributeNameObject.name,
              };
            } else {
              return {
                ...request.profileAttribute[index],
              };
            }
          }
        );
        if (!request.showReminder) {
          sessionStorage.setItem("skipReminder", "true");
        }
        dispatch(
          setUserState({
            email: request.email,
            name: request.nickname,
            avatar: formValue.avatar.value?.data ?? null,
            banner: formValue.banner.value?.data ?? null,
            status: request.status,
            contactNo: request.contactNo,
            languages: request.languages,
            pronouns: request.pronouns,
            profileAttribute: updateAttributes,
            isSkipReminder: !request.showReminder,
          })
        );
        setIsLoading(false);
        navigate("/me/my-profile?updated");
      }
    } catch (error) {
      setIsLoading(false);
      setReminderPopup(false);
      throw error;
    }
  };

  // eslint-disable-next-line
  const handleTagFieldUpdate = (key: string, id: string, value: any) => {
    let existAttributesValue = formValue.attributes.value;
    const existAttributes = existAttributesValue.find(
      (attributes: any) => attributes.id === id
    );
    let attributesValue: ObjectKey[] = [];
    if (existAttributes) {
      attributesValue = existAttributesValue.map((attributes: any) => {
        if (attributes.id === id) {
          return {
            ...attributes,
            list: value,
          };
        } else {
          return attributes;
        }
      });
    } else {
      attributesValue = [
        ...existAttributesValue,
        {
          id,
          list: value,
        },
      ];
    }

    handleInputFieldChange(key, attributesValue);
  };

  const handleReminderConfirmClick = (value: boolean) => {
    handleInputFieldChange("skipReminder", value);
  };

  const handleFileFieldChange = async (
    name: string,
    value: File | File[] | null
  ) => {
    if (!value || Array.isArray(value)) return false;

    const imageRequest = {
      image: value,
    };

    try {
      if (name === "banner") {
        const response = await createUserCover(imageRequest);
        if (response.status === 200) {
          const imageLink = response.data.userCoverPhoto.imageUrl;
          const fileValue = {
            id: "0",
            fileName: imageLink.split("/").pop(),
            type: value.type,
            data: imageLink,
            size: value.size,
            path: response.data.userCoverPhoto.imagePath,
          };
          handleInputFieldChange(name, fileValue);
        }
      } else if (name === "avatar") {
        const response = await createUserPicture(imageRequest);
        if (response.status === 200) {
          const imageLink = response.data.userProfilePicture.imageUrl;
          const fileValue = {
            id: "0",
            fileName: imageLink.split("/").pop(),
            type: value.type,
            data: imageLink,
            size: value.size,
            path: response.data.userProfilePicture.imagePath,
          };
          handleInputFieldChange(name, fileValue);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response.data) {
        setFailMsg({
          open: true,
          message: error.response.data.message,
        });
      }
      throw error;
    }
  };

  const ReminderPopupContent = () => {
    return (
      <Stack gap={`24px`} className={styles.reminderPopupContainer}>
        <Typography variant="body1" color="black.black038">
          {t("myProfile.editProfile.reminderDescription")}
        </Typography>
        <IconCheckboxes
          text={t("myProfile.editProfile.dontRemindMeAgain")}
          checked={formValue.skipReminder.value}
          setChecked={handleReminderConfirmClick}
        />
      </Stack>
    );
  };

  const handleOpenReminderPopup = () => {
    const isValid = checkValidation();
    if (isValid && !isLoading) {
      if (sessionStorage.getItem("skipReminder") === "true") {
        handleSubmit();
      } else {
        setReminderPopup(true);
      }
    }
  };

  return (
    <>
      <Header
        title={t("myProfile.editProfile.title")}
        enableCloseButton
        closeButtonNavigation="/me/my-profile"
      >
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs="auto">
            <LoadingButton
              disabled={!isDirty}
              disableRipple
              loading={isLoading}
              className={styles.saveButton}
              variant="text"
              onClick={handleOpenReminderPopup}
            >
              {t("general.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Header>
      <Box className={styles.editProfileContainer}>
        <Alert
          icon={<img src="/assets/images/circle-information.svg" alt="" />}
          severity="warning"
        >
          {t("myProfile.editProfile.warningDescription")}
        </Alert>
        <Box className={styles.formContainer}>
          <Box className={styles.formSection}>
            <Box
              className={`${styles.formField} ${styles.formField__fullWidth}`}
            >
              <UploadFileField
                id="banner"
                name="banner"
                label={t("myProfile.editProfile.coverPhoto")}
                helperText={t("event.dimension", { width: 375, height: 125 })}
                value={formValue.banner.value}
                error={formValue.banner.isError}
                required={formValue.banner.isRequired}
                accept="image/*"
                onChange={(files) => {
                  handleFileFieldChange("banner", files);
                }}
                onRemove={(files) => {
                  handleInputFieldChange("banner", files);
                }}
                dimension={{ width: 375, height: 125 }}
                disableDimensionChecking
                enableEdit={true}
                optionsTitle={t("myProfile.editProfile.editCoverPhoto")}
              />
            </Box>
            <Box className={`${styles.formField} ${styles.formField__avatar}`}>
              <UploadFileField
                id="avatar"
                name="avatar"
                label={t("myProfile.editProfile.profilePicture")}
                inputFieldType="avatar"
                value={formValue.avatar.value}
                error={formValue.avatar.isError}
                required
                accept="image/*"
                onChange={(files) => {
                  handleFileFieldChange("avatar", files);
                }}
                onRemove={(files) => {
                  handleInputFieldChange("avatar", files);
                }}
                enableEdit
                optionsTitle={t("myProfile.editProfile.editProfilePictures")}
              />
            </Box>
            <Box className={styles.formWrapper}>
              <Box className={styles.formField}>
                <Typography variant="h5" className={styles.formSectionTitle}>
                  {t("myProfile.editProfile.personalDetails")}
                </Typography>
              </Box>
              <Box className={styles.formField}>
                <InputField
                  id="nickname"
                  name="nickname"
                  label={t("myProfile.editProfile.nickname")}
                  value={formValue.nickname.value}
                  error={formValue.nickname.isError}
                  required
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleInputFieldChange(name, value);
                  }}
                />
              </Box>
              <Box className={styles.formField}>
                <InputField
                  id="status"
                  name="status"
                  label={t("myProfile.editProfile.status")}
                  value={formValue.status.value}
                  error={formValue.status.isError}
                  required
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleInputFieldChange(name, value);
                  }}
                />
              </Box>
              <Box className={styles.formField}>
                <InputField
                  id="email"
                  name="email"
                  type={formValue.email.type}
                  label={t("myProfile.editProfile.email")}
                  value={formValue.email.value}
                  error={formValue.email.isError}
                  required
                  readOnly={process.env.REACT_APP_LOCATION === "PHL"}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleInputFieldChange(name, value, [
                      {
                        name: name,
                        required: value !== "",
                      },
                    ]);
                  }}
                />
              </Box>
              {process.env.REACT_APP_LOCATION === "TWN" && (
                <Box className={styles.formField}>
                  <InputField
                    id="contactNo"
                    name="contactNo"
                    type={formValue.contactNo.type}
                    label={t("myProfile.editProfile.contactNo")}
                    value={formValue.contactNo.value}
                    error={formValue.contactNo.isError}
                    required
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleInputFieldChange(name, value, [
                        {
                          name: name,
                          required: value !== "",
                        },
                      ]);
                    }}
                  />
                </Box>
              )}
              {process.env.REACT_APP_LOCATION === "TWN" && (
                <Box className={styles.formField}>
                  <SelectField
                    id="preferredPronouns"
                    name="preferredPronouns"
                    label={t("myProfile.editProfile.preferredPronouns")}
                    placeholder={t("event.pleaseSelect")}
                    value={formValue.preferredPronouns.value}
                    error={
                      formValue.preferredPronouns.isError ||
                      formValue.preferredPronounsCustom.isError
                    }
                    required
                    disableSelectedCountOnBtn
                    options={dropdownList.preferredPronounsList}
                    getOptionValue={(value) => value._id}
                    getOptionLabel={(value) => getApiDataByLang(value.name)}
                    onChange={(value) => {
                      handleInputFieldChange("preferredPronouns", value, [
                        {
                          name: "preferredPronounsCustom",
                          required: value._id === "custom",
                        },
                      ]);
                    }}
                    otherFieldValue="custom"
                    otherFieldProps={{
                      id: "preferredPronounsCustom",
                      name: "preferredPronounsCustom",
                      placeholder: t("myProfile.editProfile.customPronouns"),
                      value: formValue.preferredPronounsCustom.value,
                      error: formValue.preferredPronounsCustom.isError,
                      required: true,
                      onChange: (e) => {
                        const { name, value } = e.target;
                        handleInputFieldChange(name, value);
                      },
                    }}
                  />
                </Box>
              )}
              <Box className={styles.formField}>
                <SelectField
                  id="spokenLanguages"
                  name="spokenLanguages"
                  label={t("myProfile.editProfile.spokenLanguages")}
                  placeholder={t("event.pleaseSelect")}
                  value={formValue.spokenLanguages.value}
                  error={formValue.spokenLanguages.isError}
                  required
                  options={dropdownList.spokenLanguagesList}
                  multiple
                  disableSelectedCountOnBtn
                  getOptionValue={(value) => value._id}
                  getOptionLabel={(value) => getApiDataByLang(value.name)}
                  onChange={(value) => {
                    handleInputFieldChange("spokenLanguages", value);
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.formSection}>
            <Box className={styles.fromWrapper}>
              {attributesList.map((attributes: ObjectKey, index: number) => {
                const correctList = formValue.attributes.value.find(
                  (value: ObjectKey) => value.id === attributes.id
                );
                return (
                  <Box key={index} className={styles.formField}>
                    <TagSelectField
                      id={attributes.id}
                      name={getApiDataByLang(attributes.name)}
                      label={getApiDataByLang(attributes.name)}
                      value={correctList ? correctList.list : []}
                      error={formValue.attributes.isError}
                      options={attributes.category}
                      multiple
                      onChange={(value) => {
                        handleTagFieldUpdate(
                          "attributes",
                          attributes.id,
                          value
                        );
                      }}
                      title={attributes.title}
                      count={attributes.limit}
                      freeText={attributes.freeText}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Snackbar
            open={failMsg.open}
            autoHideDuration={6000}
            onClose={() => setFailMsg((prev) => ({ ...prev, open: false }))}
          >
            <Alert severity="error">{failMsg.message}</Alert>
          </Snackbar>
          <Popup
            isOpen={reminderPopup}
            title={t("myProfile.editProfile.reminder")}
            content={<ReminderPopupContent />}
            setIsOpen={(isOpen: boolean) => setReminderPopup(isOpen)}
            confirmBtnText={t("general.save")}
            onClickConfirm={handleSubmit}
          />
        </Box>
      </Box>
    </>
  );
};

export default MeMyProfileEdit;
