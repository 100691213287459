import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import styles from "./socialWallCreate.module.scss";
import {
  Grid,
  Box,
  Divider,
  Typography,
  IconButton,
  Avatar,
  Stack,
  SwipeableDrawer,
  Input,
  List,
  ListItem,
  Snackbar,
  Alert,
  SvgIcon,
  AppBar,
  Breadcrumbs,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SelectField from "../../components/select-field";
import DateTimePickerField from "../../components/datetime-picker-field";
import {
  ToggleButton,
  ActionItem,
  AccountItem,
  MediaList,
  Polling,
  Popup,
  UploadFileField,
  AccountItemSkeleton,
} from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import moment, { Moment } from "moment";
import theme from "../../theme";
import Calendar from "../../components/calendar/calendar";
import {
  convertDate,
  convertStaffList,
  convertTime,
  getApiDataByLang,
} from "../../utility";
import InvitationScopeField from "../../components/invitation-scope-field";
import EventSearch from "../event/eventSearch";
import CategoryTag from "../../components/category-tag/category-tag";
import {
  createPost,
  editPost,
  getHashTagSuggestion,
  getSocialWallDetails,
  uploadAttachments,
  uploadPollVoteImage,
} from "../../services/social-wall";
import { ObjectKey } from "../../interfaces/common-interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState, setUserState } from "../../reducers/user-slice";
import {
  PaginationParamsType,
  getInvitationGroupList,
  getStaffList,
  getStaffFilterList,
} from "../../services/common";
import InfiniteScroll from "react-infinite-scroll-component";
import { MentionsInput, Mention } from "react-mentions";
import LoadingButton from "@mui/lab/LoadingButton";

interface FileObjectType {
  id: string;
  fileName: string;
  type: string;
  data: string;
  size: number;
}

interface FormValueSettingType {
  // eslint-disable-next-line
  value: any;
  isError: boolean;
  isRequired: boolean;
}

interface FormValueType {
  [key: string]: FormValueSettingType;
}
const SocialWallCreate: FC = () => {
  let { id, groupId } = useParams();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [apiData, setApiData] = useState<any>();
  const [content, setContent] = useState<string>("");
  const [wordCount, setWordCount] = useState<number>(0);
  const [postType, setPostType] = useState<string>("");
  const [tempPostType, setTempPostType] = useState<string>("");
  const [urlInput, setUrlInput] = useState<string>("");
  const [event, setEvent] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [showAudiencePopup, setShowAudiencePopup] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<React.ReactNode>();
  const [isSnackbarShow, setSnackbarShow] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [isEventSearchShow, setEventSearchShow] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [disableStartDate, setDisableStartDate] = useState<boolean>(false);

  //for tag
  const [isLoadingTag, setIsLoadingTag] = useState<boolean>(false);
  const [emptyTagResult, setEmptyTagResult] = useState<boolean>(false);

  //for partnerTag
  const [searchData, setSearchData] = useState<Array<any>>([]);
  const [listParams, setListParams] = useState<PaginationParamsType>({
    page: 1,
    pageSize: 100,
    search: "",
    filter: [],
  });
  const [hasMore, setHasMore] = useState(false);

  const nowDate = moment();
  const roundTime = 30 - (nowDate.minute() % 30);
  const roundedStartDate = nowDate.add(roundTime, "minutes").format();
  const roundedEndDate = moment(roundedStartDate).add(1, "hour").format();
  const displayWordCount =
    process.env.REACT_APP_LOCATION !== "TWN" ? true : !isAnonymous;

  let audienceList = [
    {
      id: "public",
      name: t("socialWall.create.allPartners"),
      displayName: t("socialWall.create.public"),
      description: "",
    },
    {
      id: "private",
      name: t("socialWall.create.private"),
      displayName: t("socialWall.create.private"),
      description: t("socialWall.create.chooseAudience"),
      isNextStep: true,
      isOpened: false,
      nextStepId: "invitationScopeInputWrapper",
    },
  ];

  if (groupId || apiData?.partnerNetworkGroupId) {
    audienceList = [
      ...audienceList,
      {
        id: "groupMember",
        name: t("partnerNetwork.groupMember"),
        displayName: t("partnerNetwork.groupMember"),
        description: t("partnerNetwork.groupMemberDesc"),
      },
    ];
  }

  const postDateList = [
    {
      id: "now",
      name: t("socialWall.create.postNow"),
      disableDateTime: true,
      description: "",
    },
    {
      id: "scheduled",
      name: t("socialWall.create.schedulePost"),
      disableDateTime: false,
      description: "",
    },
  ];
  const pinList = [
    {
      id: "noPin",
      name: t("socialWall.create.doNotPinOnTop"),
      displayName: "No Pin",
      disableDateTime: true,
      description: "",
    },
    {
      id: "alwaysPin",
      name: t("socialWall.create.pinForever"),
      displayName: t("socialWall.create.pinLabel"),
      disableDateTime: true,
      description: t("socialWall.create.pinForeverDesc"),
    },
    {
      id: "pinUntil",
      name: t("socialWall.create.pinUntil"),
      displayName: "",
      disableDateTime: false,
      description: t("socialWall.create.pinUntilDesc"),
    },
  ];
  const actionItemData = [
    // {
    //   image: "/assets/images/postTypeIcon_media.svg",
    //   action: "media",
    //   text: "socialWall.create.media",
    // },
    {
      image: "/assets/images/postTypeIcon_link.svg",
      action: "link",
      text: "socialWall.create.link",
    },
    {
      image: "/assets/images/postTypeIcon_event.svg",
      action: "event",
      text: "socialWall.create.events",
    },
    {
      image: "/assets/images/postTypeIcon_polling.svg",
      action: "poll",
      text: "socialWall.create.polling",
    },
  ];

  const [formValue, setFormValue] = useState<FormValueType>({
    audience: {
      value: audienceList[0],
      isError: false,
      isRequired: true,
    },
    pin: {
      value: pinList[0],
      isError: false,
      isRequired: true,
      // endDate: roundedEndDate,
    },
    pinDate: {
      value: roundedEndDate,
      isError: false,
      isRequired: true,
    },
    postDate: {
      value: {
        value: postDateList[0],
        startDate: roundedStartDate,
        endDate: roundedEndDate,
      },
      isError: false,
      isRequired: true,
    },
    invitationScope: {
      value: {
        group: [],
        individuals: [],
      },
      isError: false,
      isRequired: true,
    },
    attachments: {
      value: [],
      isError: false,
      isRequired: false,
    },
    event: {
      value: {},
      isError: false,
      isRequired: false,
    },
    polling: {
      value: {
        title: "",
        options: [
          {
            id: "polling-1",
            index: 0,
            placeholder: `${t("socialWall.poll.option")} 1`,
            value: "",
            file: {
              value: null,
              isError: false,
              isRequired: false,
            },
          },
          {
            id: "polling-2",
            index: 1,
            placeholder: `${t("socialWall.poll.option")} 2`,
            value: "",
            file: {
              value: null,
              isError: false,
              isRequired: false,
            },
          },
        ],
        duration: 0,
        isAnonymous: false,
        isPictureChoice: false,
      },
      isError: false,
      isRequired: false,
    },
  });

  const getPartnerTag = (comment: string) => {
    const tag = comment.match(
      /(@@[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]@@)/g
    );
    if (tag) {
      const repl = tag.map((word: string) => {
        return word
          .split("&&")[1]
          .replace("[[", "")
          .replace("]]", "")
          .replace("@@", "");
      });
      return repl;
    } else {
      return [];
    }
  };
  const getIndividualsId = () =>
    formValue.invitationScope.value.individuals.map((individual: any) => {
      const individualId = individual.id;
      return individualId;
    });
  const getGroupId = () =>
    formValue.invitationScope.value.group.map((group: any) => {
      const groupId = group.value;
      return groupId;
    });
  const searchForTag = (content: string) => {
    const tagList = content
      .match(/(#)([\u4e00-\u9fa5_a-zA-Z0-9]+)/g)
      ?.map((tag: string) => tag.replace("#", ""));
    return tagList;
  };
  const convertAttachments = (attachments: any) => {
    const attachmentsList = attachments.map((attachment: any) => {
      const attachmentItem = {
        data: attachment.fileUrl,
        fileName: attachment.name,
        id: attachment._id,
        size: Number(attachment.size.replace("KB", "")) * 1000,
        type: attachment.type,
      };
      return attachmentItem;
    });
    return attachmentsList;
  };
  const convertPollOptions = (pollOptions: any) => {
    // console.log("pollOptions", pollOptions);
    const options = pollOptions.map((option: any, index: number) => {
      const optionItem = {
        id: `polling-${option.seq}`,
        index: index,
        value: option.voteName,
        file: {
          value: option.imageUrl,
          isError: false,
          isRequired: false,
        },
      };

      return optionItem;
    });
    return options;
  };

  const convertFormValueToRequest = () => {
    let requestData;
    requestData = {
      content: content,
      postType: postType ? postType : "text",
      isPostAnonymously: isAnonymous,
      isPin: formValue.pin.value.id === "noPin" ? false : true,
      isSchedulePost:
        formValue.postDate.value.value.id === "now" ? false : true,
      tags: searchForTag(content) ?? [],
      // photos: [],
      // videos: [],
      attachments: [],
      annotatedUsers: getPartnerTag(content),
    };
    const getAttachmentsId = () =>
      formValue.attachments.value.map((attachment: any) => {
        const attachmentId = attachment.id;
        return attachmentId;
      });

    if (requestData.isSchedulePost) {
      if (formValue.postDate.value.endDate) {
        requestData = {
          ...requestData,
          scheduleStartTime: moment(formValue.postDate.value.startDate).unix(),
          scheduleEndTime: moment(formValue.postDate.value.endDate).unix(),
        };
      } else {
        requestData = {
          ...requestData,
          scheduleStartTime: moment(formValue.postDate.value.startDate).unix(),
          scheduleEndTime: null,
        };
      }
    }
    if (formValue.audience.value.id !== "private") {
      requestData = {
        ...requestData,
        audienceControl: {
          isPostPublic: true,
        },
        ...((groupId || apiData?.partnerNetworkGroupId) && {
          groupPostSharingOption:
            formValue.audience.value.id === "public"
              ? "allPartners"
              : "groupMember",
        }),
      };
    } else {
      requestData = {
        ...requestData,
        audienceControl: {
          isPostPublic: false,
          forPartners: getIndividualsId(),
          forGroups: getGroupId(),
        },
      };
    }
    if (formValue.pin.value.id === "pinUntil") {
      requestData = {
        ...requestData,
        pinUntil: moment(formValue.pinDate.value).endOf("date").unix() * 1000,
      };
    } else {
      requestData = {
        ...requestData,
        pinUntil: null,
      };
    }

    if (postType === "poll") {
      console.log("formValue.polling", formValue.polling);
      const pollOptions = formValue.polling.value.options.map(
        (option: any, index: number) => ({
          seq: index + 1,
          voteName: option.value,
          imageUrl: option.file.value ?? null,
        })
      );
      return {
        ...requestData,
        content: "",
        isPictureChoice: formValue.polling.value.isPictureChoice,
        pollInfo: disableStartDate
          ? apiData.pollInfo
          : {
              title: formValue.polling.value.title,
              votings: pollOptions,
              isVoteAnonymously: formValue.polling.value.isAnonymous,
              pollLifeSpan: formValue.polling.value.duration,
              // pollEndTime: requestData.isSchedulePost
              //   ? moment(formValue.postDate.value.startDate)
              //       .add(formValue.polling.value.duration, "d")
              //       .unix()
              //   : moment().add(formValue.polling.value.duration, "d").unix(),
            },
      };
    } else if (postType === "event") {
      return {
        ...requestData,
        eventId: event.id,
      };
    } else if (postType === "link") {
      return {
        ...requestData,
        link: urlInput,
      };
    } else if (postType === "media") {
      return {
        ...requestData,
        attachments: getAttachmentsId(),
      };
    } else {
      return requestData;
    }
  };

  useEffect(() => {
    if (id) {
      setIsDataLoading(true);
      getSocialWallDetails(id)
        .then((res) => {
          const apiData = res.data;
          setApiData(apiData);
          setContent(apiData.content);
          setWordCount(res.data.content.length);
          setPostType(apiData.postType);
          setIsAnonymous(apiData.isPostAnonymously);
          const limitedEditRight =
            !apiData.scheduleStartTime ||
            apiData.scheduleStartTime <= moment().unix();
          setDisableStartDate(limitedEditRight);

          if (apiData.postType === "link") {
            setUrlInput(apiData.link);
          } else if (apiData.postType === "event") {
            setEvent({
              id: apiData.event._id,
              name: getApiDataByLang(apiData.event.eventName),
              imageUrl: apiData.event.eventCoverImage,
              startsFrom: moment
                .unix(apiData.event.eventStartTimestamp)
                .format(),
              endsAt: moment.unix(apiData.event.eventEndTimestamp).format(),
              category: apiData.event.category
                ? {
                    id: apiData.event.category._id,
                    name: getApiDataByLang(apiData.event.category.categoryName),
                    bgColor: apiData.event.category.color,
                  }
                : null,
              status: "",
              location:
                apiData.event.eventLocationId &&
                apiData.event.eventLocation &&
                typeof apiData.event.eventLocation === "object"
                  ? {
                      id: apiData.event.eventLocation._id,
                      name: apiData.event.eventLocation.storeName,
                      address: apiData.event.eventLocation.address,
                    }
                  : {
                      id: "",
                      name: apiData.event.eventLocation,
                      address: "",
                    },
            });
          }

          const pollValue = apiData.pollInfo
            ? {
                title: apiData.pollInfo?.title,
                options: convertPollOptions(apiData.pollInfo?.votings),
                duration: apiData.pollInfo.pollLifeSpan,
                pollEndTime: apiData.pollInfo.pollEndTime,
                isAnonymous: apiData.pollInfo.isVoteAnonymously,
                isPictureChoice: apiData.isPictureChoice,
              }
            : { ...formValue.polling.value };

          // console.log("apiData", audienceList);
          setFormValue({
            ...formValue,
            audience: {
              ...formValue.audience,
              value: apiData.isPublic
                ? apiData.groupPostSharingOption === "allPartners"
                  ? audienceList[0]
                  : {
                      id: "groupMember",
                      name: t("partnerNetwork.groupMember"),
                      displayName: t("partnerNetwork.groupMember"),
                      description: t("partnerNetwork.groupMemberDesc"),
                    }
                : audienceList[1],
            },
            pin: {
              ...formValue.pin,
              value: apiData.isPin
                ? apiData.pinUntil
                  ? pinList[2]
                  : pinList[1]
                : pinList[0],
            },
            pinDate: {
              ...formValue.pinDate,
              value: apiData.pinUntil
                ? moment.unix(apiData.pinUntil).format()
                : "",
            },
            postDate: {
              ...formValue.postDate,
              value: {
                value: apiData.scheduleStartTime
                  ? postDateList[1]
                  : postDateList[0],
                startDate: apiData.scheduleStartTime
                  ? moment.unix(apiData.scheduleStartTime).format()
                  : moment.unix(apiData.createTime).format(),
                endDate: apiData.scheduleEndTime
                  ? moment.unix(apiData.scheduleEndTime).format()
                  : null,
              },
            },
            invitationScope: {
              ...formValue.invitationScope,
              value: {
                ...formValue.invitationScope.value,
                group: apiData.inviteScope
                  .find((item: any) => item.scopeType === "stores")
                  .value.map((item: any) => {
                    return {
                      value: item.storeId,
                      label: item.storeName,
                      operationValue: item._id,
                      districtValue: "",
                    };
                  }),
                individuals: apiData.inviteScope
                  .find((item: any) => item.scopeType === "partners")
                  .value.map((item: any) => {
                    return {
                      id: item.partnerId,
                      name: item.userName,
                      imageUrl: item.imageUrl,
                      avatar: "",
                      department: item.departmentName,
                      jobTitle: item.positionName,
                    };
                  }),
              },
            },
            attachments: {
              ...formValue.attachments,
              value: convertAttachments(apiData.attachments),
            },
            event: {
              ...formValue.event,
              value: apiData.event ?? {},
            },
            polling: {
              ...formValue.polling,
              value: pollValue,
            },
          });
          setIsDataLoading(false);
        })
        .catch((err) => {});
    }
    // else {
    //   console.log("create New Post");
    // }
  }, []);

  useEffect(() => {
    checkForm(content);
  }, [
    content,
    formValue.attachments.value,
    urlInput,
    event,
    formValue.polling.value,
  ]);

  const checkForm = (content: string) => {
    // console.log("checkForm", content);

    if (postType === "link" && urlInput && content) {
      setIsValid(true);
    } else if (postType === "poll") {
      let optionNotFilled = false;
      formValue.polling.value.options.forEach((element: any) => {
        if (!element.value) {
          optionNotFilled = true;
        }
      });

      if (
        optionNotFilled ||
        !formValue.polling.value.title ||
        formValue.polling.value.duration === 0
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    } else if (content) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleInputFieldChange = (name: string, value: any) => {
    if (name === "attachments") {
      if (value.length === 0) {
        setPostType("");
      } else {
        setPostType("media");
      }
    }
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      ...formValue[formName],
      value: value,
    };
    // console.log("updateFormValue", value);
    if (formValue[formName].isRequired) {
      let inValid = value === "" || !value;

      updateFormValue = {
        ...updateFormValue,
        isError: inValid,
      };
    }

    setFormValue((prev) => ({
      ...prev,
      [formName]: updateFormValue,
    }));
  };
  const handleUploadPollImage = async (file: File): Promise<string | null> => {
    setIsImageLoading(true);
    try {
      const response = await uploadPollVoteImage(file);
      // console.log("handleUploadPollImage", response);
      setIsImageLoading(false);
      return response.data?.socialWallVotingImageHandler[0]?.imageUrl ?? null;
    } catch (error) {
      setIsImageLoading(false);
      return null;
    }
  };

  const defaultMentionStyle: CSSProperties = {
    backgroundColor: "#D4E9E2",
    opacity: 0.5,
    padding: "1px 0px",
    margin: 0,
    borderRadius: 3,
  };
  const checkPinDate = () => {
    let pinType = formValue.pin.value?.id;
    let postType = formValue.postDate.value.value?.id;
    let pinDate = formValue.pinDate.value;
    let postStartDate = formValue.postDate.value.startDate;
    let postEndDate = formValue.postDate.value.endDate;
    console.log("postEndDate", postEndDate);
    const isOutOfRange = postEndDate
      ? moment(pinDate).isBefore(postStartDate, "day") ||
        moment(pinDate).isAfter(postEndDate, "day")
      : false;
    console.log("isOutOfRange", isOutOfRange);
    // console.log("formValue.postDate.value", formValue.postDate.value);
    setSnackbarShow(false);

    if (
      postType === "scheduled" &&
      ((pinType === "alwaysPin" && postEndDate) ||
        (pinType === "pinUntil" && isOutOfRange))
    ) {
      setFormValue((prev) => ({
        ...prev,
        pin: { ...prev.pin, isError: true },
      }));
      setSnackbarShow(true);
      setSnackbarMsg(t("socialWall.create.pinError"));
      return false;
    } else {
      setFormValue((prev) => ({
        ...prev,
        pin: { ...prev.pin, isError: false },
      }));
      return true;
    }
  };

  const handleChangePostType = (type: string, isForced: boolean) => {
    if (isForced || !postType) {
      if (type === "media" || type === "event") {
        setPostType("");
      } else {
        handleRemoveFile();
        setPostType(type);
      }
    } else if (postType && postType !== type) {
      setOpen(true);
    }

    setOpenDrawer(false);
    setTempPostType(type);

    if (type === "media" && (postType === "media" || isForced || !postType)) {
      fileInputRef.current?.click();
    }

    if (type === "event" && (postType === "event" || isForced || !postType)) {
      setEventSearchShow(true);
    }
  };

  const handleRemoveFile = (id?: number) => {
    if (id && Array.isArray(formValue.attachments.value)) {
      const filterList = formValue.attachments.value.filter(
        (value: FileObjectType, index: number) => index !== id
      );
      handleInputFieldChange(
        "attachments",
        filterList.map((value: FileObjectType, index: number) => ({
          ...value,
          // id: index.toString(),
        }))
      );
    } else {
      handleInputFieldChange("attachments", []);
    }
  };

  const OutPutMediaList = () => {
    const TempData = formValue.attachments.value.map((item: FileObjectType) => {
      return { file: item.data, type: item.type, title: item.fileName };
    });
    return formValue.attachments.value.length > 0 ? (
      <MediaList itemData={TempData} onClickRemoveBtn={handleRemoveFile} />
    ) : null;
  };

  function handleCloseMsgBar() {
    setSnackbarShow(false);
  }

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  const handleWordCount = (content: string) => {
    const converTag = content.match(
      /(@@[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]@@)/g
    );
    var remaining = content;
    if (converTag) {
      var repl = "";
      converTag.map((word: any, tagIndex: number) => {
        // console.log("handleTag progress", [content, remaining]);
        let index = remaining.indexOf(word);
        let before = remaining.slice(0, index);
        let after = remaining.slice(index + word.length, remaining.length);
        remaining = after;
        const regex = /@|&|\[|\]/g;
        repl = repl + before + "@" + word.split("&&")[0].replace(regex, "");
        return true;
      });
      setWordCount(`${repl}${remaining}`.length);
    } else {
      setWordCount(content.length);
    }
  };

  const handleTextAreaChange = async (event: any) => {
    setEmptyTagResult(false);

    const converTag = event.target.value.match(
      /(##[[)([\u4e00-\u9fa5_a-zA-Z0-9]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9]+)(]]##)/g
    );
    var remaining = event.target.value;
    if (converTag) {
      var repl = "";
      converTag.map((word: any, tagIndex: number) => {
        // console.log("handleTag progress", [content, remaining]);
        let index = remaining.indexOf(word);
        let before = remaining.slice(0, index);
        let after = remaining.slice(index + word.length, remaining.length);
        remaining = after;
        const regex = /#|&|\[|\]/g;
        repl =
          repl + before + "#" + word.split("&&")[0].replace(regex, "") + " ";
        return true;
      });
      setContent(repl + remaining);
      handleWordCount(`${repl}${remaining}`);
    } else {
      setContent(event.target.value);
      handleWordCount(event.target.value);
    }
  };

  const confirmChangePostType = () => {
    setOpen(false);
    handleChangePostType(tempPostType, true);
    if (tempPostType === "event") {
      setEventSearchShow(true);
    }
  };

  const handlePinDateChange = (value: string) => {
    setFormValue((prev) => ({
      ...prev,
      pinDate: { ...prev.pinDate, value: value },
    }));
  };

  const handleFileFieldChange = async (
    name: string,
    value: File | File[] | null
  ) => {
    if (!value) return false;

    if (Array.isArray(value)) {
      try {
        const response = await uploadAttachments({
          file: value,
        });
        if (response.status === 200) {
          const filesList = response.data.eventAttachments;
          // eslint-disable-next-line
          let filesValueList: any[] = [];
          filesList.forEach((item: ObjectKey, index: number) => {
            const fileValue: ObjectKey = {
              // id: (formValue[name].value.length + index).toString(),
              id: item._id,
              fileName: item.name,
              type: item.type,
              data: item.fileUrl,
              size: value[index].size,
            };
            filesValueList.push(fileValue);
          });

          const combineValue = [
            ...formValue[name].value,
            ...filesValueList,
          ].map((item: ObjectKey, index: number) => ({
            ...item,
            // id: index.toString(),
          }));

          handleInputFieldChange(name, combineValue);
        }
      } catch (error) {
        throw error;
      }
    }
  };
  const handleSubmitForm = () => {
    // console.log("submit", convertFormValueToRequest());
    // console.log("apiData", apiData);

    checkForm(content);
    const isPinDateValid = checkPinDate();
    const isWordCountValid =
      process.env.REACT_APP_LOCATION === "TWN"
        ? isAnonymous
          ? true
          : wordCount <= 200
        : wordCount <= 200;
    if (isValid && isPinDateValid && !isLoading && isWordCountValid) {
      console.log("groupId", groupId);
      setIsLoading(true);
      const requestData = convertFormValueToRequest();
      console.log("requestData", requestData);
      if (id) {
        editPost(id, {
          ...requestData,
          ...(apiData?.partnerNetworkGroupId && {
            partnerNetworkGroupId: apiData.partnerNetworkGroupId,
          }),
        })
          .then((result) => {
            const prevPage = sessionStorage.getItem("prevPage") ?? null;
            if (prevPage) {
              window.location.href = prevPage;
            } else {
              navigate(
                apiData.partnerNetworkGroupId
                  ? `/partner-network/detail/${apiData.partnerNetworkGroupId}`
                  : `/social-wall`
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("error", err);
            setSnackbarShow(true);
            if (err?.response?.data?.status === 4107) {
              setSnackbarMsg(t("socialWall.sensitive"));
            } else {
              setSnackbarMsg("Api error");
            }
          });
      } else {
        createPost({
          ...requestData,
          ...(groupId && { partnerNetworkGroupId: groupId }),
        })
          .then((result) => {
            let postype = formValue.postDate.value.value
              ? formValue.postDate.value.value.id === "now"
                ? "posted"
                : "scheduled"
              : "";
            localStorage.setItem(
              "post-created",
              JSON.stringify({
                isShow: true,
                type: postype,
                postId: result.data.post._id,
                startDate: formValue.postDate.value.startDate,
                endDate: formValue.postDate.value.endDate,
              })
            );
            const prevPage = sessionStorage.getItem("prevPage") ?? null;
            if (prevPage) {
              window.location.href = prevPage;
            } else {
              navigate(
                groupId ? `/partner-network/detail/${groupId}` : `/social-wall`
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("error", err);
            setSnackbarShow(true);
            if (err?.response?.data?.status === 4107) {
              setSnackbarMsg(t("socialWall.sensitive"));
            } else {
              setSnackbarMsg("Api error");
            }
          });
      }
    }
  };
  function ErrorMsgIcon() {
    return (
      <SvgIcon
        viewBox="0 0 16 16"
        width="16"
        height="16"
        style={{ width: "auto", height: "auto" }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
          <path
            d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
            stroke="#C82014"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L10 10M10 6L6 10"
            stroke="#C82014"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  }
  const eventCard = postType === "event" &&
    event &&
    Object.keys(event).length > 0 && (
      <Grid container alignItems="center" className={styles.eventResultCard}>
        <Grid item className={styles.eventResultImage}>
          <img src={event.imageUrl} alt="" />
        </Grid>
        <Grid item xs className={styles.eventResultContent}>
          <Stack spacing={0.5}>
            <Box className={styles.eventResultCategory}>
              <CategoryTag category={event.category}></CategoryTag>
            </Box>
            <Box className={styles.eventResultTitle}>
              <Typography variant="body1">{event.name}</Typography>
            </Box>
            <Box className={styles.eventResultDate}>
              <Breadcrumbs separator="|" aria-label="breadcrumb">
                <Typography variant="body2">
                  {convertDate(event.startsFrom)}
                </Typography>
                <Typography variant="body2">
                  {convertTime(event.startsFrom)}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Grid
              container
              alignItems="flex-start"
              wrap="nowrap"
              className={styles.eventResultLocation}
            >
              <img src="/assets/images/eventIcon_location_pin.svg" alt="" />
              <Typography variant="body2">{event.location.name}</Typography>
            </Grid>
          </Stack>
        </Grid>
        <Box
          className={styles.deleteEventBtn}
          onClick={() => {
            setFormValue((prev) => ({
              ...prev,
              event: { ...prev.event, value: {} },
            }));
            setPostType("");
          }}
        >
          <img src="/assets/images/close_btn_filled.svg" alt="" />
        </Box>
      </Grid>
    );

  const [optionDesc, setOptionDesc] = useState(null);
  const getSubmitData = (data: any) => {
    // console.log(data);
    setOptionDesc(data.data);
    setShowAudiencePopup(data.isOpen);
  };
  const getIsClosed = (data: boolean) => {
    // console.log(data);
    setShowAudiencePopup(data);
  };

  return (
    <>
      <Header
        title={
          id ? t("socialWall.create.editTitle") : t("socialWall.create.title")
        }
        enableCloseButton
        closeButtonFunction={() => {
          console.log("closeButtonFunction");
          const prevPage = sessionStorage.getItem("prevPage") ?? null;
          if (prevPage) {
            window.location.href = prevPage;
          } else {
            navigate(
              groupId ? `/partner-network/detail/${groupId}` : `/social-wall`
            );
          }
        }}
      >
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs="auto">
            <LoadingButton
              disabled={!isValid}
              disableRipple
              loading={isLoading}
              className={styles.saveButton}
              variant="text"
              onClick={handleSubmitForm}
            >
              {id ? t("general.done") : t("socialWall.create.post")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Header>

      <Grid item xs className={styles.formContainer} id="formContainer">
        {Boolean(userState.permission?.SocialWallAdmin) && (
          <Box className={styles.toggleButtonWrapper}>
            <ToggleButton
              label={t("socialWall.create.postAnonymously")}
              checked={isAnonymous}
              setChecked={setIsAnonymous}
            ></ToggleButton>
          </Box>
        )}

        <Box sx={{ display: "flex", marginBottom: "16px" }}>
          <Avatar
            src={
              isAnonymous
                ? "/assets/images/profileIcon_admin.png"
                : userState.avatar ?? ""
            }
          />

          <Box sx={{ marginLeft: "8px" }}>
            <Box sx={{ marginBottom: "6px" }}>
              <Typography variant="body1">
                {isAnonymous ? t("general.starbucksAdmin") : userState.name}
              </Typography>
            </Box>
            <Stack direction="row" flexWrap="wrap" columnGap="8px" rowGap="6px">
              <SelectField
                id="audience"
                name="audience"
                label={t("socialWall.create.audienceLabel")}
                value={formValue.audience.value}
                error={formValue.audience.isError}
                required={formValue.audience.isRequired}
                options={audienceList}
                isOptionReverse={true}
                getOptionValue={(value) => value.id}
                getOptionLabel={(value) => value.name}
                getOptionDisplay={(value) => value.displayName}
                getOptionDesc={(value) => value.description}
                replaceOptionDesc={optionDesc != null ? optionDesc : ""}
                onChange={(value) => {
                  handleInputFieldChange("audience", value);
                }}
                handleOptionClick={(value) => {
                  // console.log("handleOptionClick", value);
                  if (value.isNextStep) {
                    setShowAudiencePopup(true);
                  }
                }}
                type="selector"
                startAdornment={
                  <img src="/assets/images/createPostIcon_world.svg" alt="" />
                }
              />
              <DateTimePickerField
                id="postDate"
                name="postDate"
                label={t("socialWall.create.postDateLabel")}
                popupLabel={t("socialWall.create.postDateLabel")}
                startDate={formValue.postDate.value.startDate}
                endDate={formValue.postDate.value.endDate}
                error={formValue.postDate.isError}
                required={formValue.postDate.isRequired}
                startAdornment={
                  <img src="/assets/images/createPostIcon_clock.svg" alt="" />
                }
                onChange={(value: any) => {
                  handleInputFieldChange("postDate", value);
                }}
                type="selector"
                value={formValue.postDate.value.value}
                getOptionValue={(value) => value.id}
                getOptionLabel={(value) => value.name}
                disableDateTimePicker={(value) => value.disableDateTime}
                options={postDateList}
                disableStartDate={disableStartDate}
              />

              {userState?.permission?.SocialWallAdmin && (
                <SelectField
                  id="pin"
                  name="pin"
                  label={t("socialWall.create.pinLabel")}
                  value={formValue.pin.value}
                  error={formValue.pin.isError}
                  required={formValue.pin.isRequired}
                  options={pinList}
                  getOptionValue={(value) => value.id}
                  getOptionLabel={(value) => value.name}
                  getOptionDisplay={(value) => {
                    if (value.id === "noPin") return t("socialWall.pin");
                    else if (value.id === "pinUntil")
                      return convertDate(formValue.pinDate.value);
                    else return value.name;
                  }}
                  getOptionDesc={(value) => value.description}
                  onChange={(value) => {
                    handleInputFieldChange("pin", value);
                  }}
                  type="selector"
                  // disableLabel={true}
                  startAdornment={
                    formValue.pin.isError ? (
                      <img
                        src="/assets/images/createPostIcon_pin_red.svg"
                        alt=""
                      />
                    ) : (
                      <img src="/assets/images/createPostIcon_pin.svg" alt="" />
                    )
                  }
                  otherFieldValue="pinUntil"
                  otherFieldComponent={() => (
                    <PinCalendar
                      date={formValue.pinDate.value}
                      onChange={handlePinDateChange}
                    />
                  )}
                />
              )}
            </Stack>
          </Box>
        </Box>
        {postType !== "poll" && (
          <>
            <MentionsInput
              value={content}
              className={styles.mentions}
              placeholder={t("socialWall.create.postPlaceholder")}
              style={{ minHeight: "100px" }}
              onChange={(event: any) => {
                handleTextAreaChange(event);
              }}
              onBlur={() => {
                setEmptyTagResult(false);
              }}
              customSuggestionsContainer={(children) => (
                <Box
                  className={styles.suggestedWordContainer}
                  sx={{
                    marginTop: `10px`,
                  }}
                  id="staffListsContainer"
                >
                  {/* {!isLoading && !hasMore && searchData.length === 0 && (
                  <NoResults resultsType="SEARCH" />
                )} */}
                  <InfiniteScroll
                    dataLength={searchData.length} //This is important field to render the next data
                    next={nextPage}
                    hasMore={hasMore}
                    loader={<AccountItemSkeleton />}
                    scrollableTarget="staffListsContainer"
                  >
                    {children}
                  </InfiniteScroll>
                </Box>
              )}
            >
              <Mention
                trigger="@"
                data={(search, callback) => {
                  setIsLoadingTag(true);

                  const { filter, ...request } = listParams;
                  const filterRequest = filter
                    ? filter.map((item: ObjectKey) => {
                        return {
                          key: item.key,
                          selected: item.selected.map(
                            (option: ObjectKey) => option.value
                          ),
                        };
                      })
                    : [];
                  getStaffList({
                    ...request,
                    filter: filterRequest,
                    search: search,
                  }).then((response) => {
                    if (response.data.staffList.length > 0) {
                      const dataList = convertStaffList(
                        response.data.staffList
                      ).map((item: ObjectKey) => {
                        return {
                          id: item.id ?? "",
                          display: item.name ?? "",
                          avatar: item.avatar ?? "",
                          jobTitle: item.jobTitle ?? "",
                        };
                      });
                      callback(dataList);
                    } else {
                      setEmptyTagResult(true);
                    }
                    setIsLoadingTag(false);
                  });
                }}
                markup="@@[[__display__]]&&[[__id__]]@@"
                displayTransform={(id, display) => `@${display}`}
                renderSuggestion={(
                  suggestion: any,
                  search: any,
                  highlightedDisplay: any,
                  index: any,
                  focused: any
                ) => {
                  return (
                    <Box key={index}>
                      <AccountItem
                        key={index}
                        name={suggestion.display}
                        icon={suggestion.avatar}
                        description={suggestion.jobTitle}
                        padding="8px 16px"
                      />
                    </Box>
                  );
                }}
                style={{
                  ...defaultMentionStyle,
                }}
              />
              <Mention
                trigger="#"
                data={(search, callback) => {
                  setHasMore(false);
                  getHashTagSuggestion(`#${search}`).then((res) => {
                    const dataList = res.data.tags.map((tag: any) => ({
                      id: tag,
                      display: tag,
                    }));
                    callback(dataList);
                  });
                }}
                markup="##[[__display__]]&&[[__id__]]##"
                displayTransform={(id, display) => `#${display} `}
                renderSuggestion={(
                  suggestion: any,
                  search: any,
                  highlightedDisplay: any,
                  index: any,
                  focused: any
                ) => {
                  return (
                    <Typography
                      key={index}
                      variant="body1"
                      className={styles.suggestedWord}
                    >
                      {suggestion.display}
                    </Typography>
                  );
                }}
              />
            </MentionsInput>
            {displayWordCount && (
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  className={`${styles.wordCount} ${
                    wordCount > 200 ? styles.exceed : ""
                  }`}
                >
                  {wordCount > 200 &&
                    t("event.descriptionWordCountOverHelperText")}
                </Typography>
                <Typography
                  className={`${styles.wordCount} ${
                    wordCount > 200 ? styles.exceed : ""
                  }`}
                  variant="body2"
                >
                  {wordCount}/200
                </Typography>
              </Stack>
            )}
            {(isLoadingTag || (emptyTagResult && !isLoadingTag)) && (
              <Box sx={{ position: "absolute", width: "calc(100% - 32px)" }}>
                <Box
                  className={
                    styles.suggestedWordContainer + " " + styles.loading
                  }
                >
                  {isLoadingTag && <AccountItemSkeleton />}
                  {emptyTagResult && (
                    <Typography sx={{ padding: "8px" }}>
                      {t("myCalendar.noResult.title")}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </>
        )}
        {postType === "media" && <OutPutMediaList />}
        {postType === "link" && (
          <Box className={styles.UrlInputField}>
            <Input
              value={urlInput}
              placeholder="URL"
              sx={{ width: "100%" }}
              onChange={(e) => {
                const { name, value } = e.target;
                setUrlInput(value);
              }}
            />

            <Box
              style={{
                position: "absolute",
                bottom: "19px",
                right: "3px",
                width: "18px",
                height: "18px",
                borderRadius: "50%",
                backgroundColor: theme.vars.palette.black.opacity26,
              }}
              onClick={() => {
                setUrlInput("");
              }}
            >
              <img
                src="/assets/images/close_btn_white.svg"
                style={{ padding: "3px", width: "100%" }}
              />
            </Box>
          </Box>
        )}
        {eventCard}
        {postType === "poll" && !isDataLoading && (
          <Polling
            data={formValue.polling.value}
            options={formValue.polling.value.options}
            disabled={disableStartDate}
            onChange={(poll) => {
              handleInputFieldChange("polling", poll);
            }}
            uploadFn={handleUploadPollImage}
          />
        )}
      </Grid>
      <Box className={styles.postTypeContainer}>
        <UploadFileField
          id="attachments"
          name="attachments"
          value={formValue.attachments.value}
          inputFieldType="post"
          // accept="image/*"
          multiple={true}
          limitCount={10}
          limitSize={10 * 1000 * 1000} //10MB
          // onChange={(files) => {
          //   handleInputFieldChange("attachments", files);
          // }}
          onChange={(files) => {
            handleFileFieldChange("attachments", files);
          }}
          onRemove={(files) => {
            handleInputFieldChange("attachments", files);
          }}
          // ref={fileInputRef}
        />

        {actionItemData.map((item, index) => {
          // if (item.action !== "media") {
          return (
            <img
              key={index}
              src={item.image}
              alt=""
              onClick={() => {
                handleChangePostType(item.action, false);
              }}
            />
          );
          // }
        })}
        <img
          className={styles.expandIcon}
          src="/assets/images/chevron_up.svg"
          alt=""
          onClick={() => {
            setDrawerContent(
              <>
                <Box className={styles.drawerHeader}>
                  <Typography variant="h4">
                    {t("socialWall.functions")}
                  </Typography>
                  <img
                    src="/assets/images/chevron_down.svg"
                    onClick={() => setOpenDrawer(false)}
                    alt=""
                  />
                </Box>
                <Divider />

                <List className={styles.listWrapper}>
                  <ListItem
                    sx={{ padding: "0px" }}
                    className={styles.UploadFileFieldWrapper}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <UploadFileField
                      id="attachments"
                      name="attachments"
                      value={formValue.attachments.value}
                      label={t("socialWall.create.media")}
                      inputFieldType="post"
                      // accept="image/*"
                      multiple={true}
                      limitCount={10}
                      limitSize={10 * 1000 * 1000} //10MB
                      // onChange={(files) => {
                      //   handleInputFieldChange("attachments", files);
                      // }}
                      onChange={(files) => {
                        handleFileFieldChange("attachments", files);
                      }}
                      onRemove={(files) => {
                        handleInputFieldChange("attachments", files);
                      }}
                      // ref={fileInputRef}
                    />
                  </ListItem>
                  {actionItemData.map((item, index) => {
                    return (
                      <ListItem sx={{ padding: "0px" }}>
                        <ActionItem
                          key={index}
                          image={item.image}
                          text={item.text}
                          onClick={() =>
                            handleChangePostType(item.action, false)
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
                {/* <ActionItemList data={actionItemData} /> */}
              </>
            );
            setOpenDrawer(true);
          }}
        />
      </Box>
      <SwipeableDrawer
        className={styles.drawerWrapper}
        open={openDrawer}
        variant="persistent"
        anchor="bottom"
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        {drawerContent}
      </SwipeableDrawer>
      <Popup
        isOpen={open}
        setIsOpen={(close: boolean) => setOpen(close)}
        title={t("socialWall.editPost.changePostType")}
        content={t("socialWall.editPost.changePostTypeDesc")}
        confirmBtnText={t("general.continue")}
        onClickConfirm={() => {
          confirmChangePostType();
        }}
      />
      <InvitationScopeField
        type="hidden"
        id="invitationScope"
        name="invitationScope"
        label={t("general.chooseAudience")}
        value={formValue.invitationScope.value}
        error={formValue.invitationScope.isError}
        required={formValue.invitationScope.isRequired}
        startAdornment={
          <img src="/assets/images/eventIcon_user_plus.svg" alt="" />
        }
        onChange={(value) => {
          handleInputFieldChange("invitationScope", value);
        }}
        groupOptionsFetch={getInvitationGroupList}
        groupOptionsFetchKey={
          process.env.REACT_APP_LOCATION !== "TWN"
            ? "storeInfo.area"
            : "storeInfo.district"
        }
        groupOptionsIdKey="value"
        individualsOptionsFetch={getStaffList}
        individualsOptionsFetchKey="staffList"
        individualsOptionsIdKey="partnerId"
        individualsFilterFetch={getStaffFilterList}
        handleOpen={showAudiencePopup}
        handleHiddenValue={getSubmitData}
        handleHiddenClose={getIsClosed}
      />
      {isSnackbarShow && (
        <Snackbar
          open={isSnackbarShow}
          autoHideDuration={6000}
          onClose={handleCloseMsgBar}
        >
          <Alert icon={<ErrorMsgIcon />} severity="error">
            {snackbarMsg}
          </Alert>
        </Snackbar>
      )}
      {isEventSearchShow && (
        <Box className={styles.EventSearchWrapper}>
          <AppBar id={styles.header} component="nav" position="sticky">
            <Grid container gap={1.25} alignItems="center">
              <Grid item xs="auto">
                <IconButton
                  id={styles.backButton}
                  disableRipple
                  onClick={() => setEventSearchShow(false)}
                >
                  <img src="/assets/images/close_btn.svg" alt="" />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="h3"
                  className={styles.title}
                  component="div"
                >
                  {t("socialWall.create.selectEvent")}
                </Typography>
              </Grid>
            </Grid>
          </AppBar>
          <EventSearch
            value={event}
            onChange={(value) => {
              setPostType("event");
              // setFormValue((prev) => ({
              //   ...prev,
              //   event: { ...prev.event, value: value },
              // }));
              setEvent(value);
              setEventSearchShow(false);
            }}
          ></EventSearch>
        </Box>
      )}

      {isDataLoading || isImageLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </>
  );
};

const PinCalendar: React.FC<{
  date: string;
  onChange: (date: string) => void;
}> = (props) => {
  const [date, setDate] = useState(props.date);

  const handleDateChange = (value: Moment) => {
    const newDateString = value.format();

    setDate(newDateString);
    props.onChange(newDateString);
  };

  return (
    <Calendar
      value={date}
      disablePast
      // isLoading={isLoading}
      onSelectDate={handleDateChange}
    />
  );
};

export default SocialWallCreate;
