import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';

import FilesMediaPopup from '../../components/files-media-popup';
import Header from '../../components/header/header';

import { AttachmentsType } from '../../interfaces/event-interface';
import styles from './eventAttachments.module.scss';
import EventDetailContext from './eventDetailProvider';

const imageType = ['image', 'png', 'jpg', 'jpeg', 'gif'];

const EventAttachments: FC = () => {
  const { detail, isLoading } = useContext(EventDetailContext);
  const navigate = useNavigate();
  const [attachmentsList, setAttachmentsList] = useState<
    Array<AttachmentsType>
  >([]);
  let { id } = useParams();

  const updateAttachmentsList = useCallback(() => {
    if (detail.attachments) {
      setAttachmentsList(detail.attachments);
    }
  }, [detail]);

  useEffect(() => {
    updateAttachmentsList();
  }, [updateAttachmentsList]);

  useEffect(() => {
    if (!id) {
      navigate('/event');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [showPopup, setShowPopup] = useState({
    open: false,
    fileData: {
      type: '',
      content: {
        file: {
          type: '',
          name: '',
          image: '',
          video: '',
        },
      },
    },
  });

  const handleAttachmentItemClick = (file: AttachmentsType) => {
    const fileType = file.url.split('.').pop();
    if (
      !imageType.includes(file.type.toLowerCase()) &&
      !imageType.includes(fileType as string)
    ) {
      if (fileType === 'mp4' || fileType === 'mov') {
        setShowPopup({
          open: true,
          fileData: {
            type: 'video',
            content: {
              file: {
                type: 'video',
                name: file.url.split('/').pop() as string,
                image: '',
                video: file.url,
              },
            },
          },
        });
      } else {
        downloadFile(file);
      }
    } else {
      setShowPopup({
        open: true,
        fileData: {
          type: 'image',
          content: {
            file: {
              type: 'image',
              name: file.url.split('/').pop() as string,
              image: file.url,
              video: '',
            },
          },
        },
      });
    }
  };

  const handleClosePopup = (close = false) => {
    setShowPopup((prev) => ({
      ...prev,
      open: close,
    }));
  };

  const downloadFile = async (file: AttachmentsType) => {
    console.log('file', file.url.replace('http://', 'https://'));
    try {
      // const getMIMEType = getContentTypeFromExtension(file.type);
      const fileName = file.url.split('/').pop();
      const fileResponse = await fetch(
        file.url.replace('http://', 'https://'),
        {
          method: 'get',
          credentials: 'include',
          // headers: {
          //   "Content-Type": getMIMEType,
          // },
        }
      );
      const blob = await fileResponse.blob();
      const urlObject = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlObject;
      link.download = fileName as string;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(urlObject); // Clean up the temporary URL
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Header title={t('event.attachments')} enableCloseButton />
      <Grid item xs className={styles.attachmentContainer}>
        <Grid container className={styles.attachmentList}>
          {isLoading && (
            <Grid item xs={6} className={styles.attachmentItem}>
              <AttachmentItemSkeleton />
            </Grid>
          )}
          {attachmentsList.map((attachment: AttachmentsType, index: number) => {
            const fileType = attachment.url.split('.').pop();
            let attachmentIcon = '/assets/images/attachmentIcon_image.svg';
            let thumbnail = attachment.url;

            if (
              !imageType.includes(attachment.type.toLowerCase()) &&
              !imageType.includes(fileType as string)
            ) {
              if (
                fileType === 'doc' ||
                fileType === 'docx' ||
                fileType === 'document'
              ) {
                attachmentIcon = '/assets/images/files/file_word.svg';
                thumbnail = '/assets/images/files/file_word.svg';
              } else if (fileType === 'pdf') {
                attachmentIcon = '/assets/images/files/file_pdf.svg';
                thumbnail = '/assets/images/files/file_pdf.svg';
              } else if (fileType === 'ppt' || fileType === 'pptx') {
                attachmentIcon = '/assets/images/files/file_presentation.svg';
                thumbnail = '/assets/images/files/file_presentation.svg';
              } else if (fileType === 'xls' || fileType === 'xlsx') {
                attachmentIcon = '/assets/images/files/file_spreadsheet.svg';
                thumbnail = '/assets/images/files/file_spreadsheet.svg';
              } else if (fileType === 'mp4' || fileType === 'mov') {
                attachmentIcon = '/assets/images/files/file_video.svg';
                thumbnail = attachment.url;
              } else {
                attachmentIcon = '/assets/images/files/file_other.svg';
                thumbnail = '/assets/images/files/file_other.svg';
              }
            }

            return (
              <Grid key={index} item xs={6} className={styles.attachmentItem}>
                <Stack gap={1}>
                  <Box
                    className={styles.attachmentMedia}
                    onClick={() => handleAttachmentItemClick(attachment)}
                  >
                    {fileType === 'mp4' || fileType === 'mov' ? (
                      <video src={`${thumbnail}#t=0.001`}></video>
                    ) : (
                      <img src={thumbnail} alt={attachment.name} />
                    )}
                  </Box>
                  <Grid container gap={1} alignItems="center" wrap="nowrap">
                    <Grid item xs="auto">
                      <img src={attachmentIcon} alt="" />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        className={styles.attachmentName}
                      >
                        {attachment.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <FilesMediaPopup
        open={showPopup.open}
        file={showPopup.fileData}
        onClose={handleClosePopup}
      />
    </>
  );
};

const AttachmentItemSkeleton: FC = () => {
  return (
    <Stack gap={1}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        className={styles.attachmentMedia}
      />
      <Skeleton variant="rectangular" animation="wave" height={24} />
    </Stack>
  );
};

export default EventAttachments;
