import React, { createContext, useEffect, useState } from "react";
import { CardListType } from "../../interfaces/green-apron-card-interface";
import { getCardsList } from "../../services/green-apron-card";
import { FormValueType } from "./useGreenCardFormHook";
import { getApiDataByLang } from "../../utility";
import { ObjectKey } from "../../interfaces/common-interface";

interface GreenApronCardContextType {
  loadingList: boolean;
  list: Array<CardListType>;
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  selectedId: string;
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  sendValue: FormValueType | null;
  setSendValue: React.Dispatch<React.SetStateAction<FormValueType | null>>;
  sendEmailList: Array<{ receiverName: string; receiverEmail: string }> | null;
  setSendEmailList: React.Dispatch<
    React.SetStateAction<Array<{ receiverName: string; receiverEmail: string }>>
  >;
}

const GreenApronCardContext = createContext<GreenApronCardContextType>({
  loadingList: true,
  list: [],
  selectedCategory: "",
  setSelectedCategory: () => "",
  selectedId: "",
  setSelectedId: () => "",
  sendValue: null,
  setSendValue: () => "",
  sendEmailList: null,
  setSendEmailList: () => "",
});

export const GreenApronCardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [list, setList] = useState<Array<CardListType>>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [sendValue, setSendValue] = useState<FormValueType | null>(null);
  const [sendEmailList, setSendEmailList] = useState<
    Array<{ receiverName: string; receiverEmail: string }>
  >([]);
  const [loadingList, setLoadingList] = useState<boolean>(true);

  useEffect(() => {
    let active = true;

    const getList = async () => {
      try {
        const response = await getCardsList();
        const cardListDetail = response.data.cardListDetail;

        if (active) {
          setList(
            cardListDetail.categoryLists.map((category: ObjectKey) => ({
              id: category._id,
              name: getApiDataByLang(category.categoryName),
              data: category.cards.map((cards: ObjectKey) => ({
                id: cards._id,
                name: "",
                description: cards.defaultMessage,
                url: cards.imageUrl,
              })),
            }))
          );
        }
      } catch (error) {
        throw error;
      } finally {
        setLoadingList(false);
      }
    };

    if (list.length <= 0 && loadingList) {
      getList();
    }

    return () => {
      active = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("list", list);
  // }, [list]);

  // useEffect(() => {
  //   console.log("selectedCategory", selectedCategory);
  // }, [selectedCategory]);

  useEffect(() => {
    if (selectedId && selectedCategory === "") {
      const category = list.find((category) =>
        category.data.find((card) => card.id === selectedId)
      );
      if (category) {
        setSelectedCategory(category.name);
      }
    }
    // console.log("selectedId", selectedId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  // useEffect(() => {
  //   console.log("sendValue", sendValue);
  // }, [sendValue]);

  return (
    <GreenApronCardContext.Provider
      value={{
        loadingList,
        list,
        selectedCategory,
        setSelectedCategory,
        selectedId,
        setSelectedId,
        sendValue,
        setSendValue,
        sendEmailList,
        setSendEmailList,
      }}
    >
      {children}
    </GreenApronCardContext.Provider>
  );
};

export default GreenApronCardContext;
