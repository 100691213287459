import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  SvgIcon,
} from "@mui/material";
import { Checkbox } from "..";
import styles from "./post-action-button.module.scss";
import { useNavigate } from "react-router-dom";
import { Popup, AccountItem } from "../../components";
import {
  getSocialWallDetailsLikeList,
  likePost,
} from "../../services/social-wall";
import { convertDateTime } from "../../utility";
import toHtml from "html-react-parser";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState } from "../../reducers/user-slice";
// import SocialWallResponseList from "../../pages/socialWall/socialWallResponseList";

interface PostActionButtonProps {
  postId: any;
  type: string;
  // likes: Array<any>;
  // comments: Array<any>;
  likesNo: number;
  commentsNo: number;
  isLiked: boolean;
  isLikedFromApi: boolean;
  hideActionBtn?: boolean;
  hideDetails?: boolean;
  commentAction?: () => void;
  customAction?: () => void;
  setLike: (value: boolean) => void;
  likeBtnCallback?: (value: boolean) => void;
  disableLike: boolean;
  disableComment: boolean;
}

export default function PostActionButton(props: PostActionButtonProps) {
  const {
    postId,
    type,
    likesNo,
    commentsNo,
    isLiked,
    isLikedFromApi,
    hideActionBtn,
    hideDetails,
    commentAction,
    customAction,
    setLike,
    likeBtnCallback,
    disableLike,
    disableComment,
  } = props;
  // if (postId === "65cc8a88b771db6b69decf36") {
  //   console.log("PostActionButton", props);
  // }

  // const [like, setLike] = useState<boolean>(isLiked);
  const [likeList, setLikeList] = useState<Array<any>>([]);
  const [showLikeList, setShowLikeList] = useState<boolean>(false);
  const [isSnackbarShow, setSnackbarShow] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [isLikeClickable, setIsLikeClickable] = useState<boolean>(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const userState = useSelector(
  //   (state: RootState): UserState => state.userState
  // );
  const handleLikeListBtnClick = () => {
    // navigate(`/social-wall/post-detail/${postId}/like-list`);
    setShowLikeList(true);
    getSocialWallDetailsLikeList(postId).then((res) => {
      setLikeList(res.data.likes);
    });
  };
  const handleLikeBtnClick = (value: boolean) => {
    // console.log("handleLikeBtnClick", value);
    likeBtnCallback && likeBtnCallback(value);
    setIsLikeClickable(false);
    setLike(!isLiked);
    likePost(postId)
      .then((res) => {
        // setLike(!isLiked);
      })
      .catch((err) => {
        if (err.response.data.status === 4108) {
          setSnackbarShow(true);
          setSnackbarMsg(t("socialWall.reportedPost.suspendSuccessfully"));
        }
      });
  };
  useEffect(() => {
    if (isLikeClickable === false) {
      setTimeout(() => {
        setIsLikeClickable(true);
      }, 200);
    }
  }, [isLikeClickable]);

  const handleCommentBtnClick = () => {
    const inputField = document.getElementsByTagName(
      "textarea"
    )?.[0] as HTMLElement;
    if (commentAction) {
      commentAction();
      if (inputField) {
        setTimeout(() => {
          inputField?.focus();
        }, 1000);
      }
    }

    if (inputField) {
      inputField?.focus();
    } else {
      navigate(`/social-wall/post-detail/${postId}`);
    }
  };
  const reponsesItem = () => {
    return (
      <Box className={styles.detailsContainer}>
        {likeList.map((item: any, index: number) => (
          <AccountItem
            key={index}
            name={item.createByName?.userName ?? item.createByName?.name}
            icon={item.userProfilePictureUrl}
            description={item.jobTitle}
            remarks={convertDateTime(item.createTime)}
            onClick={() =>
              navigate(`/discover-people/profile/${item.createBy}`)
            }
          />
        ))}
      </Box>
    );
  };

  const popupTitle = (
    <Box className={styles.title}>
      <span>
        {type === "vote"
          ? "Responses"
          : `${t("socialWall.likes")} ${likeList.length}`}
      </span>
      <img
        src="/assets/images/close_btn.svg"
        onClick={() => {
          setShowLikeList(false);
        }}
      />
    </Box>
  );
  return (
    <>
      {type !== "poll" && !hideDetails && !hideActionBtn && (
        <Box className={styles.postActionInfoContainer}>
          <Box className={styles.infoItem} onClick={handleLikeListBtnClick}>
            <img
              className={styles.icon}
              src="/assets/images/postActionIcon_like.svg"
            ></img>
            <Typography variant="body2">
              {likesNo -
                (isLikedFromApi && !isLiked ? 1 : 0) +
                (!isLikedFromApi && isLiked ? 1 : 0)}{" "}
              {t("socialWall.likes")}
            </Typography>
          </Box>
          <Box
            className={styles.infoItem}
            onClick={customAction ?? handleCommentBtnClick}
          >
            <img
              className={styles.icon}
              src="/assets/images/postActionIcon_comment.svg"
            ></img>
            <Typography variant="body2">
              {commentsNo} {t("socialWall.comments")}
            </Typography>
          </Box>
        </Box>
      )}
      {!hideActionBtn && (
        <Box className={styles.postActionBtnContainer}>
          {!disableLike && (
            <Checkbox
              type="likeBtn"
              checked={isLiked}
              isClickable={isLikeClickable}
              setChecked={(value) => handleLikeBtnClick(value)}
            />
          )}
          {!disableComment && (
            <Button
              className={styles.infoItem}
              onClick={customAction ?? handleCommentBtnClick}
            >
              <img
                className={styles.icon}
                src="/assets/images/postActionIcon_comment.svg"
              ></img>

              <Typography variant="body1">
                {t("socialWall.comments")}
              </Typography>
            </Button>
          )}
        </Box>
      )}

      {showLikeList && (
        <Popup
          isOpen={showLikeList}
          setIsOpen={(close: boolean) => setShowLikeList(close)}
          title={popupTitle}
          content={reponsesItem()}
          disableActions={true}
        />
      )}

      {isSnackbarShow && (
        <Snackbar
          open={isSnackbarShow}
          autoHideDuration={6000}
          onClose={() => setSnackbarShow(false)}
        >
          <Alert icon={<ErrorMsgIcon />} severity="error">
            {toHtml(snackbarMsg)}
          </Alert>
        </Snackbar>
      )}

      {/* {showLikeList && <SocialWallResponseList type="like" data={likes} isOpen={showLikeList} setShowLikeList />} */}
    </>
  );
}

function ErrorMsgIcon() {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      width="16"
      height="16"
      style={{ width: "auto", height: "auto" }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <path
          d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
          stroke="#C82014"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 6L10 10M10 6L6 10"
          stroke="#C82014"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
