import React, { FC, useEffect, useState } from "react";
import styles from "./storeDirectory.module.scss";
import { Box, Grid, Skeleton } from "@mui/material";
import { ActionItem } from "../../components";
import SearchField from "../../components/search-field";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import {
  PaginationParamsType,
  getStoresFilterList,
  getStoresList,
} from "../../services/common";
import { ObjectKey } from "../../interfaces/common-interface";
import MultiFilter, {
  FilterItemProps,
  OptionsListType,
  optionItemProps,
} from "../../components/multi-filter";
import NoResults from "../../components/no-results";
import {
  convertStoreList,
  setParamsToUrl,
  splitFilterParamsFromUrl,
  splitParamsFromUrl,
} from "../../utility";
import InfiniteScroll from "react-infinite-scroll-component";

const defaultParams = {
  page: 1,
  pageSize: 20,
  search: "",
  filter: [],
};

const StoreDirectory: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<Array<ObjectKey>>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<Array<OptionsListType>>([]);
  const [listParams, setListParams] =
    useState<PaginationParamsType>(defaultParams);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [paramsConverting, setParamsConverting] = useState<boolean>(true);
  const [filterParams, setFilterParams] = useState<PaginationParamsType>({
    search: "",
    filter: [],
  });

  const handleResetField = () => {
    if (!isLoading) {
      setSearchData([]);
      setListParams((prev) => ({
        ...prev,
        page: 1,
        // search: "",
        // filter: [],
      }));
      setFilterParams((prev) => ({
        ...prev,
        search: "",
        filter: [],
      }));
    }
  };

  const handleFilter = () => {
    setOpenFilter(true);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchData([]);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      // search: value,
    }));
    setFilterParams((prev) => ({
      ...prev,
      search: value,
    }));
    let paramsObj: ObjectKey = {
      search: value,
    };
    const filterParams = searchParams.get("filter");
    if (filterParams) {
      paramsObj = {
        ...paramsObj,
        filter: filterParams,
      };
    }
    const urlParams = setParamsToUrl(paramsObj);
    setSearchParams(urlParams);
  };

  const getStoreDirectory = React.useCallback(async () => {
    let active = true;
    if (!paramsConverting) {
      setIsLoading(true);
      setHasMore(true);

      try {
        const search = searchParams.get("search");
        const filter = searchParams.get("filter");
        let newParamsObj: PaginationParamsType = {};
        if (filter) {
          newParamsObj = splitParamsFromUrl({ filter: [] }, "filter=" + filter);
        }
        const request = {
          ...listParams,
          ...newParamsObj,
          search: search ?? "",
        };
        const response = await getStoresList(request);
        if (response.status === 200) {
          if (response.data.stores.length > 0 && active) {
            const convertList = convertStoreList(response.data.stores);
            setSearchData((prev) => [...prev, ...convertList]);
            if (response.data.totalPage === listParams.page) {
              setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    }

    return () => {
      active = false;
    };
  }, [listParams, searchParams, paramsConverting]);

  useEffect(() => {
    const getFilterList = async () => {
      const response = await getStoresFilterList();
      if (response.status === 200) {
        let areaFilterList: Array<optionItemProps> = [];
        let districtFilterList: Array<optionItemProps> = [];
        if (response.data.storeInfo.area) {
          response.data.storeInfo.area.forEach((item: ObjectKey) => {
            const areaName = item.name;
            areaFilterList = [
              ...areaFilterList,
              {
                categoryId: "areaId",
                value: item.areaId,
                displayValue: item.name,
              },
            ];

            if (item.district.length > 0) {
              item.district.forEach((district: ObjectKey) => {
                districtFilterList = [
                  ...districtFilterList,
                  {
                    categoryId: "districtId",
                    value: district.districtId,
                    displayValue: areaName + " - " + district.name,
                  },
                ];
              });
            }
          });
        }

        const convertFilter: Array<OptionsListType> = [
          {
            key: "areaId",
            name: t("storeDirectory.filter.area"),
            options: areaFilterList,
          },
          {
            key: "districtId",
            name: t("storeDirectory.filter.district"),
            options: districtFilterList,
          },
        ];
        setFilterList(convertFilter);
        const filter = searchParams.get("filter");
        if (filter) {
          const filterParamsArray = splitFilterParamsFromUrl(
            filter,
            convertFilter
          );
          setFilterParams({
            search: searchParams.get("search") ?? "",
            filter: filterParamsArray,
          });
          setSearchData([]);
          setParamsConverting(false);
        } else {
          setParamsConverting(false);
        }
      }
    };

    getFilterList();
  }, [t]);

  useEffect(() => {
    if (!paramsConverting) {
      const search = searchParams.get("search");
      let updateFilterParams = {
        search: "",
        filter: [],
      };
      if (search) {
        updateFilterParams = {
          ...updateFilterParams,
          search: search,
        };
      }
      const filter = searchParams.get("filter");
      if (filter) {
        const filterParamsArray = splitFilterParamsFromUrl(filter, filterList);
        updateFilterParams = {
          ...updateFilterParams,
          filter: filterParamsArray,
        };
      }
      setFilterParams((prev) => ({
        ...prev,
        ...updateFilterParams,
      }));
    }
  }, [searchParams]);

  useEffect(() => {
    getStoreDirectory();
  }, [getStoreDirectory]);

  const handleApplySelectedFilter = (selected: ObjectKey[]) => {
    setSearchData([]);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      filter: selected,
    }));
    setFilterParams((prev) => ({
      ...prev,
      filter: selected,
    }));
    let paramsObj: ObjectKey = {
      filter: selected,
    };
    const searchValueParams = searchParams.get("search");
    if (searchValueParams) {
      paramsObj = {
        ...paramsObj,
        search: searchValueParams,
      };
    }
    const urlParams = setParamsToUrl(paramsObj);
    setSearchParams(urlParams);
    setOpenFilter(false);
  };

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  return (
    <>
      <Header
        title={t("storeDirectory.title")}
        enableBackButton
        closeButtonFunction={() => {
          // console.log("closeButtonFunction");
          const prevPage = sessionStorage.getItem("prevPage") ?? null;
          if (prevPage) {
            window.location.href = prevPage;
          } else {
            navigate("/home");
          }
        }}
      />
      <SearchField
        id="search"
        name="search"
        value={filterParams.search ?? ""}
        placeholder={t("general.search")}
        onChange={handleValueChange}
        onReset={handleResetField}
        onFilter={handleFilter}
        activeFilter={filterParams.filter?.length !== 0}
      />
      {filterList && (
        <MultiFilter
          id={"filterPopup"}
          open={openFilter}
          selected={
            filterParams.filter
              ? (filterParams.filter as FilterItemProps[])
              : ([] as FilterItemProps[])
          }
          options={filterList}
          onChange={(value) => handleApplySelectedFilter(value)}
          onClose={() => setOpenFilter(false)}
        />
      )}
      <Grid id="storeListsContainer" item xs className={styles.searchResult}>
        {/* {isLoading && <LoadingSkeleton />} */}
        {!isLoading && !hasMore && searchData.length === 0 && (
          <NoResults resultsType="SEARCH" />
        )}
        <InfiniteScroll
          dataLength={searchData.length} //This is important field to render the next data
          next={nextPage}
          hasMore={hasMore}
          loader={<LoadingSkeleton />}
          scrollableTarget="storeListsContainer"
        >
          {searchData.map((item, index) => (
            <ActionItem
              key={index}
              text={item.name}
              description={item.address}
              padding="8px 16px"
              onClick={() => navigate(`details/${item.id}`)}
            />
          ))}
        </InfiniteScroll>
      </Grid>
    </>
  );
};

function LoadingSkeleton() {
  return (
    <Box sx={{ padding: 2 }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={40}
      />
    </Box>
  );
}

export default StoreDirectory;
