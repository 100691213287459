const MuiDialog = {
  styleOverrides: {
    root: {},
    paper: {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    paperFullWidth: {
      maxWidth: "none",
      width: "100%",
      margin: 0,
      marginTop: "auto",
    },
    paperFullScreen: {
      borderRadius: 0,
    },
  },
  defaultProps: {
    fullWidth: true,
    scroll: "paper" as any,
    keepMounted: true,
    // PaperProps: {
    //   sx: {
    //     padding: "16px 16px 24px",
    //   },
    // },
  },
};

export default MuiDialog;
