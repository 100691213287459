import React, { FC } from "react";
import InputField, { InputFieldProps } from "../input-field";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Radio,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import styles from "./select-field.module.scss";
import SelectFieldContainer from "../select-field-container";
import { containsObject } from "../../utility";
import { useSelectHook } from "./useSelectHook";

export interface SelectFieldProps
  extends Omit<InputFieldProps, "value" | "multiline"> {
  // eslint-disable-next-line
  type?: string;
  startAdornment?: React.ReactNode;
  value: any;
  popupLabel?: string;
  confirmLabel?: string;
  // eslint-disable-next-line
  options: Array<any>;
  isOptionReverse?: boolean;
  multiple?: boolean;
  disableLabel?: boolean;
  error?: boolean;
  // eslint-disable-next-line
  getOptionValue: (value: any) => string;
  // eslint-disable-next-line
  getOptionLabel: (value: any) => string;
  getOptionDisplay?: (value: any) => string;
  getOptionDesc?: (value: any) => string;
  otherFieldValue?: string;
  otherFieldProps?: InputFieldProps;
  otherFieldComponent?: () => React.ReactNode;
  // eslint-disable-next-line
  optionsItem?: (
    value: any,
    id: string,
    classes?: string,
    isSelected?: boolean
  ) => React.ReactNode;
  disableDateTimePicker?: (value: any) => boolean;
  handleOptionClick?: (value: any) => void;
  replaceOptionDesc?: string;
  disableSelectedCountOnBtn?: boolean;
  disableOptions?: boolean;
}

const SelectField: FC<SelectFieldProps> = (props) => {
  const {
    type,
    startAdornment,
    value,
    options,
    isOptionReverse = false,
    multiple,
    disableLabel,
    confirmLabel,
    error,
    getOptionValue,
    getOptionLabel,
    getOptionDisplay,
    getOptionDesc,
    otherFieldValue,
    otherFieldProps,
    otherFieldComponent,
    optionsItem,
    disableDateTimePicker,
    handleOptionClick,
    replaceOptionDesc,
    disableSelectedCountOnBtn,
    disableOptions,
    ...containerProps
  } = props;
  const {
    multipleValueDisplayRef,
    selectedValue,
    enableOtherField,
    handleSelectOption,
    handleReset,
    displayValue,
    handleClear,
  } = useSelectHook({
    value,
    multiple,
    otherFieldValue,
    otherFieldProps,
    getOptionValue,
    getOptionLabel,
    getOptionDisplay,
    disableDateTimePicker,
  });

  const handleConfirmSelected = (callback: () => void) => {
    handleSelectOption(selectedValue, multiple);
    containerProps.onChange(selectedValue);
    callback();
  };

  const SelectedList = () => {
    if (!selectedValue || !Array.isArray(selectedValue)) return null;

    return (
      <List className={styles.optionsList}>
        {/* eslint-disable-next-line */}
        {(selectedValue as Array<any>).map((option: any, index: number) => {
          const selectedOptionID = `${
            containerProps.name
          }SelectedOption_${getOptionValue(option)}`;
          return (
            <Option
              key={index}
              option={option}
              id={selectedOptionID}
              onClick={() => {
                handleSelectOption(option, multiple);
              }}
              forRemove
            />
          );
        })}
      </List>
    );
  };

  const Option = (props: {
    // eslint-disable-next-line
    option: any;
    id: string;
    isReverse?: boolean;
    onClick: () => void;
    forRemove?: boolean;
  }) => {
    let isSelected = false;
    if (selectedValue) {
      if (multiple) {
        isSelected = containsObject(props.option, selectedValue);
      } else {
        isSelected =
          getOptionValue(selectedValue) === getOptionValue(props.option);
      }
    }

    const removeBtnProps = props.forRemove
      ? {
          secondaryAction: (
            <IconButton
              id={`remove-${props.id}-btn`}
              edge="end"
              className={styles.removeBtn}
              onClick={props.onClick}
            >
              <img src="/assets/images/close_btn.svg" alt="Remove Option" />
            </IconButton>
          ),
        }
      : {};

    return (
      <ListItem id={props.id} disablePadding {...removeBtnProps}>
        <ListItemButton
          className={`${styles.optionItem} ${
            isSelected && styles.optionItem__active
          } ${props.isReverse ? "optionItem__reverse" : ""} ${
            props.option.isNextStep ? "optionItem__arrow" : ""
          } ${!isSelected && disableOptions ? "optionItem__disabled" : ""}`}
          onClick={() => {
            if (!props.forRemove) {
              props.onClick();
            }
          }}
          sx={{
            "&.optionItem__arrow:after": {
              backgroundImage: `url("../assets/images/chevron_right.svg")`,
            },
          }}
        >
          {optionsItem ? (
            optionsItem(props.option, props.id)
          ) : (
            <>
              <Box className={styles.optionName}>
                <ListItemText primary={getOptionLabel(props.option)} />
                {getOptionDesc && (
                  <Typography
                    variant="caption"
                    className={`${props.option.isNextStep ? "desc" : ""}`}
                  >
                    {replaceOptionDesc
                      ? props.option.isNextStep
                        ? replaceOptionDesc
                        : getOptionDesc(props.option)
                      : getOptionDesc(props.option)}
                  </Typography>
                )}
              </Box>
              {!props.forRemove && (
                <ListItemIcon>
                  <Radio
                    disableRipple
                    checked={isSelected}
                    disabled={!isSelected && disableOptions}
                    value={getOptionValue(props.option)}
                    inputProps={{ "aria-labelledby": props.id }}
                    tabIndex={-1}
                    edge="end"
                    className={styles.optionRadio}
                  />
                </ListItemIcon>
              )}
            </>
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  const otherComponent = () => {
    if (!otherFieldProps && !otherFieldComponent) return <></>;

    return (
      <>
        {otherFieldComponent ? (
          otherFieldComponent()
        ) : otherFieldProps ? (
          <ListItem disablePadding className={styles.optionOtherField}>
            <InputField {...otherFieldProps} />
          </ListItem>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <SelectFieldContainer
      {...containerProps}
      ref={multiple ? multipleValueDisplayRef : null}
      disableLabel={disableLabel}
      confirmLabel={confirmLabel}
      multiple={multiple ? true : false}
      value={displayValue()}
      selectedValue={selectedValue}
      selectedList={<SelectedList />}
      onConfirmSelected={handleConfirmSelected}
      onClear={handleClear}
      onCancel={handleReset}
      type={type}
      error={error}
      disabled={
        containerProps.disabled ||
        (enableOtherField && otherFieldProps && otherFieldProps.value === "")
      }
      startAdornment={startAdornment}
      disableSelectedCountOnBtn={disableSelectedCountOnBtn}
    >
      <List className={styles.optionsList}>
        {/* eslint-disable-next-line */}
        {options.map((option: any, index: number) => {
          const optionID = `${containerProps.name}SelectOption_${getOptionValue(
            option
          )}`;
          return (
            <Option
              key={index}
              option={option}
              id={optionID}
              isReverse={isOptionReverse}
              onClick={() => {
                handleOptionClick && handleOptionClick(option);
                if (!disableOptions) {
                  handleSelectOption(option, multiple);
                }
              }}
            />
          );
        })}
        {enableOtherField && otherComponent()}
      </List>
    </SelectFieldContainer>
  );
};

export default SelectField;
