import { AxiosPromise } from "axios";
import { instance, searchInstance } from "./api-services";
import { ObjectKey } from "../interfaces/common-interface";

export interface PaginationParamsType {
  page?: number;
  pageSize?: number;
  sort?: string;
  by?: "asc" | "desc";
  search?: string;
  jobTitle?: string;
  filter?: ObjectKey[];
  type?: string;
  eventType?: string;
  registerType?: string;
}

const directoryServicePath = "/directory";

// Store Directory API
// get store list API
export const getStoresList = (data?: PaginationParamsType): AxiosPromise => {
  const params = `?page=${data?.page}&pageSize=${data?.pageSize}&search=${
    data?.search ? data.search : ""
  }`;
  let filter: ObjectKey | null = null;
  if (data && data.filter && data.filter.length > 0) {
    filter = {};
    data.filter.forEach((item) => {
      filter = { ...filter, [item.key]: item.selected };
    });
  }

  return searchInstance({
    method: process.env.REACT_APP_LOCATION === "TWN" ? "GET" : "POST",
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/storeInfo/list${params}`,
    data: filter ?? undefined,
  });
};

// get store filter list API
export const getDistrictList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `district`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/storeInfo/district`,
  });

export const getStoresFilterList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getStoresFilterList`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/storeInfo/groupList`,
  });

// get store detail API
export const getStoreDetail = (storeId: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getStoreDetail-${storeId}`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/storeInfo/detail/${storeId}`,
  });

// Old get event admin list API (deprecated)
export const getEventAdmins = (params?: PaginationParamsType): AxiosPromise =>
  instance({
    method: "GET",
    id: `getEventAdmins`,
    url: `${directoryServicePath}/profile/eventAdmins`,
    params,
  });

// Staff Directory API
// get staff list API
export const getStaffList = (data?: PaginationParamsType): AxiosPromise => {
  const params = `?page=${data?.page}&pageSize=${data?.pageSize}&search=${
    data?.search ? data.search : ""
  }`;
  let filter: ObjectKey | null = null;
  if (data && data.filter && data.filter.length > 0) {
    filter = {};
    data.filter.forEach((item) => {
      filter = { ...filter, [item.key]: item.selected };
    });
  }

  return searchInstance({
    method: "POST",
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/staffDirectory/filter${params}`,
    data: {
      filter,
    },
  });
};

// get staff filter list API
export const getStaffFilterList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getStaffFilterList`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/staffDirectory/search`,
  });

// get staff detail API
export const getStaffDetail = (staffId: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getStaffDetail-${staffId}`,
    cache: { ttl: 30 * 1000 },
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/staffDirectory/profile`,
    params: {
      id: staffId,
    },
  });

// get Invitation Scope Group List API
export const getInvitationGroupList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getInvitationGroupList`,
    url: `${directoryServicePath}/${process.env.REACT_APP_API_LOCATION}/storeInfo/groupList`,
  });

// get Inbox Message List API
export const getInboxMessageList = (params: {
  page: number;
  pageSize: number;
  filter?: string;
}): AxiosPromise =>
  searchInstance({
    method: "GET",
    id: `getInboxMessageList-${params.page}-${params.pageSize}`,
    url: `${directoryServicePath}/inbox`,
    cache: false,
    params,
  });

// get Inbox Message List Status API
export const getInboxMessageStatus = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getInboxMessageStatus",
    cache: { ttl: 30 * 1000 },
    url: `${directoryServicePath}/inbox/readRecord`,
  });

// get Sendbird config API
export const getSendbirdConfig = (): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    url: `${directoryServicePath}/chat/sendBirdUserInfo`,
  });
