import i18next from "i18next";
import { set } from "immer/dist/internal";
import moment from "moment";
import { useState, useEffect } from "react";
import {
  CreateSurveyCoverImage,
  createQuestionAnswerImage,
} from "../../services/survey";
import { useTranslation } from "react-i18next";

interface ReturnFunctionType {
  isDirty: boolean;
  // selectedLang: Array<string>;
  formValue: FormValueType;
  handleInputFieldChange: (name: string, value: any) => void;
  handleTextareaFieldChange: (name: string, value: any, limit?: number) => void;
  handleChangeLang: (selected: Array<string>) => void;
  handleToggleButtonChange: (name: string, checked: boolean) => void;
  checkValidation: () => boolean;
  convertFormValueToRequest: () => any;
  uploadImage: (image: any) => any;
  // eslint-disable-next-line
  setInitFormValue: (data: FormValueType) => void;
  // setSelectedLang: (data: Array<string>) => void;
  uploadQuestionAnswerImage: (file: File) => Promise<string | null>;
}

interface FormValueSettingType {
  // eslint-disable-next-line
  value: any;
  isError: boolean;
  isRequired: boolean;
}

export interface FormValueType {
  [key: string]: FormValueSettingType;
}

export const useSurveyFormHook = (): ReturnFunctionType => {
  const nowDate = moment();
  const roundTime = 30 - (nowDate.minute() % 30);
  const roundedStartDate = nowDate.add(roundTime, "minutes").format();
  const roundedEndDate = moment(roundedStartDate).add(1, "hour").format();
  const { t } = useTranslation();
  // const [selectedLang, setSelectedLang] = useState<Array<string>>(
  //   i18next.language.includes("zh") ? ["TC"] : ["EN"]
  // );
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [formValue, setFormValue] = useState<FormValueType>({
    cover: {
      value: null,
      isError: false,
      isRequired: true,
    },
    displayLanguage: {
      value: ["en"],
      isError: false,
      isRequired: true,
    },
    nameEN: {
      value: "",
      isError: false,
      isRequired: false,
    },
    descriptionEN: {
      value: "",
      isError: false,
      isRequired: false,
    },
    nameTC: {
      value: "",
      isError: false,
      isRequired: false,
    },
    descriptionTC: {
      value: "",
      isError: false,
      isRequired: false,
    },
    surveyDate: {
      value: {
        startDate: roundedStartDate,
        endDate: roundedEndDate,
      },
      isError: false,
      isRequired: true,
    },
    respondents: {
      value: {
        id: "public",
        name: t("general.allPartners"),
        displayName: t("general.allPartners"),
        description: "",
      },
      isError: false,
      isRequired: true,
    },
    invitationScope: {
      value: {
        group: [],
        individuals: [],
      },
      isError: false,
      isRequired: true,
    },
    anonymousResponses: {
      value: false,
      isError: false,
      isRequired: false,
    },
    questions: {
      value: [],
      isError: false,
      isRequired: true,
    },
  });

  // useEffect(() => {
  //   console.log("formValue", formValue);
  // }, [formValue]);

  const handleInputFieldChange = (name: string, value: any) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
      },
    };

    if (formValue[formName].isRequired) {
      let inValid = value === "" || !value;

      updateFormValue = {
        [formName]: {
          ...updateFormValue[formName],
          isError: inValid,
        },
      };
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    // if (!isDirty && name !== "questions" && value.length > 0) {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleTextareaFieldChange = (
    name: string,
    value: any,
    limit?: number
  ) => {
    const formName = name as keyof typeof formValue;
    let inValid = formValue[formName].isRequired
      ? value === "" || !value
      : false;

    if (!inValid && limit) {
      inValid = value.length > limit;
    }

    const updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
        isError: inValid,
      },
    };

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleChangeLang = (selected: Array<string>) => {
    const haveEN = selected.some((lang: string) => lang === "en");
    const haveTC = selected.some((lang: string) => lang === "tc");
    const langList = [];
    if (haveEN) {
      langList.push("en");
    }
    if (haveTC) {
      langList.push("tc");
    }
    const updateFormValue = {
      ...formValue,
      displayLanguage: {
        ...formValue.displayLanguage,
        value: langList,
      },
      nameEN: {
        ...formValue.nameEN,
        isRequired: haveEN,
      },
      descriptionEN: {
        ...formValue.descriptionEN,
        isRequired: haveEN,
      },
      nameTC: {
        ...formValue.nameTC,
        isRequired: haveTC,
      },
      descriptionTC: {
        ...formValue.descriptionTC,
        isRequired: haveTC,
      },
    };

    setFormValue(updateFormValue);
    // setSelectedLang(selected);
  };

  const handleToggleButtonChange = (name: string, checked: boolean) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: checked,
      },
    };

    if (formName === "externalLink") {
      updateFormValue = {
        ...updateFormValue,
        ["externalLinkUrl" as keyof typeof formValue]: {
          ...formValue.externalLinkUrl,
          isRequired: checked,
        },
      };
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));
  };

  const checkValidation = () => {
    const requiredValue = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].isRequired)
    );

    let errorField: Array<string> = [];
    for (const [key, value] of Object.entries(requiredValue)) {
      if (!value.value) {
        errorField.push(key);
      } else if (Array.isArray(value.value)) {
        if (value.value.length <= 0) {
          errorField.push(key);
        }
      } else if (typeof value.value === "object") {
        if (key === "respondents") {
          const isFill = Object.entries(value.value).some(
            ([key]) => value.value[key].length > 0
          );
          if (!isFill) errorField.push(key);
        } else if (key === "surveyDate") {
          const isFill = value.value.startDate && value.value.endDate;
          if (!isFill) errorField.push(key);
        } else {
          if (Object.keys(value.value).length <= 0) {
            errorField.push(key);
          }
        }
      }
    }

    if (errorField.length > 0) {
      let errorFieldObj: FormValueType = formValue;
      errorField.forEach((key: string) => {
        errorFieldObj = {
          ...errorFieldObj,
          [key]: {
            ...errorFieldObj[key],
            isError: true,
          },
        };
      });
      setFormValue(errorFieldObj);
      return false;
    }

    return true;
  };
  const convetOptionsToRequest = (options: Array<any>, withImage?: boolean) => {
    if (withImage) {
      const optionList = options.map((item: any) => ({
        value: item.value,
        imageName: item.file.value,
        ...(item?.isOther && { isOthers: item.isOther }),
      }));
      // console.log("optionList", optionList);
      return optionList;
    }
    let optionList: Array<any> = [];

    for (let index = 0; index < options.length; index++) {
      optionList.push({
        value: options[index].value,
        ...(options[index]?.isOther && { isOthers: options[index].isOther }),
      });
    }
    return optionList;
  };
  const convetQuestionToRequest = () => {
    const questions = formValue.questions.value;
    let questionList: Array<any> = [];
    for (let index = 0; index < questions.length; index++) {
      const value = questions[index];
      const getQuestionFieldValue = (index: keyof FormValueType) =>
        value[index].value;
      let commonQuestionObject = {
        questionType: getQuestionFieldValue("questionType"),
        questionNo: index + 1,
        question: getQuestionFieldValue("question"),
        isRequired: getQuestionFieldValue("required"),
        pictureChoice: getQuestionFieldValue("pictureChoice"),
        questionImageName: getQuestionFieldValue("uploadPhotos"),
      };

      // console.log(
      //   'getQuestionFieldValue("pictureChoice"),',
      //   getQuestionFieldValue("pictureChoice")
      // );

      if (
        getQuestionFieldValue("questionType") === "6448afcf45a431c8981ff6d5"
      ) {
        //Rank order
        questionList.push({
          ...commonQuestionObject,
          orderAnswers: convetOptionsToRequest(
            getQuestionFieldValue("options"),
            getQuestionFieldValue("pictureChoice")
          ),
        });
      } else if (
        getQuestionFieldValue("questionType") === "6448afac1dde4cea6034cda0"
      ) {
        //Multiple choice
        questionList.push({
          ...commonQuestionObject,
          answerChoices: convetOptionsToRequest(
            getQuestionFieldValue("options"),
            getQuestionFieldValue("pictureChoice")
          ),
          otherOption: getQuestionFieldValue("otherOption"),
          isMultipleSelection: getQuestionFieldValue("allowMultipleSelection"),
          multipleSelection: getQuestionFieldValue("multipleSelection"),
          answerNumbers: getQuestionFieldValue("answerNumbers")
            ? getQuestionFieldValue("answerNumbers")
            : convetOptionsToRequest(getQuestionFieldValue("options")).length,
        });
      } else if (
        getQuestionFieldValue("questionType") === "6448af76a5f840a74e23814f"
      ) {
        //Opinion scale
        questionList.push({
          ...commonQuestionObject,
          opinionScale: getQuestionFieldValue("opinionScale"),
          lowScoreLabel: getQuestionFieldValue("lowScoreLabel"),
          highScoreLabel: getQuestionFieldValue("highScoreLabel"),
        });
      } else {
        questionList.push(commonQuestionObject);
      }
    }
    return questionList;
  };
  const uploadImage = async (value: any) => {
    const FD = new FormData();
    const image = { ...FD, image: value };

    const responseData = await CreateSurveyCoverImage(image).catch((err) => {
      throw err;
    });
    if (responseData) {
      setIsDirty(true);
      return responseData;
    } else {
      return {};
    }
    // .then((res) => {
    //   responseData = res.data.data.surveyCoverPhoto;
    //   console.log("postCreateSurvey", responseData);
    // return responseData;
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  const convertFormValueToRequest = () => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;
    const surveyTitle = [];
    const surveyDescription = [];

    if (getFieldValue("displayLanguage").includes("en")) {
      surveyTitle.push({ lang: "en", value: getFieldValue("nameEN") });
      surveyDescription.push({
        lang: "en",
        value: getFieldValue("descriptionEN"),
      });
    }

    if (getFieldValue("displayLanguage").includes("tc")) {
      surveyTitle.push({ lang: "tc", value: getFieldValue("nameTC") });
      surveyDescription.push({
        lang: "tc",
        value: getFieldValue("descriptionTC"),
      });
    }
    const surveyStartDate = getFieldValue("surveyDate").startDate;
    const surveyEndDate = getFieldValue("surveyDate").endDate;
    // console.log("cover", getFieldValue("cover"));
    return {
      ...getFieldValue("cover"),
      displayLanguage: getFieldValue("displayLanguage"),
      surveyTitle: surveyTitle,
      surveyDescription: surveyDescription,
      respondents: {
        forPartners:
          getFieldValue("respondents").id === "private"
            ? getFieldValue("invitationScope").individuals.map(
                (item: any) => item.id
              )
            : [],
        forGroups:
          getFieldValue("respondents").id === "private"
            ? getFieldValue("invitationScope").group.map(
                (item: any) => item.value
              )
            : [],
      },

      allowAllPartners: getFieldValue("respondents").id === "public",
      anonymousResponses: getFieldValue("anonymousResponses"),
      questions: convetQuestionToRequest(),
      surveyStartDate: moment(surveyStartDate).unix(),
      surveyEndDate: surveyEndDate ? moment(surveyEndDate).unix() : null,
    };
  };

  const setInitFormValue = (data: FormValueType) => {
    setFormValue((prev) => ({
      ...prev,
      ...data,
    }));
    if (!isDirty) {
      setIsDirty(true);
    }
    // setSelectedLang(data.displayLanguage.value);
  };

  const uploadQuestionAnswerImage = async (
    file: File
  ): Promise<string | null> => {
    try {
      const response = await createQuestionAnswerImage(file);
      setIsDirty(true);
      return response.data?.surveyQAPhoto?.imageUrl ?? null;
    } catch (error) {
      return null;
    }
  };

  return {
    isDirty,
    // selectedLang,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    handleChangeLang,
    handleToggleButtonChange,
    checkValidation,
    convertFormValueToRequest,
    uploadImage,
    setInitFormValue,
    // setSelectedLang,
    uploadQuestionAnswerImage,
  };
};
