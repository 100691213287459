const MuiPickersCalendarHeader = {
  styleOverrides: {
    root: {
      marginTop: 0,
      padding: 0,
      paddingBottom: "16px",
    },
  },
};

export default MuiPickersCalendarHeader;
