import { AxiosPromise, AxiosResponse } from "axios";
import { instance, searchInstance } from "./api-services";
import { compressFileList, removeApiCacheSession } from "../utility";
// import { ObjectKey } from "../interfaces/common-interface";

const servicePath = "/social-wall";

export interface SocailWallListRequestType {
  page: number;
  tab?: string;
  pageSize?: number;
  sort?: string;
  by?: string;
  search?: string;
  partnerNetworkGroupId?: string;
}
interface postProps {
  content: string;
  postType: string;
  isPostAnonymously: boolean;
  isScheuldePost: boolean;
  tags: [];
  photos: [];
  attachments: [];
  scheduleStartTime?: number;
  scheduleEndTime?: number;
  audienceControl: {
    isPostPublic: boolean;
    forPartners?: Array<string>;
    forOu?: Array<string>;
    forStores?: Array<string>;
    forGroups?: Array<string>;
    forDistrict?: Array<string>;
  };
  pollInfo?: {
    title: string;
    votings: Array<{ seq: number; voteName: string }>;
    isVoteAnonymously: boolean;
    pollEndTime: number;
  };
  eventId?: string;
}
/////Admin APIs/////
export const pinPost = (id: string): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "POST",
    url: `${servicePath}/admin/pin/${id}`,
  });
};

export const pinUntilPost = (id: string, pinUntil: string): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "POST",
    url: `${servicePath}/admin/pin/${id}`,
    data: {
      pinUntil: pinUntil,
    },
  });
};

export const unpinPost = (id: string): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "POST",
    url: `${servicePath}/admin/unpin/${id}`,
  });
};

export const getReportedPostList = (
  params: SocailWallListRequestType
): AxiosPromise =>
  instance({
    method: "GET",
    url: `${servicePath}/admin/reportedPost`,
    cache: false,
    params: params,
  });
export const getReportedPostCount = (
  params: SocailWallListRequestType
): AxiosPromise =>
  instance({
    method: "GET",
    url: `${servicePath}/admin/reportedPost`,
    cache: false,
    params: params,
  });

export const getReportedPostDetails = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    url: `${servicePath}/admin/reportedPost/${id}`,
    cache: false,
  });

export const updateReportedPost = (
  id: string,
  status: string,
  remarks?: string
): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "PUT",
    url: `${servicePath}/admin/reportedPost/${id}`,
    data: {
      reportStatus: status,
      comment: remarks,
    },
  });
};
export const deleteReportedPost = (
  id: string,
  comment: string
): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "POST",
    url: `${servicePath}/admin/reportedPost/${id}`,
    data: {
      comment: comment,
    },
  });
};
export const restoreReportedPost = (id: string): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "PUT",
    url: `${servicePath}/admin/restorePost`,
    data: {
      postId: id,
    },
  });
};
export const getReportedByList = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getReportedByList-${id}`,
    url: `${servicePath}/admin/reportedBy/${id}`,
  });

export const getReportedPostLogList = (
  params: SocailWallListRequestType
): AxiosPromise =>
  searchInstance({
    method: "GET",
    id: `getReportedPostLogList-${params.tab}-${params.page}-${params.pageSize}`,
    url: `${servicePath}/admin/reportedPost/log`,
    params: params,
  });

export const getReportedPostLogDetail = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getReportedPostLogDetail-${id}`,
    url: `${servicePath}/admin/reportedPost/log/${id}`,
  });

export const getScheduledPostList = (
  params: SocailWallListRequestType
): AxiosPromise =>
  searchInstance({
    method: "GET",
    id: `getScheduledPostList-${params.page}-${params.tab}-${params.pageSize}-${params.sort}-${params.by}-${params.search}`,
    url: `${servicePath}/admin/schedulePost`,
    params: params,
  });

export const editScheduledPost = (
  id: string,
  input: postProps
): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "PUT",
    url: `${servicePath}/admin/schedulePost/${id}`,
    data: input,
  });
};

export const deleteScheduledPost = (id: string): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "DELETE",
    url: `${servicePath}/admin/schedulePost/${id}`,
  });
};

export const getInboxStatus = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getInboxStatus`,
    cache: { ttl: 5 * 60 * 1000 },
    url: `${servicePath}/admin/inboxStatus`,
  });

/////User APIs/////

export const createPost = (
  // input: postProps
  input: any
): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  removeApiCacheSession("getPartnerNetwork-");
  return instance({
    method: "POST",
    url: `${servicePath}/user`,
    data: input,
    // {
    //   content: 'content 1',
    //   postType: 'text',
    //   isPostAnonymously: true,
    //   isSchedulePost: false,
    //   tags: [],
    //   photos: [],
    //   attachments: [],
    //   audienceControl: {
    //     isPostPublic: false,
    //   },
    // },
  });
};

export const uploadAttachments = async (data: {
  file: File[];
}): Promise<AxiosResponse> => {
  const formData = new FormData();

  try {
    const fileList = await compressFileList(data.file, 0.5, 1280);
    fileList.forEach((item: File | undefined) => {
      if (item) formData.append("file[]", item, encodeURIComponent(item.name));
    });
  } catch (error) {
    console.log("compressFileList error:", error);
  }

  return instance({
    method: "POST",
    url: `${servicePath}/user/attachments`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};
export const editPost = (id: string, input: any): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "PUT",
    url: `${servicePath}/user/${id}`,
    data: input,
  });
};

export const deletePost = (id: string): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  removeApiCacheSession("getPartnerNetwork-");
  return instance({
    method: "DELETE",
    url: `${servicePath}/user/${id}`,
  });
};

export const reportPost = (
  id: string,
  input: {
    reportType: string;
    ReportDetails: string;
  }
): AxiosPromise =>
  instance({
    method: "POST",
    url: `${servicePath}/user/${id}/report`,
    data: input,
  });

export const likePost = (id: string): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  return instance({
    method: "POST",
    url: `${servicePath}/user/${id}/like`,
  });
};

export const likeComment = (id: string): AxiosPromise =>
  instance({
    method: "POST",
    url: `${servicePath}/user/${id}/like`,
    data: {
      isComment: true,
    },
  });

export const commentPost = (
  id: string,
  comment: string,
  isAnonymous: boolean,
  partnerTag: Array<string>
): AxiosPromise => {
  removeApiCacheSession("getSocialWallList-");
  removeApiCacheSession("getPartnerNetwork-");
  return instance({
    method: "POST",
    url: `${servicePath}/user/${id}/comment`,
    data: {
      comment: comment,
      postAnonymously: isAnonymous,
      annotatedUsers: partnerTag,
    },
  });
};

/////Timeline APIs/////
export const getSocialWallList = (
  params: SocailWallListRequestType
): AxiosPromise =>
  instance({
    method: "GET",
    id: `getSocialWallList-${params.page}-${params.pageSize}-${params.tab}${
      params.partnerNetworkGroupId ? `-${params.partnerNetworkGroupId}` : ""
    }`,
    cache: { ttl: 15 * 60 * 1000 },
    url: `${servicePath}/timeline`,
    params: params,
  });

export const getGroupGallery = (
  params: SocailWallListRequestType
): AxiosPromise =>
  instance({
    method: "GET",
    id: `getSocialWallList-gallery-${params.page}-${params.pageSize}-${params.partnerNetworkGroupId}`,
    cache: { ttl: 15 * 60 * 1000 },
    url: `${servicePath}/timeline/gallery/${params.partnerNetworkGroupId}`,
    params: {
      page: params.page,
      pageSize: params.pageSize,
    },
  });

export const getSocialWallDetails = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getSocialWallList-details-${id}`,
    url: `${servicePath}/timeline/${id}`,
  });

export const getVoteDetails = (id: string, optionNum: number): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    url: `${servicePath}/timeline/${id}/voteLog`,
    params: {
      option: optionNum,
    },
  });

export const getSearchSocialWall = (
  params: SocailWallListRequestType
): AxiosPromise =>
  instance({
    method: "GET",
    url: `${servicePath}/timeline/search`,
    params: params,
  });

export const pollVote = (id: string, vote: number): AxiosPromise =>
  instance({
    method: "POST",
    url: `${servicePath}/user/vote`,
    data: {
      postId: id,
      voteOption: vote,
    },
  });

export const uploadPollVoteImage = (image: File): AxiosPromise => {
  const formData = new FormData();
  formData.append("image", image, encodeURIComponent(image.name));
  return instance({
    method: "POST",
    url: `${servicePath}/user/votingImage`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};
// export const getSearchPartnerTag = (id: string): AxiosPromise =>
//   instance({
//     method: "GET",
//     url: `${servicePath}//user/partner/id`,
//   });
export const getHashTagSuggestion = (text: string): AxiosPromise =>
  searchInstance({
    method: "GET",
    id: `getHashTagSuggestion-${text}`,
    url: `${servicePath}/user/tagSuggestion`,
    params: {
      search: text,
    },
  });

export const getSearchSuggestion = (text?: string): AxiosPromise =>
  searchInstance({
    method: "GET",
    id: `getSearchSuggestion-${text}`,
    url: `${servicePath}/timeline/searchRecords`,
    params: {
      search: text,
    },
  });

export const deleteRecentSearch = (word?: string): AxiosPromise =>
  instance({
    method: "DELETE",
    url: `${servicePath}/timeline/searchRecords`,
    params: {
      word: word,
    },
  });

export const getSearchPostByHashTag = (
  params: SocailWallListRequestType
): AxiosPromise =>
  instance({
    method: "GET",
    url: `${servicePath}/user/tag`,
    params: params,
  });

export const getSocialWallDetailsLikeList = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    url: `${servicePath}/timeline/${id}/likes`,
  });

export const getSocialWallDetailsCommentList = (
  id: string,
  params: { page?: number; pageSize?: number }
): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    url: `${servicePath}/timeline/${id}/comments`,
    params: params,
  });

export const getSocialWallConfig = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getSocialWallConfig",
    url: `${servicePath}/timeline/globalConfig`,
  });
