import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./partnerNetwork.module.scss";
import MorePopup from "./components/morePopup";
import GroupDetail from "./components/groupDetail";
import SocialWall from "../socialWall/socialWall";
import { hideGroup } from "../../services/partner-network";
import SnackBar from "./components/snackBar";
import usePermissionHook from "./hook/usePermissionHook";
import GroupActionPopup from "./components/groupActionPopup";
import { BottomNavMenu } from "../../components";

type Props = {};

const PartnerNetworkDetail = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isMoreOpen, setMoreOpen] = useState<boolean>(false);
  const [isHidden, setHidden] = useState<boolean>(false);
  const { groupId } = useParams();
  const { socialWallPermission, userState } = usePermissionHook();
  const [popupData, setPopupData] = useState<any>(null);
  const [isHideGroupOpen, setHideGroupOpen] = useState<boolean>(false);

  const isAdmin =
    socialWallPermission?.create.all ||
    (socialWallPermission?.create.admin &&
      Boolean(userState.permission?.SocialWallAdmin));

  const popupListItem = [
    {
      image: "/assets/images/postActionIcon_edit.svg",
      text: t("partnerNetwork.editGroup"),
      url: `/partner-network/edit-group/${groupId}`,
    },
    {
      image: `/assets/images/${isHidden ? "eye" : "eye_slash"}.svg`,
      text: isHidden
        ? t("partnerNetwork.unHide")
        : t("partnerNetwork.hideGroup"),
      onClick: () => {
        // console.log(groupId);
        setPopupData({ id: groupId, isActive: isHidden ? false : true });
        setMoreOpen(false);
        setHideGroupOpen(true);
      },
    },
  ];
  const handleHideGroup = async () => {
    try {
      const response = await hideGroup({
        groupId: popupData.id,
        isHidden: popupData.isActive,
      });
      if (response.status === 200) {
        // console.log("response", response);
        sessionStorage.setItem(
          "snackbar",
          JSON.stringify({
            type: "success",
            message: popupData.isActive
              ? t("partnerNetwork.hideGroupSuccess")
              : t("partnerNetwork.showGroupSuccess"),
          })
        );
        window.location.reload();
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <div className={styles.detailPage} id="postListContainer">
        {groupId && (
          <GroupDetail
            id={groupId}
            closeButtonNavigation="/partner-network"
            headerButton={
              <IconButton
                aria-label="Search"
                sx={{ padding: "0px" }}
                onClick={() => {
                  setMoreOpen(true);
                }}
              >
                <img src="/assets/images/moreAction_icon.svg" alt="" />
              </IconButton>
            }
            isHidden={(isHidden: boolean) => setHidden(isHidden)}
          />
        )}
        <Grid
          container
          justifyContent="space-between"
          style={{ padding: "12px 16px" }}
        >
          <div className={styles.pageTitle}>{t("socialWall.post")}</div>
          {isAdmin && (
            <IconButton
              aria-label="Plus"
              sx={{ padding: "0px" }}
              onClick={() => {
                sessionStorage.setItem("prevPage", window.location.href);
                navigate(`/partner-network/create-post/${groupId}`);
              }}
            >
              <img src="/assets/images/toolbar_plus_black.svg" alt="" />
            </IconButton>
          )}
        </Grid>
        <SocialWall isGroup groupId={groupId} />
      </div>
      <MorePopup
        isOpen={isMoreOpen}
        setIsOpen={setMoreOpen}
        option={popupListItem}
      />
      {popupData && (
        <GroupActionPopup
          isOpen={isHideGroupOpen}
          setIsOpen={setHideGroupOpen}
          title={
            popupData.isActive
              ? t("partnerNetwork.hideGroup")
              : t("partnerNetwork.unHide")
          }
          sub={
            popupData.isActive
              ? t("partnerNetwork.groupWillHide")
              : t("partnerNetwork.groupWillShow")
          }
          confirmBtnText={
            popupData.isActive
              ? t("partnerNetwork.hide")
              : t("partnerNetwork.unHide")
          }
          onClickConfirm={handleHideGroup}
        />
      )}
      <SnackBar />
    </>
  );
};

export default PartnerNetworkDetail;
