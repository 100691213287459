import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ListItem from '../../components/content-hub/list-item';
import Header from '../../components/header/header';
import InfiniteScrollContainer from '../../components/infinite-scroll-container/InfiniteScrollContainer';

import { getJoinedList } from '../../services/partner-network';
import { getApiDataByLang } from '../../utility';

type Props = {};

const PartnerNetworkJoined = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [joinedList, setJoinedList] = useState<Array<any>>([]);
  const convertData = (response: any, isNext: boolean) => {
    const data = response.result;
    if (data) {
      const temp = data.map((item: any) => {
        return {
          id: item._id,
          title: getApiDataByLang(item.groupName),
          image: item.coverImageUrl,
          subtitle: `${item.memberCount} ${t('partnerNetwork.members')}`,
        };
      });
      if (!isNext) {
        setJoinedList(temp);
      } else {
        setJoinedList((prev: any) => [...prev, ...temp]);
      }
    }
  };
  return (
    <>
      <Header
        title={t('partnerNetwork.joined')}
        enableBackButton
        closeButtonNavigation={`/partner-network`}
      />
      <div style={{ padding: '0 16px' }}>
        <InfiniteScrollContainer
          fetchData={getJoinedList}
          returnData={convertData}
          list={joinedList}
        >
          {joinedList.length > 0 &&
            joinedList.map((item, index) => {
              return (
                <ListItem
                  {...item}
                  isGroup
                  key={`joined-${index}`}
                  onDetailClick={(id: any) => {
                    // console.log("list id", id);
                    navigate(`/partner-network/detail/${id}`);
                  }}
                />
              );
            })}
        </InfiniteScrollContainer>
      </div>
    </>
  );
};

export default PartnerNetworkJoined;
