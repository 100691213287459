import { Alert, Link, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {};

const SnackBar = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [snackbar] = useState(
    sessionStorage.getItem("snackbar")
      ? JSON.parse(sessionStorage.getItem("snackbar") as string)
      : null
  );
  const [snackbarOpen, setSnackbarOpen] = useState(
    sessionStorage.getItem("snackbar") ? true : false
  );

  useEffect(() => {
    if (snackbar) {
      setTimeout(() => {
        sessionStorage.removeItem("snackbar");
      }, 6000);
    }
  }, [snackbar]);
  return (
    snackbar && (
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          severity={snackbar?.type}
          {...(snackbar?.id && {
            action: (
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => {
                  sessionStorage.removeItem("snackbar");
                  window.location.href = `/partner-network/detail/${snackbar?.id}`;
                  // navigate(`/partner-network/detail/${snackbar?.id}`);
                }}
              >
                {t("general.view")}
              </Link>
            ),
          })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    )
  );
};

export default SnackBar;
