import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./leaderboard.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { NoResults, Popup, ShortcutMenu } from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import moment from "moment";
import LeaderboardMission from "./leaderboardMission";
import LeaderboardCampaign from "./leaderboardCampaign";

import {
  // getLeaderboardInfo,
  getPartnerCherriesSummary,
  getCoffeeCherriesMissionsList,
  getRankingRewardsList,
} from "../../services/leaderboard";

import {
  addThousandSeparator,
  convertDate,
  convertDateTime,
  convertTime,
  dateTimeFormat,
  nth,
} from "../../utility";
import { useDispatch } from "react-redux";
interface logType {
  id: string;
  lastUpdateBy: string;
  lastUpdateDateTime: string;
  logStatus: "keep" | "suspend" | "restore" | "delete" | "resolve";
}

function Leaderboard() {
  // const { data } = props;
  const navigate = useNavigate();
  let { id } = useParams();

  const [comment, setComment] = useState<string>("");
  const [disableCampaign, setDisableCampaign] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const dispatch = useDispatch();

  const popupContent = (
    <Stack className={styles.popupContent} gap="16px">
      <Typography variant="body1">
        {data?.leaderboardInfo?.leaderboardDesc}
      </Typography>
      <Typography variant="h5">
        {t("leaderboard.aboutLeaderboardTncTitle")}
      </Typography>
      <Typography variant="body1">
        {t("leaderboard.aboutLeaderboardTnc")}
      </Typography>
    </Stack>
  );
  useEffect(() => {
    const loginAndGetUserInfo = async () => {
      setIsLoading(true);
      try {
        const partnerCherriesSummary = await getPartnerCherriesSummary();
        const coffeeCherriesMissionsList =
          await getCoffeeCherriesMissionsList();
        const rankingRewardsList = await getRankingRewardsList();
        if (rankingRewardsList.data.status === 4053) {
          setDisableCampaign(true);
        }
        const joinedData = {
          partnerCherriesSummary: partnerCherriesSummary.data,
          coffeeCherriesMissionsList:
            coffeeCherriesMissionsList.data.cherriesEarningMechanism,
          rankingRewardsList: rankingRewardsList.data.prizeLists,
          leaderboardInfo: {
            leaderboardTitle: t("leaderboard.info.title"),
            leaderboardDesc: t("leaderboard.info.desc"),
          },
        };
        setData(joinedData);
      } catch (error: any) {
        console.error("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    loginAndGetUserInfo();
  }, []);

  const menuItems = [
    {
      image: "/assets/images/menu_basket.svg",
      text: "homepage.basket",
      url: "/leaderboard/point-record",
      disabled: false,
    },
    {
      image: "/assets/images/menu_pastWinners.svg",
      text: "homepage.pastWinners",
      url: "/leaderboard/past-winners",
      disabled: false,
    },
    {
      image: "/assets/images/shortcutMenu_rankings.svg",
      text: "homepage.rankings",
      url: "/leaderboard/rankings",
      disabled: false,
      // disabled: data?.rankingRewardsList?.length === 0
    },
    {
      image: "/assets/images/menu_myRewards.svg",
      text: "homepage.myRewards",
      url: "/leaderboard/my-rewards",
      disabled: false,
    },
  ];

  return (
    <>
      <Box className={styles.headerContainer}>
        <Header
          title={t("leaderboard.title")}
          enableBackButton
          disableBottomBorder
          colouredBackground
          closeButtonFunction={() => {
            // console.log("closeButtonFunction");
            const prevPage = sessionStorage.getItem("prevPage") ?? null;
            if (prevPage) {
              window.location.href = prevPage;
            } else {
              navigate("/home");
            }
          }}
        >
          <IconButton
            aria-label="Info"
            style={{ padding: 0 }}
            onClick={() => setShowPopup(true)}
          >
            <img src="/assets/images/information_white.svg" alt="" />
          </IconButton>
        </Header>
        <Stack direction="row" spacing={2} className={styles.infoContainter}>
          <Stack className={styles.infoWrapper}>
            <Typography variant="body2">
              {t("leaderboard.coffeeCherries")}
            </Typography>
            <Stack direction="row" spacing={2} className={styles.infoData}>
              <Typography variant="h2">
                {data &&
                data?.partnerCherriesSummary?.partnerCherries &&
                Object.keys(data?.partnerCherriesSummary?.partnerCherries)
                  .length > 0
                  ? addThousandSeparator(
                      data.partnerCherriesSummary.partnerCherries.cherries
                    )
                  : "-"}
              </Typography>
              {/* To Revise */}
              <Typography variant="body1">{t("leaderboard.pts")}</Typography>
            </Stack>
          </Stack>
          <Stack className={styles.infoWrapper}>
            <Typography variant="body2">
              {t("leaderboard.myRanking")}
            </Typography>
            <Stack direction="row" spacing={2} className={styles.infoData}>
              <Typography variant="h2">
                {data &&
                data?.partnerCherriesSummary?.partnerCherries &&
                Object.keys(data?.partnerCherriesSummary?.partnerCherries)
                  .length > 0
                  ? addThousandSeparator(
                      data.partnerCherriesSummary.partnerCherries.rank
                    )
                  : "-"}
              </Typography>
              {/* To Revise */}
              <Typography variant="body1">
                {data && data?.partnerCherriesSummary?.partnerCherries
                  ? nth(data.partnerCherriesSummary.partnerCherries.rank)
                  : ""}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {data &&
          data?.partnerCherriesSummary &&
          data?.partnerCherriesSummary?.info?.endDate && (
            <Typography className={styles.seasonEndDate} variant="body2">
              {t("leaderboard.seasonEndDate", {
                date: convertDate(
                  data.partnerCherriesSummary.info.endDate,
                  false,
                  true
                ),
              })}
            </Typography>
          )}

        <Box className={styles.menuBackground}>
          <Box className={styles.menuWrapper}>
            <ShortcutMenu data={menuItems} />
          </Box>
        </Box>
      </Box>
      {!isLoading && (
        <Grid item xs className={styles.contentContainer}>
          {!disableCampaign ? (
            <>
              <LeaderboardMission data={data?.coffeeCherriesMissionsList} />

              <LeaderboardCampaign
                title={data?.partnerCherriesSummary?.info.rewardCampaignName}
                caption={data?.partnerCherriesSummary?.info.rewardDescription}
                data={data?.rankingRewardsList}
              />
            </>
          ) : (
            <NoResults desc={t("leaderboard.noCampaign")} />
          )}
        </Grid>
      )}

      <Popup
        isOpen={showPopup}
        setIsOpen={(close: boolean) => {
          setShowPopup(close);
        }}
        title={
          <Box className={styles.popupTitle}>
            <span>{data?.leaderboardInfo?.leaderboardTitle}</span>
            <img
              src="/assets/images/close_btn.svg"
              onClick={() => {
                setShowPopup(false);
              }}
            />
          </Box>
        }
        content={popupContent}
        disableActions={true}
      />
    </>
  );
}

export default Leaderboard;
