const MuiListItemText = {
  styleOverrides: {
    root: {
      margin: 0,
      color: "rgba(33, 33, 33, 1)",
    },
    secondary: {
      paddingTop: 4,
      color: "rgba(158, 158, 158, 1)",
    },
  },
  defaultProps: {
    primaryTypographyProps: {
      variant: "body1" as any,
    },
    secondaryTypographyProps: {
      variant: "body1" as any,
    },
  },
};

export default MuiListItemText;
