import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./languages";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import theme from "./theme";
import { store } from "./store/store";
import { Provider } from "react-redux";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
import {
  postUserLoginSSO,
  setAuthorizationToken,
} from "./services/api-services";
import { useNavigate } from "react-router";
import { getUserInfo } from "./services/user";
import { setUserState } from "./reducers/user-slice";
import moment from "moment";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  // console.log("addEventCallback", event);
  const loginSuccess =
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS;
  // const navigate = useNavigate();
  if (loginSuccess && event.payload) {
    const payload = event.payload as AuthenticationResult;
    // console.log("payload", payload);
    const account = payload.account;
    // sessionStorage.setItem("sso-access-token", payload.accessToken);
    const loginRequest = {
      token_type: payload.tokenType,
      scope: payload.scopes,
      expires_in: moment(payload.expiresOn).unix(),
      ext_expires_in: moment(payload.extExpiresOn).unix(),
      access_token: payload.accessToken,
    };
    const redirectDomain =
      process.env.REACT_APP_REDIRECT_AFTER_AZURE_LOGIN ??
      process.env.REACT_APP_AZURE_REDIRECT;
    const redirectLink = `${redirectDomain}?request=LOGIN_SUCCESS`;
    // const redirectLink = `${redirectDomain}?request=${JSON.stringify(
    //   loginRequest
    // )}`;
    localStorage.setItem("SSO_REQUEST", JSON.stringify(loginRequest));
    // console.log(redirectLink);
    if (localStorage.getItem("DEBUG_MODE") === "true") {
      localStorage.setItem("REQUEST_PARAMS", JSON.stringify(loginRequest));
    }
    localStorage.setItem("MS_TOKEN", loginRequest.access_token);
    if (
      window.location.href
        .replace(window.location.origin, "")
        .includes("/login")
    ) {
      window.location.href = redirectLink;
    }
  } else if (
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
    event.payload
  ) {
    const payload = event.payload as AuthenticationResult;
    sessionStorage.setItem("sso-access-token", payload.accessToken);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <I18nextProvider i18n={i18n}>
        <CssVarsProvider theme={theme}>
          <Provider store={store}>
            <App />
          </Provider>
        </CssVarsProvider>
      </I18nextProvider>{" "}
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
