import React, { useEffect, useState } from 'react';

import { Skeleton } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NoResults } from '..';

type Props = {
  fetchData: (params: any) => Promise<any>;
  children: React.ReactNode;
  returnData: (data: any, isNext: boolean) => void;
  list: Array<any>;
  apiParams?: any;
  scrollableTarget?: string;
  pageSize?: number;
  loadingSpinner?: React.ReactNode;
};

const InfiniteScrollContainer = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { scrollableTarget = 'scrollableDiv' } = props;
  const [isEnded, setIsEnded] = useState<boolean>(false);
  // const [list, setList] = useState<Array<any>>([]);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: props.pageSize ?? 10,
    }
  );
  const { children } = props;

  async function fetchMoreData() {
    try {
      const response = await props.fetchData({
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
        ...(props.apiParams && { ...props.apiParams }),
      });
      if (response.status === 200 && Object.keys(response.data).length > 0) {
        // console.log("joinedResponse", response);
        // const result = response.data.result;
        props.returnData(response.data, true);
        // setList((prev: any) => [...prev, ...response.data.result]);
        if (
          apiPagination.page >= (response.data.totalPage ?? response.data.total)
        ) {
          setIsEnded(true);
        } else {
          setApiPagination({ page: apiPagination.page + 1 });
        }
      }
    } catch (error) {
      props.returnData(null, false);
      throw error;
    }
  }

  useEffect(() => {
    const getList = async () => {
      setLoading(true);
      try {
        const response = await props.fetchData({
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
          ...(props.apiParams && { ...props.apiParams }),
        });

        if (response.status === 200 && Object.keys(response.data).length > 0) {
          //   console.log("joinedResponse", response);
          props.returnData(response.data, false);
          // setList(response.data.result);
          if (
            apiPagination.page >=
              (response.data.totalPage ?? response.data.total) ||
            !(response.data.totalPage ?? response.data.total)
          ) {
            setIsEnded(true);
          } else {
            setApiPagination({ page: apiPagination.page + 1 });
          }
        } else {
          props.returnData(null, false);
        }
      } catch (error) {
        props.returnData(null, false);
        throw error;
      } finally {
        setLoading(false);
      }
    };
    getList();
  }, []);

  return !loading ? (
    <>
      {props.list.length === 0 ? (
        <NoResults />
      ) : (
        <>
          <InfiniteScroll
            dataLength={props.list.length}
            next={fetchMoreData}
            hasMore={!isEnded}
            loader={<Skeleton height={40} width="100%" />}
            scrollableTarget={scrollableTarget}
          >
            {children}
          </InfiniteScroll>
        </>
      )}
    </>
  ) : (
    <>
      {Array.from({ length: 4 }).map((_, index) => {
        return (
          <React.Fragment key={index}>
            {props.loadingSpinner ? (
              <>{props.loadingSpinner}</>
            ) : (
              <Skeleton height={40} width="100%" />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default InfiniteScrollContainer;
