import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import styles from "./group-item-card.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  data: {
    id: string;
    content: {
      name: string;
      imageUrl: string;
      members: Array<any>;
      memberCount: number;
      totalPosts: number;
    };
  };
  onDetailClick: () => void;
};

const GroupItemCard = (props: Props) => {
  const { t } = useTranslation();
  let MemberListAvatarGroup: any = [];
  if (props.data.content.memberCount > props.data.content.members.length) {
    MemberListAvatarGroup = [
      ...props.data.content.members,
      ...Array(
        props.data.content.memberCount - props.data.content.members.length
      ),
    ];
  } else {
    MemberListAvatarGroup = props.data.content.members;
  }

  return (
    <Box
      className={`groupItemCard ${styles.group}`}
      onClick={props.onDetailClick}
    >
      <Box
        className={styles.groupImage}
        {...(props.data.content.imageUrl && {
          style: { backgroundImage: `url("${props.data.content.imageUrl}")` },
        })}
      >
        <AvatarGroup
          max={5}
          className={`${styles.memberList}${
            MemberListAvatarGroup.length > 4 ? ` ${styles.maxMember}` : ""
          }`}
        >
          {MemberListAvatarGroup.map((member: any, index: number) => (
            <Avatar
              key={`member${index}-${member?.partnerId}`}
              alt={member?.userName}
              src={member?.userProfilePictureUrl}
            />
          ))}
        </AvatarGroup>
      </Box>
      <Typography className={styles.groupTitle} variant="body1">
        {props.data.content.name}
      </Typography>
      <Box className={styles.groupCount}>
        <Box className={`countItem ${styles.countItem}`}>
          <img
            src="/assets/images/homepage_member_total.svg"
            alt="total member"
          />
          <Typography variant="body2">
            {props.data.content.memberCount}
          </Typography>
        </Box>
        <Box className={`countItem ${styles.countItem}`}>
          <img src="/assets/images/homepage_post_total.svg" alt="total posts" />
          <Typography variant="body2">
            {props.data.content.totalPosts}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupItemCard;
