import React from 'react';

import { sliderUnstyledClasses } from '@mui/base';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

import RoundButton from './../round-button';
import styles from './popup.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LeftTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface IPopupAccordion {
  id?: string;
  isOpen: boolean;
  setIsOpen: (toOpen: boolean) => void;
  title?: React.ReactNode;
  content?: React.ReactNode;
  fullScreen?: boolean;
  disableActions?: boolean;
  disableCancelClose?: boolean;
  cancelBtnText?: string;
  confirmBtnText?: string;
  buttonColor?: string;
  isDisabled?: boolean;
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
}

function Popup(props: IPopupAccordion) {
  const { t } = useTranslation();
  const {
    isOpen,
    setIsOpen,
    title,
    content,
    cancelBtnText,
    confirmBtnText = t('general.yes'),
    isDisabled = false,
  } = props;
  const cancelBtnLabel = cancelBtnText
    ? cancelBtnText
    : props.onClickConfirm
      ? t('general.cancel')
      : t('general.ok');

  const handleClose = () => {
    props.onClickCancel && props.onClickCancel();
    if (!props.disableCancelClose) {
      setIsOpen(false);
    }
  };

  const contentElement = () => {
    if (typeof content === 'string') {
      return <DialogContentText>{content}</DialogContentText>;
    } else {
      return content;
    }
  };

  return (
    <Dialog
      id={props.id}
      className={styles.popup}
      open={isOpen}
      onClose={handleClose}
      fullScreen={props.fullScreen}
      TransitionComponent={props.fullScreen ? LeftTransition : Transition}
    >
      {title && <DialogTitle component="div">{title}</DialogTitle>}
      <DialogContent id={`${props.id}-content`}>
        {content && contentElement()}
      </DialogContent>
      {!props.disableActions && (
        <DialogActions>
          <RoundButton
            className={props.onClickConfirm ? 'reversetype' : ''}
            onClick={handleClose}
          >
            {cancelBtnLabel}
          </RoundButton>

          {props.onClickConfirm && (
            <RoundButton
              onClick={props.onClickConfirm}
              disabled={isDisabled}
              sx={
                props.buttonColor ? { backgroundColor: props.buttonColor } : {}
              }
            >
              {confirmBtnText}
            </RoundButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default Popup;
