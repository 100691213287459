import React, { useState } from "react";
import SearchField from "../../../components/search-field";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {};

const SearchSection = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      navigate(`/partner-network/search?value=${searchValue}`);
    }
  };
  const handleResetField = () => {
    setSearchValue("");
  };
  return (
    <SearchField
      id="search"
      name="search"
      placeholder={t("general.search")}
      value={searchValue}
      onChange={handleValueChange}
      onKeyDown={handleKeyDown}
      onReset={handleResetField}
    />
  );
};

export default SearchSection;
