const MuiAlert = {
  styleOverrides: {
    root: {
      width: "100%",
      alignItems: "center",
      padding: 10,
    },
    icon: {
      marginRight: 10,
      padding: 0,
    },
    message: {
      flex: 1,
      padding: 0,
    },
    action: {
      padding: 0,
      marginLeft: 10,
      marginRight: 0,
      "& button": {
        fontWeight: 600,
      },
    },
  },
};

export default MuiAlert;
