import * as React from "react";
import { Box, Typography, Grid, Stack, Breadcrumbs } from "@mui/material";
import styles from "./event-result-card.module.scss";
import moment from "moment";
import CategoryTag from "../category-tag/category-tag";
import { useTranslation } from "react-i18next";
import { convertDate, convertTime, getApiDataByLang } from "../../utility";
import { EventCardBaseType } from "../../interfaces/event-interface";
import { useNavigate } from "react-router-dom";

// interface EventCardProps extends EventCardBaseType {
//   size?: "small" | "large";
//   onClick: (id: string) => void;
// }

export default function EventResultCard(props: { data: any }) {
  const { data } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const event = {
    id: data._id,
    partnerNetworkGroupId: data.partnerNetworkGroupId ?? null,
    name:
      typeof data.eventName === "string"
        ? data.eventName
        : getApiDataByLang(data.eventName),
    imageUrl: data.eventCoverImage,
    startsFrom: moment.unix(data.eventStartTimestamp).format(),
    endsAt: moment.unix(data.eventEndTimestamp).format(),
    category: data.category
      ? {
          id: data.category._id,
          name: getApiDataByLang(data.category.categoryName),
          bgColor: data.category.color,
        }
      : null,
    status: "",
    location:
      data.eventLocationId &&
      data.eventLocation &&
      typeof data.eventLocation === "object"
        ? {
            id: data.eventLocation._id,
            name: data.eventLocation.storeName,
            address: data.eventLocation.address,
          }
        : {
            id: "",
            name: data.eventLocation,
            address: "",
          },
  };

  return (
    <Grid
      container
      alignItems="center"
      className={styles.eventResultCard}
      onClick={() => {
        navigate(
          event.partnerNetworkGroupId
            ? `/partner-network/event/${event.partnerNetworkGroupId}/${event.id}`
            : `/event/${event.id}`
        );
      }}
    >
      <Grid item className={styles.eventResultImage}>
        <img src={event.imageUrl} alt="" />
      </Grid>
      <Grid item xs className={styles.eventResultContent}>
        <Stack spacing={0.5}>
          <Box className={styles.eventResultCategory}>
            {event.category && (
              <CategoryTag category={event.category}></CategoryTag>
            )}
          </Box>
          <Box className={styles.eventResultTitle}>
            <Typography variant="body1">{event.name}</Typography>
          </Box>
          <Box className={styles.eventResultDate}>
            <Breadcrumbs separator="|" aria-label="breadcrumb">
              <Typography variant="body2">
                {convertDate(event.startsFrom)}
              </Typography>
              <Typography variant="body2">
                {convertTime(event.startsFrom)}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Grid
            container
            alignItems="flex-start"
            wrap="nowrap"
            className={styles.eventResultLocation}
          >
            <img src="/assets/images/eventIcon_location_pin.svg" alt="" />
            <Typography variant="body2">{event.location.name}</Typography>
          </Grid>
        </Stack>
      </Grid>
      {/* <Box
        className={styles.deleteEventBtn}
        onClick={() => {
          setFormValue((prev) => ({
            ...prev,
            event: { ...prev.event, value: {} },
          }));
          setPostType("");
        }}
      >
        <img src="/assets/images/close_btn_filled.svg" alt="" />
      </Box> */}
    </Grid>
  );
}
