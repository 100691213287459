import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  AppBar,
  IconButton,
  Dialog,
  DialogContent,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  PollButton,
  PostActionButton,
  Popup,
  ActionItemList,
  RoundButton,
  NoticeBox,
  Toolbar,
  CustomSwiper,
} from "..";
import styles from "./media-popup.module.scss";

import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { MediaProps } from "../../interfaces/social-wall-interface";

interface MediaPopupProps {
  content: React.ReactNode;
  media: Array<MediaProps>;
  isOpen: boolean;
  setIsOpen: (toOpen: boolean) => void;
  initialSlide?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function MediaPopup(props: MediaPopupProps) {
  const { isOpen, setIsOpen, content, media, initialSlide } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };

  const getSwiperData = (data: any) => {
    let mediaContent;
    if (data.content.type === "IMAGE") {
      mediaContent = (
        <div className="swiper-zoom-container">
          <img src={data.content.fileUrl} />
        </div>
      );
    } else {
      mediaContent = (
        <video
          width="100%"
          src={`${data.content.fileUrl}#t=0.001`}
          controls={true}
          autoPlay={true}
        />
      );
    }
    return mediaContent;
  };
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={styles.mediaPopup}
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
      >
        <img src="/assets/images/close_btn_white.svg" />
      </IconButton>

      <DialogContent>
        <CustomSwiper
          data={media}
          swiperId={`media-popup-swiper`}
          content={getSwiperData}
          initialSlide={initialSlide}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={16}
          isAlignCenter={true}
        />

        {content}
      </DialogContent>
    </Dialog>
  );
}
