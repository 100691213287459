import { FC } from "react";
import styles from "./me.module.scss";
import { Grid, Box, Typography } from "@mui/material";
import Header from "../../components/header/header";
import toHtml from "html-react-parser";
import { useTranslation } from "react-i18next";

const MeAboutUs: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header title={t("myProfile.me.aboutUs")} enableBackButton />
      <Grid item xs className={styles.container}>
        <Box className={styles.content}>
          <Typography variant="body1" color="text.primary">
            {toHtml(t("myProfile.me.aboutUsDetails"))}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default MeAboutUs;
