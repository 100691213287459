import { FC, useState, useEffect, useCallback } from "react";
import styles from "./invitation-scope-field.module.scss";
import { PaginationParamsType } from "../../services/common";
import { ObjectKey } from "../../interfaces/common-interface";
import { Skeleton, Box } from "@mui/material";
import NoResults from "../no-results";
import CheckboxTree from "react-checkbox-tree";
import InvitedGroupIcon from "../invited-group-icon";
import {
  findMultiLvObjectByValue,
  getInvitationScopeGroupList,
} from "../../utility";

interface GroupListSectionProps {
  value: Array<ObjectKey>;
  initList: boolean;
  listFetch: (data?: PaginationParamsType) => Promise<ObjectKey>;
  listFetchKey: string;
  onClick: (selectedId: Array<ObjectKey>) => void;
  lockSelected?: Array<ObjectKey> | null;
}

const GroupListSection: FC<GroupListSectionProps> = (props) => {
  const [expanded, setExpanded] = useState<Array<any>>([]);
  const [result, setResult] = useState<Array<ObjectKey>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getList = useCallback(async () => {
    if (!props.initList) return false;
    let active = true;

    setIsLoading(true);
    try {
      const response = await props.listFetch();
      let responseList: Array<ObjectKey> = [];

      if (props.listFetchKey) {
        const keyArray = props.listFetchKey.split(".");
        let responseObject: ObjectKey = {};

        if (keyArray.length === 1) {
          responseList = getInvitationScopeGroupList(
            response.data[keyArray[0]]
          );

          let haveNullValue = false;
          responseList.forEach((item: ObjectKey) => {
            if (!item.value) {
              haveNullValue = true;
              return false;
            } else if (item.children && item.children.length > 0) {
              item.children.forEach((subItem: ObjectKey) => {
                if (!subItem.value) {
                  haveNullValue = true;
                  return false;
                } else if (subItem.children && subItem.children.length > 0) {
                  subItem.children.forEach((lastItem: ObjectKey) => {
                    if (!lastItem.value) {
                      haveNullValue = true;
                      return false;
                    }
                  });
                }
              });
            }
          });

          if (haveNullValue) {
            responseList = [];
          }
        } else {
          keyArray.forEach((key: string, index: number) => {
            if (index === 0) {
              responseObject = response.data[key];
            } else if (index + 1 === keyArray.length) {
              responseList = getInvitationScopeGroupList(responseObject[key]);
            } else {
              responseObject = responseObject[key];
            }
          });
        }
      } else {
        responseList = getInvitationScopeGroupList(response.data);
      }

      if (responseList.length > 0) {
        if (active) {
          setResult((prev) => [...prev, ...responseList]);
        }
      }
      setIsLoading(false);
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code && error.code !== "ERR_CANCELED") {
        setIsLoading(false);
      }
      throw error;
    }

    return () => {
      active = false;
    };
  }, [props.initList]);

  useEffect(() => {
    if (result.length <= 0) {
      getList();
    }
  }, [getList, result]);

  const addIconToGroupOptionsList = () => {
    return result.map((option: any) => {
      let children: any = option.children;
      if (option.children) {
        children = option.children.map((childrenOption: any) => {
          let children: any = childrenOption.children;
          if (childrenOption.children) {
            children = childrenOption.children.map((lastChildren: any) => {
              return (lastChildren = {
                ...lastChildren,
                className: styles.optionLastItem,
                icon: <img src="/assets/images/structure_icon.svg" alt="" />,
              });
            });
          }
          return (childrenOption = {
            ...childrenOption,
            children: children,
            className: styles.optionSubItem,
            icon: <img src="/assets/images/structure_icon.svg" alt="" />,
          });
        });
      }
      return (option = {
        ...option,
        children: children,
        className: styles.optionItem,
        icon: <InvitedGroupIcon />,
      });
    });
  };

  const CheckboxIcon = (props: { isSelect?: boolean }) => {
    return (
      <Box className={styles.checkboxIcon}>
        {props.isSelect ? (
          <img src="/assets/images/checkbox_tick.svg" alt="" />
        ) : (
          <span></span>
        )}
      </Box>
    );
  };

  const ExpendArrow = (props: { isOpen?: boolean }) => {
    return (
      <Box data-open={Boolean(props.isOpen)} className={styles.expendArrow}>
        <img
          src="/assets/images/chevron_right.svg"
          alt={Boolean(props.isOpen) ? "Close" : "Open"}
        />
      </Box>
    );
  };

  const [selected, setSelected] = useState<Array<string>>([]);
  const [locked, setLocked] = useState<Array<string>>([]);

  useEffect(() => {
    const selectedOptionsId: Array<string> = props.value.map(
      (option: ObjectKey) => option.value
    );
    setSelected(selectedOptionsId);
    if (props.lockSelected && props.lockSelected.length > 0) {
      const lockedOptionsId: Array<string> = props.lockSelected.map(
        (option: ObjectKey) => option.value
      );
      setLocked(lockedOptionsId);
    }
  }, [props.value]);

  return (
    <Box className={styles.checkboxTreeList}>
      {isLoading && <LoadingSkeleton />}
      {!isLoading && result.length <= 0 && <NoResults />}
      <CheckboxTree
        nodes={addIconToGroupOptionsList()}
        checked={selected}
        expanded={expanded}
        onCheck={(checked, targetNode) => {
          if (locked.includes(targetNode.value)) return false;
          if (targetNode.children) {
            let openExpanded = [targetNode.value];
            targetNode.children.forEach((item) => {
              if (item.children) {
                openExpanded = [...openExpanded, item.value];
              }
            });
            // if (props.lockSelected)
            setExpanded([...expanded, ...openExpanded]);
          }
          props.onClick(
            checked.map((id: string) =>
              findMultiLvObjectByValue(id, result, "value")
            )
          );
        }}
        onExpand={(expanded) => {
          setExpanded(expanded);
        }}
        checkModel="all"
        icons={{
          check: <CheckboxIcon isSelect={true} />,
          uncheck: <CheckboxIcon />,
          halfCheck: <CheckboxIcon />,
          expandClose: <ExpendArrow />,
          expandOpen: <ExpendArrow isOpen={true} />,
        }}
        showExpandAll
      />
    </Box>
  );
};

function LoadingSkeleton() {
  return (
    <Box sx={{ padding: 2 }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={40}
      />
    </Box>
  );
}

export default GroupListSection;
