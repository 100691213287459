import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import {
  CustomSwiper,
  Popup,
  SocialWallCard,
  SocialWallCardSkeleton,
} from "../../components";
import styles from "./sections.module.scss";
import { SectionProps } from "../../interfaces/homepage-interface";
import theme from "../../theme";
import { getSocialWallList } from "../../services/social-wall";
import { useNavigate } from "react-router";
import { UserState } from "../../reducers/user-slice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import SocialWallPostDetail from "../socialWall/socialWallPostDetail";
import { SocialWallProps } from "../../interfaces/social-wall-interface";

function SocialWallSections(props: { socialWallPermission: any }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const navigate = useNavigate();
  const [socialWallDetailOpen, setSocialWallDetailOpen] = useState(false);
  const [activePostId, setActivePostId] = useState<string>("");
  const [activePost, setActivePost] = useState<SocialWallProps>(null!);
  useEffect(() => {
    setIsLoading(true);
    getSocialWallList({ page: 1, pageSize: 5, tab: "all" }).then((res) => {
      setData(res.data.posts);
      setIsLoading(false);
    });
  }, []);

  const getSocialWallSwiperData = (data: any) => {
    return (
      <SocialWallCard
        key={data.index}
        id={data.content._id}
        {...data.content}
        size="small"
        disableLike={
          Boolean(userState.permission?.SocialWallAdmin)
            ? !props.socialWallPermission?.like.admin
            : !props.socialWallPermission?.like.all
        }
        disableComment={
          Boolean(userState.permission?.SocialWallAdmin)
            ? !props.socialWallPermission?.comment.admin
            : !props.socialWallPermission?.comment.all
        }
        handleActionBtnClick={() => {
          // console.log("handleActionBtnClick", data.content);
          setSocialWallDetailOpen(true);
          setActivePostId(data.content._id);
          setActivePost(data.content);
        }}
      />
    );
  };

  return (
    <>
      <Box className={styles.sectionContainer}>
        <Box className={styles.sectionWrapper}>
          <Box className={styles.sectionHeader}>
            <Typography className={styles.sectionTitle} variant="body1">
              {t("homepage.socialWall")}
            </Typography>

            <img
              src="/assets/images/chevron_right.svg"
              alt=""
              onClick={() => {
                navigate("/social-wall");
              }}
            />
          </Box>
          {isLoading && <SocialWallCardSkeleton />}

          {data && data.length > 0 && (
            <Box>
              <CustomSwiper
                data={data.slice(0, Math.min(data.length, 5))}
                swiperId={`social-wall-swiper`}
                content={getSocialWallSwiperData}
                slidesPerView={1.12}
                slidesPerGroup={1}
                spaceBetween={16}
                isAutoHeight={false}
                isPagination
              />
            </Box>
          )}
        </Box>
      </Box>
      {activePostId && socialWallDetailOpen && (
        <Popup
          id="SocialWallDetailPopup"
          isOpen={socialWallDetailOpen}
          setIsOpen={(close: boolean) => setSocialWallDetailOpen(close)}
          content={
            <SocialWallPostDetail
              postId={activePostId}
              postDetail={activePost}
              closeButtonFunction={() => setSocialWallDetailOpen(false)}
            />
          }
          disableActions={true}
          fullScreen
        />
      )}
    </>
  );
}

export default SocialWallSections;
