import React, { useState } from "react";
import { Box, ImageList, ImageListItem } from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./media-list.module.scss";

interface itemDataType {
  file: string;
  type: string;
  title: string;
}
interface MediaListProps {
  itemData: Array<itemDataType>;
  onClickRemoveBtn: (id: number) => void;
}
// const itemData = [
//   {
//     img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//     title: "Breakfast",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//     title: "Burger",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//     title: "Camera",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//     title: "Coffee",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//     title: "Hats",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//     title: "Honey",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
//     title: "Basketball",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
//     title: "Fern",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
//     title: "Mushrooms",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
//     title: "Tomato basil",
//   },
// ];
// const MediaList = (props: AccountItemListProps) => {
// const { name, icon, description, padding } = props;
const MediaList = (props: MediaListProps) => {
  const { t } = useTranslation();

  const handleClick = (id: number) => {
    props.onClickRemoveBtn(id);
  };
  return (
    <ImageList
      sx={{ width: "auto", height: 120 }}
      cols={10}
      rowHeight={120}
      id="mediaList"
    >
      {props.itemData.map((item: itemDataType, index) => (
        <ImageListItem key={index} className={styles.mediaItem}>
          {item.type === "IMAGE" && (
            <img
              src={`${item.file}`}
              alt={item.title}
              loading="lazy"
              style={{
                width: "120px",
                borderRadius: "3.75px",
                objectFit: "contain",
              }}
            />
          )}
          {item.type === "VIDEO" && (
            <video
              src={`${item.file}#t=0.001`}
              style={{
                width: "120px",
                borderRadius: "3.75px",
                objectFit: "contain",
              }}
            />
          )}
          {item.type !== "IMAGE" && item.type !== "VIDEO" && (
            <img
              style={{
                width: "120px",
              }}
              src={`/assets/images/files/file_${item.type.toLowerCase()}.svg`}
              alt={item.type.toLowerCase()}
            />
          )}
          <Box
            className={styles.imgRemoveBtn}
            onClick={() => {
              handleClick(index);
            }}
          >
            <img
              src="/assets/images/close_btn_white.svg"
              style={{ padding: "4px", width: "100%" }}
            />
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default MediaList;
