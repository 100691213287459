import i18n from "../../languages";

export const APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID as string;

export const stringSet = {
  MESSAGE_STATUS__YESTERDAY: i18n.t("chat.MESSAGE_STATUS__YESTERDAY"),
  CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE: i18n.t(
    "chat.CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE"
  ),
  CHANNEL__MESSAGE_LIST__NOTIFICATION__ON: i18n.t(
    "chat.CHANNEL__MESSAGE_LIST__NOTIFICATION__ON"
  ),
  CHANNEL_PREVIEW_MOBILE_LEAVE: i18n.t("chat.CHANNEL_PREVIEW_MOBILE_LEAVE"),
  CHANNEL_SETTING__HEADER__TITLE: i18n.t("chat.CHANNEL_SETTING__HEADER__TITLE"),
  CHANNEL_SETTING__PROFILE__EDIT: i18n.t("chat.CHANNEL_SETTING__PROFILE__EDIT"),
  CHANNEL_SETTING__MEMBERS__TITLE: i18n.t(
    "chat.CHANNEL_SETTING__MEMBERS__TITLE"
  ),
  CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS: i18n.t(
    "chat.CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS"
  ),
  CHANNEL_SETTING__MEMBERS__INVITE_MEMBER: i18n.t(
    "chat.CHANNEL_SETTING__MEMBERS__INVITE_MEMBER"
  ),
  CHANNEL_SETTING__MEMBERS__YOU: i18n.t("chat.CHANNEL_SETTING__MEMBERS__YOU"),
  CHANNEL_SETTING__MEMBERS__SELECT_TITLE: i18n.t(
    "chat.CHANNEL_SETTING__MEMBERS__SELECT_TITLE"
  ),
  CHANNEL_SETTING__MEMBERS__OPERATOR: i18n.t(
    "chat.CHANNEL_SETTING__MEMBERS__OPERATOR"
  ),
  CHANNEL_SETTING__LEAVE_CHANNEL__TITLE: i18n.t(
    "chat.CHANNEL_SETTING__LEAVE_CHANNEL__TITLE"
  ),
  CHANNEL_SETTING__OPERATORS__TITLE: i18n.t(
    "chat.CHANNEL_SETTING__OPERATORS__TITLE"
  ),
  CHANNEL_SETTING__OPERATORS__TITLE_ALL: i18n.t(
    "chat.CHANNEL_SETTING__OPERATORS__TITLE_ALL"
  ),
  CHANNEL_SETTING__OPERATORS__TITLE_ADD: i18n.t(
    "chat.CHANNEL_SETTING__OPERATORS__TITLE_ADD"
  ),
  CHANNEL_SETTING__OPERATORS__ADD_BUTTON: i18n.t(
    "chat.CHANNEL_SETTING__OPERATORS__ADD_BUTTON"
  ),
  CHANNEL_SETTING__MUTED_MEMBERS__TITLE: i18n.t(
    "chat.CHANNEL_SETTING__MUTED_MEMBERS__TITLE"
  ),
  CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL: i18n.t(
    "chat.CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL"
  ),
  CHANNEL_SETTING__NO_UNMUTED: i18n.t("chat.CHANNEL_SETTING__NO_UNMUTED"),
  CHANNEL_SETTING__BANNED_MEMBERS__TITLE: i18n.t(
    "chat.CHANNEL_SETTING__BANNED_MEMBERS__TITLE"
  ),
  CHANNEL_SETTING__FREEZE_CHANNEL: i18n.t(
    "chat.CHANNEL_SETTING__FREEZE_CHANNEL"
  ),
  CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR"
  ),
  CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR"
  ),
  CHANNEL_SETTING__MODERATION__MUTE: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__MUTE"
  ),
  CHANNEL_SETTING__MODERATION__UNMUTE: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__UNMUTE"
  ),
  CHANNEL_SETTING__MODERATION__BAN: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__BAN"
  ),
  CHANNEL_SETTING__MODERATION__UNBAN: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__UNBAN"
  ),
  CHANNEL_SETTING__MODERATION__EMPTY_BAN: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__EMPTY_BAN"
  ),
  CHANNEL_SETTING__MODERATION__ALL_BAN: i18n.t(
    "chat.CHANNEL_SETTING__MODERATION__ALL_BAN"
  ),
  OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS: i18n.t(
    "chat.OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS"
  ),
  OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS: i18n.t(
    "chat.OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS"
  ),
  OPEN_CHANNEL_LIST__TITLE: i18n.t("chat.OPEN_CHANNEL_LIST__TITLE"),
  CREATE_OPEN_CHANNEL_LIST__TITLE: i18n.t(
    "chat.CREATE_OPEN_CHANNEL_LIST__TITLE"
  ),
  CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION: i18n.t(
    "chat.CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION"
  ),
  CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD: i18n.t(
    "chat.CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD"
  ),
  CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION: i18n.t(
    "chat.CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION"
  ),
  CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER: i18n.t(
    "chat.CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER"
  ),
  CREATE_OPEN_CHANNEL_LIST__SUBMIT: i18n.t(
    "chat.CREATE_OPEN_CHANNEL_LIST__SUBMIT"
  ),
  OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__OPERATOR_URL: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__OPERATOR_URL"
  ),
  OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL"
  ),
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT"
  ),
  OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT"
  ),
  OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD"
  ),
  OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL"
  ),
  OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL"
  ),
  OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE"
  ),
  OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL"
  ),
  OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE"
  ),
  OPEN_CHANNEL_SETTINGS__MEMBERS__YOU: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__MEMBERS__YOU"
  ),
  OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR"
  ),
  OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__EMPTY_LIST: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__EMPTY_LIST"
  ),
  OPEN_CHANNEL_SETTINGS__SEE_ALL: i18n.t("chat.OPEN_CHANNEL_SETTINGS__SEE_ALL"),
  OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE"
  ),
  OPEN_CHANNEL_SETTINGS__NO_TITLE: i18n.t(
    "chat.OPEN_CHANNEL_SETTINGS__NO_TITLE"
  ),
  OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: i18n.t(
    "chat.OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR"
  ),
  OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: i18n.t(
    "chat.OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR"
  ),
  OPEN_CHANNEL_SETTING__MODERATION__MUTE: i18n.t(
    "chat.OPEN_CHANNEL_SETTING__MODERATION__MUTE"
  ),
  OPEN_CHANNEL_SETTING__MODERATION__UNMUTE: i18n.t(
    "chat.OPEN_CHANNEL_SETTING__MODERATION__UNMUTE"
  ),
  OPEN_CHANNEL_SETTING__MODERATION__BAN: i18n.t(
    "chat.OPEN_CHANNEL_SETTING__MODERATION__BAN"
  ),
  OPEN_CHANNEL_SETTING__MODERATION__UNBAN: i18n.t(
    "chat.OPEN_CHANNEL_SETTING__MODERATION__UNBAN"
  ),
  TRYING_TO_CONNECT: i18n.t("chat.TRYING_TO_CONNECT"),
  TYPING_INDICATOR__IS_TYPING: i18n.t("chat.TYPING_INDICATOR__IS_TYPING"),
  TYPING_INDICATOR__AND: i18n.t("chat.TYPING_INDICATOR__AND"),
  TYPING_INDICATOR__ARE_TYPING: i18n.t("chat.TYPING_INDICATOR__ARE_TYPING"),
  TYPING_INDICATOR__MULTIPLE_TYPING: i18n.t(
    "chat.TYPING_INDICATOR__MULTIPLE_TYPING"
  ),
  CHANNEL_FROZEN: i18n.t("chat.CHANNEL_FROZEN"),
  PLACE_HOLDER__NO_CHANNEL: i18n.t("chat.PLACE_HOLDER__NO_CHANNEL"),
  PLACE_HOLDER__WRONG: i18n.t("chat.PLACE_HOLDER__WRONG"),
  PLACE_HOLDER__RETRY_TO_CONNECT: i18n.t("chat.PLACE_HOLDER__RETRY_TO_CONNECT"),
  PLACE_HOLDER__NO_MESSAGES: i18n.t("chat.PLACE_HOLDER__NO_MESSAGES"),
  TOOLTIP__AND_YOU: i18n.t("chat.TOOLTIP__AND_YOU"),
  TOOLTIP__YOU: i18n.t("chat.TOOLTIP__YOU"),
  TOOLTIP__UNKNOWN_USER: i18n.t("chat.TOOLTIP__UNKNOWN_USER"),
  UNKNOWN__UNKNOWN_MESSAGE_TYPE: i18n.t("chat.UNKNOWN__UNKNOWN_MESSAGE_TYPE"),
  UNKNOWN__CANNOT_READ_MESSAGE: i18n.t("chat.UNKNOWN__CANNOT_READ_MESSAGE"),
  MESSAGE_EDITED: i18n.t("chat.MESSAGE_EDITED"),
  MODAL__DELETE_MESSAGE__TITLE: i18n.t("chat.MODAL__DELETE_MESSAGE__TITLE"),
  MODAL__CHANNEL_INFORMATION__TITLE: i18n.t(
    "chat.MODAL__CHANNEL_INFORMATION__TITLE"
  ),
  MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE: i18n.t(
    "chat.MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE"
  ),
  MODAL__CHANNEL_INFORMATION__UPLOAD: i18n.t(
    "chat.MODAL__CHANNEL_INFORMATION__UPLOAD"
  ),
  MODAL__CHANNEL_INFORMATION__CHANNEL_NAME: i18n.t(
    "chat.MODAL__CHANNEL_INFORMATION__CHANNEL_NAME"
  ),
  MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER: i18n.t(
    "chat.MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER"
  ),
  MODAL__INVITE_MEMBER__TITLE: i18n.t("chat.MODAL__INVITE_MEMBER__TITLE"),
  MODAL__INVITE_MEMBER__SELECTED: i18n.t("chat.MODAL__INVITE_MEMBER__SELECTED"),
  MODAL__CHOOSE_CHANNEL_TYPE__TITLE: i18n.t(
    "chat.MODAL__CHOOSE_CHANNEL_TYPE__TITLE"
  ),
  MODAL__CHOOSE_CHANNEL_TYPE__GROUP: i18n.t(
    "chat.MODAL__CHOOSE_CHANNEL_TYPE__GROUP"
  ),
  MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP: i18n.t(
    "chat.MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP"
  ),
  MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST: i18n.t(
    "chat.MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST"
  ),
  MODAL__CREATE_CHANNEL__TITLE: i18n.t("chat.MODAL__CREATE_CHANNEL__TITLE"),
  MODAL__CREATE_CHANNEL__GROUP: i18n.t("chat.MODAL__CREATE_CHANNEL__GROUP"),
  MODAL__CREATE_CHANNEL__SUPER: i18n.t("chat.MODAL__CREATE_CHANNEL__SUPER"),
  MODAL__CREATE_CHANNEL__BROADCAST: i18n.t(
    "chat.MODAL__CREATE_CHANNEL__BROADCAST"
  ),
  MODAL__CREATE_CHANNEL__SELECTED: i18n.t(
    "chat.MODAL__CREATE_CHANNEL__SELECTED"
  ),
  MODAL__LEAVE_CHANNEL__TITLE: i18n.t("chat.MODAL__LEAVE_CHANNEL__TITLE"),
  MODAL__LEAVE_CHANNEL__FOOTER: i18n.t("chat.MODAL__LEAVE_CHANNEL__FOOTER"),
  MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_MUTED: i18n.t(
    "chat.MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_MUTED"
  ),
  MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_FROZEN: i18n.t(
    "chat.MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_FROZEN"
  ),
  USER_PROFILE__MESSAGE: i18n.t("chat.USER_PROFILE__MESSAGE"),
  USER_PROFILE__USER_ID: i18n.t("chat.USER_PROFILE__USER_ID"),
  EDIT_PROFILE__TITLE: i18n.t("chat.EDIT_PROFILE__TITLE"),
  EDIT_PROFILE__IMAGE_LABEL: i18n.t("chat.EDIT_PROFILE__IMAGE_LABEL"),
  EDIT_PROFILE__IMAGE_UPLOAD: i18n.t("chat.EDIT_PROFILE__IMAGE_UPLOAD"),
  EDIT_PROFILE__NICKNAME_LABEL: i18n.t("chat.EDIT_PROFILE__NICKNAME_LABEL"),
  EDIT_PROFILE__NICKNAME_PLACEHOLDER: i18n.t(
    "chat.EDIT_PROFILE__NICKNAME_PLACEHOLDER"
  ),
  EDIT_PROFILE__USERID_LABEL: i18n.t("chat.EDIT_PROFILE__USERID_LABEL"),
  EDIT_PROFILE__THEME_LABEL: i18n.t("chat.EDIT_PROFILE__THEME_LABEL"),
  MESSAGE_INPUT__PLACE_HOLDER: i18n.t("chat.MESSAGE_INPUT__PLACE_HOLDER"),
  MESSAGE_INPUT__PLACE_HOLDER__DISABLED: i18n.t(
    "chat.MESSAGE_INPUT__PLACE_HOLDER__DISABLED"
  ),
  MESSAGE_INPUT__PLACE_HOLDER__MUTED: i18n.t(
    "chat.MESSAGE_INPUT__PLACE_HOLDER__MUTED"
  ),
  MESSAGE_INPUT__PLACE_HOLDER__MUTED_SHORT: i18n.t(
    "chat.MESSAGE_INPUT__PLACE_HOLDER__MUTED_SHORT"
  ),
  MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER: i18n.t(
    "chat.MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER"
  ),
  BUTTON__CANCEL: i18n.t("chat.BUTTON__CANCEL"),
  BUTTON__DELETE: i18n.t("chat.BUTTON__DELETE"),
  BUTTON__SAVE: i18n.t("chat.BUTTON__SAVE"),
  BUTTON__CREATE: i18n.t("chat.BUTTON__CREATE"),
  BUTTON__INVITE: i18n.t("chat.BUTTON__INVITE"),
  BUTTON__OK: i18n.t("chat.BUTTON__OK"),
  BADGE__OVER: i18n.t("chat.BADGE__OVER"),
  NO_TITLE: i18n.t("chat.NO_TITLE"),
  NO_NAME: i18n.t("chat.NO_NAME"),
  NO_MEMBERS: i18n.t("chat.NO_MEMBERS"),
  MESSAGE_MENU__COPY: i18n.t("chat.MESSAGE_MENU__COPY"),
  MESSAGE_MENU__REPLY: i18n.t("chat.MESSAGE_MENU__REPLY"),
  MESSAGE_MENU__THREAD: i18n.t("chat.MESSAGE_MENU__THREAD"),
  MESSAGE_MENU__OPEN_IN_CHANNEL: i18n.t("chat.MESSAGE_MENU__OPEN_IN_CHANNEL"),
  MESSAGE_MENU__EDIT: i18n.t("chat.MESSAGE_MENU__EDIT"),
  MESSAGE_MENU__RESEND: i18n.t("chat.MESSAGE_MENU__RESEND"),
  MESSAGE_MENU__DELETE: i18n.t("chat.MESSAGE_MENU__DELETE"),
  MESSAGE_MENU__SAVE: i18n.t("chat.MESSAGE_MENU__SAVE"),
  SEARCH: i18n.t("chat.SEARCH"),
  SEARCH_IN_CHANNEL: i18n.t("chat.SEARCH_IN_CHANNEL"),
  SEARCH_IN: i18n.t("chat.SEARCH_IN"),
  SEARCHING: i18n.t("chat.SEARCHING"),
  NO_SEARCHED_MESSAGE: i18n.t("chat.NO_SEARCHED_MESSAGE"),
  QUOTE_MESSAGE_INPUT__REPLY_TO: i18n.t("chat.QUOTE_MESSAGE_INPUT__REPLY_TO"),
  QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE: i18n.t(
    "chat.QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE"
  ),
  QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF: i18n.t(
    "chat.QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF"
  ),
  QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO: i18n.t(
    "chat.QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO"
  ),
  QUOTED_MESSAGE__REPLIED_TO: i18n.t("chat.QUOTED_MESSAGE__REPLIED_TO"),
  QUOTED_MESSAGE__CURRENT_USER: i18n.t("chat.QUOTED_MESSAGE__CURRENT_USER"),
  QUOTED_MESSAGE__UNAVAILABLE: i18n.t("chat.QUOTED_MESSAGE__UNAVAILABLE"),
  THREAD__HEADER_TITLE: i18n.t("chat.THREAD__HEADER_TITLE"),
  CHANNEL__THREAD_REPLY: i18n.t("chat.CHANNEL__THREAD_REPLY"),
  CHANNEL__THREAD_REPLIES: i18n.t("chat.CHANNEL__THREAD_REPLIES"),
  CHANNEL__THREAD_OVER_MAX: i18n.t("chat.CHANNEL__THREAD_OVER_MAX"),
  THREAD__THREAD_REPLY: i18n.t("chat.THREAD__THREAD_REPLY"),
  THREAD__THREAD_REPLIES: i18n.t("chat.THREAD__THREAD_REPLIES"),
  THREAD__INPUT__REPLY_TO_THREAD: i18n.t("chat.THREAD__INPUT__REPLY_TO_THREAD"),
  THREAD__INPUT__REPLY_IN_THREAD: i18n.t("chat.THREAD__INPUT__REPLY_IN_THREAD"),
  MENTION_NAME__NO_NAME: i18n.t("chat.MENTION_NAME__NO_NAME"),
  MENTION_COUNT__OVER_LIMIT: i18n.t("chat.MENTION_COUNT__OVER_LIMIT"),
  UI__FILE_VIEWER__UNSUPPORT: i18n.t("chat.UI__FILE_VIEWER__UNSUPPORT"),
  VOICE_MESSAGE: i18n.t("chat.VOICE_MESSAGE"),
  CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GIF: i18n.t(
    "chat.CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GIF"
  ),
  CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_PHOTO: i18n.t(
    "chat.CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_PHOTO"
  ),
  CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VIDEO: i18n.t(
    "chat.CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VIDEO"
  ),
  CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_AUDIO: i18n.t(
    "chat.CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_AUDIO"
  ),
  CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VOICE_MESSAGE: i18n.t(
    "chat.CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VOICE_MESSAGE"
  ),
  CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GENERAL: i18n.t(
    "chat.CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GENERAL"
  ),
};

export const myColorSet = {
  "--sendbird-light-primary-500": "#00487c",
  "--sendbird-light-primary-400": "#00754A",
  "--sendbird-light-primary-300": "#00754A",
  "--sendbird-light-primary-200": "#0496ff",
  "--sendbird-light-primary-100": "#D4E9E2",
};

export default {
  APP_ID,
  myColorSet,
  stringSet,
};
