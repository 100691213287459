import { FC, useContext, useEffect, useState } from "react";
import styles from "./greenApronCard.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/header/header";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CardListType,
  GreenApronCardProps,
} from "../../interfaces/green-apron-card-interface";
import { convertDateWithFullMonth } from "../../utility";
import moment from "moment";
import GreenApronCardContext from "./greenApronCardProvider";
import { useGreenCardFormHook } from "./useGreenCardFormHook";
import { sendCard } from "../../services/green-apron-card";
import LoadingButton from "@mui/lab/LoadingButton";

const GreenApronCardPreview: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    list,
    selectedCategory,
    selectedId,
    sendValue,
    setSendValue,
    sendEmailList,
  } = useContext(GreenApronCardContext);
  const { checkValidation, convertFormValueToRequest, setInitFormValue } =
    useGreenCardFormHook();
  const cardDetail = sendValue;
  const cardDefaultInfo = list
    .find((section: CardListType) => section.id === selectedCategory)
    ?.data.find((card: GreenApronCardProps) => card.id === selectedId);
  const [sendDate, setSendDate] = useState<string>("");
  const handleSubmit = async () => {
    const isValid = checkValidation();
    if (isValid) {
      setIsLoading(true);
      const request = convertFormValueToRequest();
      try {
        // console.log("request", request);
        const response = await sendCard(request);
        if (response.status === 200) {
          if (cardDetail?.sendDate.value.id === "NOW") {
            setSendValue(null);
            navigate("/green-apron-card?send=success");
          } else {
            navigate("/green-apron-card?send=schedule", { state: cardDetail });
          }
        }
      } catch (error) {
        navigate("/green-apron-card?send=failed", { state: cardDetail });
        throw error;
      } finally {
        setIsLoading(false);
      }
    } else {
      navigate("/green-apron-card/send");
    }
  };

  useEffect(() => {
    if (!cardDetail) {
      if (!isLoading) navigate("/green-apron-card");
    } else {
      setInitFormValue(cardDetail);
      setSendDate(
        cardDetail.sendDate.value.id === "NOW"
          ? convertDateWithFullMonth(moment().format())
          : convertDateWithFullMonth(
              moment(cardDetail.sendDateSchedule.value).format()
            )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail]);

  return (
    <Grid item xs className={styles.detailContainer}>
      <Header
        enableBackButton
        colouredBackground
        disableBottomBorder
        title={t("greenApronCard.preview")}
      >
        <LoadingButton
          id="sendGreenApronCardButton"
          disableRipple
          loading={isLoading}
          className={styles.editButton}
          variant="text"
          onClick={handleSubmit}
        >
          {t("greenApronCard.send")}
        </LoadingButton>
      </Header>
      <Box className={styles.detailContent}>
        <Box className={styles.detailCard}>
          <Box className={styles.detailCardToTarget}>
            <Typography variant="body1">{t("greenApronCard.to")}</Typography>
            <Typography variant="subtitle1">
              {cardDetail?.receiver?.value.length > 0
                ? cardDetail?.receiver?.value[0]?.name
                : sendEmailList && sendEmailList[0]?.receiverName}
            </Typography>
          </Box>
          <Box className={styles.detailCardInfo}>
            <Box className={styles.detailCardImage}>
              <img src={cardDefaultInfo?.url} alt={cardDefaultInfo?.name} />
            </Box>
            <Box className={styles.detailCardDefaultMsg}>
              <Typography variant="body1">
                {cardDefaultInfo?.description}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.detailCardDescription}>
            <Typography variant="subtitle2">
              {cardDetail?.content.value}
            </Typography>
          </Box>
          <Box className={styles.detailCardFromTarget}>
            <Typography variant="subtitle2">
              {t("greenApronCard.from")}
            </Typography>
            <Typography variant="subtitle1">
              {cardDetail?.sender.value.name}
            </Typography>
            <Typography variant="body1" className={styles.date}>
              {sendDate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default GreenApronCardPreview;
