import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { ObjectKey } from '../../interfaces/common-interface';
import i18n, { PageLang } from '../../languages';
import {
  getInboxMessageList,
  PaginationParamsType,
} from '../../services/common';
import { convertDate, convertTime, getApiDataByLang } from '../../utility';
import NoResults from '../no-results';
import Popup from '../popup';
import styles from './inbox-message.module.scss';

interface InboxMessageProps {
  open: boolean;
  onClose: () => void;
  callback?: (haveUnread: boolean) => void;
}

interface InboxMsgType {
  id: string;
  title: string;
  thumbnail: Array<string>;
  isSystemIcon?: boolean;
  datetime: number;
  isRead: boolean;
  redirectLink: string | null;
}

const InboxMessage: FC<InboxMessageProps> = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inboxMsgList, setInboxMsgList] = useState<Array<InboxMsgType>>([]);
  const [listParams, setListParams] = useState<{
    page: number;
    pageSize: number;
    filter: string;
  }>({
    page: 1,
    pageSize: 20,
    filter: '',
  });
  const [hasMore, setHasMore] = useState(true);
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const { t } = useTranslation();

  const filterList = [
    {
      id: 'SocialWall',
      name: t('inbox.filter.socialWall'),
      value: 'SOCIAL_WALL',
      disabled: false,
    },
    {
      id: 'Event',
      name: t('inbox.filter.event'),
      value: 'EVENT',
      disabled: false,
    },
    {
      id: 'GreenApronCard',
      name: t('inbox.filter.greenApronCard'),
      value: 'GREEN_APRON_CARD',
      disabled: false,
    },
    {
      id: 'Survey',
      name: t('inbox.filter.survey'),
      value: 'SURVEY',
      disabled: false,
    },
    {
      id: 'Leaderboard',
      name: t('inbox.filter.leaderboard'),
      value: 'LEADER_BOARD',
      disabled: false,
    },
    {
      id: 'DigitalCoupon',
      name: t('inbox.filter.digitalCoupon'),
      value: 'COUPON',
      disabled: process.env.REACT_APP_LOCATION !== 'HK',
    },
  ];

  const getList = useCallback(async () => {
    let active = true;
    setHasMore(true);
    try {
      if (localStorage.getItem('LOAD_INBOX_MESSAGE') === 'true') {
        const response = await getInboxMessageList(listParams);
        if (response.status === 200) {
          const inboxData = response.data.userInboxes;
          if (active && inboxData) {
            localStorage.setItem('LOAD_INBOX_MESSAGE', 'false');
            const list: Array<InboxMsgType> = inboxData.map(
              (msgItem: ObjectKey) => {
                const item = msgItem.item ?? msgItem;
                let redirectLink = null;
                let thumbnailIcon: Array<string> = [];
                let thumbnailName: Array<string> = [];
                let inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`);
                let displayName = '';
                let isSystem = false;
                if (msgItem.thumbnails && msgItem.thumbnails.length > 0) {
                  thumbnailIcon = msgItem.thumbnails.map(
                    (thumbnail: ObjectKey) => thumbnail.thumbPath
                  );
                  thumbnailName = msgItem.thumbnails.map(
                    (thumbnail: ObjectKey) => thumbnail.thumbName
                  );
                  if (
                    item.inboxType === 'SOCIAL_WALL_LIKE' ||
                    item.inboxType === 'SOCIAL_WALL_COMMENT' ||
                    item.inboxType === 'SOCIAL_WALL_REPORTED'
                  ) {
                    if (msgItem.thumbnails.length === 1) {
                      let nameOption: ObjectKey = {
                        partner1: thumbnailName[0],
                      };
                      if (item.inboxType === 'SOCIAL_WALL_REPORTED') {
                        nameOption = {
                          partner1: thumbnailName[0],
                          partner3: msgItem.postCreator, //thumbnailName[2]
                        };
                      }
                      inboxMsgContent = t(
                        `homepage.inboxMsg.${item.inboxType}_ONE_PARTNER`,
                        nameOption
                      );
                    } else if (msgItem.thumbnails.length === 2) {
                      let nameOption: ObjectKey = {
                        partner1: thumbnailName[0],
                        partner2: thumbnailName[1],
                      };
                      if (item.inboxType === 'SOCIAL_WALL_REPORTED') {
                        nameOption = {
                          partner1: thumbnailName[0],
                          partner2: thumbnailName[1],
                          partner3: msgItem.postCreator, //thumbnailName[2]
                        };
                      }
                      inboxMsgContent = t(
                        `homepage.inboxMsg.${item.inboxType}_TWO_PARTNER`,
                        nameOption
                      );
                    } else {
                      let nameOption: ObjectKey = {
                        partner1: thumbnailName[0],
                        partner2: thumbnailName[1],
                        count: inboxData.thumbnails.length - 2,
                      };
                      if (item.inboxType === 'SOCIAL_WALL_REPORTED') {
                        nameOption = {
                          partner1: thumbnailName[0],
                          partner2: thumbnailName[1],
                          partner3: msgItem.postCreator, //thumbnailName[2]
                          count: inboxData.thumbnails.length - 2,
                        };
                      }
                      inboxMsgContent = t(
                        `homepage.inboxMsg.${item.inboxType}`,
                        nameOption
                      );
                    }
                  }
                }

                // console.log('item', item);
                // console.log('inboxMsgContent', inboxMsgContent);
                if (
                  item.inboxType === 'COUPON_REQUISITION_FAILED' ||
                  item.inboxType === 'COUPON_REQUISITION_SUCCESS' ||
                  item.inboxType === 'COUPON_ASSIGNED_SUCCESS' ||
                  item.inboxType === 'COUPON_ASSIGNED_FAILED'
                ) {
                  isSystem = true;
                  thumbnailIcon = [
                    '/assets/images/notificationIcon-coupon.svg',
                  ];
                  if (
                    item.inboxType === 'COUPON_REQUISITION_FAILED' ||
                    item.inboxType === 'COUPON_REQUISITION_SUCCESS'
                  ) {
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      couponName: item.couponName
                        ? getApiDataByLang(item.couponName)
                        : '',
                    });
                  } else if (item.inboxType === 'COUPON_ASSIGNED_SUCCESS') {
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      couponNumber: item.couponCount,
                    });
                  } else {
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`);
                  }
                }

                switch (item.inboxType) {
                  case 'SOCIAL_WALL': // old inbox type
                    redirectLink = `/social-wall`;
                    break;
                  case 'SOCIAL_WALL_LIKE':
                    redirectLink = `/social-wall/post-detail/${item.redirectId}`;
                    break;
                  case 'SOCIAL_WALL_COMMENT':
                    redirectLink = `/social-wall/post-detail/${item.redirectId}`;
                    break;
                  case 'SOCIAL_WALL_MENTION':
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      partner: thumbnailName[0],
                    });
                    redirectLink = `/social-wall/post-detail/${item.redirectId}`;
                    break;
                  case 'SOCIAL_WALL_SCHEDULED':
                    redirectLink = `/social-wall/post-detail/${item.redirectId}`;
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-default.svg',
                    ];
                    break;
                  case 'SOCIAL_WALL_SUSPEND':
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-default.svg',
                    ];
                    break;
                  case 'SOCIAL_WALL_DELETED':
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-default.svg',
                    ];
                    break;
                  case 'SOCIAL_WALL_REPORTED':
                    redirectLink = `/social-wall/reported-post/post-detail/${item.redirectId}`;
                    break;
                  case 'GREEN_APRON_CARD':
                  case 'GREEN_APRON_CARD_SENT':
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      sender: thumbnailName[0],
                    });
                    redirectLink = `/green-apron-card/${item.redirectId}/reply`;
                    break;
                  case 'GREEN_APRON_CARD_REPLIED':
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      receiver: thumbnailName[0],
                    });
                    redirectLink = `/green-apron-card/${item.redirectId}`;
                    break;
                  case 'GREEN_APRON_CARD_SCHEDULED':
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      receiver: thumbnailName[0],
                    });
                    redirectLink = `/green-apron-card/${item.redirectId}`;
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-default.svg',
                    ];
                    break;
                  case 'EVENT':
                  case 'EVENT_REMINDER':
                    displayName = getApiDataByLang(item.metadata.eventName);
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      eventName: displayName,
                      eventTime:
                        convertDate(item.metadata.eventStartTimestamp) +
                        ' ' +
                        convertTime(item.metadata.eventStartTimestamp),
                    });
                    redirectLink = `/event/${item.redirectId}`;
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-event.svg',
                    ];
                    break;
                  case 'EVENT_CANCELLED':
                    displayName = getApiDataByLang(item.metadata.eventName);
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      eventName: displayName,
                      eventStartTime:
                        convertDate(item.metadata.eventStartTimestamp) +
                        ' ' +
                        convertTime(item.metadata.eventStartTimestamp),
                      eventEndTime:
                        convertDate(item.metadata.eventEndTimestamp) +
                        ' ' +
                        convertTime(item.metadata.eventEndTimestamp),
                    });
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-event.svg',
                    ];
                    break;
                  case 'SURVEY_REMINDER':
                    displayName = getApiDataByLang(item.name);
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      eventName: displayName,
                    });
                    redirectLink = `/survey/form/${item.redirectId}`;
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-survey.svg',
                    ];
                    break;
                  case 'SURVEY': // old inbox type
                  case 'SURVEY_SCHEDULED':
                    redirectLink = `/survey`;
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-default.svg',
                    ];
                    break;
                  case 'PRIZE':
                    displayName = getApiDataByLang(item.rewardCampaignName);
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      campaignName: displayName,
                      ranking: getApiDataByLang(item.prizeName),
                    });
                    redirectLink = `/leaderboard/my-rewards`;
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-leaderboard.svg',
                    ];
                    break;
                  case 'NO_PRIZE':
                    displayName = item.rewardCampaignName
                      ? getApiDataByLang(item.rewardCampaignName)
                      : 'Test';
                    inboxMsgContent = t(`homepage.inboxMsg.${item.inboxType}`, {
                      campaignName: displayName,
                    });
                    redirectLink = `/leaderboard/my-rewards`;
                    isSystem = true;
                    thumbnailIcon = [
                      '/assets/images/notificationIcon-leaderboard.svg',
                    ];
                    break;
                }

                return {
                  id: item._id,
                  title: inboxMsgContent,
                  thumbnail: thumbnailIcon,
                  isSystemIcon: isSystem,
                  datetime: item.lastUpdateTime,
                  isRead: item.isRead,
                  redirectLink: redirectLink,
                };
              }
            );
            setInboxMsgList((prev) => [...prev, ...list]);
            const haveUnreadMsg = list.find(
              (item: InboxMsgType) => !item.isRead
            );
            if (haveUnreadMsg && props.callback) {
              props.callback(true);
            }
            if (
              response.data.totalCount === [...inboxMsgList, ...list].length
            ) {
              setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    if (!props.open && !isLoading) {
      setInboxMsgList([]);
      setIsLoading(true);
    }
    if (props.open && isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [props.open, isLoading]);

  const nextPage = () => {
    // console.log("isFilter", isFilter);
    if (!isLoading && !isFilter) {
      localStorage.setItem('LOAD_INBOX_MESSAGE', 'true');
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
    if (isFilter) {
      setIsFilter(false);
    }
  };

  const handleOnChange = (value: string) => {
    setSelectedValue(value);
  };
  const handleConfirmFilter = () => {
    if (!isLoading) {
      localStorage.setItem('LOAD_INBOX_MESSAGE', 'true');
      setListParams((prev) => ({
        ...prev,
        page: 1,
        filter: selectedValue,
      }));
      setInboxMsgList([]);
      setIsFilter(true);
    }
  };

  const handleResetFilter = () => {
    setSelectedValue('');
  };
  return (
    <>
      <InboxMessageContainer
        id="inboxMsgPopupCloseButton"
        title={t('inbox.title')}
        isOpen={props.open}
        onClose={() => {
          localStorage.setItem('LOAD_INBOX_MESSAGE', 'false');
          props.onClose();
        }}
        headerButton={
          <IconButton
            id={'inboxMsgPopupFilterButton'}
            disableRipple
            className={styles.button}
            onClick={() => setFilterPopupOpen(true)}
          >
            <img src="/assets/images/filter.svg" alt="filter" />
          </IconButton>
        }
        fullScreen
        disableActions
        isCanClose
      >
        <Box
          id="inboxMsgListsContainer"
          sx={{
            display: 'block',
            overflow: 'auto',
            width: '100%',
            height: '100%',
          }}
        >
          {/* {isLoading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <CircularProgress />
          </Stack>
        )} */}
          {!isLoading && !hasMore && inboxMsgList.length <= 0 && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100%' }}
            >
              <NoResults />
            </Stack>
          )}
          <InfiniteScroll
            dataLength={inboxMsgList.length} //This is important field to render the next data
            next={nextPage}
            hasMore={hasMore}
            loader={<LoadingSkeleton />}
            scrollableTarget="inboxMsgListsContainer"
          >
            <List disablePadding>
              {inboxMsgList.map((item: InboxMsgType, index: number) => (
                <ListItem
                  key={index}
                  disablePadding
                  className={styles.inboxMsgItem}
                >
                  <ListItemButton
                    disableRipple
                    className={styles.inboxMsgButton}
                    onClick={() => {
                      if (item.redirectLink) {
                        // navigate(item.redirectLink);
                        window.location.href = item.redirectLink;
                      }
                    }}
                  >
                    <ListItemIcon className={styles.inboxMsgNotification}>
                      {!item.isRead && <span />}
                    </ListItemIcon>
                    <ListItemAvatar
                      className={`
                    ${styles.inboxMsgAvatar}
                    ${item.thumbnail.length > 1 && styles.multiAvatar}
                  `}
                    >
                      {item.thumbnail.length <= 0 ? (
                        <Avatar className={styles.avatar} />
                      ) : (
                        item.thumbnail.map(
                          (avatar: string, avatarIndex: number) => (
                            <Avatar
                              key={avatarIndex}
                              src={
                                avatar?.startsWith('https://') ||
                                avatar?.startsWith('http://') ||
                                item.isSystemIcon
                                  ? avatar
                                  : `${process.env.REACT_APP_API_DOMAIN}/media/${avatar}`
                              }
                              className={styles.avatar}
                            />
                          )
                        )
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={moment.unix(item.datetime).fromNow()}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
        </Box>
      </InboxMessageContainer>
      <InboxMessageContainer
        id="inboxMsgFilterPopup"
        title={t('general.filter')}
        isOpen={filterPopupOpen}
        onClose={() => setFilterPopupOpen(false)}
        onClickConfirm={handleConfirmFilter}
        confirmBtnText={t('general.apply')}
        headerButton={
          <Link
            id="resetFilterButton"
            component="button"
            typography="body1"
            className={styles.resetFilterButton}
            onClick={handleResetFilter}
          >
            {t('general.reset')}
          </Link>
        }
      >
        <Stack className={styles.filterContainer}>
          {filterList && (
            <List className={styles.filterField}>
              {filterList
                .filter((item: ObjectKey) => !item.disabled)
                .map((item: ObjectKey, index: number) => {
                  return (
                    <FilterItem
                      key={index}
                      name={item.name}
                      value={item.value}
                      isSelected={selectedValue === item.value}
                      onChange={handleOnChange}
                    />
                  );
                })}
            </List>
          )}
        </Stack>
      </InboxMessageContainer>
    </>
  );
};
interface FilterItemProps {
  name: string;
  value: string;
  isSelected: boolean;
  onChange: (value: string) => void;
}

const FilterItem: FC<FilterItemProps> = (props) => {
  return (
    <ListItemButton
      id={`Filter_${props.value}`}
      selected={props.isSelected}
      className={`${styles.filterItem} ${props.isSelected && styles.active}`}
      onClick={() => props.onChange(props.value)}
    >
      <ListItemText primary={props.name} />
      {props.isSelected && (
        <ListItemIcon>
          <img src="/assets/images/check.svg" alt="Selected" />
        </ListItemIcon>
      )}
    </ListItemButton>
  );
};

function LoadingSkeleton() {
  return (
    <ListItem disablePadding className={styles.inboxMsgItem}>
      <ListItemButton disableRipple className={styles.inboxMsgButton}>
        <ListItemIcon className={styles.inboxMsgNotification}></ListItemIcon>
        <ListItemAvatar className={styles.inboxMsgAvatar}>
          <Skeleton variant="circular" width={50} height={50} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant="text" width="80%" />}
          secondary={<Skeleton variant="text" width="30%" />}
        />
      </ListItemButton>
    </ListItem>
  );
}
interface InboxMessageContainerProps {
  id: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  isCanClose?: boolean;
  onFilterClick?: () => void;
  onClickConfirm?: () => void;
  confirmBtnText?: string;
  fullScreen?: boolean;
  disableActions?: boolean;
  children: ReactNode;
  headerButton?: ReactNode;
}

const InboxMessageContainer: FC<InboxMessageContainerProps> = (props) => {
  const InboxMsgHeader = () => {
    return (
      <Grid container alignItems="center" className={styles.popupHeader}>
        <Grid item xs="auto">
          {props.isCanClose && (
            <IconButton
              id={props.id}
              disableRipple
              className={styles.button}
              onClick={props.onClose}
            >
              <img src="/assets/images/arrow_left.svg" alt="" />
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          xs
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant="h3">{props.title}</Typography>
          {props.headerButton && <>{props.headerButton}</>}
        </Grid>
      </Grid>
    );
  };

  return (
    <Popup
      id="inboxMsgPopup"
      isOpen={props.isOpen}
      setIsOpen={(isOpen) => props.onClose()}
      title={<InboxMsgHeader />}
      content={props.children}
      {...(props.disableActions && { disableActions: true })}
      {...(props.fullScreen && { fullScreen: true })}
      {...(props.onClickConfirm && {
        onClickConfirm: () => {
          props.onClose();
          props.onClickConfirm && props.onClickConfirm();
        },
      })}
      {...(props.confirmBtnText && { confirmBtnText: props.confirmBtnText })}
    />
  );
};

export default InboxMessage;
