import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./pastWinnersList.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ActionItem, Popup, ShortcutMenu } from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import moment from "moment";
import LeaderboardMission from "./leaderboardMission";
import LeaderboardCampaign from "./leaderboardCampaign";
import toHtml from "html-react-parser";
import { addThousandSeparator, convertNumberDate } from "../../utility";
import { getPastWinnersList } from "../../services/leaderboard";

function PastWinnersList() {
  let { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getPastWinnersList(id as string).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [filter, setFilter] = useState(Array<any>);
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs className={styles.pageContainer}>
        <Header
          title={t("leaderboard.pastWinners")}
          enableBackButton
          disableBottomBorder
          colouredBackground
        ></Header>
        {!isLoading && data && (
          <Stack className={styles.contentContainer}>
            <Typography
              className={styles.date}
              variant="body2"
            >{`${convertNumberDate(
              data.rewardInfo.startDate
            )} - ${convertNumberDate(data.rewardInfo.endDate)}`}</Typography>

            <Box className={styles.topThreeContainer}>
              <img
                src="/assets/images/crown_deco.svg"
                className={styles.crown}
              />
              <img
                src="/assets/images/star_deco.svg"
                className={styles.star1_1}
              />
              <img
                src="/assets/images/star_deco.svg"
                className={styles.star1_2}
              />
              <img
                src="/assets/images/star_deco.svg"
                className={styles.star2}
              />
              <img
                src="/assets/images/star_deco.svg"
                className={styles.star3}
              />
              {data.rewardWinnerList.winners?.map(
                (item: any, index: number) => {
                  if (index <= 2) {
                    return (
                      <Stack
                        key={index}
                        className={`${styles.rankItem} top${index + 1}`}
                        gap="6px"
                      >
                        <Box className={styles.iconWrapper}>
                          <Avatar
                            src={item.userProfilePictureUrl}
                            onClick={() =>
                              navigate(
                                `/discover-people/profile/${item.partnerId}`
                              )
                            }
                          />
                          <Typography className={styles.rank} variant="body2">
                            {item.rank}
                          </Typography>
                        </Box>
                        <Typography> {item.partnerName}</Typography>
                        <Typography className={styles.points}>
                          {addThousandSeparator(item.coffeeCherries)}
                        </Typography>
                      </Stack>
                    );
                  }
                }
              )}
            </Box>

            <Stack className={styles.rankingList} gap="16px">
              {data.rewardWinnerList.winners?.map(
                (item: any, index: number) => {
                  if (index >= 3) {
                    return (
                      <Stack
                        key={index}
                        direction="row"
                        className={styles.rankItem}
                        gap="8px"
                      >
                        <Typography className={styles.rank}>
                          {item.rank}
                        </Typography>
                        <Avatar
                          src={item.userProfilePictureUrl}
                          onClick={() =>
                            navigate(
                              `/discover-people/profile/${item.partnerId}`
                            )
                          }
                        />
                        <Typography> {item.partnerName}</Typography>

                        <Typography className={styles.points}>
                          {addThousandSeparator(item.coffeeCherries)}{" "}
                          <span>{t("leaderboard.pts")}</span>
                        </Typography>
                      </Stack>
                    );
                  }
                }
              )}
            </Stack>
          </Stack>
        )}
      </Grid>
    </>
  );
}

export default PastWinnersList;
