import React, { useState, useEffect } from "react";
import Header from "../../components/header/header";
import sectionStyles from "../homepage/sections.module.scss";
import styles from "./newsAndSupports.module.scss";
import contentHubStyles from "../../components/content-hub/content-hub.module.scss";
import { useNavigate } from "react-router";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { Popup } from "../../components";
import FaqItem from "../../components/content-hub/faq-item";
import ListItem from "../../components/content-hub/list-item";
import ActiveItemDetail from "../../components/content-hub/item-detail";

import { listItemType } from "../../components/content-hub/interface";
// import { contentList } from "./makeData";
import { useTranslation } from "react-i18next";
import {
  getContentHubSummary,
  getContentHubDetail,
  Module,
} from "../../services/homepage";
import { getApiDataByLang } from "../../utility";
import { encode, decode } from "html-entities";
type Props = {};

const NewsAndSupports = (props: Props) => {
  const { t } = useTranslation();
  const [isDetailOpen, setDetailOpen] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<listItemType | null>(null);
  const [contentList, setContentList] = useState<any>([]);
  const [isAllEmpty, setIsAllEmpty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const convertData = (data: any) => {
    return data.length > 0
      ? data.map((item: any) => {
          return {
            id: item.id,
            title: getApiDataByLang(item.title),
            image: item.coverImage,
            tab: item.contentCategory.categoryName
              ? getApiDataByLang(item.contentCategory.categoryName)
              : "",
            color: item.contentCategory.color,
            date: item.startDate,
          };
        })
      : [];
  };

  useEffect(() => {
    const getSummaryData = async () => {
      try {
        const response: any = await getContentHubSummary();
        // console.log("getSummaryData", response);
        const summary = response.data;
        if (response && summary) {
          if (
            summary["Announcements"].length === 0 &&
            summary["PartnerAnniversary"].length === 0 &&
            summary["Newsletters"].length === 0 &&
            summary["FAQs"].length === 0
          ) {
            setIsAllEmpty(true);
          }
          const temp = [
            {
              id: "ANNOUNCEMENTS",
              title: t("homepage.contentHub.Announcements"),
              url: "/announcement",
              list: convertData(summary["Announcements"]),
            },
            {
              id: "PARTNER_ANNIVERSARY",
              title: t("homepage.contentHub.PartnerAnniversary"),
              url: "/partner-anniversary",
              list: convertData(summary["PartnerAnniversary"]),
            },
            {
              id: "NEWSLETTERS",
              title: t("homepage.contentHub.Newsletters"),
              url: "/newsletters",
              list: convertData(summary["Newsletters"]),
            },
            {
              id: "FAQS",
              title: t("homepage.contentHub.Faq"),
              url: "/faqs",
              list:
                summary["FAQs"].length > 0
                  ? summary["FAQs"].map((item: any) => {
                      return {
                        id: item.id,
                        title: getApiDataByLang(item.question),
                        tab: getApiDataByLang(
                          item.contentCategory.categoryName
                        ),
                        color: item.contentCategory.color,
                        content: decode(getApiDataByLang(item.answer)),
                      };
                    })
                  : [],
            },
          ];
          // console.log("temp", temp);
          setContentList(temp);
        }
      } catch (error) {
        // console.log("error", error);
        throw error;
      }
    };
    getSummaryData();
  }, []);

  const handleGetDetail = (id: string, module: Module) => {
    const getDetail = async () => {
      try {
        const response: any = await getContentHubDetail(id, {
          module: module,
        });

        const detail = response.data;
        if (response && detail) {
          const temp = {
            id: detail.id,
            title: getApiDataByLang(detail.title),
            image: detail.coverImage,
            tab: getApiDataByLang(detail.contentCategory.categoryName),
            color: detail.contentCategory.color,
            date: detail.startDate,
            content: getApiDataByLang(detail.content),
          };
          setActiveItem(temp);
          setIsLoading(false);
        }
      } catch (error) {
        // console.log(error);
        throw error;
      }
    };
    getDetail();
  };

  return (
    <>
      <Header
        title={t("homepage.PRO")}
        enableBackButton
        closeButtonNavigation="/home"
      />
      <>
        <Box
          className={`${sectionStyles.sectionContainer} ${styles.container}`}
        >
          {!isAllEmpty ? (
            contentList.map((item: any) => {
              const listData = item.list;
              return listData.length > 0 ? (
                <>
                  <Box
                    className={sectionStyles.sectionWrapper}
                    {...(item.id === "FAQS" && {
                      style: { padding: "0" },
                    })}
                  >
                    <Box
                      className={sectionStyles.sectionHeader}
                      {...(item.id === "FAQS" && {
                        style: {
                          padding: "16px",
                          marginBottom: "0",
                          borderBottom: "1px solid #EDEBE9",
                        },
                      })}
                    >
                      <Typography
                        className={sectionStyles.sectionTitle}
                        variant="body1"
                      >
                        {item.title}
                      </Typography>
                      <img
                        src="/assets/images/chevron_right.svg"
                        alt=""
                        onClick={() => {
                          navigate(item.url);
                        }}
                      />
                    </Box>
                    <Box className={contentHubStyles.listContainer}>
                      {listData && listData.length > 0 ? (
                        listData.map((listItem: any, index: number) =>
                          item.id === "FAQS" ? (
                            <FaqItem {...listItem} />
                          ) : (
                            <ListItem
                              {...listItem}
                              key={`list-item-${index}`}
                              onDetailClick={(id: any) => {
                                setIsLoading(true);
                                setDetailOpen(true);
                                handleGetDetail(id, item.id);
                              }}
                            />
                          )
                        )
                      ) : (
                        <div className={styles.noData}>
                          <div className={styles.noDataContainer}>
                            <img src="/assets/images/empty.png" alt="no data" />
                            <h3>{t("homepage.contentHub.noData")}</h3>
                          </div>
                        </div>
                      )}
                    </Box>
                  </Box>
                </>
              ) : null;
            })
          ) : (
            <div className={contentHubStyles.noData}>
              <div className={contentHubStyles.noDataContainer}>
                <img src="/assets/images/empty.png" alt="no data" />
                <h3>{t("general.stayTuned")}</h3>
                <p>{t("general.stayTunedMsg")}</p>
              </div>
            </div>
          )}
        </Box>
      </>
      {activeItem && (
        <Popup
          id={styles.detailPopup}
          isOpen={isDetailOpen}
          setIsOpen={(close: boolean) => setDetailOpen(close)}
          title={
            <>
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => {
                  setDetailOpen(false);
                  setIsLoading(true);
                }}
              >
                <img src="/assets/images/arrow_left.svg" alt="detail back" />
              </IconButton>
            </>
          }
          content={
            activeItem && (
              <ActiveItemDetail {...activeItem} isLoading={isLoading} />
            )
          }
          disableActions={true}
          fullScreen={true}
        />
      )}
    </>
  );
};

export default NewsAndSupports;
