import { FC, useEffect, useRef, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  ListItemText,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { LanguageDropdown, ToggleButton } from '../../components';
import AutoCompleteField from '../../components/auto-complete-field';
import DateTimePickerField from '../../components/datetime-picker-field';
import Header from '../../components/header/header';
import InputField from '../../components/input-field';
import InvitationScopeField from '../../components/invitation-scope-field';
import SelectAdminCard from '../../components/select-admin-card';
import SelectField from '../../components/select-field';
import SurveyField from '../../components/survey-field';
import UploadFileField from '../../components/upload-file-field';

import { ObjectKey } from '../../interfaces/common-interface';
import { user, UserState } from '../../reducers/user-slice';
import {
  getEventAdmins,
  getInvitationGroupList,
  getStaffFilterList,
  getStaffList,
  getStoresList,
} from '../../services/common';
import {
  createEvent,
  getEventCategory,
  getEventDetail,
  updateEvent,
  uploadAttachments,
  uploadCoverImage,
} from '../../services/event';
import { RootState } from '../../store/store';
import {
  convertInvitationScopeGroupData,
  convertStaffList,
  convertStoreList,
  formatFileSize,
  getApiDataByLang,
  getDropdownListOnStore,
} from '../../utility';
import styles from './eventCreate.module.scss';
import { FormValueType, useEventFormHook } from './useEventFormHook';

interface DataWithLanguage {
  lang: string;
  value: string;
}

const EventCreate: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [partnerNetworkGroupId, setPartnerNetworkGroupId] = useState<
    string | null
  >(null);
  const navigate = useNavigate();
  const descriptionLimit = 200;
  const descriptionTcLimit = 100;
  const {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    handleChangeDisplayLanguage,
    handleToggleButtonChange,
    checkValidation,
    convertFormValueToRequest,
    setInitFormValue,
  } = useEventFormHook();
  const [dropdownList, setDropdownList] = useState<{
    [key: string]: Array<any>;
  }>({
    adminList: [],
    categoryList: [],
    invitationScopeList: [],
    invitationScopeGroupList: [],
    invitationScopeIndividualList: [],
    locationList: [],
    maxCapacityList: [],
  });
  const [loadedDropdownList, setLoadedDropdownList] = useState(false);
  const [showInvitationPopup, setShowInvitationPopup] =
    useState<boolean>(false);
  const [optionDesc, setOptionDesc] = useState<string | null>(null);
  const { id, groupId } = useParams();
  const editMode = Boolean(id);
  const [failMsg, setFailMsg] = useState({
    open: false,
    status: 'error',
    message: '',
  });
  const formRef = useRef<HTMLDivElement | null>(null);
  const [lockInvitationScope, setLockInvitationScope] =
    useState<ObjectKey | null>(null);
  const [registrationPeriodEnd, setRegistrationPeriodEnd] = useState<
    string | null
  >(null);
  const [registrationPeriodError, setRegistrationPeriodError] =
    useState<string>(t('event.registrationPeriodErrorText'));
  const [maxCapacityLimit, setMaxCapacityLimit] = useState<number>(1);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );

  const invitationScopeList = [
    {
      id: 'public',
      name: t('general.allPartners'),
      description: '',
      disable: false,
    },
    {
      id: 'private',
      name: t('general.private'),
      description: t('general.privateDesc'),
      isNextStep: true,
      isOpened: false,
      nextStepId: 'invitationScopeInputWrapper',
      disable: false,
    },
    {
      id: 'groupMember',
      name: t('partnerNetwork.groupMember'),
      description: t('partnerNetwork.groupMemberDesc'),
      disable: !groupId && !partnerNetworkGroupId,
    },
  ];

  const maxCapacityList = [
    {
      name: t('event.noLimitPeople'),
      value: 'NO_LIMIT',
    },
    {
      name: t('event.limitTo'),
      value: 'LIMIT_TO',
    },
  ];

  const getSubmitData = (data: any) => {
    setOptionDesc(data.data);
    setShowInvitationPopup(data.isOpen);
  };

  const getIsClosed = (data: boolean) => {
    setShowInvitationPopup(data);
  };

  const checkRegistrationPeriodValidation = () => {
    let isValid = true;
    console.log('registrationPeriodEnd', registrationPeriodEnd);
    if (registrationPeriodEnd !== null) {
      if (
        moment(formValue.registrationPeriod.value.endDate).unix() <
        moment(registrationPeriodEnd).unix()
      ) {
        setRegistrationPeriodError(t('event.registrationPeriodEditError'));
        setInitFormValue({
          ...formValue,
          registrationPeriod: {
            ...formValue.registrationPeriod,
            isError: true,
          },
        });
        isValid = false;
      } else {
        setRegistrationPeriodError(t('event.registrationPeriodErrorText'));
      }
    }

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = checkValidation();
    const isValidRegistrationPeriod = checkRegistrationPeriodValidation();

    if (isValid && isValidRegistrationPeriod) {
      setIsSaving(true);
      const request = convertFormValueToRequest();
      // console.log("request", request);
      try {
        let response: ObjectKey;
        if (id) {
          response = await updateEvent({
            id: id,
            request: {
              ...request,
              ...(groupId && { partnerNetworkGroupId: groupId }),
            },
          });
        } else {
          response = await createEvent({
            ...request,
            ...(groupId && { partnerNetworkGroupId: groupId }),
          });
        }
        if (response.status === 200) {
          navigate(
            groupId
              ? `/partner-network/event/${groupId}?create=success&id=${response.data.event._id}`
              : `/event?create=success&id=${response.data.event._id}`
          );
        } else {
          setFailMsg((prev) => ({
            ...prev,
            open: true,
            message: 'Create event failed',
          }));
        }
      } catch (error) {
        setFailMsg((prev) => ({
          ...prev,
          open: true,
          message: 'Create event failed',
        }));
        throw error;
      } finally {
        setIsSaving(false);
      }
    } else {
      formRef.current
        ?.querySelector('*[data-error="true"]')
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const convertResponseToFormValue = (data: any) => {
      const eventData = data.event;
      let appendsFormValue = formValue;
      let appendsLanguage: Array<string> = [];

      if (Array.isArray(eventData['eventName'])) {
        eventData['eventName'].forEach((data: DataWithLanguage) => {
          const targetKey = ('name' +
            data.lang.toUpperCase()) as keyof FormValueType;
          appendsFormValue = {
            ...appendsFormValue,
            [targetKey]: {
              ...appendsFormValue[targetKey],
              value: data.value,
              isRequired: true,
            },
          };
          if (appendsLanguage.indexOf(data.lang.toLowerCase()) === -1) {
            appendsLanguage = [...appendsLanguage, data.lang.toLowerCase()];
          }
        });
        appendsFormValue = {
          ...appendsFormValue,
          displayLanguage: {
            ...appendsFormValue.displayLanguage,
            value: appendsLanguage,
          },
        };
      } else {
        appendsFormValue = {
          ...appendsFormValue,
          nameEN: {
            ...appendsFormValue.nameEN,
            value: eventData['eventName'],
            isRequired: true,
          },
        };
      }

      if (Array.isArray(eventData['description'])) {
        eventData['description'].forEach((data: DataWithLanguage) => {
          const targetKey = ('description' +
            data.lang.toUpperCase()) as keyof FormValueType;
          appendsFormValue = {
            ...appendsFormValue,
            [targetKey]: {
              ...appendsFormValue[targetKey],
              value: data.value,
              isRequired: false,
            },
          };
        });
      } else {
        appendsFormValue = {
          ...appendsFormValue,
          descriptionEN: {
            ...appendsFormValue.descriptionEN,
            value: eventData['description'],
            isRequired: false,
          },
        };
      }

      let correctLocation: ObjectKey | null = null;
      if (typeof eventData.eventLocation === 'string') {
        correctLocation = {
          id: '-999',
          name: eventData.eventLocation,
          address: eventData.eventLocation,
        };
      } else {
        correctLocation = convertStoreList([eventData.eventLocation])[0];
      }

      const correctCategory = dropdownList.categoryList.find(
        (category) => category.value === eventData.category._id
      );

      let correctAdminList: Array<ObjectKey> = [];
      if (eventData.eventAdmin.length > 0) {
        correctAdminList = convertStaffList(eventData.eventAdmin);
      }

      const correctInvitationScopeGroup = eventData.inviteScope.find(
        (item: ObjectKey) => item.scopeType === 'stores'
      );
      let correctGroupValue: ObjectKey[] = [];
      if (
        correctInvitationScopeGroup &&
        correctInvitationScopeGroup.value.length > 0
      ) {
        correctGroupValue = convertInvitationScopeGroupData(
          correctInvitationScopeGroup.value
        );
      }

      const correctInvitationScopeIndividual = eventData.inviteScope.find(
        (item: ObjectKey) => item.scopeType === 'partners'
      );
      let correctIndividualsValue: ObjectKey[] = [];
      if (
        correctInvitationScopeIndividual &&
        correctInvitationScopeIndividual.value.length > 0
      ) {
        correctIndividualsValue = convertStaffList(
          correctInvitationScopeIndividual.value
        );
      }

      if (eventData.isExternalLink) {
        appendsFormValue = {
          ...appendsFormValue,
          externalLink: {
            ...appendsFormValue.externalLink,
            value: eventData.isExternalLink,
          },
          externalLinkUrl: {
            ...appendsFormValue.externalLinkUrl,
            value: eventData.externalLinkURL,
          },
          additionalInformation: {
            ...appendsFormValue.additionalInformation,
            value: eventData.externalLinkInfo,
          },
        };
      }

      if (eventData.isEventSurvey) {
        appendsFormValue = {
          ...appendsFormValue,
          eventSurvey: {
            ...appendsFormValue.eventSurvey,
            value: eventData.isEventSurvey,
          },
          selectSurvey: {
            ...appendsFormValue.selectSurvey,
            value: {
              id: eventData.eventSurvey._id,
              name: getApiDataByLang(eventData.eventSurvey.surveyTitle),
            },
          },
        };
      }

      if (eventData.maxCapacity) {
        const maxCapacityValue = maxCapacityList.find(
          (value: ObjectKey) => value.value === 'LIMIT_TO'
        );
        appendsFormValue = {
          ...appendsFormValue,
          maxCapacity: {
            ...appendsFormValue.maxCapacity,
            value: maxCapacityValue,
          },
          maxCapacityLimit: {
            ...appendsFormValue.maxCapacityLimit,
            value: eventData.maxCapacity,
          },
        };
        setMaxCapacityLimit(parseInt(eventData.maxCapacity));
      } else {
        const maxCapacityValue = maxCapacityList.find(
          (value: ObjectKey) => value.value === 'NO_LIMIT'
        );
        appendsFormValue = {
          ...appendsFormValue,
          maxCapacity: {
            ...appendsFormValue.maxCapacity,
            value: maxCapacityValue,
          },
        };
      }
      const correctGroupName: Array<string> = correctGroupValue.map(
        (item) => item.label
      );
      const correctIndividualsName: Array<string> = correctIndividualsValue.map(
        (item) => item.name
      );
      setOptionDesc(
        [...correctGroupName, ...correctIndividualsName].join(', ')
      );
      setLockInvitationScope({
        group: correctGroupValue,
        individuals: correctIndividualsValue,
      });

      appendsFormValue = {
        ...appendsFormValue,
        eventDate: {
          ...appendsFormValue.eventDate,
          value: {
            startDate: moment.unix(eventData.eventStartTimestamp).format(),
            endDate: moment.unix(eventData.eventEndTimestamp).format(),
          },
        },
        location: {
          ...appendsFormValue.location,
          value: correctLocation,
        },
        isPublic: {
          value: eventData.isPublic
            ? eventData?.groupEventSharingOption === 'groupMember'
              ? invitationScopeList[2]
              : invitationScopeList[0]
            : invitationScopeList[1],
          isError: false,
          isRequired: true,
        },
        invitationScope: {
          ...appendsFormValue.invitationScope,
          value: {
            group: correctGroupValue,
            individuals: correctIndividualsValue,
          },
          isRequired: !eventData.isPublic,
        },
        eventAdmin: {
          ...appendsFormValue.eventAdmin,
          value: correctAdminList,
        },
        allowAttendanceWithQrCode: {
          ...appendsFormValue.allowAttendanceWithQrCode,
          value: eventData.isQRCodeAttend,
        },
        category: {
          ...appendsFormValue.category,
          value: correctCategory,
        },
        registrationPeriod: {
          ...appendsFormValue.registrationPeriod,
          value: {
            startDate: moment.unix(eventData.registrationPeriodStart).format(),
            endDate: eventData.registrationPeriodEnd
              ? moment.unix(eventData.registrationPeriodEnd).format()
              : null,
          },
        },
        sendReminder: {
          ...appendsFormValue.sendReminder,
          value: eventData.isSendReminder,
        },
      };
      if (eventData.registrationPeriodEnd) {
        setRegistrationPeriodEnd(
          moment.unix(eventData.registrationPeriodEnd).format()
        );
      }

      let attachmentsValueList: ObjectKey[] = [];
      if (eventData.eventAttachments.length > 0) {
        attachmentsValueList = eventData.eventAttachments.map(
          (attachment: ObjectKey, index: number) => ({
            id: index.toString(),
            data: attachment.attachmentUrl,
            fileName: attachment.attachmentName,
            type: attachment.attachmentExtension,
            size: attachment.attachmentSize,
          })
        );
      }

      if (eventData.eventCoverImage) {
        appendsFormValue = {
          ...appendsFormValue,
          cover: {
            ...appendsFormValue.cover,
            value: {
              // ...coverImageValue,
              id: '0',
              data: eventData.eventCoverImage,
              fileName: eventData.eventCoverImage.split('/').pop(),
              type: eventData.eventCoverImage.split('.').pop(),
              size: 0,
            },
          },
          attachments: {
            ...appendsFormValue.attachments,
            value: attachmentsValueList,
          },
        };
      }

      handleChangeDisplayLanguage(appendsLanguage);
      setInitFormValue(appendsFormValue);

      if (groupId) {
        if (!userState.permission?.SocialWallAdmin) {
          navigate(`/partner-network/event/${groupId}?error=permission`);
        }
      } else {
        if (!userState.permission?.EventAdmin) {
          navigate(`/event?error=permission`);
        }
      }
    };

    const getDetail = async (eventId: string) => {
      try {
        const response = await getEventDetail(eventId);
        // console.log("response", response);
        if (response.data.event?.partnerNetworkGroupId) {
          setPartnerNetworkGroupId(response.data.event.partnerNetworkGroupId);
        }
        convertResponseToFormValue(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    if (loadedDropdownList) {
      if (id) {
        getDetail(id);
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, loadedDropdownList, dropdownList]);

  useEffect(() => {
    const getDropdownList = async () => {
      try {
        const categoryList: any = await getDropdownListOnStore(
          'eventCategory',
          'eventCategorys',
          getEventCategory
        );

        const removeIsDeletedCategoryList = categoryList
          .filter((item: ObjectKey) => !item.isDeleted)
          .filter(
            (item: ObjectKey) => item.status.toLowerCase() !== 'inactive'
          );

        setDropdownList({
          categoryList: removeIsDeletedCategoryList.map((item: ObjectKey) => ({
            name: item.categoryName,
            value: item._id,
          })),
        });
        setLoadedDropdownList(true);
      } catch (error) {
        throw error;
      }
    };

    getDropdownList();
  }, []);

  const handleFileFieldChange = async (
    name: string,
    value: File | File[] | null
  ) => {
    if (!value) return false;

    if (Array.isArray(value)) {
      try {
        const response = await uploadAttachments({
          file: value,
        });
        if (response.status === 200) {
          const filesList = response.data.eventAttachments;
          // eslint-disable-next-line
          let filesValueList: any[] = [];
          filesList.forEach((item: ObjectKey, index: number) => {
            const fileValue: ObjectKey = {
              id: (formValue[name].value.length + index).toString(),
              fileName: item.attachmentUrl.split('/').pop(),
              type: item.attachmentExtension,
              data: item.attachmentUrl,
              size: value[index].size,
            };
            filesValueList.push(fileValue);
          });

          const combineValue = [
            ...formValue[name].value,
            ...filesValueList,
          ].map((item: ObjectKey, index: number) => ({
            ...item,
            id: index.toString(),
          }));

          handleInputFieldChange(name, combineValue);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        throw error;
      }
    } else {
      try {
        const response = await uploadCoverImage({
          image: value,
        });
        if (response.status === 200) {
          const imageLink = response.data.eventCoverImage.imageUrl;
          const fileValue = {
            id: '0',
            fileName: imageLink.split('/').pop(),
            type: value.type,
            data: imageLink,
            size: value.size,
          };
          handleInputFieldChange(name, fileValue);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        if (error.response.data) {
          setFailMsg((prev) => ({
            ...prev,
            open: true,
            status: 'error',
            message: error.response.data.message,
          }));
        }
        throw error;
      }
    }
  };

  const getInvitationScopeListName = (value: ObjectKey) => {
    let outputValue: string | undefined = undefined;
    let outputValueArray: Array<string> = [];
    const selectedValue = value;

    if (selectedValue.group.length > 0) {
      const groupArray = selectedValue.group.map(
        (item: ObjectKey) => item.label
      );
      outputValueArray = [...outputValueArray, ...groupArray];
    }
    if (selectedValue.individuals.length > 0) {
      const individualsArray = selectedValue.individuals.map(
        (item: ObjectKey) => item.name
      );
      outputValueArray = [...outputValueArray, ...individualsArray];
    }

    if (outputValueArray.length > 0) {
      outputValue = outputValueArray.join(', ');
    }

    return outputValue;
  };

  return (
    <>
      <Header
        title={editMode ? t('event.editEvent') : t('event.newEvent')}
        enableCloseButton
        closeButtonNavigation={
          groupId ? `/partner-network/event/${groupId}` : `/event`
        }
      >
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs="auto">
            <LoadingButton
              disabled={!isDirty}
              disableRipple
              loading={isSaving}
              className={styles.saveButton}
              variant="text"
              onClick={handleSubmit}
            >
              {t('general.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Header>
      <Grid ref={formRef} item xs className={styles.eventContainer}>
        {isLoading ? (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={styles.formContainerLoading}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Stack gap={1} className={styles.formContainer}>
            <Box>
              <Box
                className={`${styles.formWrapper} ${styles.formField} ${styles.formField__fullWidth}`}
              >
                <UploadFileField
                  id="cover"
                  name="cover"
                  label={t('event.coverImageLabel')}
                  helperText={t('event.coverImageErrorText', {
                    width: 1125,
                    height: 632,
                  })}
                  value={formValue.cover.value}
                  error={formValue.cover.isError}
                  required={formValue.cover.isRequired}
                  accept="image/*"
                  limitSize={5 * 1000 * 1000}
                  onChange={(files) => {
                    handleFileFieldChange('cover', files);
                  }}
                  onRemove={(files) => {
                    handleInputFieldChange('cover', files);
                  }}
                  dimension={{ width: 1125, height: 632 }}
                  disableDimensionChecking
                  enableEdit={true}
                  optionsTitle={t('myProfile.editProfile.editCoverPhoto')}
                  disabled={editMode}
                />
              </Box>
              <Box className={styles.formWrapper}>
                {process.env.REACT_APP_LOCATION !== 'PHL' && (
                  <Box className={styles.formField}>
                    <LanguageDropdown
                      value={formValue.displayLanguage.value}
                      disabled={editMode}
                      onChange={handleChangeDisplayLanguage}
                    />
                  </Box>
                )}
                {formValue.displayLanguage.value.includes('en') && (
                  <>
                    <Box className={styles.formField}>
                      <InputField
                        id="nameEN"
                        name="nameEN"
                        placeholder={t('event.eventNameEnLabel')}
                        value={formValue.nameEN.value}
                        error={formValue.nameEN.isError}
                        required={formValue.nameEN.isRequired}
                        readOnly={editMode}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          handleInputFieldChange(name, value);
                        }}
                      />
                    </Box>
                    <Box className={styles.formField}>
                      <InputField
                        id="descriptionEN"
                        name="descriptionEN"
                        placeholder={t('event.descriptionEnLabel')}
                        value={formValue.descriptionEN.value}
                        error={formValue.descriptionEN.isError}
                        required={formValue.descriptionEN.isRequired}
                        readOnly={editMode}
                        multiline
                        helperText={
                          <Grid container flexWrap="nowrap">
                            {formValue.descriptionEN.isError && (
                              <Grid item xs="auto" flexShrink={1}>
                                <Typography variant="body2" align="left">
                                  {t(
                                    'event.descriptionWordCountOverHelperText'
                                  )}
                                </Typography>
                              </Grid>
                            )}
                            <Grid item xs>
                              <Typography variant="body2" align="right">
                                {`${formValue.descriptionEN.value.length}/${descriptionLimit}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                        onChange={(e) => {
                          const { name, value } = e.target;
                          handleTextareaFieldChange(
                            name,
                            value,
                            descriptionLimit
                          );
                        }}
                      />
                    </Box>
                  </>
                )}
                {formValue.displayLanguage.value.includes('tc') && (
                  <>
                    <Box className={styles.formField}>
                      <InputField
                        id="nameTC"
                        name="nameTC"
                        placeholder={t('event.eventNameTcLabel')}
                        value={formValue.nameTC.value}
                        error={formValue.nameTC.isError}
                        required={formValue.nameTC.isRequired}
                        readOnly={editMode}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          handleInputFieldChange(name, value);
                        }}
                      />
                    </Box>
                    <Box className={styles.formField}>
                      <InputField
                        id="descriptionTC"
                        name="descriptionTC"
                        placeholder={t('event.descriptionTcLabel')}
                        value={formValue.descriptionTC.value}
                        error={formValue.descriptionTC.isError}
                        required={formValue.descriptionTC.isRequired}
                        readOnly={editMode}
                        multiline
                        helperText={
                          <Grid container>
                            {formValue.descriptionTC.isError && (
                              <Grid item xs="auto" flexShrink={1}>
                                <Typography variant="body2" align="left">
                                  {t(
                                    'event.descriptionWordCountOverHelperText'
                                  )}
                                </Typography>
                              </Grid>
                            )}
                            <Grid item xs>
                              <Typography variant="body2" align="right">
                                {`${formValue.descriptionTC.value.length}/${descriptionTcLimit}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                        onChange={(e) => {
                          const { name, value } = e.target;
                          handleTextareaFieldChange(
                            name,
                            value,
                            descriptionTcLimit
                          );
                        }}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box className={styles.formWrapper}>
              <Box className={styles.formField}>
                <DateTimePickerField
                  id="eventDate"
                  name="eventDate"
                  label={t('event.eventDateLabel')}
                  popupLabel={t('event.eventsScheduleTitle')}
                  startDate={formValue.eventDate.value.startDate}
                  endDate={formValue.eventDate.value.endDate}
                  error={formValue.eventDate.isError}
                  required={formValue.eventDate.isRequired}
                  disabled={editMode}
                  disableEndDateControl
                  startAdornment={
                    <img src="/assets/images/eventIcon_calendar.svg" alt="" />
                  }
                  helperText={
                    formValue.eventDate.isError
                      ? t('event.eventDateErrorText')
                      : undefined
                  }
                  // eslint-disable-next-line
                  onChange={(value: any) => {
                    handleInputFieldChange('eventDate', value);
                  }}
                  spacialTimeOptions={{
                    minutes: Array.from(Array(60), (_, index) => ({
                      id: moment(index, 'm').format('mm'),
                      value: moment(index, 'm').format('mm'),
                    })),
                  }}
                />
              </Box>
              <Box className={styles.formField}>
                <AutoCompleteField
                  id="location"
                  name="location"
                  label={t('event.locationLabel')}
                  disableLabel
                  disableActions
                  value={formValue.location.value}
                  error={formValue.location.isError}
                  required={formValue.location.isRequired}
                  disabled={editMode}
                  freeText
                  startAdornment={
                    <img
                      src="/assets/images/eventIcon_location_pin.svg"
                      alt=""
                    />
                  }
                  getOptionValue={(value) => value.id}
                  getOptionLabel={(value) => value.address}
                  filterKey={['name', 'address']}
                  onChange={(value) => {
                    handleInputFieldChange('location', value);
                  }}
                  optionsItem={(option, id) => (
                    <ListItemText
                      id={id}
                      primary={option.name}
                      secondary={option.address}
                    />
                  )}
                  listFetch={getStoresList}
                  listFetchKey="stores"
                  convertResponse={convertStoreList}
                />
              </Box>
              <Box className={styles.formField}>
                <SelectField
                  id="isPublic"
                  name="isPublic"
                  label={t('event.invitationScopeLabel')}
                  disableLabel
                  value={formValue.isPublic.value}
                  error={formValue.isPublic.isError}
                  required={formValue.isPublic.isRequired}
                  disableOptions={editMode}
                  options={invitationScopeList.filter((item) => !item.disable)}
                  isOptionReverse={true}
                  getOptionValue={(value) => value.id}
                  getOptionLabel={(value) => value.name}
                  getOptionDisplay={(value) => {
                    return value.id === 'private'
                      ? getInvitationScopeListName(
                          formValue.invitationScope.value
                        )
                      : value.name;
                  }}
                  getOptionDesc={(value) => value.description}
                  replaceOptionDesc={optionDesc ? optionDesc : ''}
                  onChange={(value) => {
                    const inviteScope = formValue.invitationScope.value;
                    handleInputFieldChange('isPublic', value, [
                      {
                        name: 'isPublic',
                        error:
                          value.id === 'private' &&
                          inviteScope.group.length === 0 &&
                          inviteScope.individuals.length === 0,
                      },
                    ]);
                  }}
                  handleOptionClick={(value) => {
                    if (value.isNextStep) {
                      setShowInvitationPopup(true);
                    }
                  }}
                  startAdornment={
                    <img src="/assets/images/eventIcon_user_plus.svg" alt="" />
                  }
                />
                <InvitationScopeField
                  type="hidden"
                  id="invitationScope"
                  name="invitationScope"
                  label={t('event.invitationScopeLabel')}
                  value={formValue.invitationScope.value}
                  error={formValue.invitationScope.isError}
                  required={formValue.invitationScope.isRequired}
                  startAdornment={
                    <img src="/assets/images/eventIcon_user_plus.svg" alt="" />
                  }
                  onChange={(value) => {
                    handleInputFieldChange('invitationScope', value);
                  }}
                  groupOptionsFetch={getInvitationGroupList}
                  groupOptionsFetchKey={
                    process.env.REACT_APP_LOCATION !== 'TWN'
                      ? 'storeInfo.area'
                      : 'storeInfo.district'
                  }
                  groupOptionsIdKey="value"
                  individualsOptionsFetch={getStaffList}
                  individualsOptionsFetchKey="staffList"
                  individualsOptionsIdKey="partnerId"
                  individualsFilterFetch={getStaffFilterList}
                  handleOpen={showInvitationPopup}
                  handleHiddenValue={getSubmitData}
                  handleHiddenClose={getIsClosed}
                  lockSelected={lockInvitationScope}
                />
              </Box>
              <Box className={styles.formField}>
                <AutoCompleteField
                  id="eventAdmin"
                  name="eventAdmin"
                  label={t('event.eventAdminLabel')}
                  popupLabel={t('event.selectEventAdminTitle')}
                  multiple
                  disableLabel
                  value={formValue.eventAdmin.value}
                  error={formValue.eventAdmin.isError}
                  required={formValue.eventAdmin.isRequired}
                  startAdornment={
                    <img src="/assets/images/eventIcon_admin.svg" alt="" />
                  }
                  getOptionValue={(value) => value.id}
                  getOptionLabel={(value) => value.name}
                  onChange={(value) => {
                    handleInputFieldChange('eventAdmin', value);
                  }}
                  optionsItem={(option, id, classes) => (
                    <SelectAdminCard {...option} id={id} classes={classes} />
                  )}
                  disableSearchCancel
                  listFetch={getEventAdmins}
                  {...(groupId && { groupId: groupId })}
                  listFetchKey="eventAdmins"
                  convertResponse={convertStaffList}
                />
              </Box>
            </Box>
            <Box className={styles.formWrapper}>
              <Box className={styles.formToggleField}>
                <ToggleButton
                  label={t('event.externalLinkLabel')}
                  checked={formValue.externalLink.value}
                  setChecked={(checked: boolean) => {
                    handleToggleButtonChange('externalLink', checked);
                  }}
                />
              </Box>
              {formValue.externalLink.value && (
                <>
                  <Box className={styles.formField}>
                    <InputField
                      id="externalLinkUrl"
                      name="externalLinkUrl"
                      placeholder={t('event.urlLabel')}
                      value={formValue.externalLinkUrl.value}
                      error={formValue.externalLinkUrl.isError}
                      required={formValue.externalLinkUrl.isRequired}
                      startAdornment={
                        formValue.externalLinkUrl.value !== '' ? (
                          <img src="/assets/images/eventIcon_link.svg" alt="" />
                        ) : undefined
                      }
                      onChange={(e) => {
                        const { name, value } = e.target;
                        handleInputFieldChange(name, value);
                      }}
                    />
                  </Box>
                  <Box className={styles.formField}>
                    <InputField
                      id="additionalInformation"
                      name="additionalInformation"
                      placeholder={t('event.additionalInformationLabel')}
                      value={formValue.additionalInformation.value}
                      error={formValue.additionalInformation.isError}
                      required={formValue.additionalInformation.isRequired}
                      startAdornment={
                        formValue.additionalInformation.value !== '' ? (
                          <img
                            src="/assets/images/eventIcon_circle_information.svg"
                            alt=""
                          />
                        ) : undefined
                      }
                      multiline
                      onChange={(e) => {
                        const { name, value } = e.target;
                        handleInputFieldChange(name, value);
                      }}
                    />
                  </Box>
                </>
              )}
              <Box className={styles.formToggleField}>
                <ToggleButton
                  label={t('event.allowAttendanceWithQrCodeLabel')}
                  disabled={editMode}
                  checked={formValue.allowAttendanceWithQrCode.value}
                  setChecked={(checked: boolean) => {
                    handleToggleButtonChange(
                      'allowAttendanceWithQrCode',
                      checked
                    );
                  }}
                />
              </Box>
              <Box className={styles.formToggleField}>
                <ToggleButton
                  label={t('event.eventSurveyLabel')}
                  checked={formValue.eventSurvey.value}
                  setChecked={(checked: boolean) => {
                    handleToggleButtonChange('eventSurvey', checked);
                  }}
                />
              </Box>
              {formValue.eventSurvey.value && (
                <Box className={styles.formField}>
                  <SurveyField
                    id="selectSurvey"
                    name="selectSurvey"
                    label={t('event.selectSurveyLabel')}
                    disableLabel
                    value={formValue.selectSurvey.value}
                    error={formValue.selectSurvey.isError}
                    required={formValue.selectSurvey.isRequired}
                    startAdornment={
                      <img
                        src="/assets/images/eventIcon_file_pencil_alt.svg"
                        alt=""
                      />
                    }
                    onChange={(value) => {
                      handleInputFieldChange('selectSurvey', value);
                    }}
                    onRemove={() => {
                      handleInputFieldChange('selectSurvey', null);
                    }}
                  />
                </Box>
              )}
              <Box className={styles.formField}>
                <UploadFileField
                  id="attachments"
                  name="attachments"
                  placeholder={t('event.addAttachmentsLabel')}
                  helperText={t('event.maxAttachmentsAnsSize', {
                    limit: 10,
                    size: formatFileSize(10 * 1000 * 1000), //10MB
                  })}
                  inputFieldType="input"
                  limitCount={10}
                  limitSize={10 * 1000 * 1000} //10MB
                  multiple
                  value={formValue.attachments.value}
                  error={formValue.attachments.isError}
                  required={formValue.attachments.isRequired}
                  onChange={(files) => {
                    handleFileFieldChange('attachments', files);
                  }}
                  onRemove={(files) => {
                    handleInputFieldChange('attachments', files);
                  }}
                />
              </Box>
            </Box>
            <Box className={styles.formWrapper}>
              <Box className={styles.formField}>
                <SelectField
                  id="category"
                  name="category"
                  label={t('event.categoryLabel')}
                  placeholder={t('event.pleaseSelect')}
                  value={formValue.category.value}
                  error={formValue.category.isError}
                  required={formValue.category.isRequired}
                  disabled={editMode}
                  options={dropdownList.categoryList}
                  getOptionValue={(value) => value.value}
                  getOptionLabel={(value) => value.name}
                  onChange={(value) => {
                    handleInputFieldChange('category', value);
                  }}
                />
              </Box>
              <Box className={styles.formField}>
                <DateTimePickerField
                  id="registrationPeriod"
                  name="registrationPeriod"
                  label={t('event.registrationPeriodLabel')}
                  startDate={formValue.registrationPeriod.value.startDate}
                  endDate={formValue.registrationPeriod.value.endDate}
                  error={formValue.registrationPeriod.isError}
                  required={formValue.registrationPeriod.isRequired}
                  disableStartDate={editMode}
                  helperText={
                    formValue.registrationPeriod.isError
                      ? registrationPeriodError
                      : undefined
                  }
                  // eslint-disable-next-line
                  onChange={(value: any) => {
                    handleInputFieldChange('registrationPeriod', value);
                  }}
                />
              </Box>
              <Box className={styles.formField}>
                <SelectField
                  id="maxCapacity"
                  name="maxCapacity"
                  label={t('event.maxCapacityLabel')}
                  placeholder={t('event.pleaseSelect')}
                  value={formValue.maxCapacity.value}
                  error={
                    formValue.maxCapacity.isError ||
                    formValue.maxCapacityLimit.isError
                  }
                  required={formValue.maxCapacity.isRequired}
                  options={maxCapacityList}
                  disableOptions={editMode}
                  getOptionValue={(value) => value.value}
                  getOptionLabel={(value) => value.name}
                  onChange={(value) => {
                    handleInputFieldChange('maxCapacity', value, [
                      {
                        name: 'maxCapacityLimit',
                        error:
                          value.value === 'LIMIT_TO' &&
                          Number(formValue.maxCapacityLimit.value) <
                            Number(maxCapacityLimit),
                      },
                    ]);
                  }}
                  otherFieldValue="LIMIT_TO"
                  otherFieldProps={{
                    id: 'maxCapacityLimit',
                    name: 'maxCapacityLimit',
                    type: 'number',
                    min: maxCapacityLimit,
                    placeholder: t('event.estimatedNumbersOfAttendee'),
                    value: formValue.maxCapacityLimit.value,
                    error: formValue.maxCapacityLimit.isError,
                    required: true,
                    onChange: (e) => {
                      const { name, value } = e.target;
                      handleInputFieldChange(name, value, [
                        {
                          name: 'maxCapacityLimit',
                          error: Number(value) < Number(maxCapacityLimit),
                        },
                      ]);
                    },
                  }}
                />
              </Box>
              <Box className={styles.formToggleField}>
                <ToggleButton
                  label={t('event.sendReminderLabel')}
                  // disabled={editMode}
                  checked={formValue.sendReminder.value}
                  setChecked={(checked: boolean) => {
                    handleToggleButtonChange('sendReminder', checked);
                  }}
                />
              </Box>
            </Box>
          </Stack>
        )}
        <Snackbar
          open={failMsg.open}
          autoHideDuration={6000}
          onClose={() => setFailMsg((prev) => ({ ...prev, open: false }))}
        >
          <Alert severity={failMsg.status as AlertColor}>
            {failMsg.message}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default EventCreate;
