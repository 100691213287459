import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import styles from "./datetime-picker-field.module.scss";

interface ControlEndDateTimeButtonProps {
  isAdd: boolean;
  onAddField: () => void;
  onRemoveField: () => void;
}

const ControlEndDateTimeButton: FC<ControlEndDateTimeButtonProps> = (props) => {
  const { t } = useTranslation();
  const buttonOption = {
    id: props.isAdd ? "addEndDateButton" : "removeEndDateButton",
    component: "button",
    className: styles.addEndDateButton,
    onClick: () => (props.isAdd ? props.onAddField() : props.onRemoveField()),
  };
  const buttonLabel = props.isAdd
    ? t("event.addEndDate")
    : t("event.removeEndDate");

  return (
    <Link variant="body2" {...buttonOption}>
      {props.isAdd ? (
        <img src="/assets/images/plus.svg" alt="" />
      ) : (
        <img src="/assets/images/minus.svg" alt="" />
      )}
      {buttonLabel}
    </Link>
  );
};

export default ControlEndDateTimeButton;
