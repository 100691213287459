import * as React from "react";
import { Tabs, Tab, Box, TableContainerTypeMap } from "@mui/material";
import styles from "./tab.module.scss";

interface TabItemProps {
  value: string;
  display?: string;
  disable?: boolean;
}
interface CustomTabProps {
  data: Array<TabItemProps>;
  type?: string;
  activeTab: string;
  onTabClick: (active: string) => void;
}
export default function CustomTab(props: CustomTabProps) {
  const { data, type, activeTab, onTabClick } = props;
  const [value, setValue] = React.useState(data[0].value);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    onTabClick(newValue);
  };

  return (
    <Box className={styles.tabContainer}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        {data.map((item, index) => {
          if (!item.disable) {
            return (
              <Tab
                key={index}
                value={item.value}
                label={item.display ? item.display : item.value}
                sx={{
                  width:
                    type === "fullWidth"
                      ? `calc(100% / ${data.length})`
                      : "auto",
                  minWidth: "auto",
                }}
              />
            );
          }
        })}
      </Tabs>
    </Box>
  );
}
