import React, { FC } from "react";
import { Link } from "@mui/material";
import styles from "./datetime-picker-field.module.scss";

interface DateButtonProps {
  id: string;
  value: string;
  active: boolean;
  disabled: boolean;
  onClick: () => void;
}

const DateButton: FC<DateButtonProps> = React.memo((props) => {
  const { value, active, ...btnProps } = props;

  return (
    <Link
      {...btnProps}
      variant="body1"
      component="button"
      className={`${styles.dateButton} ${active ? styles.active : ""}`}
    >
      {value}
    </Link>
  );
});

export default DateButton;
