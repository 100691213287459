import { useId, FC } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type QuestionFooterDragAndDropProps<T> = {
  items: T[];
  onOrderChange: (items: T[], result: DropResult) => void;
  render: FC<T>;
  direction?: "horizontal" | "vertical";
  droppableClassName?: string;
};

export const QuestionFooterDragAndDrop = <T extends Record<string, any>>(
  props: QuestionFooterDragAndDropProps<T>
) => {
  const { items, onOrderChange, render, direction, droppableClassName } = props;
  const id = useId();

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    onOrderChange(newItems, result);
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable-${id}`} direction={direction}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={droppableClassName}
          >
            {items.map((item, index) => (
              <Draggable
                key={`question-footer-${index}`}
                draggableId={`question-footer-${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {render(item, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
