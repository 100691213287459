import type {} from "@mui/material/themeCssVarsAugmentation";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import theme from "../../theme";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { PollOptionsProps } from "../../interfaces/social-wall-interface";
import styles from "./poll-button.module.scss";
import { Box, Typography } from "@mui/material";
import { pollVote } from "../../services/social-wall";
import { t } from "i18next";

interface PollButtonProps {
  postID: string;
  data: any;
  stat: any;
  // data: Array<PollOptionsProps>;
  voted: string;
  disabled?: boolean;
  isPictureChoice?: boolean;
}
function PollButton(props: PollButtonProps) {
  const {
    postID,
    data,
    stat,
    voted,
    disabled,
    isPictureChoice = false,
  } = props;
  const [selected, setSelected] = useState<string>("");
  const [totalVote, setTotalVote] = useState<number>(0);
  const [isVoting, setIsVoting] = useState<boolean>(false);

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: "100%",
    height: 20,
    border: `1px solid ${theme.vars.palette.black.opacity26}`,
    ".Mui-focusVisible &": {},
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
    "&:before": {
      display: "block",
      width: "100%",
      height: 14,
      margin: 3,
      borderRadius: "50%",
      backgroundColor: theme.vars.palette.white.opacity100,
      content: '""',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "transparent",
    border: `1px solid ${theme.vars.palette.green.starbucks}`,
    "&:before": {
      display: "block",
      width: 14,
      height: 14,
      margin: 3,
      borderRadius: "50%",
      backgroundColor: theme.vars.palette.green.starbucks,
      content: '""',
    },
  });

  function BpRadio(props: RadioProps) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  useEffect(() => {
    setTotalVote(0);
    if (stat) {
      data.forEach((item: any) => {
        setTotalVote((prev) => prev + stat[item.seq]);
      });
    }

    if (voted) {
      setSelected(voted);
    }
  }, []);

  const handleVote = (event: any) => {
    if (!isVoting) {
      setIsVoting(true);
      pollVote(postID, event.target.defaultValue).then((res) => {
        setSelected(event.target.defaultValue);
        setIsVoting(false);
      });
    }
  };

  return (
    <RadioGroup
      className={styles.radioBtnContainter}
      defaultValue="female"
      aria-labelledby="demo-customized-radios"
      name="customized-radios"
    >
      {data &&
        data.map((item: any, index: number) => (
          <Box
            key={index}
            className={`${styles.radioBtnWrapper} ${
              selected === item.seq ? styles.active : ""
            }`}
          >
            <FormControlLabel
              style={{ margin: 0 }}
              value={item.seq}
              disabled={disabled}
              control={<BpRadio />}
              label={
                <div className={styles.voteLabel}>
                  {isPictureChoice && (
                    <div
                      className={styles.imageContainer}
                      style={{ aspectRatio: "4/3" }}
                    >
                      <img
                        src={
                          item.imageUrl ?? "/assets/images/survey_answer.svg"
                        }
                        {...(item.imageUrl && { className: styles.voteImage })}
                        alt={item.voteName}
                      />
                    </div>
                  )}
                  {item.voteName}
                </div>
              }
              onChange={(event) => {
                handleVote(event);
              }}
            />
            {stat && (
              <Typography className={styles.votes} variant="body2">
                {stat[item.seq] +
                  (selected === item.seq && selected !== voted
                    ? 1
                    : voted === item.seq && selected !== item.seq
                    ? -1
                    : 0)}{" "}
                {t("socialWall.poll.vote")}
                <span>
                  {totalVote === 0 && !voted && selected === ""
                    ? 0
                    : Math.round(
                        ((stat[item.seq] +
                          (selected === item.seq && selected !== voted
                            ? 1
                            : voted === item.seq && selected !== item.seq
                            ? -1
                            : 0)) /
                          (totalVote + (!voted && selected !== "" ? 1 : 0))) *
                          100
                      )}
                  %
                </span>
              </Typography>
            )}
          </Box>
        ))}
    </RadioGroup>
  );
}

export default PollButton;
