import React, { FC, useState, useEffect } from "react";
import { Stack, Grid, Typography } from "@mui/material";
import moment from "moment";
import styles from "./datetime-picker-field.module.scss";
import { useTranslation } from "react-i18next";
import Picker from "react-mobile-picker";
import { ObjectKey } from "../../interfaces/common-interface";

interface TimePickerProps {
  value: string;
  onChange: (value: string) => void;
  spacialTimeOptions?: {
    hours?: ObjectKey[];
    minutes?: ObjectKey[];
    meridiem?: ObjectKey[];
  };
}

const hours = Array.from(Array(12), (_, index) => ({
  id: moment(index + 1, "H").format("hh"),
  value: moment(index + 1, "H").format("hh"),
}));

const minutes = [
  {
    id: "00",
    value: "00",
  },
  {
    id: "30",
    value: "30",
  },
];

const meridiem = [
  {
    id: "AM",
    value: "AM",
  },
  {
    id: "PM",
    value: "PM",
  },
];

const TimePicker: FC<TimePickerProps> = React.memo((props) => {
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState<{
    hour: string;
    minute: string;
    meridiem: string;
  }>({
    hour: "12",
    minute: "00",
    meridiem: "PM",
  });

  const optionGroups = {
    hour: props.spacialTimeOptions?.hours
      ? props.spacialTimeOptions.hours.map((item) => item.value)
      : hours.map((item) => item.value),
    minute: props.spacialTimeOptions?.minutes
      ? props.spacialTimeOptions.minutes.map((item) => item.value)
      : minutes.map((item) => item.value),
    meridiem: props.spacialTimeOptions?.meridiem
      ? props.spacialTimeOptions.meridiem.map((item) => item.value)
      : meridiem.map((item) => item.value),
  };

  const handleWheelPickerChange = (name: string, value: string) => {
    const updatedTimeObject = {
      ...selectedTime,
      [name]: value,
    };

    let updateHour = parseInt(updatedTimeObject.hour);

    if (parseInt(updatedTimeObject.hour) === 12) {
      if (updatedTimeObject.meridiem === "AM") {
        updateHour = updateHour - 12;
      }
    } else {
      if (updatedTimeObject.meridiem === "PM") {
        updateHour = updateHour + 12;
      }
    }
    const updatedTime = `${updateHour}:${updatedTimeObject.minute}`;
    // const updatedTime = `${parseInt(updatedTimeObject.hour)}:${
    //   updatedTimeObject.minute
    // }`;
    // let active = moment(props.value).format("h:mm") !== updatedTime;
    // let activeMeridiem =
    //   moment(props.value).format("A") !== updatedTimeObject.meridiem;

    setSelectedTime(updatedTimeObject);
    // active && activeMeridiem && props.onChange(updatedTime);
    // props.onChange(updatedTime + " " + updatedTimeObject.meridiem);
    props.onChange(updatedTime);
  };

  useEffect(() => {
    const momentValue = moment(props.value);

    setSelectedTime({
      hour: momentValue.format("hh"),
      minute: momentValue.format("mm"),
      meridiem: parseInt(momentValue.format("H")) < 12 ? "AM" : "PM",
    });
  }, [props.value]);

  return (
    <Stack className={styles.timePickerContainer}>
      <Typography>{t("event.setTime")}</Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={styles.timePickerWrapper}
      >
        <Picker
          optionGroups={optionGroups}
          valueGroups={selectedTime}
          onChange={handleWheelPickerChange}
          wheel="normal"
          height={153}
          itemHeight={32}
        />
      </Grid>
    </Stack>
  );
});

export default TimePicker;
