const MuiYearCalendar = {
  styleOverrides: {
    root: {
      width: "100%",
      maxHeight: "208px",
    },
  },
};

export default MuiYearCalendar;
