import { AxiosPromise } from "axios";
import { instance, searchInstance } from "./api-services";
import { removeApiCacheSession } from "../utility";
// import { ObjectKey } from "../interfaces/common-interface";

interface objectDetailsProps {
  lang: string;
  value: string;
}
export interface SurveyListRequestType {
  tab: string;
  page: number;
  pageSize: number;
}
export const postCreateSurvey = (input: {
  displayLanguage: Array<string>;
  surveyTitle: Array<objectDetailsProps>;
  surveyDescription: Array<objectDetailsProps>;
  respondents: Array<any>;
  allowAllPartners: Boolean;
  anonymousResponses: Boolean;
  questions: Array<any>;
  surveyStartDate: number;
  surveyEndDate: number;
  coverImageName: string;
  coverImagePath: string;
  coverImageExtension: string;
  thumbName: string;
  thumbExtension: string;
  thumbPath: string;
}): AxiosPromise => {
  removeApiCacheSession("getSurvey");
  return instance({
    method: "POST",
    url: `/event-roster-and-schedule/survey`,
    data: input,
  });
};

export const postEditSurvey = (
  id: string,
  input: {
    displayLanguage: Array<string>;
    surveyTitle: Array<objectDetailsProps>;
    surveyDescription: Array<objectDetailsProps>;
    respondents: Array<any>;
    allowAllPartners: Boolean;
    anonymousResponses: Boolean;
    questions: Array<any>;
    surveyStartDate: number;
    surveyEndDate: number;
    coverImageName: string;
    coverImagePath: string;
    coverImageExtension: string;
    thumbName: string;
    thumbExtension: string;
    thumbPath: string;
  }
): AxiosPromise => {
  removeApiCacheSession("getSurvey");
  return instance({
    method: "POST",
    url: `/event-roster-and-schedule/survey/${id}`,
    data: input,
  });
};

export const postCreateSurveyAnswer = (input: {
  surveyId: string;
  answers: Array<any>;
}): AxiosPromise => {
  removeApiCacheSession("getSurvey");
  return instance({
    method: "POST",
    url: `/event-roster-and-schedule/survey/survey/answer`,
    data: input,
  });
};

export const deleteSurvey = (id: string): AxiosPromise => {
  removeApiCacheSession("getSurvey");
  return instance({
    method: "DELETE",
    url: `/event-roster-and-schedule/survey/${id}`,
  });
};

export const getSurveyList = (params: SurveyListRequestType): AxiosPromise =>
  searchInstance({
    method: "GET",
    id: `getSurveyList-${params.tab}-${params.page}-${params.pageSize}`,
    url: `/event-roster-and-schedule/survey/list`,
    params: params,
  });
export const getSurvey = (
  id: string,
  params: { isEdit: boolean }
): AxiosPromise =>
  instance({
    method: "GET",
    // id: `getSurveyList-details-${id}`,
    cache: false,
    url: `/event-roster-and-schedule/survey/${id}`,
    params: params,
  });

export const getSurveyTemplateList = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getSurveyTemplateList",
    url: `/event-roster-and-schedule/survey/template`,
  });

export const getSurveyTemplate = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getSurveyTemplate-details-${id}`,
    url: `/event-roster-and-schedule/survey/template/${id}`,
    data: {
      page: "1",
      pageSize: "10",
    },
  });

export const getSurveyQuestionType = (): AxiosPromise =>
  instance({
    method: "GET",
    id: "getSurveyQuestionType",
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `/event-roster-and-schedule/survey/questionType`,
  });
export const CreateSurveyCoverImage = (file: any): AxiosPromise =>
  instance({
    method: "POST",
    url: `/event-roster-and-schedule/survey/survey/coverImage?image`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: file,
  });

export const createQuestionAnswerImage = (image: File): AxiosPromise => {
  const formData = new FormData();
  formData.append("image", image, image.name);
  return instance({
    method: "POST",
    url: `/event-roster-and-schedule/survey/survey/questionAnswerImage`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};
