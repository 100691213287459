import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import {
  Avatar,
  AvatarGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./partnerNetwork.module.scss";
import GroupDetail from "./components/groupDetail";
import MemberListPopup from "./components/memberListPopup";

type Props = {};

const PartnerNetworkAbout = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [isMemberListOpen, setMemberListOpen] = useState(false);
  const [data, setData] = useState<any>(null!);
  const { groupId } = useParams();
  const aboutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && aboutRef.current) {
      if (aboutRef?.current?.clientHeight >= 80) {
        setShowReadMore(true);
        setIsReadMore(true);
      }
    }
  }, [data]);

  let MemberListAvatarGroup: any = [];
  if (data?.memberCount > data?.members.length) {
    MemberListAvatarGroup = [
      ...data.members,
      ...Array(data.memberCount - data.members.length),
    ];
  } else {
    MemberListAvatarGroup = data?.members;
  }

  return (
    <>
      <div className={styles.detailPage} id="postListContainer">
        {groupId && (
          <GroupDetail
            id={groupId}
            closeButtonNavigation={`/partner-network/detail/${groupId}`}
            headerButton={
              <IconButton
                aria-label="Plus"
                sx={{ padding: "0px" }}
                onClick={() =>
                  navigate(`/partner-network/create-post/${groupId}`)
                }
              >
                <img src="/assets/images/toolbar_plus_black.svg" alt="" />
              </IconButton>
            }
            passDetail={(detail: any) => {
              // console.log("detail", detail);
              setData(detail);
            }}
          />
        )}
        <Grid
          container
          justifyContent="space-between"
          style={{ padding: "12px 16px" }}
        >
          <div className={styles.pageTitle}>{t("partnerNetwork.about")}</div>
        </Grid>
        <div className={styles.about}>
          <div
            className={`${styles.aboutContent}${
              showReadMore && !isReadMore ? ` ${styles.more}` : ""
            }`}
            ref={aboutRef}
          >
            {data?.about}
          </div>
          {showReadMore && (
            <button
              className={styles.button}
              role="button"
              onClick={() => {
                setIsReadMore(!isReadMore);
              }}
            >
              {isReadMore ? t("general.readMore") : t("general.readLess")}
            </button>
          )}
        </div>
        <div className={styles.members}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: "12px" }}
          >
            <div className={styles.pageTitle}>
              {t("partnerNetwork.members")}
            </div>
            <button
              className={styles.button}
              role="button"
              onClick={() => setMemberListOpen(true)}
            >
              {t("event.viewAll")}
            </button>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            {data && (
              <AvatarGroup max={5} className={styles.memberList}>
                {MemberListAvatarGroup.map((member: any, index: number) => (
                  <Avatar
                    key={`member${index}-${member?.partnerId}`}
                    alt={member?.userName}
                    src={member?.userProfilePictureUrl}
                  />
                ))}
              </AvatarGroup>
            )}
            <Typography variant="body1" align="right">
              {data?.memberCount}
            </Typography>
          </Grid>
        </div>
      </div>
      {groupId && (
        <MemberListPopup
          isOpen={isMemberListOpen}
          setIsOpen={setMemberListOpen}
          groupId={groupId}
        />
      )}
    </>
  );
};

export default PartnerNetworkAbout;
