import { FC, useEffect, useRef, useState } from "react";
import styles from "./firstLogin.module.scss";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Popup, RoundButton } from "../../components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { acceptTnc, postUserLogout } from "../../services/api-services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState, setUserState } from "../../reducers/user-slice";
import toHtml from "html-react-parser";
import { handleLogoutAction } from "../../utility";
const FirstLogin: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tAnsCContentRef = useRef<HTMLDivElement>(null);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [openTAndCPopup, setOpenTAndCPopup] = useState<boolean>(false);
  const [disableAcceptButton, setDisableAcceptButton] = useState<boolean>(true);
  const stepContentRef = useRef<HTMLDivElement>(null);

  const introductionSlideData = [
    {
      image: "/assets/images/onboarding_socialWall.png",
      title: "login.introTitle1",
      description: "login.introDesc1",
    },
    {
      image: "/assets/images/onboarding_event.png",
      title: "login.introTitle2",
      description: "login.introDesc2",
    },
    {
      image: "/assets/images/onboarding_beansEcosystem.png",
      title: "login.introTitle3",
      description: "login.introDesc3",
    },
  ];

  const goToNextPage = () => {
    if (page < 2) {
      stepContentRef.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setPage((prev) => prev + 1);
    } else {
      // window.location.href = `/home?lang=${i18n.language}`;
      // navigate(`/home?lang=${i18n.language}`);
      navigate(`/home`);
    }
  };

  const handleCloseTAndCPopup = (close: boolean) => {
    postUserLogout();
    setOpenTAndCPopup(close);
    handleLogoutAction();
  };

  const handleAcceptClick = async () => {
    try {
      const response = await acceptTnc();
      if (response.status === 200) {
        dispatch(setUserState({ ...userState, isAcceptedTnc: true }));
        setOpenTAndCPopup(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleScrollTAndC = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight === scrollHeight) {
      setDisableAcceptButton(false);
    }
  };

  useEffect(() => {
    if (!userState.isAcceptedTnc) {
      setOpenTAndCPopup(true);
    }
  }, [userState]);

  setTimeout(() => {
    document
      .querySelector("#tAnsCContentContainer")
      ?.addEventListener("scroll", () => {
        if (tAnsCContentRef.current) {
          const { scrollTop, scrollHeight, clientHeight } =
            tAnsCContentRef.current;

          if (scrollTop + clientHeight + 10 >= scrollHeight) {
            setDisableAcceptButton(false);
          }
        }
      });
  }, 300);

  useEffect(() => {
    if (tAnsCContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tAnsCContentRef.current;

      if (scrollTop + clientHeight + 10 >= scrollHeight) {
        setDisableAcceptButton(false);
      }
    }
  }, [tAnsCContentRef, openTAndCPopup]);

  return (
    <Grid
      container
      item
      xs
      flexDirection="column"
      flexWrap="nowrap"
      className={styles.introductionWrapper}
    >
      {userState.isAcceptedTnc && (
        <>
          <Grid
            ref={stepContentRef}
            container
            item
            xs
            flexDirection="column"
            alignItems="center"
            className={styles.introductionPage}
          >
            <Box className={styles.introductionItem}>
              <img
                className={styles.introductionImage}
                src={introductionSlideData[page].image}
                alt=""
              />
              <Box className={styles.pagination}>
                <Box
                  className={`${styles.dot} ${page === 0 ? styles.active : ""}`}
                ></Box>
                <Box
                  className={`${styles.dot} ${page === 1 ? styles.active : ""}`}
                ></Box>
                <Box
                  className={`${styles.dot} ${page === 2 ? styles.active : ""}`}
                ></Box>
              </Box>
              <Typography className={styles.title} variant="h3" align="center">
                {t(introductionSlideData[page].title)}
              </Typography>
              <Typography
                className={styles.description}
                variant="body1"
                align="center"
              >
                {t(introductionSlideData[page].description)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs="auto" className={styles.nextBtnContainer}>
            <RoundButton onClick={goToNextPage}>
              {t("general.next")}
            </RoundButton>
          </Grid>
        </>
      )}
      <Popup
        id="TAndCPopup"
        isOpen={openTAndCPopup}
        setIsOpen={(close: boolean) => handleCloseTAndCPopup(close)}
        content={
          <Stack className={styles.popupContainer}>
            <Grid item xs="auto" className={styles.popupHeader}>
              <IconButton
                id={"inboxMsgPopupCloseButton"}
                disableRipple
                className={styles.button}
                onClick={() => handleCloseTAndCPopup(false)}
              >
                <img src="/assets/images/close_btn.svg" alt="" />
              </IconButton>
            </Grid>
            <Grid item xs className={styles.popupContent}>
              <Box
                id="tAnsCContentContainer"
                ref={tAnsCContentRef}
                className={styles.popupDescription}
                onScroll={handleScrollTAndC}
              >
                <Typography
                  className={styles.title}
                  variant="h3"
                  align="center"
                >
                  {t("login.termsAndConditions")}
                </Typography>
                <Typography className={styles.description} variant="body1">
                  {toHtml(`${t("login.termsAndConditionsDesc")}`)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs="auto" className={styles.popupButton}>
              <RoundButton
                disabled={disableAcceptButton}
                onClick={handleAcceptClick}
              >
                {t("login.acceptTerms")}
              </RoundButton>
            </Grid>
          </Stack>
        }
        fullScreen
        disableActions
      />
    </Grid>
  );
};

export default FirstLogin;
