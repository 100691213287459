import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./partnerNetwork.module.scss";
import FilesMediaPopup, {
  PopupProps,
} from "../../components/files-media-popup";
import { NoResults } from "../../components";
import { getGroupGallery } from "../../services/social-wall";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from "@mui/material";
type Props = {};

const PartnerNetworkGallery = (props: Props) => {
  const { groupId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null!);
  const [isMediaOpen, setMediaOpen] = useState(false);
  const [popupDetail, setPopupDetail] = useState<PopupProps>({
    type: "",
    content: null,
  });
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 21,
    }
  );
  useEffect(() => {
    setIsLoading(true);
    const getDetail = async (groupId: string) => {
      try {
        const response = await getGroupGallery({
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
          partnerNetworkGroupId: groupId,
        });
        if (response.status === 200 && response.data.posts.length > 0) {
          // console.log("response", response);
          setData(response.data.posts);
          if (
            apiPagination.page >= response.data.totalPage ||
            !response.data.totalPage
          ) {
            setIsEnded(true);
          } else {
            setApiPagination({ page: apiPagination.page + 1 });
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };
    groupId && getDetail(groupId);
  }, []);

  async function fetchMoreData() {
    try {
      const response = await getGroupGallery({
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
        partnerNetworkGroupId: groupId,
      });
      if (response.status === 200 && response.data.posts.length > 0) {
        // console.log("response", response);
        setData((prev: any) => [...prev, ...response.data.posts]);
        if (apiPagination.page >= response.data.totalPage) {
          setIsEnded(true);
        } else {
          setApiPagination({ page: apiPagination.page + 1 });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const handleGalleryClick = async (data: any) => {
    // console.log("postId", data);
    setMediaOpen(true);
    setPopupDetail({
      type: data.type === "IMAGE" ? "image" : "video",
      content: {
        file: {
          extension: data.fileExtension,
          name: data.fileName,
          image: data.fileUrl,
          video: data.fileUrl,
          type: data.type.toLowerCase(),
          postId: data.postId,
        },
      },
    });
  };

  return (
    <>
      <Header
        title={t("partnerNetwork.gallery")}
        enableBackButton
        closeButtonNavigation={`/partner-network/detail/${groupId}`}
      />
      <div className={styles.gallery}>
        {data && data.length > 0 ? (
          <>
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreData}
              hasMore={!isEnded}
              loader={<Skeleton height={40} width="100%" />}
              // scrollableTarget="scrollableDiv"
            >
              <div className={styles.galleryContainer}>
                {data.map((item: any, index: number) => {
                  return (
                    <div
                      key={`galleryItem-${index}`}
                      className={styles.galleryItem}
                      onClick={() => handleGalleryClick(item)}
                    >
                      <GalleryDisplay data={item} />
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </>
        ) : (
          <NoResults />
        )}
      </div>
      {isMediaOpen && (
        <FilesMediaPopup
          open={isMediaOpen}
          file={popupDetail}
          onClose={(close?: boolean) => setMediaOpen(close ?? false)}
          disableFileName
        />
      )}
    </>
  );
};

type GalleryDisplayProps = {
  data: any;
};

const GalleryDisplay = ({ data }: GalleryDisplayProps) => {
  // console.log(data);
  if (data.type === "IMAGE") {
    return <img src={data.fileUrl} alt={data.fileName} />;
  } else if (data.type === "VIDEO") {
    return (
      <div className={styles.videoContainer}>
        <video src={`${data.fileUrl}#t=0.001`} />
        <img src="/assets/images/video_playBtn.svg" alt="play" />
      </div>
    );
  } else {
    return null;
  }
};
export default PartnerNetworkGallery;
