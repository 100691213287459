import React from "react";
import "sendbird-uikit/dist/index.css";
import styles from "./chat.module.scss";
import { APP_ID, myColorSet, stringSet } from "./const";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";

import zhTW from "date-fns/locale/zh-TW/index";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState } from "../../reducers/user-slice";
import ChatRouter from "./ChatRouter";
import { BottomNavMenu } from "../../components";
import { useParams } from "react-router-dom";
import { getSendbirdConfig } from "../../services/common";

type Props = {};

const Chat = (props: Props) => {
  // console.log("APP_ID", APP_ID);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const { channelUrl } = useParams();
  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    const getConfig = async () => {
      try {
        const res = await getSendbirdConfig();
        // console.log("getConfig", res);
        if (res.status === 200 && res.data) {
          localStorage.setItem("SendBirdUserId", res.data.sendBirdUserId);
          setData(res.data);
        }
      } catch (error) {
        // console.log(error);
        throw error;
      }
    };
    getConfig();
  }, []);

  return (
    <div
      className={`${styles.ChatApp} ${
        !userState?.chat?.FileSharing ? styles.disableFileSharing : ""
      }`}
      {...(channelUrl && { style: { height: "100%" } })}
    >
      {data && (
        <SendbirdProvider
          accessToken={data.sendBirdAccessToken}
          colorSet={myColorSet}
          stringSet={stringSet}
          userId={data.sendBirdUserId}
          appId={APP_ID}
          nickname={data.sendBirdNickName}
          allowProfileEdit
          // dateLocale={zhTW}
          breakpoint={!window.location.pathname.endsWith("/settings")}
        >
          <ChatRouter />
        </SendbirdProvider>
      )}
      {!channelUrl && <BottomNavMenu />}
    </div>
  );
};

export default Chat;
