import * as React from "react";
import { Box, Card, CardContent, Skeleton } from "@mui/material";
import styles from "./event-card.module.scss";

export default function EventCardSkeleton(props: { size?: "small" | "large" }) {
  return (
    <Card
      className={`${styles.cardWrapper} ${styles.skeleton} ${
        styles["cardSize--" + props.size]
      }`}
    >
      <Skeleton
        className={styles.cardThumbnail}
        animation="wave"
        variant="rectangular"
      />
      <CardContent className={styles.cardContent}>
        <Box className={styles.eventDetails}>
          <Skeleton
            variant="text"
            animation="wave"
            height={props.size === "small" ? "20px" : 42}
            width="100%"
          />
          <Skeleton
            variant="text"
            animation="wave"
            height={props.size === "small" ? "16px" : 20}
            width="55%"
            className={styles.timeContainer}
          />
        </Box>
        <Skeleton
          variant="text"
          animation="wave"
          height={26}
          width="45%"
          className={styles.statusTag}
        />
      </CardContent>
    </Card>
  );
}
