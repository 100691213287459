import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Popup } from '../../components';
import Header from '../../components/header/header';
import NoResults from '../../components/no-results';
import SearchField from '../../components/search-field';

import { AttendeesType } from '../../interfaces/event-interface';
import { UserState } from '../../reducers/user-slice';
import { RootState } from '../../store/store';
import { convertDate, convertTime } from '../../utility';
import styles from './eventAttendeesList.module.scss';
import EventDetailContext from './eventDetailProvider';

interface FilterType {
  type: string | null;
  attendance: string | null;
}

const EventAttendeesList: FC = () => {
  const { detail, isLoading } = useContext(EventDetailContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [attendeesFullList, setAttendeesFullList] = useState<
    Array<AttendeesType>
  >([]);
  const [attendeesList, setAttendeesList] = useState<Array<AttendeesType>>([]);
  let { id, groupId } = useParams();

  const [open, setOpen] = useState(false);
  const defaultFilter: FilterType = {
    type: null,
    attendance: null,
  };
  const [beforeConfirmFilter, setBeforeConfirmFilter] =
    useState<FilterType>(defaultFilter);
  const [filter, setFilter] = useState<FilterType>(defaultFilter);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const isAdmin = groupId
    ? Boolean(userState.permission?.SocialWallAdmin)
    : Boolean(userState.permission?.EventAdmin);

  const updateAttendeesList = useCallback(() => {
    setAttendeesFullList(detail.checkIn);
  }, [detail]);

  useEffect(() => {
    updateAttendeesList();
  }, [updateAttendeesList]);

  useEffect(() => {
    if (!id) {
      navigate('/event');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleResetField = () => {
    setAttendeesList(attendeesFullList);
    setSearchValue('');
  };

  useEffect(() => {
    let searchedList: Array<AttendeesType> = attendeesFullList;
    if (searchValue !== '') {
      searchedList = searchedList.filter((user: AttendeesType) =>
        user.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    if (filter.type) {
      searchedList = searchedList.filter(
        (user: AttendeesType) => user.type === filter.type
      );
    }
    if (filter.attendance) {
      const attendanceValue =
        filter.attendance === 'CHECKED_IN' ? 'string' : 'object';
      searchedList = searchedList.filter(
        (user: AttendeesType) => typeof user.checkTime === attendanceValue
      );
    }
    setAttendeesList(searchedList);
  }, [searchValue, filter, attendeesFullList]);

  function handleOpenFilterPopup() {
    setBeforeConfirmFilter(filter);
    setOpen(true);
  }

  function handleChangeFilter(name: keyof FilterType, value: string) {
    const updateValue = beforeConfirmFilter[name] !== value ? value : null;

    setBeforeConfirmFilter((prev) => ({ ...prev, [name]: updateValue }));
  }

  function handleConfirmFilter() {
    setFilter(beforeConfirmFilter);
  }

  function handleResetFilter() {
    setBeforeConfirmFilter(defaultFilter);
  }

  const FilterHeader = () => {
    return (
      <Grid container alignItems="center" className={styles.filterHeader}>
        <Grid item xs>
          <Typography variant="h4">{t('general.filter')}</Typography>
        </Grid>
        <Grid item xs="auto">
          <Link
            id="resetFilterButton"
            component="button"
            typography="body1"
            className={styles.resetFilterButton}
            onClick={handleResetFilter}
          >
            {t('general.reset')}
          </Link>
        </Grid>
      </Grid>
    );
  };

  const LoadingList = () => {
    return (
      <>
        {Array.from(new Array(10)).map((_, index) => (
          <ListItem key={index} className={styles.attendeesItem}>
            <ListItemAvatar>
              <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
              primary={<Skeleton variant="text" />}
              secondary={<Skeleton variant="text" />}
              className={styles.attendeesInformation}
            />
          </ListItem>
        ))}
      </>
    );
  };

  return (
    <>
      <Header title={t('event.attendeesList')} enableBackButton />
      <SearchField
        id="attendeesSearch"
        name="attendeesSearch"
        value={searchValue}
        activeFilter={open}
        onChange={handleValueChange}
        onReset={handleResetField}
        onFilter={isAdmin ? handleOpenFilterPopup : undefined}
      />
      <Popup
        id="filterPopup"
        isOpen={open}
        setIsOpen={(close: boolean) => setOpen(close)}
        title={<FilterHeader />}
        content={
          <FilterPopupContent
            selectedFilter={beforeConfirmFilter}
            onChangeFilter={handleChangeFilter}
          />
        }
        onClickConfirm={() => {
          setOpen(false);
          handleConfirmFilter();
        }}
      />
      <Grid item xs className={styles.attendeesListContainer}>
        <Stack className={styles.attendeesListWrapper}>
          {!isLoading && isAdmin && (
            <Box className={styles.count}>
              <Typography variant="h2" component="span">
                {detail.checkedAttendeesCount}
              </Typography>
              <Typography
                variant="subtitle2"
                component="span"
              >{`/${detail.allAttendeesCount}`}</Typography>
            </Box>
          )}
          <Typography variant="body1" className={styles.checkedInText}>
            {t('event.checkedIn')}
          </Typography>
          {!isLoading && attendeesList.length === 0 && <NoResults />}
          <List dense>
            {isLoading ? (
              <LoadingList />
            ) : (
              attendeesList.map((user: AttendeesType, index: number) => {
                let checkInTime = t('event.notYetCheckIn');

                if (user.checkTime) {
                  checkInTime = t('event.checkInTime', {
                    date: convertDate(user.checkTime),
                    time: convertTime(user.checkTime),
                  });
                }

                return (
                  <ListItem
                    key={index}
                    id={`attendeesUser-${user.id}`}
                    className={styles.attendeesItem}
                  >
                    <ListItemAvatar>
                      <Avatar alt={user.name} src={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.name}
                      secondary={isAdmin ? checkInTime : undefined}
                      primaryTypographyProps={{ variant: 'body1' }}
                      secondaryTypographyProps={{ variant: 'body1' }}
                      className={styles.attendeesInformation}
                    />
                  </ListItem>
                );
              })
            )}
          </List>
        </Stack>
      </Grid>
    </>
  );
};

interface FilterItemProps {
  type: keyof FilterType;
  name: string;
  value: string;
  isSelected: boolean;
  onChange: (name: keyof FilterType, value: string) => void;
}

const FilterItem: FC<FilterItemProps> = (props) => {
  return (
    <ListItemButton
      id={`${props.type}Filter_${props.value}`}
      selected={props.isSelected}
      className={`${styles.filterItem} ${props.isSelected && styles.active}`}
      onClick={() => props.onChange(props.type, props.value)}
    >
      <ListItemText primary={props.name} />
      {props.isSelected && (
        <ListItemIcon>
          <img src="/assets/images/check.svg" alt="Selected" />
        </ListItemIcon>
      )}
    </ListItemButton>
  );
};

interface FilterPopupContentProps {
  selectedFilter: FilterType;
  onChangeFilter: (name: keyof FilterType, value: string) => void;
}

const FilterPopupContent: FC<FilterPopupContentProps> = (props) => {
  return (
    <Stack className={styles.filterContainer}>
      <List className={styles.filterField}>
        <ListSubheader disableSticky className={styles.filterTitle}>
          <Typography variant="body1">{t('event.filter.type')}</Typography>
        </ListSubheader>
        <FilterItem
          type="type"
          name={t('event.filter.supportCentre')}
          value="SUPPORT_CENTER"
          isSelected={props.selectedFilter.type === 'SUPPORT_CENTER'}
          onChange={props.onChangeFilter}
        />
        <FilterItem
          type="type"
          name={t('event.filter.operation')}
          value="OPERATIONS"
          isSelected={props.selectedFilter.type === 'OPERATIONS'}
          onChange={props.onChangeFilter}
        />
      </List>
      <List className={styles.filterField}>
        <ListSubheader disableSticky className={styles.filterTitle}>
          <Typography variant="body1">
            {t('event.filter.attendance')}
          </Typography>
        </ListSubheader>
        <FilterItem
          type="attendance"
          name={t('event.filter.checkedIn')}
          value="CHECKED_IN"
          isSelected={props.selectedFilter.attendance === 'CHECKED_IN'}
          onChange={props.onChangeFilter}
        />
        <FilterItem
          type="attendance"
          name={t('event.filter.notYetCheckIn')}
          value="NOT_YET"
          isSelected={props.selectedFilter.attendance === 'NOT_YET'}
          onChange={props.onChangeFilter}
        />
      </List>
    </Stack>
  );
};

export default EventAttendeesList;
