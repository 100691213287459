import React from 'react';

import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { CustomSwiper } from '../../components';

import styles from './sections.module.scss';

type Props = {};

const NewsSupportsSection = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const swiperData = [
    {
      id: 'partnerAnniversary',
      image: '/assets/images/homepage_partner_anniversary.png',
      title: t('homepage.contentHub.PartnerAnniversary'),
      url: '/partner-anniversary',
    },
    {
      id: 'theGreenBox',
      image: '/assets/images/homepage_theGreenBox.png',
      title: t('homepage.greenBox'),
      url: '/the-green-box',
      disabled: process.env.REACT_APP_LOCATION === 'PHL',
    },
    {
      id: 'FWDMedicalClaim',
      image: '/assets/images/homepage_fwd.png',
      title: t('homepage.FWDMedicalClaim'),
      url: '/FWDMedicalClaim',
      disabled: process.env.REACT_APP_LOCATION !== 'HK',
    },
    {
      id: 'fidelityBeanStock',
      image: '/assets/images/homepage_fidelity.png',
      title: t('homepage.fidelityBeanStock'),
      url: '/fidelityBeanStock',
      disabled: process.env.REACT_APP_LOCATION !== 'HK',
    },
    {
      id: 'ADP',
      image: '/assets/images/homepage_adp.png',
      title: t('homepage.ADP'),
      url: '/adp',
      disabled: process.env.REACT_APP_LOCATION !== 'HK',
    },
    {
      id: 'Announcement',
      image: '/assets/images/homepage_announcement.png',
      title: t('homepage.contentHub.Announcements'),
      url: '/announcement',
    },
    {
      id: 'newsletters',
      image: '/assets/images/homepage_newsletters.png',
      title: t('homepage.contentHub.Newsletters'),
      url: '/newsletters',
      disabled: process.env.REACT_APP_LOCATION === 'PHL',
    },
    {
      id: 'faqs',
      image: '/assets/images/homepage_faqs.png',
      title: t('homepage.contentHub.Faq'),
      url: '/faqs',
    },
    {
      id: 'more',
      image: '',
      title: t('homepage.more'),
      url: '/menu',
      disabled: process.env.REACT_APP_LOCATION === 'TWN',
    },
  ];

  const getSwiperList = (data: any) => {
    return (
      <Box
        className={`${styles.swiperItem}${
          data.content.id === 'more' ? ` ${styles.more}` : ''
        }`}
        key={data.content.id}
        {...(data.content.image && {
          style: { backgroundImage: `url("${data.content.image}")` },
        })}
        onClick={() => {
          sessionStorage.setItem('prevPage', window.location.href);
          navigate(data.content.url);
        }}
      >
        <label>{data.content.title}</label>
      </Box>
    );
  };

  return (
    <Box className={styles.sectionContainer}>
      <Box className={styles.sectionWrapper}>
        <Box className={styles.sectionHeader}>
          <Typography className={styles.sectionTitle} variant="body1">
            {t('homepage.PRO')}
          </Typography>

          <img
            src="/assets/images/chevron_right.svg"
            alt=""
            onClick={() => {
              navigate('/pro');
            }}
          />
        </Box>
        <Box>
          <CustomSwiper
            data={swiperData.filter((item) => !item.disabled)}
            swiperId={`social-wall-swiper`}
            content={getSwiperList}
            slidesPerView={2.12}
            slidesPerGroup={1}
            spaceBetween={12}
            isAutoHeight={false}
            isAutoWidth
            isPagination
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewsSupportsSection;
