import React, { FC, useEffect, useState, useRef, CSSProperties } from "react";
import styles from "./socialWallPostDetail.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  Snackbar,
  Alert,
  SvgIcon,
  Stack,
  CircularProgress,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  AccountItem,
  AccountItemSkeleton,
  Popup,
  SocialWallCard,
  ToggleButton,
} from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import toHtml from "html-react-parser";
import moment from "moment";
import theme from "../../theme";
import SocialWallResponseList from "./socialWallResponseList";
import {
  commentPost,
  getHashTagSuggestion,
  getSocialWallDetails,
  getSocialWallDetailsCommentList,
  likeComment,
  getSocialWallConfig,
} from "../../services/social-wall";
import SocialWallCardSkeleton from "../../components/social-wall-card/skeleton";
import { SocialWallCardProps } from "../../interfaces/social-wall-interface";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import { UserState, setUserState } from "../../reducers/user-slice";
import { Mention, MentionsInput } from "react-mentions";
import InfiniteScroll from "react-infinite-scroll-component";
import { ObjectKey } from "../../interfaces/common-interface";
import {
  PaginationParamsType,
  getInvitationGroupList,
  getStaffList,
} from "../../services/common";
import { convertStaffList, removeApiCacheSession } from "../../utility";

type Props = {
  postId?: string;
  postDetail?: any;
  closeButtonFunction?: (props: {
    id: string;
    isLiked: boolean;
    commentsNo: number;
  }) => void;
};

const SocialWallPostDetail: FC<Props> = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  if (props.postId) {
    id = props.postId;
  }
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [socialWallPermission, setSocialWallPermission] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isPostingComment, setIsPostingComment] = useState(false);
  const [data, setData] = useState<any>(null);
  const [comment, setComment] = useState<string>("");
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [displayComments, setDisplayComments] = useState<Array<any>>([]);
  const [displayNewComments, setNewComments] = useState<Array<any>>([]);
  const [isSnackbarShow, setSnackbarShow] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [listParams, setListParams] = useState<PaginationParamsType>({
    page: 1,
    pageSize: 100,
    search: "",
    filter: [],
  });
  const [isLoadingTag, setIsLoadingTag] = useState<boolean>(false);
  const [emptyTagResult, setEmptyTagResult] = useState<boolean>(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState<boolean>(false);
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 10,
    }
  );
  const [commentsNo, setCommentsNo] = useState<number>(0);
  const [isPostLiked, setIsPostLiked] = useState<boolean>(false);
  // const [likesNo, setLikesNo] = useState<number>(0);
  useEffect(() => {
    setData(null);
    setDisplayComments([]);
    setIsLoading(true);
    const getConfig = async () => {
      const response = await getSocialWallConfig();
      setSocialWallPermission(response.data);
    };
    getConfig();
    const getPostDetail = async () => {
      try {
        const response = await getSocialWallDetails(id as string);
        console.log("getPostDetail", response.data);
        setData(response.data);
        setCommentsNo(response.data.commentsNo);
        if (response.data.reportStatus === "suspend") {
          setErrorPopupOpen(true);
        }
      } catch (error: any) {
        // console.log(error?.response?.data?.status);
        if (error?.response?.data?.status === 4108) {
          setErrorPopupOpen(true);
        }
      }
    };
    const getCommentList = async () => {
      try {
        const response = await getSocialWallDetailsCommentList(id as string, {
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
        });
        setDisplayComments(response.data.comments);
        if (
          apiPagination.page >= response.data.totalPage ||
          !response.data.totalPage
        ) {
          setIsEnded(true);
        } else {
          setApiPagination({ page: apiPagination.page + 1 });
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsEnded(true);
        setIsLoading(false);
      }
    };
    if (props.postDetail) {
      console.log("props.postDetail", props.postDetail);
      setData(props.postDetail);
      setCommentsNo(props.postDetail.commentsNo);
    } else {
      getPostDetail();
    }
    getCommentList();

    // });
  }, [id]);

  async function fetchMoreData() {
    try {
      const response = await getSocialWallDetailsCommentList(id as string, {
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
      });

      setDisplayComments((prev) => [...prev, ...response.data.comments]);

      if (apiPagination.page >= response.data.totalPage) {
        setIsEnded(true);
      } else {
        setApiPagination({ page: apiPagination.page + 1 });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const commentItem = (item: any) => <Typography>{item.id}</Typography>;

  const handleEnterComment = (event: any) => {
    setEmptyTagResult(false);
    const converTag = event.target.value.match(
      /(##[[)([\u4e00-\u9fa5_a-zA-Z0-9]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9]+)(]]##)/g
    );

    var remaining = event.target.value;
    if (converTag) {
      var repl = "";
      converTag.map((word: any, tagIndex: number) => {
        // console.log("handleTag progress", [content, remaining]);
        let index = remaining.indexOf(word);
        let before = remaining.slice(0, index);
        let after = remaining.slice(index + word.length, remaining.length);
        remaining = after;
        const regex = /#|&|\[|\]/g;
        repl =
          repl + before + "#" + word.split("&&")[0].replace(regex, "") + " ";
        return true;
      });
      setComment(repl + remaining);
    } else {
      setComment(event.target.value);
    }
  };

  const handleTag = (content: any) => {
    const tag = content.match(
      /(#)([\u4e00-\u9fa5_a-zA-Z0-9]+)|(##[[)([\u4e00-\u9fa5_a-zA-Z0-9]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9]+)(]]##)|(@@[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]@@)/g
    );
    var remaining = content;
    if (tag) {
      const repl = tag.map((word: any, tagIndex: number) => {
        // console.log("handleTag progress", [content, remaining]);
        let index = remaining.indexOf(word);
        let before = remaining.slice(0, index);
        let after = remaining.slice(index + word.length, remaining.length);
        remaining = after;

        return (
          <>
            {before}
            <span
              className={styles.hashtag}
              onClick={() => {
                if (word.charAt(0) === "#") {
                  if (word.charAt(1) === "#") {
                    navigate(
                      `/social-wall/search/${word
                        .split("&&")[0]
                        .replaceAll("[", "")
                        .replaceAll("]", "")
                        .replaceAll("#", "")} `
                    );
                  } else {
                    navigate(
                      `/social-wall/search/${word.slice(1, word.length)}`
                    );
                  }
                } else if (word.charAt(0) === "@") {
                  navigate(
                    `/discover-people/profile/${word
                      .split("&&")[1]
                      .replace("[[", "")
                      .replace("]]", "")
                      .replace("@@", "")}`
                  );
                }
              }}
            >
              {(word.charAt(0) === "#" ? "#" : "@") +
                word
                  .split("&&")[0]
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll("@", "")
                  .replaceAll("#", "")}
            </span>
            {tagIndex === tag.length - 1 ? after : ""}
          </>
        );
      });
      return repl;
    }
    return content;
  };
  const getPartnerTag = (comment: string) => {
    const tag = comment.match(
      /(@@[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]&&[[)([\u4e00-\u9fa5_a-zA-Z0-9\s]+)(]]@@)/g
    );
    if (tag) {
      const repl = tag.map((word: string) => {
        return word
          .split("&&")[1]
          .replace("[[", "")
          .replace("]]", "")
          .replace("@@", "");
      });
      return repl;
    } else {
      return [];
    }
  };

  const submitComment = () => {
    // const inputValue = (
    //   document.getElementById("commentInput") as HTMLInputElement
    // ).innerHTML.replace(/\n\r?/g, "<br />");
    if (!isPostingComment) {
      setIsPostingComment(true);
      commentPost(id as string, comment, isAnonymous, getPartnerTag(comment))
        .then((res) => {
          const newComment = {
            ...res.data.comment[0],
            id: res.data.comment[0]._id,
            userIcon: isAnonymous
              ? "/assets/images/profileIcon_admin.png"
              : res.data.comment[0].userProfilePictureUrl,
            comment: res.data.comment[0].comment,
            likes: [],
            isLike: false,
          };

          setNewComments((prev) => [...prev, newComment]);
          setComment("");
          setIsTyping(false);
          setIsPostingComment(false);
          setCommentsNo((prev) => prev + 1);
        })
        .catch((err) => {
          // console.error(err, "Fail to post comment, please try again later!");
          // console.log("err", err?.response?.data?.status);
          setSnackbarShow(true);
          setIsPostingComment(false);
          if (err?.response?.data?.status === 4108) {
            setSnackbarMsg(
              `${t("socialWall.reportedPost.suspendSuccessfully")}<br>${t(
                "socialWall.commentErrorMsg"
              )}`
            );
          } else if (err?.response?.data?.status === 4107) {
            setSnackbarMsg(t("socialWall.sensitive"));
          } else {
            setSnackbarMsg(t("socialWall.commentErrorMsg"));
          }
        });
    }

    // document.getElementById("commentInput")?.onfocus();
  };

  const isEmptyOrSpaces = (str: any) => {
    return str === null || str.match(/^ *$/) !== null;
  };
  const notInitialRender = useRef(false);
  useEffect(() => {
    if (notInitialRender.current) {
      const inputField = document.getElementsByTagName(
        "textarea"
      )?.[0] as HTMLElement;
      inputField.focus();
    } else {
      notInitialRender.current = true;
    }
  }, [isAnonymous]);

  const defaultMentionStyle: CSSProperties = {
    backgroundColor: "#D4E9E2",
    opacity: 0.5,
    padding: "1px 0px",
    margin: 0,
    borderRadius: 3,
  };
  // console.log(data, "data");
  return (
    <>
      {data ? (
        <Header
          title={" "}
          enableBackButton
          disableBottomBorder
          {...(props.postId
            ? {
                closeButtonFunction: () =>
                  props.closeButtonFunction &&
                  props.closeButtonFunction({
                    id: data._id,
                    isLiked: isPostLiked,
                    commentsNo: commentsNo,
                  }),
              }
            : { closeButtonNavigation: "/social-wall" })}
        />
      ) : (
        <div style={{ padding: "11px 16px" }}>
          <Skeleton variant="rectangular" width={28} height={28} />
        </div>
      )}
      <Box>
        {data ? (
          <SocialWallCard
            {...data}
            id={data._id}
            size="large"
            commentsNo={commentsNo}
            disableLike={
              Boolean(userState.permission?.SocialWallAdmin)
                ? !socialWallPermission?.like.admin
                : !socialWallPermission?.like.all
            }
            disableComment={
              Boolean(userState.permission?.SocialWallAdmin)
                ? !socialWallPermission?.comment.admin
                : !socialWallPermission?.comment.all
            }
            passIsLiked={(isLiked: boolean) => {
              setIsPostLiked(isLiked);
            }}
          />
        ) : (
          <SocialWallCardSkeleton />
        )}
      </Box>
      <Popup
        id="ErrorPopup"
        isOpen={errorPopupOpen}
        setIsOpen={(close: boolean) => setErrorPopupOpen(close)}
        title={
          <IconButton
            disableRipple
            size="small"
            className={styles.closeButton}
            onClick={() => {
              removeApiCacheSession("getSocialWallList-");
              navigate("/social-wall");
            }}
          >
            <img src="/assets/images/close_btn.svg" alt="" />
          </IconButton>
        }
        content={
          <Box className={styles.noPostContainer}>
            <img src="/assets/images/empty.png" alt="" />
            <Typography variant="h3">
              {t("socialWall.reportedPost.suspendSuccessfully")}
            </Typography>
            <Typography>
              {t("socialWall.reportedPost.suspendPostDesc")}
            </Typography>
          </Box>
        }
        disableActions={true}
        fullScreen={true}
      />
      {!isLoading ? (
        <Grid
          item
          xs
          className={styles.postCommentContainer}
          // id="scrollableDiv"
        >
          {(Boolean(userState.permission?.SocialWallAdmin)
            ? socialWallPermission?.comment.admin
            : socialWallPermission?.comment.all) && (
            <Box className={styles.commentField}>
              <Avatar
                sx={{ width: "32px", height: "32px" }}
                src={
                  isAnonymous
                    ? "/assets/images/profileIcon_admin.png"
                    : userState.avatar ?? ""
                }
              />
              <>
                <Box
                  className={styles.postCommentForm}
                  onClick={() => setIsTyping(true)}
                >
                  <MentionsInput
                    value={comment}
                    className={styles.mentions}
                    placeholder={t("socialWall.addComment")}
                    style={{
                      minHeight: isTyping ? "100px" : "34px",
                      padding: "8px",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    onChange={(event: any) => {
                      handleEnterComment(event);
                    }}
                    onFocus={() => setIsTyping(true)}
                    onBlur={() => setEmptyTagResult(false)}
                    customSuggestionsContainer={(children) => (
                      <Box
                        className={styles.suggestedWordContainer}
                        sx={{
                          marginTop: `20px`,
                          height: "auto",
                        }}
                        id="staffListsContainer"
                      >
                        {/* {!isLoading && !hasMore && searchData.length === 0 && (
                  <NoResults resultsType="SEARCH" />
                )} */}
                        {/* <InfiniteScroll
                    dataLength={searchData.length} //This is important field to render the next data
                    next={nextPage}
                    hasMore={hasMore}
                    loader={<AccountItemSkeleton />}
                    scrollableTarget="staffListsContainer"
                  > */}
                        {children}

                        {/* </InfiniteScroll> */}
                      </Box>
                    )}
                  >
                    <Mention
                      trigger="@"
                      data={(search, callback) => {
                        setIsLoadingTag(true);
                        const { filter, ...request } = listParams;
                        const filterRequest = filter
                          ? filter.map((item: ObjectKey) => {
                              return {
                                key: item.key,
                                selected: item.selected.map(
                                  (option: ObjectKey) => option.value
                                ),
                              };
                            })
                          : [];
                        getStaffList({
                          ...request,
                          filter: filterRequest,
                          search: search,
                        }).then((response) => {
                          if (response.data.staffList.length > 0) {
                            const dataList = convertStaffList(
                              response.data.staffList
                            ).map((item: ObjectKey) => {
                              return {
                                id: item.id,
                                display: item.name,
                                avatar: item.avatar,
                                jobTitle: item.jobTitle,
                              };
                            });

                            callback(dataList);
                          } else {
                            setEmptyTagResult(true);
                          }
                          setIsLoadingTag(false);
                        });
                      }}
                      markup="@@[[__display__]]&&[[__id__]]@@"
                      displayTransform={(id, display) => `@${display}`}
                      renderSuggestion={(
                        suggestion: any,
                        search: any,
                        highlightedDisplay: any,
                        index: any,
                        focused: any
                      ) => {
                        return (
                          <Box key={index}>
                            <AccountItem
                              key={index}
                              name={suggestion.display}
                              icon={suggestion.avatar}
                              description={suggestion.jobTitle}
                              padding="8px 16px"
                            />
                          </Box>
                        );
                      }}
                      style={{
                        ...defaultMentionStyle,
                      }}
                    />
                    <Mention
                      trigger="#"
                      data={(search, callback) => {
                        // setHasMore(false);
                        getHashTagSuggestion(`#${search}`).then((res) => {
                          const dataList = res.data.tags.map((tag: any) => ({
                            id: tag,
                            display: tag,
                          }));
                          callback(dataList);
                        });
                      }}
                      markup="##[[__display__]]&&[[__id__]]##"
                      displayTransform={(id, display) => `#${display} `}
                      renderSuggestion={(
                        suggestion: any,
                        search: any,
                        highlightedDisplay: any,
                        index: any,
                        focused: any
                      ) => {
                        return (
                          <Typography
                            key={index}
                            variant="body1"
                            className={styles.suggestedWord}
                          >
                            {suggestion.display}
                          </Typography>
                        );
                      }}
                    />
                  </MentionsInput>

                  {socialWallPermission?.comment.admin &&
                    Boolean(userState.permission?.SocialWallAdmin) &&
                    isTyping && (
                      <Box className={styles.commentFieldOptions}>
                        {/* <Typography></Typography> */}
                        <ToggleButton
                          label={t("socialWall.create.postAnonymously")}
                          checked={isAnonymous}
                          setChecked={setIsAnonymous}
                        ></ToggleButton>
                      </Box>
                    )}
                </Box>
                {isTyping && (
                  <Button
                    id="postBtn"
                    className={styles.postButton}
                    onClick={(event) => {
                      if (!isEmptyOrSpaces(comment)) {
                        submitComment();
                      } else {
                        const inputField = document.getElementsByTagName(
                          "textarea"
                        )?.[0] as HTMLElement;
                        inputField.focus();
                      }
                    }}
                  >
                    {t("socialWall.post")}
                  </Button>
                )}
                {(isLoadingTag || (emptyTagResult && !isLoadingTag)) && (
                  <Box
                    className={styles.suggestedWordContainer}
                    sx={{
                      marginTop: `20px`,
                      height: "auto",
                    }}
                  >
                    {isLoadingTag && <AccountItemSkeleton />}
                    {emptyTagResult && (
                      <Typography sx={{ padding: "8px" }}>
                        {t("myCalendar.noResult.title")}
                      </Typography>
                    )}
                  </Box>
                )}
              </>
            </Box>
          )}{" "}
          <Stack direction="column-reverse">
            {displayNewComments.map((item: any, index: number) => (
              <Box className={styles.commentContainer} key={index}>
                <Box className={styles.row}>
                  <Box className={styles.avatarWrapper}>
                    <Avatar
                      sx={{ width: "32px", height: "32px" }}
                      src={
                        item.postAnonymously
                          ? "/assets/images/profileIcon_admin.png"
                          : item.userProfilePictureUrl
                      }
                      onClick={() => {
                        if (!item.postAnonymously) {
                          navigate(`/discover-people/profile/${item.createBy}`);
                        }
                      }}
                    />
                  </Box>
                  <Box className={styles.commentWrapper}>
                    <Typography>
                      {item.postAnonymously
                        ? t("general.starbucksAdmin")
                        : item.createByName?.userName ??
                          item.createByName?.name}
                    </Typography>
                    <Typography className={styles.comment} variant="body1">
                      {handleTag(item.comment)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={styles.row}>
                  <Box className={styles.cell}></Box>
                  <Box className={styles.cell}>
                    <CommentLikes
                      isLike={item.isLike}
                      id={item._id}
                      likes={item.likes.length}
                      errorHandle={() => {
                        setSnackbarShow(true);
                        setSnackbarMsg(
                          t("socialWall.reportedPost.suspendSuccessfully")
                        );
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
          <InfiniteScroll
            dataLength={displayComments.length}
            next={fetchMoreData}
            hasMore={!isEnded}
            loader={<SocialWallCardSkeleton isComment />}
            scrollableTarget="SocialWallDetailPopup-content"
          >
            {displayComments.map((item: any, index: number) => (
              <Box className={styles.commentContainer} key={index}>
                <Box className={styles.row}>
                  <Box className={styles.avatarWrapper}>
                    <Avatar
                      sx={{ width: "32px", height: "32px" }}
                      src={
                        item.postAnonymously
                          ? "/assets/images/profileIcon_admin.png"
                          : item.userProfilePictureUrl
                      }
                      onClick={() => {
                        if (!item.postAnonymously) {
                          navigate(`/discover-people/profile/${item.createBy}`);
                        }
                      }}
                    />
                  </Box>

                  <Box className={styles.commentWrapper}>
                    <Typography>
                      {" "}
                      {item.postAnonymously
                        ? t("general.starbucksAdmin")
                        : item.createByName?.userName ??
                          item.createByName?.name}
                    </Typography>
                    <Typography className={styles.comment} variant="body1">
                      {handleTag(item.comment)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={styles.row}>
                  <Box className={styles.cell}></Box>
                  <Box className={styles.cell}>
                    <CommentLikes
                      isLike={item.isLike}
                      id={item._id}
                      likes={item.likes.length}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </Grid>
      ) : (
        <SocialWallCardSkeleton isComment />
      )}
      {isSnackbarShow && (
        <Snackbar
          open={isSnackbarShow}
          autoHideDuration={6000}
          onClose={() => setSnackbarShow(false)}
        >
          <Alert icon={<ErrorMsgIcon />} severity="error">
            {toHtml(snackbarMsg)}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

function ErrorMsgIcon() {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      width="16"
      height="16"
      style={{ width: "auto", height: "auto" }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <path
          d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
          stroke="#C82014"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 6L10 10M10 6L6 10"
          stroke="#C82014"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
interface CommentLikeProps {
  isLike: boolean;
  id: string;
  likes: number;
  errorHandle?: () => void;
}
const CommentLikes: FC<CommentLikeProps> = (props) => {
  const [isLiked, setIsLiked] = useState<boolean>(props.isLike);

  const handleLikeComment = (id: string) => {
    setIsLiked((prev) => !prev);
    likeComment(id)
      .then((res) => {
        // setIsLiked((prev) => !prev);
      })
      .catch((err) => {
        if (err.response.data.status === 4108) {
          props.errorHandle && props.errorHandle();
        }
      });
  };

  // useEffect(() => {
  //   console.log("isLiked", isLiked);
  // }, [isLiked]);

  return (
    <Box className={styles.likesWrapper}>
      <Typography
        className={isLiked ? styles.liked : ""}
        variant="caption"
        onClick={() => handleLikeComment(props.id)}
      >
        {t("socialWall.likes")}
      </Typography>
      <Box className={styles.numberOfLikes}>
        <Typography
          className={styles.number}
          variant="caption"
          color={theme.vars.palette.black.opacity26}
        >
          {props.likes +
            (!props.isLike && isLiked ? 1 : 0) -
            (props.isLike && !isLiked ? 1 : 0)}
        </Typography>
        <img src="/assets/images/liked_heart_icon.svg" />
      </Box>
    </Box>
  );
};

export default SocialWallPostDetail;
