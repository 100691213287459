import SbMessageSearch from "@sendbird/uikit-react/MessageSearch";
import { useParams, useNavigate } from "react-router-dom";

import { useNavigateOnBan } from "./hooks/useNavigateOnBan";

export function MessageSearch() {
  const { channelUrl } = useParams();
  const navigator = useNavigate();
  useNavigateOnBan();
  return (
    <SbMessageSearch
      channelUrl={channelUrl as string}
      onCloseClick={() => {
        navigator(`/chat/${channelUrl}`);
      }}
      onResultClick={(message) => {
        navigator(
          `/chat/${channelUrl}?messageId=${message?.messageId}&createdAt=${message?.createdAt}`
        );
      }}
    />
  );
}
