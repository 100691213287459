import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import theme from "../../theme";
import styles from "./notice-box.module.scss";
import { Alert } from "@mui/material";

interface NoticeBoxProps {
  text: string;
  type: string;
  atBottom?: boolean;
}
function NoticeBox(props: NoticeBoxProps) {
  const { text, type, atBottom } = props;

  return (
    <Alert
      className={`${styles.noticeBox} ${type} ${atBottom ? styles.fixed : ""}`}
      severity={type === "info" ? "info" : "success"}
      iconMapping={{
        success: <img src="/assets/images/noticeBoxIcon_success.svg" />,
        info: <img src="/assets/images/noticeBoxIcon_notice.svg" />,
      }}
    >
      {text}
    </Alert>
  );
}

export default NoticeBox;
