import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { CustomSwiper, EventCard, EventCardSkeleton } from '../../components';

import { ObjectKey } from '../../interfaces/common-interface';
import { EventCardBaseType } from '../../interfaces/event-interface';
import { SectionProps } from '../../interfaces/homepage-interface';
import { getEventList } from '../../services/event';
import theme from '../../theme';
import { getApiDataByLang } from '../../utility';
import styles from './sections.module.scss';

function EventSections(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Array<any>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const getList = async () => {
      try {
        const listRequest = {
          month: moment().month() + 1,
          year: moment().year(),
          eventType: 'Open,Upcoming',
          registerType: 'Open,Upcoming',
        };
        const response = await getEventList(listRequest);
        const events = response.data.events;

        const convertEvents = events.map((event: ObjectKey) => {
          return {
            id: event._id,
            partnerNetworkGroupId: event.partnerNetworkGroupId ?? null,
            name: event.eventName,
            imageUrl: event.eventCoverImage,
            startsFrom: moment.unix(event.eventStartTimestamp).format(),
            endsAt: moment.unix(event.eventEndTimestamp).format(),
            category: event.category
              ? {
                  id: event.category._id,
                  name: getApiDataByLang(event.category.categoryName),
                  bgColor: event.category.color,
                }
              : null,
            status:
              event.status.register.charAt(0).toLowerCase() +
              event.status.register.slice(1).replace('-', ''),
            location:
              event.eventLocationId &&
              event.eventLocation &&
              typeof event.eventLocation === 'object'
                ? {
                    id: event.eventLocation._id,
                    name: event.eventLocation.storeName,
                    address: event.eventLocation.address,
                  }
                : {
                    id: '',
                    name: event.eventLocation,
                    address: '',
                  },
          };
        });

        setData(convertEvents);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    setIsLoading(true);
    getList();
  }, []);
  // useEffect(() => {
  //   console.log("api", data);
  // }, [data]);
  const getEventSwiperData = (data: any) => {
    return (
      <EventCard
        key={data.index}
        {...data.content}
        name={
          typeof data.content.name === 'string'
            ? data.content.name
            : getApiDataByLang(data.content.name)
        }
        onClick={(id: string) =>
          navigate(
            data.content.partnerNetworkGroupId
              ? `/partner-network/event/${data.content.partnerNetworkGroupId}/${id}`
              : `/event/${id}`
          )
        }
      />
    );
  };
  return (
    <Box className={styles.sectionContainer}>
      <Box className={styles.sectionWrapper}>
        <Box className={styles.sectionHeader}>
          <Typography className={styles.sectionTitle} variant="body1">
            {t('homepage.events')}
          </Typography>
          <img
            src="/assets/images/chevron_right.svg"
            alt=""
            onClick={() => navigate('/Event')}
          />
        </Box>
        {data.length > 0 && (
          <Box>
            <CustomSwiper
              data={data.slice(0, Math.min(data.length, 5))}
              swiperId={`event-swiper`}
              content={getEventSwiperData}
              slidesPerView={1.5}
              slidesPerGroup={1}
              spaceBetween={16}
              isAutoHeight={true}
              isPagination
            />
          </Box>
        )}
        {data.length === 0 && !isLoading && (
          <Box className={styles.noticeContainer}>
            <img src="/assets/images/event_logo.svg" alt="" />
            <Typography
              variant="body2"
              color={theme.vars.palette.black.opacity38}
            >
              {t('homepage.noEvents')}
            </Typography>
          </Box>
        )}

        {isLoading && <EventCardSkeleton size="small" />}
      </Box>
    </Box>
  );
}

export default EventSections;
