import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import styles from "./toolbar.module.scss";
import Popup from "../popup";
import ActionItemList, { ItemProps } from "../action-item-list";
import QrCodeScanner from "../qr-code-scanner";
import InboxMessage from "../inbox-message/inbox-message";
import { UserState } from "../../reducers/user-slice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getSocialWallConfig } from "../../services/social-wall";
import { ObjectKey } from "../../interfaces/common-interface";
import { getInboxMessageStatus } from "../../services/common";

interface IBreadcrumbProps {
  config?: ObjectKey;
}

interface ToolbarItemsType {
  image: string;
  action: string;
  notify?: string;
}

const inboxMsgToolbarItem = {
  image: "/assets/images/toolbar_notice.svg",
  notify: "/assets/images/toolbar_noticeActive.svg",
  action: "inboxMsg",
};

const Toolbar = (props: IBreadcrumbProps) => {
  const { t } = useTranslation();

  const [openCreatePopup, setOpenCreatePopup] = useState<boolean>(false);
  const [openScanner, setOpenScanner] = useState<boolean>(false);
  const [openInboxMsgPopup, setOpenInboxMsgPopup] = useState<boolean>(false);
  const [unreadMsgNotify, setUnreadMsgNotify] = useState<boolean>(false);
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [toolbarItems, setToolbarItems] = useState<Array<ToolbarItemsType>>([
    inboxMsgToolbarItem,
  ]);
  const [createPopupData, setCreatePopupData] = useState<Array<ItemProps>>([]);

  const createPopupItem = <ActionItemList data={createPopupData} />;

  const handleOnclickAction = (action: string) => {
    if (action === "create") {
      setOpenCreatePopup(true);
    } else if (action === "inboxMsg") {
      handleOpenInboxMsg();
    } else if (action === "scan") {
      setOpenScanner(true);
    }
  };

  const popupTitle = (
    <Box className={styles.popupTitle}>
      <span>{t("homepage.createPopup.title")}</span>
      <img
        src="/assets/images/close_btn.svg"
        onClick={() => {
          setOpenCreatePopup(false);
        }}
      />
    </Box>
  );

  const handleOpenInboxMsg = () => {
    localStorage.setItem("LOAD_INBOX_MESSAGE", "true");
    setOpenInboxMsgPopup(true);
    setUnreadMsgNotify(false);
  };

  // const checkUnreadMsg = (haveUnread: boolean) => {
  //   if (haveUnread) {
  //     setUnreadMsgNotify(true);
  //   }
  // };

  useEffect(() => {
    let active = true;

    const getConfig = async () => {
      try {
        // const response = await getSocialWallConfig();
        let createPopupOption: Array<ItemProps> = [];
        let toolbarOption: Array<ToolbarItemsType> = [];
        if (props.config) {
          const { create } = props.config;
          if (create) {
            if (
              create.all ||
              (create.admin && Boolean(userState.permission?.SocialWallAdmin))
            ) {
              createPopupOption = [
                ...createPopupOption,
                {
                  image: "/assets/images/toolsIcon_memoPencil.svg",
                  url: "/social-wall/create",
                  text: "homepage.createPopup.post",
                },
              ];
            }
          }
        }
        if (Boolean(userState.permission?.EventAdmin)) {
          createPopupOption = [
            ...createPopupOption,
            {
              image: "/assets/images/toolsIcon_calendar.svg",
              url: "/event/create",
              text: "homepage.createPopup.event",
            },
          ];
        }
        if (
          Boolean(userState.permission?.EventAdmin) ||
          Boolean(userState.permission?.SocialWallAdmin)
        ) {
          toolbarOption = [
            ...toolbarOption,
            {
              image: "/assets/images/toolbar_scan.svg",
              action: "scan",
            },
          ];
        }
        if (Boolean(userState.permission?.SurveyAdmin)) {
          createPopupOption = [
            ...createPopupOption,
            {
              image: "/assets/images/toolsIcon_memoPencil.svg",
              url: "/survey/create",
              text: "homepage.createPopup.survey",
            },
          ];
        }

        if (createPopupOption.length > 0) {
          toolbarOption = [
            ...toolbarOption,
            {
              image: "/assets/images/toolbar__plus.svg",
              action: "create",
            },
          ];
        }

        setToolbarItems([...toolbarOption, inboxMsgToolbarItem]);
        setCreatePopupData(createPopupOption);

        const inboxStatusResponse = await getInboxMessageStatus();
        if (
          inboxStatusResponse.status === 200 &&
          !inboxStatusResponse.data.isRead
        ) {
          setUnreadMsgNotify(true);
        }
      } catch (error) {
        throw error;
      }
    };

    getConfig();

    return () => {
      active = false;
    };
  }, [props.config]);

  return (
    <>
      <Box className={styles.toolbarWrapper}>
        <Box className={styles.toolbarItemContainer}>
          {toolbarItems.map((item: ToolbarItemsType, index: number) => {
            let icon = item.image;
            if (item.action === "inboxMsg" && unreadMsgNotify && item.notify) {
              icon = item.notify;
            }
            return (
              <Box className={styles.toolbarItem} key={index}>
                <img
                  src={icon}
                  onClick={() => handleOnclickAction(item.action)}
                  alt=""
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <QrCodeScanner open={openScanner} onClose={() => setOpenScanner(false)} />
      <InboxMessage
        open={openInboxMsgPopup}
        onClose={() => setOpenInboxMsgPopup(false)}
      />
      <Popup
        isOpen={openCreatePopup}
        setIsOpen={(close: boolean) => setOpenCreatePopup(close)}
        title={popupTitle}
        content={createPopupItem}
        disableActions={true}
      />
    </>
  );
};

export default Toolbar;
