import { FC } from "react";
import styles from "./category-tag.module.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CategoryType } from "../../interfaces/event-interface";

interface CategoryTagProps {
  category: CategoryType;
}

const CategoryTag: FC<CategoryTagProps> = (props) => {
  return (
    <Typography
      variant="body2"
      className={styles.tag}
      sx={{ backgroundColor: props.category.bgColor }}
      data-category={props.category.id}
    >
      {props.category.name}
    </Typography>
  );
};

export default CategoryTag;
