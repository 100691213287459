import React from "react";
import sectionStyles from "../../homepage/sections.module.scss";
import styles from "../partnerNetwork.module.scss";
import GroupItemCard from "../../../components/group-item-card";
import { useNavigate } from "react-router-dom";
import { Box, Skeleton, Typography } from "@mui/material";
import { NoResults } from "../../../components";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  data: Array<any>;
  next: () => void;
  hasMore: boolean;
};

const GroupList = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box className={`${styles.groupList} ${sectionStyles.sectionContainer}`}>
      <Box className={sectionStyles.sectionWrapper}>
        <Box
          className={sectionStyles.sectionHeader}
          style={{ marginBottom: "4px" }}
        >
          <Typography className={sectionStyles.sectionTitle} variant="body1">
            {t("partnerNetwork.groups")}
          </Typography>
        </Box>
        {props.data.length === 0 ? (
          <NoResults />
        ) : (
          <InfiniteScroll
            dataLength={props.data.length}
            next={props.next}
            hasMore={props.hasMore}
            loader={<Skeleton height={40} width="100%" />}
            scrollableTarget="scrollableDiv"
          >
            {props.data.length > 0 &&
              props.data.map((item, index) => (
                <GroupItemCard
                  key={`group${index}-${item.content.id}`}
                  data={item}
                  onDetailClick={() =>
                    navigate(`/partner-network/detail/${item.content.id}`)
                  }
                />
              ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
};

export default GroupList;
