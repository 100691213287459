const MuiAvatarGroup = {
  styleOverrides: {
    // eslint
    root: ({ ownerState: { max } }: any) => ({
      ...[...Array(max)].reduce(
        (result, curr, index) => ({
          ...result,
          [`& > .MuiAvatar-root:nth-child(${index + 1})`]: {
            zIndex: max - index,
          },
        }),
        {}
      ),
    }),
  },
};

export default MuiAvatarGroup;
