import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
  ActionItemList,
  BottomNavMenu,
  CustomTab,
  NoResults,
  Popup,
  SocialWallCard,
} from '../../components';
import Header from '../../components/header/header';
import SocialWallCardSkeleton from '../../components/social-wall-card/skeleton';

import {
  postDetailsProps,
  SocialWallProps,
} from '../../interfaces/social-wall-interface';
import { setUserState, UserState } from '../../reducers/user-slice';
import {
  getInboxStatus,
  getSocialWallConfig,
  getSocialWallList,
  SocailWallListRequestType,
} from '../../services/social-wall';
import { RootState, store } from '../../store/store';
import { convertDateTimeWithDivider, getApiDataByLang } from '../../utility';
import SnackBar from '../partnerNetwork/components/snackBar';
import styles from './socialWall.module.scss';
import SocialWallPostDetail from './socialWallPostDetail';

type Props = {
  isGroup?: boolean;
  groupId?: string;
};

function SocialWall(props: Props) {
  const { isGroup = false, groupId = null } = props;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabData = [
    { value: 'all', display: t('socialWall.all') },
    // { value: "workplace", display: "Workplace" },
    { value: 'annotated', display: t('socialWall.atYou') },
    { value: 'private', display: t('socialWall.private') },
    { value: 'my', display: t('socialWall.myPost') },
  ];
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [activeTab, setActiveTab] = useState<string>(
    sessionStorage.getItem('activeSocialWallTab') ?? tabData[0].value
  );
  const [postList, setPostList] = useState<Array<postDetailsProps>>([]);
  const [showNotice, setShowNotice] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [snackAlertOpen, setSnackAlertOpen] = useState<boolean>(false);
  const [showFullScreenPopup, setShowFullScreenPopup] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reportSuccess, setReportSuccess] = useState(
    JSON.parse(localStorage.getItem('is-post-reported') as string)
  );

  const getCreatePostBar = JSON.parse(
    localStorage.getItem('post-created') as string
  );
  const [isSnackbarShow, setSnackbarShow] = useState(
    getCreatePostBar ? getCreatePostBar.isShow : false
  );
  const [listParams, setListParams] = useState<SocailWallListRequestType>({
    page: Number(searchParams.get('page') ?? 1),
    pageSize: Number(searchParams.get('pageSize') ?? 10),
    tab: groupId ? 'all' : activeTab,
    ...(groupId && { partnerNetworkGroupId: groupId }),
  });
  const [hasMore, setHasMore] = useState(true);
  const [socialWallPermission, setSocialWallPermission] = useState<any>();
  const [socialWallDetailOpen, setSocialWallDetailOpen] = useState(false);
  const [activePostId, setActivePostId] = useState<string>('');
  const navigate = useNavigate();

  function handleChangeTab(active: string) {
    setIsLoading(true);
    setPostList([]);
    setActiveTab(active);
    setListParams((prev) => ({ ...prev, page: 1, tab: active }));
    sessionStorage.setItem('activeSocialWallTab', active);
  }

  useEffect(() => {
    const getConfig = async () => {
      const response = await getSocialWallConfig();
      setSocialWallPermission(response.data);
    };
    getConfig();

    setTimeout(() => {
      getInboxStatus().then((res) => {
        if (res.data.reportedCount > 0) {
          setShowNotice(true);
        }
      });
    }, 1000);
  }, []);

  const openReviewPopup = () => {
    setShowPopup(true);
  };
  const openPinPopup = () => {
    setShowFullScreenPopup(true);
    setShowPopup(true);
  };

  const popupTitle = (
    <Box className={styles.popupTitle}>
      <span>{t('socialWall.review')}</span>
      <img
        src="/assets/images/close_btn.svg"
        onClick={() => {
          setShowPopup(false);
        }}
      />
    </Box>
  );

  const actionItemData = [
    {
      image: '/assets/images/postActionIcon_pin.svg',
      url: '/social-wall/reported-post',
      text: 'socialWall.reportedPost.title',
      disable: Boolean(userState.permission?.SocialWallAdmin) ? false : true,
    },
    {
      image: '/assets/images/postActionIcon_edit.svg',
      url: '/social-wall/scheduled-post',
      text: 'socialWall.scheduledPost.title',
      disable:
        socialWallPermission?.create.all ||
        (socialWallPermission?.create.admin &&
          Boolean(userState.permission?.SocialWallAdmin))
          ? false
          : true,
    },
  ];
  const actionItem = (
    <Box className={styles.detailsContainer}>
      <ActionItemList
        data={actionItemData.filter((item) => item.disable === false)}
      />
    </Box>
  );
  function LoadingSkeleton() {
    return (
      <Box sx={{ padding: 2 }}>
        <SocialWallCardSkeleton />
      </Box>
    );
  }
  function handleCloseMsgBar() {
    localStorage.setItem('is-post-reported', 'false');
    localStorage.setItem('post-created', 'false');
    setReportSuccess(false);
    setSnackbarShow(false);
  }

  const getList = useCallback(async () => {
    let active = true;
    setIsLoading(true);

    try {
      // console.log("listParams", listParams);
      const response = await getSocialWallList(listParams);
      // console.log("response", response);
      const activeSocialWallTab = groupId
        ? 'all'
        : sessionStorage.getItem('activeSocialWallTab') ?? 'all';

      if (activeSocialWallTab === listParams.tab) {
        if (response.status === 200 && active) {
          setPostList((prev) => [...prev, ...response.data.posts]);

          if (listParams.page >= response.data.totalPage) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setHasMore(false);
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      // console.log("error", error);
      if (error?.code !== 'ERR_CANCELED') {
        throw error;
      }
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  useEffect(() => {
    getList();
  }, [getList]);

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  // useEffect(() => {
  //   console.log("postList", postList);
  // }, [postList]);
  return (
    <>
      {!isGroup && (
        <Header title={t('socialWall.title')}>
          <Grid container spacing={2} direction="row" justifyContent="flex-end">
            {(socialWallPermission?.create.all ||
              Boolean(userState.permission?.SocialWallAdmin)) && (
              <Grid item xs="auto">
                <IconButton
                  className={
                    showNotice && userState?.permission?.SocialWallAdmin
                      ? 'active'
                      : ''
                  }
                  aria-label={t('socialWall.review')}
                  sx={{ padding: '0px' }}
                  onClick={openReviewPopup}
                >
                  <img src="/assets/images/toolbar_archive_black.svg" alt="" />
                </IconButton>
              </Grid>
            )}
            <Grid item xs="auto">
              <IconButton
                aria-label="Search"
                sx={{ padding: '0px' }}
                onClick={() => navigate('/social-wall/search')}
              >
                <img src="/assets/images/toolbar_search_black.svg" alt="" />
              </IconButton>
            </Grid>
            {(socialWallPermission?.create.all ||
              (socialWallPermission?.create.admin &&
                Boolean(userState.permission?.SocialWallAdmin))) && (
              <Grid item xs="auto">
                <IconButton
                  aria-label="Plus"
                  sx={{ padding: '0px' }}
                  onClick={() => {
                    sessionStorage.setItem('prevPage', window.location.href);
                    navigate('/social-wall/create');
                  }}
                >
                  <img src="/assets/images/toolbar_plus_black.svg" alt="" />
                </IconButton>
              </Grid>
            )}
          </Grid>
          {(showPopup || showFullScreenPopup) && (
            <Popup
              isOpen={showPopup || showFullScreenPopup}
              setIsOpen={(close: boolean) => {
                setShowPopup(close);
                setShowFullScreenPopup(close);
              }}
              fullScreen={showFullScreenPopup}
              title={popupTitle}
              content={actionItem}
              disableActions={true}
            />
          )}
        </Header>
      )}

      <Grid item xs className={styles.postContainer}>
        {!isGroup && (
          <>
            {(socialWallPermission?.create.all ||
              (socialWallPermission?.create.admin &&
                Boolean(userState.permission?.SocialWallAdmin))) && (
              <Box className={styles.createPostContainer}>
                <ButtonBase
                  sx={{ justifyContent: 'flex-start' }}
                  onClick={() => navigate('/social-wall/create')}
                >
                  {userState && (
                    <Avatar
                      sx={{ width: '30px', height: '30px' }}
                      src={userState.avatar ? userState.avatar : ''}
                    />
                  )}

                  <Typography sx={{ marginLeft: '8px' }}>
                    {t('socialWall.whatsOnYourMind')}
                  </Typography>
                </ButtonBase>
              </Box>
            )}
            <Box className={styles.tabWrapper}>
              <CustomTab
                data={tabData}
                activeTab={activeTab}
                onTabClick={handleChangeTab}
              />
            </Box>
          </>
        )}

        <Grid
          item
          xs
          className={styles.postList}
          {...(!isGroup && {
            id: 'postListContainer',
            style: { overflow: 'auto' },
          })}
        >
          {isLoading && <LoadingSkeleton />}
          {!isLoading && postList.length === 0 && <NoResults />}
          {postList.length > 0 && (
            <InfiniteScroll
              dataLength={postList.length} //This is important field to render the next data
              next={nextPage}
              hasMore={hasMore}
              loader={<LoadingSkeleton />}
              scrollableTarget="postListContainer"
            >
              {
                //remove duplicate post in postList from the api cache
                postList
                  .filter(
                    (post, index, self) =>
                      index === self.findIndex((t) => t._id === post._id)
                  )
                  .map((data: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        {...(!isGroup && { style: { marginTop: '8px' } })}
                      >
                        <SocialWallCard
                          {...data}
                          id={data._id}
                          size="large"
                          disableLike={
                            Boolean(userState.permission?.SocialWallAdmin)
                              ? !socialWallPermission?.like.admin
                              : !socialWallPermission?.like.all
                          }
                          disableComment={
                            Boolean(userState.permission?.SocialWallAdmin)
                              ? !socialWallPermission?.comment.admin
                              : !socialWallPermission?.comment.all
                          }
                          handleActionBtnClick={() => {
                            // console.log("handleActionBtnClick", data._id);
                            setSocialWallDetailOpen(true);
                            setActivePostId(data._id);
                          }}
                          handleSnackOpen={(isOpen) => {
                            setSnackAlertOpen(isOpen);
                          }}
                          onClickMoreAction={() => {
                            setSnackAlertOpen(false);
                          }}
                          likeBtnCallback={(value: boolean) => {
                            // console.log("likeBtnCallback", value);
                            setPostList((prev) => {
                              const newArr = prev.map((item: any) => {
                                if (item._id === data._id) {
                                  return {
                                    ...item,
                                    likesNo:
                                      item.likesNo +
                                      (item.isLike && !value ? -1 : 0) +
                                      (!item.isLike && value ? 1 : 0),
                                    isLike: value,
                                  };
                                }
                                return item;
                              });
                              return newArr;
                            });
                          }}
                        />
                      </Box>
                    );
                  })
              }
            </InfiniteScroll>
          )}
        </Grid>
      </Grid>
      {snackAlertOpen && <SnackBar />}
      <BottomNavMenu />
      {reportSuccess && (
        <Snackbar
          open={reportSuccess}
          autoHideDuration={6000}
          onClose={handleCloseMsgBar}
        >
          <Alert severity="success">{t('socialWall.reportSuccessfully')}</Alert>
        </Snackbar>
      )}
      {isSnackbarShow && (
        <Snackbar
          open={isSnackbarShow}
          autoHideDuration={6000}
          onClose={handleCloseMsgBar}
        >
          <Alert severity="success">
            <Box className={styles.alertMsg}>
              <Typography variant="body2">
                {getCreatePostBar.type === 'posted'
                  ? t('socialWall.createdPostMsg')
                  : `${
                      t('socialWall.schduledPostMsg') +
                      ' ' +
                      t('socialWall.from') +
                      ' ' +
                      convertDateTimeWithDivider(getCreatePostBar.startDate)
                    } ${
                      getCreatePostBar.endDate
                        ? t('socialWall.to') +
                          ' ' +
                          convertDateTimeWithDivider(getCreatePostBar.endDate)
                        : ''
                    }`}
              </Typography>
              <Typography
                className={styles.alertBtn}
                variant="body2"
                onClick={() => {
                  if (getCreatePostBar.type === 'posted') {
                    navigate(
                      `/social-wall/post-detail/${getCreatePostBar.postId}`
                    );
                  } else {
                    sessionStorage.setItem('prevPage', window.location.href);
                    navigate(`/social-wall/edit/${getCreatePostBar.postId}`);
                  }
                }}
              >
                {getCreatePostBar.type === 'posted'
                  ? t('socialWall.viewPost')
                  : t('socialWall.create.editTitle')}
              </Typography>
            </Box>
          </Alert>
        </Snackbar>
      )}
      {activePostId && socialWallDetailOpen && (
        <Popup
          id="SocialWallDetailPopup"
          isOpen={socialWallDetailOpen}
          setIsOpen={(close: boolean) => setSocialWallDetailOpen(close)}
          content={
            <SocialWallPostDetail
              postId={activePostId}
              closeButtonFunction={(props) => {
                // console.log("detailPopupAction", props);
                setPostList((prev) => {
                  const newArr = prev.map((item: any) => {
                    if (item._id === props.id) {
                      return {
                        ...item,
                        commentsNo: props.commentsNo,
                        likesNo:
                          item.likesNo -
                          (item.isLike && !props.isLiked ? 1 : 0) +
                          (!item.isLike && props.isLiked ? 1 : 0),
                        isLike: props.isLiked,
                      };
                    }
                    return item;
                  });
                  return newArr;
                });
                setSocialWallDetailOpen(false);
              }}
            />
          }
          disableActions={true}
          fullScreen
        />
      )}
    </>
  );
}

interface EventTabProps {
  name: string;
  title: string;
  icon: string;
  activeIcon?: string;
  count: number;
  isActive: boolean;
  onClick: (active: string) => void;
}

const EventTab: FC<EventTabProps> = (props) => {
  const iconPath =
    props.isActive && props.activeIcon ? props.activeIcon : props.icon;

  return (
    <Button
      id={props.name + 'Tab'}
      disableRipple
      className={props.isActive ? styles.active : undefined}
      onClick={() => props.onClick(props.name)}
    >
      <Typography variant="body2">{props.title}</Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        columnGap={0.5}
        className={styles.eventCount}
      >
        <img src={iconPath} alt={props.title} />
        <Typography variant="h2" component="span">
          {props.count}
        </Typography>
      </Grid>
    </Button>
  );
};

export default SocialWall;
