import { FC } from "react";
import { EventDetailProvider } from "./eventDetailProvider";
import { Outlet } from "react-router-dom";

const EventDetailContainer: FC = () => {
  return (
    <EventDetailProvider>
      <Outlet />
    </EventDetailProvider>
  );
};

export default EventDetailContainer;
