import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AccountItem, NoResults, Popup } from '../../components';
import Header from '../../components/header/header';
import SearchField from '../../components/search-field';

import { ObjectKey } from '../../interfaces/common-interface';
import { getStoreDetail } from '../../services/common';
import styles from './storeDetails.module.scss';

interface partnerProps {
  id: string;
  name: string;
  jobTitle: string;
  icon: string;
}
interface storeDetailsProps {
  id: string;
  name: string;
  storeCode: string;
  phone: string;
  address: string;
  partners: Array<partnerProps>;
}

const StoreDetails: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState<storeDetailsProps>(null!);
  const [popupOpen, setPopupOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterPartnerList, setFilterPartnerList] = useState<
    Array<partnerProps>
  >([]);
  const { t } = useTranslation();
  let { id } = useParams();

  const handleResetField = () => {
    if (!isLoading) {
      setFilterPartnerList([]);
      setSearchValue('');
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    setFilterPartnerList([]);
  };

  const filterPartner = useCallback(() => {
    if (!data || data.partners.length === 0) return false;

    if (searchValue.length > 0) {
      const filterPartnerList = data.partners.filter((partner) =>
        partner.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilterPartnerList(filterPartnerList);
    } else {
      setFilterPartnerList(data.partners);
    }
  }, [searchValue, data]);

  useEffect(() => {
    filterPartner();
  }, [filterPartner]);

  useEffect(() => {
    let active = true;

    const getDetail = async (storeId: string) => {
      try {
        const response = await getStoreDetail(storeId);
        if (response.status === 200) {
          const { store, partners } = response.data;

          const partnerList = partners.map((partner: ObjectKey) => ({
            id: partner.id,
            name: partner.displayName,
            jobTitle: partner.jobTitle,
            icon: partner.thumbnail,
          }));

          if (active) {
            setData({
              id: store._id,
              name: store.displayName ?? store.storeName,
              storeCode: store.storeCode,
              phone: store.mobilePhone ?? '-',
              address:
                store.district && store.area
                  ? store.district + '-' + store.area
                  : '-',
              partners: partnerList,
            });
            setFilterPartnerList(partnerList);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    if (id) {
      getDetail(id);
    } else {
      navigate(-1);
    }

    return () => {
      active = false;
    };
  }, [id, navigate]);

  useEffect(() => {
    if (!isLoading && !data) {
      navigate(-1);
    }
  }, [isLoading, data, navigate]);

  if (!isLoading && !data) {
    return null;
  }

  return (
    <>
      <Header
        title={t('storeDirectory.details')}
        enableBackButton
        // closeButtonNavigation="/home"
      />
      <Stack className={styles.storeInfoContainer} gap="16px">
        <Box className={styles.storeNameWrapper}>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            <Typography variant="h3">{data.name}</Typography>
          )}
          {isLoading ? (
            <Skeleton variant="text" width={200} />
          ) : (
            <Typography variant="body1">
              {t('storeDirectory.storeId', {
                id: data.storeCode,
              })}
            </Typography>
          )}
        </Box>
        <Stack direction="row" gap="8px">
          <img src="/assets/images/phone_icon.svg" alt="" />
          {isLoading ? (
            <Skeleton variant="text" width={200} />
          ) : (
            <Typography variant="body1">{data.phone}</Typography>
          )}
        </Stack>
        <Stack direction="row" gap="8px">
          <img src="/assets/images/location_icon.svg" alt="" />
          {isLoading ? (
            <Skeleton variant="text" width={200} />
          ) : (
            <Typography variant="body1">{data.address}</Typography>
          )}
        </Stack>
      </Stack>
      <Box className={styles.partnerInfoContainer}>
        <Typography variant="h5">{t('storeDirectory.partners')}</Typography>
        <Stack direction="row">
          <AvatarGroup
            max={5}
            className={styles.partnersContainer}
            onClick={() => setPopupOpen(true)}
          >
            {isLoading
              ? Array.from(new Array(5)).map((_, index) => (
                  <Skeleton key={index} variant="circular">
                    <Avatar />
                  </Skeleton>
                ))
              : data.partners.map((user: partnerProps, index: number) => (
                  <Avatar key={index} alt={user.name} src={user.icon} />
                ))}
          </AvatarGroup>
        </Stack>
      </Box>
      <Popup
        id="partnerListPopup"
        isOpen={popupOpen}
        title={
          <Grid container alignItems="center" className={styles.popupHeader}>
            <Grid item xs="auto">
              <IconButton
                disableRipple
                className={styles.backButton}
                onClick={() => setPopupOpen(false)}
              >
                <img src="/assets/images/arrow_left.svg" alt="" />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">
                {t('storeDirectory.partners')}
              </Typography>
            </Grid>
          </Grid>
        }
        content={
          <Stack className={styles.popupContainer}>
            <SearchField
              id="search"
              name="search"
              placeholder={t('general.search')}
              value={searchValue}
              onChange={handleValueChange}
              onReset={handleResetField}
            />
            {filterPartnerList.length > 0 ? (
              <Grid item xs className={styles.popupContent}>
                <List>
                  {filterPartnerList.map(
                    (user: partnerProps, index: number) => (
                      <ListItem key={index} disablePadding>
                        <AccountItem
                          key={index}
                          name={user.name}
                          icon={user.icon}
                          description={user.jobTitle}
                          padding="12px 16px"
                          onClick={() =>
                            navigate(`/discover-people/profile/${user.id}`)
                          }
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Grid>
            ) : (
              <>
                {searchValue !== '' ? (
                  <NoResults resultsType="SEARCH" />
                ) : (
                  <NoResults />
                )}
              </>
            )}
          </Stack>
        }
        disableActions
        fullScreen
        setIsOpen={(isClose) => setPopupOpen(isClose)}
      />
    </>
  );
};

export default StoreDetails;
