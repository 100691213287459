import React, { FC, useEffect, useState } from "react";
import { InputFieldProps } from "../input-field";
import { List, Grid, Stack, Box, Tab, Tabs } from "@mui/material";
import styles from "./invitation-scope-field.module.scss";
import SelectFieldContainer from "../select-field-container";
import { useSelectHook } from "../select-field/useSelectHook";
import InvitedGroupIcon from "../invited-group-icon";
import { useTranslation } from "react-i18next";

import CheckboxTree from "react-checkbox-tree";
import SearchField from "../search-field";
import { GroupOption, IndividualsOption } from "./option";
import { findMultiLvObjectByValue } from "../../utility";
import { PaginationParamsType } from "../../services/common";
import { ObjectKey } from "../../interfaces/common-interface";
import IndividualsListSection from "./individuals-list-section";
import GroupListSection from "./group-lists-section";

export interface InvitationScopeFieldProps
  extends Omit<InputFieldProps, "value" | "multiline"> {
  type?: string;
  // eslint-disable-next-line
  value: any;
  popupLabel?: string;

  // eslint-disable-next-line
  handleHiddenValue?: (data: any) => void;
  handleHiddenClose?: (data: boolean) => void;
  handleOpen?: boolean;

  groupOptionsFetch: (data?: PaginationParamsType) => Promise<ObjectKey>;
  groupOptionsFetchKey: string;
  groupOptionsIdKey: string;
  individualsOptionsFetch: (data?: PaginationParamsType) => Promise<ObjectKey>;
  individualsOptionsFetchKey: string;
  individualsOptionsIdKey: string;
  individualsFilterFetch?: () => Promise<ObjectKey>;
  lockSelected?: ObjectKey | null;
}

const InvitationScopeField: FC<InvitationScopeFieldProps> = (props) => {
  const { t } = useTranslation();
  const {
    type,
    value,
    handleHiddenValue,
    handleHiddenClose,
    handleOpen,
    lockSelected,
    ...containerProps
  } = props;
  const {
    multipleValueDisplayRef,
    selectedValue,
    handleSelectOption,
    handleReset,
  } = useSelectHook({
    value,
    multiple: true,
  });
  const [initListKey, setInitListKey] = useState("");

  const handleConfirmSelected = (callback: () => void) => {
    // console.log("handleConfirmSelected", displayValue);
    if (type === "hidden") {
      handleHiddenValue &&
        handleHiddenValue({ data: displayValue, isOpen: false });
    }
    containerProps.onChange(selectedValue);
    callback();
  };
  const handleClose = () => {
    if (type === "hidden") {
      handleHiddenClose && handleHiddenClose(false);
    }
    handleReset();
  };

  const [tab, setTab] = useState("group");
  const [displayValue, setDisplayValue] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    let outputValue: string | undefined = undefined;
    let outputValueArray: Array<string> = [];

    if (selectedValue.group.length > 0) {
      const groupArray = selectedValue.group.map(
        (item: ObjectKey) => item.label
      );
      outputValueArray = [...outputValueArray, ...groupArray];
    }
    if (selectedValue.individuals.length > 0) {
      const individualsArray = selectedValue.individuals.map(
        (item: ObjectKey) => item.name
      );
      outputValueArray = [...outputValueArray, ...individualsArray];
    }

    if (outputValueArray.length > 0) {
      outputValue = outputValueArray.join(", ");
    }

    if (multipleValueDisplayRef.current) {
      multipleValueDisplayRef.current.innerHTML = outputValue ?? "";
    }
    setDisplayValue(outputValue);
  }, [selectedValue]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    setInitListKey(newValue);
  };

  const SelectedList = () => {
    let count = 0;
    Object.keys(selectedValue).forEach((key) => {
      count = count + selectedValue[key].length;
    });

    if (count <= 0) return null;

    const groupArray = selectedValue.group;
    const individualsArray = selectedValue.individuals;

    const handleRemoveSelected = (
      option: any,
      key: string,
      type: "group" | "individuals"
    ) => {
      let selected = selectedValue[type].filter(
        (item: ObjectKey) => item[key] !== option[key]
      );

      if (type === "group") {
        if (option.districtValue) {
          selected = selected.filter(
            (item: ObjectKey) => item[key] !== option.districtValue
          );
        }
        if (option.operationValue) {
          selected = selected.filter(
            (item: ObjectKey) => item[key] !== option.operationValue
          );
        }
      }

      handleSelectOption({
        ...selectedValue,
        [type]: selected,
      });
    };

    return (
      <List className={styles.optionsList}>
        {groupArray.map((option: ObjectKey, index: number) => {
          const optionID = `groupSelectOption_${option.value}`;
          const isLocked = Boolean(
            lockSelected?.group?.find(
              (item: ObjectKey) => item.value === option.value
            )
          );
          return (
            <GroupOption
              key={index}
              option={option}
              isSelected={true}
              isLocked={isLocked}
              id={optionID}
              forRemove
              onClick={() => {
                if (!isLocked) handleRemoveSelected(option, "value", "group");
              }}
            />
          );
        })}
        {individualsArray.map((option: ObjectKey, index: number) => {
          const optionID = `individualsSelectOption_${option.id}`;
          const isLocked = Boolean(
            lockSelected?.individuals?.find(
              (item: ObjectKey) => item.id === option.id
            )
          );
          return (
            <IndividualsOption
              key={index}
              option={option}
              isSelected={true}
              isLocked={isLocked}
              id={optionID}
              forRemove
              onClick={() => {
                if (!isLocked)
                  handleRemoveSelected(option, "id", "individuals");
              }}
            />
          );
        })}
      </List>
    );
  };

  const handleClear = () => {
    if (lockSelected) {
      handleSelectOption(lockSelected);
    } else {
      handleSelectOption({ group: [], individuals: [] });
    }
    if (multipleValueDisplayRef.current) {
      multipleValueDisplayRef.current.innerHTML = "";
    }
  };

  useEffect(() => {
    if (handleOpen && initListKey === "") {
      setInitListKey(tab);
    }
  }, [handleOpen]);

  return (
    <SelectFieldContainer
      {...containerProps}
      ref={multipleValueDisplayRef}
      disableLabel
      multiple
      value={displayValue}
      selectedValue={selectedValue}
      selectedList={<SelectedList />}
      onConfirmSelected={handleConfirmSelected}
      onClear={handleClear}
      onCancel={handleClose}
      handleOpen={handleOpen}
      type={type}
      onClickField={() => {
        if (initListKey === "") setInitListKey(tab);
      }}
    >
      <Stack className={styles.invitationScopeContainer}>
        <Box>
          <Tabs value={tab} onChange={handleChange}>
            <Tab id="groupTab" label={t("event.groups")} value="group" />
            <Tab
              id="individualsTab"
              label={t("event.individuals")}
              value="individuals"
            />
          </Tabs>
        </Box>
        <Grid item xs className={styles.invitationScopeTabPanelSection}>
          <Box
            data-hidden={tab !== "group"}
            className={styles.invitationScopeTabPanel}
          >
            <GroupListSection
              value={selectedValue.group as Array<ObjectKey>}
              initList={initListKey === "group"}
              listFetch={props.groupOptionsFetch}
              listFetchKey={props.groupOptionsFetchKey}
              onClick={(selectedId: Array<ObjectKey>) => {
                handleSelectOption({
                  ...selectedValue,
                  group: selectedId,
                });
              }}
              lockSelected={lockSelected ? lockSelected.group : null}
            />
          </Box>
          <Box
            data-hidden={tab !== "individuals"}
            className={styles.invitationScopeTabPanel}
          >
            <IndividualsListSection
              value={selectedValue.individuals as Array<ObjectKey>}
              initList={initListKey === "individuals"}
              listFetch={props.individualsOptionsFetch}
              listFetchKey={props.individualsOptionsFetchKey}
              filterFetch={props.individualsFilterFetch}
              onClick={(selectedId: Array<ObjectKey>) => {
                handleSelectOption({
                  ...selectedValue,
                  individuals: selectedId,
                });
              }}
              lockSelected={lockSelected ? lockSelected.individuals : null}
            />
          </Box>
        </Grid>
      </Stack>
    </SelectFieldContainer>
  );
};

export default InvitationScopeField;
