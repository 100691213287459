import React, { FC, useEffect, useState } from "react";
import styles from "../event/eventDetail.module.scss";
import { RoundButton } from "../../components";
import { Box, Grid, Link, Skeleton, Stack, Typography } from "@mui/material";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { convertDateWithWeek, convertDateTime } from "../../utility";
import { ObjectKey } from "../../interfaces/common-interface";
import moment from "moment";
import parse from "html-react-parser";

const MsTeamsEventDetail: FC = () => {
  const { t } = useTranslation();
  const [readMoreDesc, setReadMoreDesc] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [detail, setDetail] = useState<ObjectKey>(null!);
  const location = useLocation();
  const navigate = useNavigate();

  function handleClickReadMore() {
    setReadMoreDesc(true);
  }

  useEffect(() => {
    if (!location.state) {
      navigate("/calendar");
    } else {
      // console.log("location", location);
      const event = location.state;
      const dom = document.createElement("div");
      dom.innerHTML = event.description;
      // const content = dom.getElementsByTagName("p").item(0)?.innerHTML;
      const content = dom.innerHTML;
      const joinLink = dom
        .getElementsByClassName("me-email-headline")
        .item(0)
        ?.getAttribute("href");

      // console.log("joinLink", joinLink);
      let meetingId = "";
      let passcode = "";

      const emailContent = dom.getElementsByClassName("me-email-text").item(0);
      if (emailContent) {
        const teamsDetails = emailContent.querySelectorAll("span");
        let havePasscode = false;
        let havePasscodeTitle = "";
        teamsDetails.forEach((teamsDetail) => {
          if (havePasscode) {
            passcode = teamsDetail.textContent
              ? havePasscodeTitle +
                " " +
                teamsDetail.textContent.replace("\n", "")
              : "";
          } else if (teamsDetail.textContent?.includes("Passcode:")) {
            havePasscode = true;
            havePasscodeTitle = teamsDetail.textContent ?? "";
          } else if (teamsDetail.dataset?.tid === "meeting-code") {
            meetingId = teamsDetail.textContent?.replace("\n", " ") ?? "";
          }
        });
      }

      setDetail({
        id: event.id,
        name: event.title,
        description: content,
        // location: {
        //   id: "",
        //   name: "",
        //   address: "",
        // },
        location: event.location,
        startsFrom: moment.unix(event.startDate).format(),
        endsAt: moment.unix(event.endDate).format(),
        isOnlineMeeting: event.isOnlineMeeting,
        joinLink,
        meetingId,
        passcode,
      });
      setIsLoading(false);
    }
  }, [location.state]);

  const handleJoinTeams = () => {
    if (detail.joinLink) {
      window.open(detail.joinLink, "_blank");
    }
  };

  return (
    <>
      <Header title={t("myCalendar.eventDetails.title")} enableBackButton />
      <Grid container item xs flexWrap="wrap" className={styles.eventContainer}>
        {isLoading ? (
          <>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Skeleton animation="wave" height={56} />
              <Skeleton animation="wave" width="60%" height={28} />
              <Skeleton animation="wave" width="60%" height={28} />
              <Skeleton animation="wave" width="80%" height={28} />
              <Skeleton animation="wave" width="80%" height={28} />
              <Skeleton animation="wave" width="40%" height={28} />
            </Stack>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h5">
                {t("myCalendar.eventDetails.about")}
              </Typography>
              <Skeleton animation="wave" height={60} variant="rectangular" />
            </Stack>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h5">
                {t("myCalendar.eventDetails.eventDetails")}
              </Typography>
              <Skeleton animation="wave" height={110} variant="rectangular" />
            </Stack>
          </>
        ) : (
          <>
            <Stack gap={2} className={styles.eventInformationCard}>
              <Typography variant="h3" lineHeight="normal">
                {detail.name}
              </Typography>
              <Stack gap={2}>
                <EventDetailItem iconUrl="/assets/images/eventIcon_calendar.svg">
                  <Typography variant="body1">
                    {convertDateWithWeek(detail.startsFrom)}
                  </Typography>
                </EventDetailItem>
                <EventDetailItem iconUrl="/assets/images/eventIcon_clock_two.svg">
                  <Typography variant="body1">{`${convertDateTime(
                    detail.startsFrom
                  )} - ${convertDateTime(detail.endsAt)}`}</Typography>
                </EventDetailItem>
                {detail.location && (
                  <EventDetailItem iconUrl="/assets/images/eventIcon_location_pin.svg">
                    <Typography variant="body1">{detail.location}</Typography>
                    {detail.location.name && (
                      <Typography variant="body1">
                        {detail.location.name}
                      </Typography>
                    )}
                    {detail.location.address && (
                      <Typography variant="body2">
                        {detail.location.address}
                      </Typography>
                    )}
                  </EventDetailItem>
                )}
              </Stack>
            </Stack>
            {detail.description !== "" && (
              <Stack gap={2} className={styles.eventInformationCard}>
                <Typography variant="h5">
                  {t("myCalendar.eventDetails.about")}
                </Typography>
                <Box className={styles.eventInformationAbout}>
                  <Typography
                    component="div"
                    variant="body1"
                    className={`
                      ${styles.eventInformationAboutDescription}
                      ${readMoreDesc ? styles.more : ""}
                    `}
                  >
                    {parse(detail.description)}
                  </Typography>
                  <Link
                    id="readMoreDescriptionBtn"
                    component="button"
                    typography="h5"
                    disabled={readMoreDesc}
                    onClick={handleClickReadMore}
                  >
                    {t("myCalendar.eventDetails.readMore")}
                  </Link>
                </Box>
              </Stack>
            )}
            {detail.isOnlineMeeting && (
              <Stack gap={2} className={styles.eventInformationCard}>
                <Typography variant="h5">
                  {t("myCalendar.eventDetails.eventDetails")}
                </Typography>
                <Box>
                  {detail.joinLink && detail.joinLink !== "" && (
                    <>
                      <Typography>
                        {t("myCalendar.eventDetails.link")}
                        {":"}
                      </Typography>
                      <Link
                        id="joinLink"
                        href={detail.joinLink}
                        target="_blank"
                      >
                        {detail.joinLink}
                      </Link>
                    </>
                  )}
                  <Stack gap={1} paddingTop={1}>
                    {detail.meetingId !== "" && (
                      <Typography>{detail.meetingId}</Typography>
                    )}
                    {detail.passcode !== "" && (
                      <Typography>{detail.passcode}</Typography>
                    )}
                  </Stack>
                </Box>
              </Stack>
            )}
          </>
        )}
      </Grid>
      {detail && detail.joinLink !== "" && (
        <Grid
          container
          alignItems="center"
          className={styles.registerActionsContainer}
        >
          <Grid item xs="auto" paddingRight={1}>
            <img
              src="/assets/images/teams_logo.svg"
              alt={t("myCalendar.eventDetails.teams")}
            />
          </Grid>
          <Grid item xs paddingRight={1}>
            <Typography>{t("myCalendar.eventDetails.teams")}</Typography>
          </Grid>
          <Grid item xs="auto">
            <RoundButton
              id="joinTeamsBtn"
              size="small"
              className="small"
              onClick={handleJoinTeams}
            >
              {t("myCalendar.eventDetails.joinNow")}
            </RoundButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

interface EventDetailItemProps {
  iconUrl: string;
  children: React.ReactNode;
}

const EventDetailItem: FC<EventDetailItemProps> = (props) => {
  return (
    <Grid
      container
      gap={1}
      alignItems="flex-start"
      className={styles.eventInformationItem}
    >
      <Grid item xs="auto" className={styles.itemIcon}>
        <img src={props.iconUrl} alt="" />
      </Grid>
      <Grid item xs className={styles.itemContent}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default MsTeamsEventDetail;
