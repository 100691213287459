import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AttachmentsType,
  AttendeesType,
  EventDetailType,
} from "../../interfaces/event-interface";
import moment from "moment";
import { ObjectKey } from "../../interfaces/common-interface";
import {
  getEventDetail,
  getEventAttendees,
  getEventAttachments,
} from "../../services/event";
import { getApiDataByLang, getDropdownListOnStore } from "../../utility";
import { getStoresList } from "../../services/common";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState } from "../../reducers/user-slice";
import { use } from "i18next";

interface EventDetailContextType {
  detail: EventDetailType;
  isAdmin: boolean;
  isAssignedEventAdmin: boolean;
  reloadKey: string;
  setReloadKey: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const initDetail: EventDetailType = {
  id: "",
  name: "",
  description: "",
  imageUrl: "",
  startsFrom: "",
  endsAt: "",
  category: null,
  status: "open",
  location: {
    id: "",
    name: "",
    address: "",
  },
  checkIn: [],
  allowAttendanceWithQrCode: false,
  attachments: undefined,
  registrationPeriod: {
    startsFrom: "",
    endsAt: undefined,
  },
  maxCapacity: 0,
  sendReminder: false,
  allAttendeesCount: 0,
  checkedAttendeesCount: 0,
};

const EventDetailContext = createContext<EventDetailContextType>({
  detail: initDetail,
  isAdmin: false,
  isAssignedEventAdmin: false,
  reloadKey: "",
  setReloadKey: () => "",
  isLoading: true,
  setIsLoading: () => true,
});

export const EventDetailProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [detail, setDetail] = useState<EventDetailType>(initDetail);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadKey, setReloadKey] = useState("");
  const navigate = useNavigate();
  let { id, groupId } = useParams();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [eventAdminList, setEventAdminList] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAssignedEventAdmin, setIsAssignedEventAdmin] = useState(false);
  const getDetail = React.useCallback(
    async (eventId: string) => {
      let active = true;

      try {
        const response = await getEventDetail(eventId);
        const attendeesResponse = await getEventAttendees(eventId);
        const { eventAttendees } = attendeesResponse.data;
        let attendeesList: AttendeesType[] = [];
        if (eventAttendees && eventAttendees.length > 0) {
          attendeesList = convertAttendees(eventAttendees);
        }

        const eventInfo = response.data.event;
        let convertEventDetail: EventDetailType = detail;

        if (eventInfo.isExternalLink) {
          convertEventDetail = {
            ...convertEventDetail,
            externalLink: eventInfo.externalLinkURL,
            externalLinkInformation: eventInfo.externalLinkInfo,
          };
        }

        if (eventInfo.isEventSurvey) {
          convertEventDetail = {
            ...convertEventDetail,
            survey: eventInfo.survey,
          };
        }

        if (
          eventInfo.eventAttachments &&
          eventInfo.eventAttachments.length > 0
        ) {
          convertEventDetail = {
            ...convertEventDetail,
            attachments: convertAttachments(eventInfo.eventAttachments),
          };
        }

        const currentLocation =
          eventInfo.eventLocationId &&
          eventInfo.eventLocation &&
          typeof eventInfo.eventLocation === "object"
            ? {
                id: eventInfo.eventLocation._id,
                name: eventInfo.eventLocation.storeName,
                address: eventInfo.eventLocation.address,
              }
            : {
                id: "",
                name: eventInfo.eventLocation,
                address: "",
              };

        // let eventStatus = "upcoming";
        // if (typeof eventInfo.status === "string") {
        //   eventStatus =
        //     eventInfo.status.charAt(0).toLowerCase() +
        //     eventInfo.status.slice(1).replace("-", "");
        // } else {
        //   if (eventInfo.status.event === "Ended") {
        //     eventStatus = eventInfo.status.event.toLowerCase();
        //   } else if (eventInfo.status.event === "Open") {
        //     eventStatus =
        //       eventInfo.status.register.charAt(0).toLowerCase() +
        //       eventInfo.status.register.slice(1).replace("-", "");
        //   }
        // }
        let eventStatus =
          eventInfo.status.register.charAt(0).toLowerCase() +
          eventInfo.status.register.slice(1).replace("-", "");

        convertEventDetail = {
          ...convertEventDetail,
          id: eventInfo._id,
          createBy: eventInfo.createBy,
          partnerNetworkGroupId: eventInfo?.partnerNetworkGroupId ?? null,
          name:
            typeof eventInfo.eventName === "string"
              ? eventInfo.eventName
              : getApiDataByLang(eventInfo.eventName),
          description:
            typeof eventInfo.description === "string"
              ? eventInfo.description
              : getApiDataByLang(eventInfo.description),
          imageUrl: eventInfo.eventCoverImage,
          startsFrom: moment.unix(eventInfo.eventStartTimestamp).format(),
          endsAt: moment.unix(eventInfo.eventEndTimestamp).format(),
          category: eventInfo.category
            ? {
                id: eventInfo.category._id,
                name: getApiDataByLang(eventInfo.category.categoryName),
                bgColor: eventInfo.category.color,
              }
            : null,
          // status: response.data.isUserRegistered ? "registered" : "open",
          status: eventStatus as any,
          location: currentLocation,
          allowAttendanceWithQrCode: eventInfo.isQRCodeAttend,
          registrationPeriod: {
            startsFrom: moment.unix(eventInfo.registrationPeriodStart).format(),
            endsAt: eventInfo.registrationPeriodEnd
              ? moment.unix(eventInfo.registrationPeriodEnd).format()
              : undefined,
          },
          maxCapacity: eventInfo.maxCapacity ?? 0,
          sendReminder: eventInfo.isSendReminder,
          allAttendeesCount: response.data.allAttendeesCount,
          checkedAttendeesCount: response.data.checkedAttendeesCount,
          checkIn: attendeesList,
        };
        const eventAdminId = eventInfo.eventAdmin.map(
          (admin: ObjectKey) => admin.partnerId
        );
        setEventAdminList([...eventAdminId, eventInfo.createBy]);
        if (eventAdminId.includes(userState.partnerId)) {
          setIsAssignedEventAdmin(true);
        }
        if (active) {
          setDetail(convertEventDetail);
        }
      } catch (error: any) {
        // handle redirect to event list page and show error when get detail api fail
        // console.log("error", error?.response?.data.status);
        if (error?.response?.data.status === 4111) {
          // console.log("groupId", groupId);
          navigate(
            groupId
              ? `/partner-network/event/${groupId}?error=permission`
              : "/event?error=permission"
          );
        } else {
          navigate("/event");
        }
        throw error;
      } finally {
        setIsLoading(false);
        setReloadKey("");
      }

      return () => {
        active = false;
      };
    },
    [id]
  );

  useEffect(() => {
    if (userState.partnerId && eventAdminList.includes(userState.partnerId)) {
      setIsAdmin(true);
    }
  }, [userState.partnerId, eventAdminList]);

  const convertAttendees = (list: ObjectKey[]): AttendeesType[] => {
    return list.map((data: ObjectKey, index: number) => {
      return {
        id: data.partnerId ?? data._id,
        name: data.userName ?? `Demo Name - ${data.partnerId ?? data._id}`,
        avatar: data.profileUrl ?? "/assets/images/profileIcon_1.png",
        type: index <= 2 ? "OPERATIONS" : "SUPPORT_CENTER",
        checkTime: data.isAttended
          ? moment.unix(data.registerTime ?? data.createTime).format()
          : null,
      };
    });
  };

  const getAttendees = React.useCallback(
    async (eventId: string) => {
      let active = true;

      try {
        const response = await getEventAttendees(eventId);
        let attendeesList: AttendeesType[] = [];
        if (
          response.data.eventAttendees &&
          response.data.eventAttendees.length > 0
        ) {
          attendeesList = convertAttendees(response.data.eventAttendees);
        }

        if (active) {
          setDetail((prev) => ({
            ...prev,
            checkIn: attendeesList,
          }));
        }
      } catch (error) {
        throw error;
      } finally {
        setReloadKey("");
        if (isLoading) setIsLoading(false);
      }

      return () => {
        active = false;
      };
    },
    [id]
  );

  const convertAttachments = (list: ObjectKey[]): AttachmentsType[] => {
    return list.map((data: ObjectKey, index: number) => {
      return {
        id: data._id ?? index.toString(),
        name: data.attachmentName,
        type: data.attachmentExtension,
        url: data.attachmentUrl,
      };
    });
  };

  const getAttachments = React.useCallback(
    async (eventId: string) => {
      let active = true;

      try {
        const response = await getEventAttachments(eventId);
        let attachmentsList: AttachmentsType[] = [];
        if (
          response.data.eventAttachments.eventAttachments &&
          response.data.eventAttachments.eventAttachments.length > 0
        ) {
          attachmentsList = convertAttachments(
            response.data.eventAttachments.eventAttachments
          );
        }

        if (active) {
          setDetail((prev) => ({
            ...prev,
            attachments: attachmentsList,
          }));
        }
      } catch (error) {
        throw error;
      } finally {
        setReloadKey("");
        if (isLoading) setIsLoading(false);
      }

      return () => {
        active = false;
      };
    },
    [id]
  );

  useEffect(() => {
    if (!id) {
      navigate("/event");
    } else {
      if (reloadKey !== "") setIsLoading(true);

      switch (reloadKey) {
        case "detail":
          setDetail(initDetail);
          getDetail(id);
          break;
        case "attendees":
          getAttendees(id);
          break;
        case "attachments":
          getAttachments(id);
          break;
        default:
          getDetail(id);
          break;
      }
    }
  }, [id, getDetail, getAttendees, getAttachments, reloadKey]);

  // useEffect(() => {
  //   if (detail.id !== "" && isLoading) {
  //     getAttendees(detail.id);
  //     // getAttachments(detail.id);
  //   }
  // }, [detail.id]);

  // useEffect(() => {
  //   console.log("detail", detail);
  // }, [detail]);

  return (
    <EventDetailContext.Provider
      value={{
        detail,
        isAdmin,
        isAssignedEventAdmin,
        reloadKey,
        setReloadKey,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </EventDetailContext.Provider>
  );
};

export default EventDetailContext;
