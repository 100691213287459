import React from "react";
import styles from "./content-hub.module.scss";
import { listDetailType } from "./interface";
import { convertDate } from "../../utility";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const ListItem = (props: listDetailType) => {
  const { t } = useTranslation();
  const {
    onDetailClick,
    onEyeBtnClick,
    onEditClick,
    isGroup = false,
    isEdit = false,
    ...rest
  } = props;

  return (
    <div
      className={`${styles.item}${isGroup ? ` ${styles.groupItem}` : ""}`}
      {...(!isEdit &&
        onDetailClick && { onClick: () => onDetailClick(props.id) })}
    >
      <div className={styles.itemImage}>
        <img src={props.image} alt={props.title} />
      </div>
      <Box className={styles.itemContent}>
        <Box>
          {props.tab && (
            <label
              className={styles.categoryLabel}
              style={{ backgroundColor: props.color }}
            >
              {props.tab}
            </label>
          )}
          <div className={styles.itemTitle}>{props.title}</div>
        </Box>

        {props.date && (
          <div className={styles.date}>
            {convertDate(props.date, false, true)}
          </div>
        )}
        {isGroup && props.subtitle && props.post ? (
          <div className={styles.groupInfo}>
            {props.subtitle && (
              <div className={styles.date}>
                {" "}
                <img
                  src="/assets/images/homepage_member_total.svg"
                  alt="total member"
                />
                {props.subtitle}
              </div>
            )}
            {props.post && (
              <div className={styles.date}>
                <img
                  src="/assets/images/homepage_post_total.svg"
                  alt="total posts"
                />
                {props.post}
              </div>
            )}
          </div>
        ) : (
          <>
            {props.subtitle && (
              <div className={styles.date}>{props.subtitle}</div>
            )}
          </>
        )}
        {isEdit && (
          <>
            <div className={styles.groupEdit}>
              {onEyeBtnClick && (
                <button
                  className={styles.img}
                  onClick={() => {
                    // console.log(props);
                    onEyeBtnClick({ id: props.id, isActive: props.isActive });
                  }}
                >
                  <img
                    src={`/assets/images/${
                      props.isActive ? "eye" : "eye_slash"
                    }.svg`}
                    alt="is active?"
                  />
                </button>
              )}
              {onEditClick && (
                <button onClick={() => onEditClick(props.id)}>
                  {t("general.edit")}
                </button>
              )}
            </div>
          </>
        )}
      </Box>
    </div>
  );
};

export default ListItem;
