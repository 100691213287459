import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./survey.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomSwiper, CustomTab } from "../../components";
import { useTranslation } from "react-i18next";
import { getSurveyTemplateList } from "../../services/survey";
import { getApiDataByLang } from "../../utility";
interface SurveyProps {
  title: string;
  image: string;
  startDate: string;
  endDate: string;
  status: string;
}
interface SurveyRecentSurveyProps {
  data: Array<SurveyProps>;
}

function SurveyTemplate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    getSurveyTemplateList().then((response) => {
      const filteredData = response.data.surveyTemplate?.filter(
        (item: any) => item.status === "Active"
      );
      setData(filteredData);
      setIsLoading(false);
    });
  }, []);

  const getSwiperData = (data: any) => {
    const item = data.content;
    return (
      <Stack
        className={styles.templateWrapper}
        gap="8px"
        onClick={() => navigate(`/survey/create/${item._id}`)}
      >
        <img
          src={item.thumbUrl
            .replace("_thumb.png", ".png")
            .replace("_thumb.jpeg", ".jpeg")}
          alt={item.surveyTemplateName[0].value}
        />
        <Typography variant="body1" className={styles.cardTitle}>
          {getApiDataByLang(item.surveyTemplateName)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack gap="8px" className={styles.sectionContainer}>
      <Stack direction="row">
        <Typography variant="h5" className={styles.sectionHeader}>
          {t("survey.template")}
        </Typography>
        <Typography
          variant="body2"
          className={styles.sectionBtn}
          onClick={() => navigate("/survey/template")}
        >
          {t("survey.seeAll")}
        </Typography>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <CustomSwiper
          data={data}
          swiperId={`mission-swiper`}
          content={getSwiperData}
          slidesPerView={2.3}
          slidesPerGroup={1}
          spaceBetween={8}
        />
      </Box>
    </Stack>
  );
}

export default SurveyTemplate;
