import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./leaderboard.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface CampaignProps {
  title: string;
  caption: string;
  data: Array<any>;
}

function LeaderboardCampaign(props: CampaignProps) {
  const { title, caption, data } = props;
  const navigate = useNavigate();

  const campaignItem = (item: any) => (
    <Stack
      direction="row"
      gap="16px"
      className={styles.campaignItemWrapper}
      onClick={() => navigate(`/leaderboard/reward-detail/${item._id}`)}
    >
      <img src={item.imageUrl}></img>
      <Typography variant="subtitle2">{item.prizeName}</Typography>
    </Stack>
  );
  return (
    <Box className={styles.campaignCointainer}>
      <Box className={styles.campaignDescCointainer}>
        <Typography variant="subtitle1" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={styles.caption}>
          {caption}
        </Typography>
        {/* {data && data.length === 0 && (
          <Typography variant="body1" className={styles.caption}>
            Start earning coffee cherries with every mission!!
          </Typography>
        )} */}
      </Box>
      {data && data.length > 0 && (
        <Stack className={styles.campaignItemCointainer}>
          {data.map((item) => campaignItem(item))}
        </Stack>
      )}
    </Box>
  );
}

export default LeaderboardCampaign;
