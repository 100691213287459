import React, { FC, useState } from "react";
import InputField, { InputFieldProps } from "../input-field";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Radio,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import styles from "./dropdown-field.module.scss";
import { containsObject } from "../../utility";
import Popup from "../popup";

export interface DropdownFieldProps
  extends Omit<InputFieldProps, "value" | "multiline"> {
  startAdornment?: React.ReactNode;
  // eslint-disable-next-line
  value: any;
  popupLabel?: string;
  // eslint-disable-next-line
  options: Array<any>;
  multiple?: boolean;
  disableLabel?: boolean;
  error?: boolean;
  // eslint-disable-next-line
  getOptionValue: (value: any) => string;
  // eslint-disable-next-line
  getOptionLabel: (value: any) => string;
  // eslint-disable-next-line
  optionsItem?: (
    value: any,
    id: string,
    classes?: string,
    isSelected?: boolean
  ) => React.ReactNode;
}

const DropdownField: FC<DropdownFieldProps> = (props) => {
  const {
    label,
    value,
    popupLabel,
    options,
    multiple,
    disableLabel,
    getOptionValue,
    getOptionLabel,
    optionsItem,
    onChange,
    ...inputProps
  } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>(value);

  React.useEffect(() => {
    if (
      selectedValue &&
      value &&
      getOptionValue(selectedValue) === getOptionValue(value)
    ) {
      return;
    } else {
      setSelectedValue(value);
    }
  }, [value]);

  const handleOpenPopup = () => {
    if (props.disabled) {
      return false;
    }
    setOpenPopup(true);
  };

  const checkOptionExistInArray = (option: any, existOption: Array<any>) => {
    if (containsObject(option, existOption)) {
      const removeSelectedValue = existOption.filter(
        // eslint-disable-next-line
        (value: any) => getOptionValue(value) !== getOptionValue(option)
      );
      return removeSelectedValue;
    } else {
      return [...existOption, option];
    }
  };

  const handleSelectOption = (option: any, isMultiple?: boolean) => {
    if (isMultiple) {
      let newSelectedValue = selectedValue;

      if (Array.isArray(option)) {
        option.forEach((newOption: any) => {
          newSelectedValue = checkOptionExistInArray(
            newOption,
            newSelectedValue
          );
        });
      } else {
        newSelectedValue = checkOptionExistInArray(option, newSelectedValue);
      }

      setSelectedValue(newSelectedValue);
    } else {
      setSelectedValue(option);
      handleClosePopup(option);
    }
  };

  const Option = (props: {
    // eslint-disable-next-line
    option: any;
    id: string;
    onClick: () => void;
  }) => {
    let isSelected = false;
    if (selectedValue) {
      if (multiple) {
        isSelected = containsObject(props.option, selectedValue);
      } else {
        isSelected =
          getOptionValue(selectedValue) === getOptionValue(props.option);
      }
    }

    return (
      <ListItem id={props.id} disablePadding>
        <ListItemButton
          className={`${styles.optionItem} ${
            isSelected && styles.optionItem__active
          }`}
          onClick={() => props.onClick()}
        >
          {optionsItem ? (
            optionsItem(props.option, props.id, "", isSelected)
          ) : (
            <>
              <ListItemText
                primary={getOptionLabel(props.option)}
                primaryTypographyProps={{ color: "text.primary" }}
              />
              <ListItemIcon>
                <Radio
                  disableRipple
                  checked={isSelected}
                  value={getOptionValue(props.option)}
                  inputProps={{ "aria-labelledby": props.id }}
                  tabIndex={-1}
                />
              </ListItemIcon>
            </>
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  const displayValue = () => {
    let output;
    if (multiple) {
      if (Array.isArray(value) && value.length > 0) {
        output = value.map((item: any) => getOptionLabel(item)).join(", ");
      }
    } else {
      if (value) {
        output = getOptionLabel(value);
      }
    }
    return output;
  };

  // eslint-disable-next-line
  const handleClosePopup = (selected: any) => {
    onChange(selected);
    setOpenPopup(false);
  };

  const inputLabel = () => {
    if (!disableLabel) return label;

    if (Array.isArray(value)) {
      return value.length > 0 ? undefined : label;
    } else {
      return value ? undefined : label;
    }
  };

  return (
    <>
      <InputField
        {...inputProps}
        onChange={(e) => e.preventDefault()}
        onClick={handleOpenPopup}
        value={displayValue()}
        selectField
        label={inputLabel()}
        endAdornment={<img src="/assets/images/chevron_down.svg" alt="" />}
      />
      <Popup
        isOpen={openPopup}
        title={
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h4" color="text.primary">
                {popupLabel ?? label}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                disableRipple
                size="small"
                className={styles.closeButton}
                onClick={() => handleClosePopup(selectedValue)}
              >
                <img src="/assets/images/close_btn.svg" alt="" />
              </IconButton>
            </Grid>
          </Grid>
        }
        content={
          <List disablePadding className={styles.sectionList}>
            {options.map((option: any, index: number) => {
              const optionID = `${
                inputProps.name
              }DropdownOption_${getOptionValue(option)}`;
              return (
                <Option
                  key={index}
                  option={option}
                  id={optionID}
                  onClick={() => {
                    handleSelectOption(option, multiple);
                  }}
                />
              );
            })}
          </List>
        }
        setIsOpen={(isOpen: boolean) => handleClosePopup(selectedValue)}
        disableActions
      />
    </>
  );
};

export default DropdownField;
