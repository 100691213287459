import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./no-results.module.scss";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

interface NoResultsProps {
  resultsType?:
    | "DEFAULT"
    | "SEARCH"
    | "USER"
    | "REWARD"
    | "FILES"
    | "REPORTED_POST"
    | "SCHEDULED_POST"
    | string;
  title?: string;
  desc?: ReactNode;
  image?: string;
}

const NoResults: FC<NoResultsProps> = (props) => {
  const { resultsType = "DEFAULT", title, desc, image } = props;
  const { t } = useTranslation();
  let titleText = "";
  let descText = "";
  let imageSrc = "";

  switch (resultsType) {
    case "SEARCH":
      titleText = t("myCalendar.noResult.title");
      descText = t("myCalendar.noResult.description");
      imageSrc = "/assets/images/emptySearchResult.svg";
      break;
    case "USER":
      titleText = t("leaderboard.userNotFound");
      descText = t("leaderboard.userNotFoundMsg");
      imageSrc = "/assets/images/user_not_founds.png";
      break;
    case "REWARD":
      titleText = t("leaderboard.noReward");
      descText = t("leaderboard.noRewardDescription");
      imageSrc = "/assets/images/no_reward_image.svg";
      break;
    case "FILES":
      titleText = t("files.noFiles");
      descText = t("files.noFilesDescription");
      imageSrc = "/assets/images/files/Empty.svg";
      break;
    case "REPORTED_POST":
      titleText = t("reportedPost.noPost");
      descText = t("reportedPost.noPostMsg");
      imageSrc = "/assets/images/empty.svg";
      break;
    case "SCHEDULED_POST":
      titleText = t("scheduledPost.noPost");
      descText = t("scheduledPost.noPostMsg");
      imageSrc = "/assets/images/empty.svg";
      break;
    default:
      titleText = t("general.stayTuned");
      descText = t("general.stayTunedMsg");
      imageSrc = "/assets/images/empty.png";
      break;
  }

  function checkDescPropIsString() {
    if (desc && typeof desc !== "string" && typeof desc !== "number")
      return false;

    return true;
  }

  function outputDesc(text: string) {
    if (desc) return desc;
    return text;
  }

  return (
    <Box className={styles.emptyResultContainer}>
      <img src={image ?? imageSrc} alt="" />
      <Typography variant="h3">{title ?? titleText}</Typography>
      {checkDescPropIsString() ? (
        <Typography variant="body1" color={theme.vars.palette.black.opacity38}>
          {outputDesc(descText)}
        </Typography>
      ) : (
        outputDesc(descText)
      )}
    </Box>
  );
};

export default NoResults;
