const MuiInputBase = {
  styleOverrides: {
    root: {
      minHeight: 48,
      padding: 0,
    },
    input: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    inputSizeSmall: {},
  },
  defaultProps: {
    variant: "standard",
    fullWidth: true,
  },
};

export default MuiInputBase;
