import React, { FC, useEffect, useState } from "react";
import styles from "./calendar.module.scss";
import { Box, Fade, Grid } from "@mui/material";
import {
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar, DateView } from "@mui/x-date-pickers/DateCalendar";
import moment, { Moment } from "moment";
import Picker from "react-mobile-picker";

interface EventCalendarProps {
  value?: string | Moment | null;
  activeMonth?: number;
  activeYear?: number;
  highlightDayList?: Array<number>;
  disableHighlightToday?: boolean;
  disablePast?: boolean;
  isLoading?: boolean;
  type?: "date" | "time";
  onSelectDate: (date: Moment) => void;
  onSelectMonth?: (date: Moment) => void;
}

function ServerDay(
  props: PickersDayProps<Moment> & { highlightedDays?: number[] }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) > 0;

  return (
    <Grid className={styles.calendarDay}>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disableRipple
        className={styles.days}
        data-today={other.today}
        data-current-month={!outsideCurrentMonth}
      />
      {isSelected && <Box component={"span"} className={styles.event} />}
    </Grid>
  );
}

const Calendar: FC<EventCalendarProps> = React.memo((props) => {
  const { highlightDayList } = props;
  const initDate = props.value
    ? moment(props.value)
    : props.activeMonth &&
      props.activeYear &&
      props.activeMonth !== parseInt(moment().format("M")) &&
      props.activeYear !== parseInt(moment().format("YYYY"))
    ? moment(props.activeMonth + "-" + props.activeYear, "M-YYYY")
    : moment();

  const [selectedDate, setSelectedDate] = useState<Moment>(initDate);
  const [selectedMonthYear, setSelectedMonthYear] = useState<{
    month: string;
    year: string;
  }>({
    month: selectedDate.format("MMMM"),
    year: selectedDate.format("YYYY"),
  });
  const [viewMode, setViewMode] = useState<DateView>("day");

  const years = Array.from(Array(10), (_, index) => (index + 2023).toString());
  const months = Array.from(Array(12 - moment().month()), (_, index) =>
    moment(index + moment().month() + 1, "M").format("MMMM")
  );
  const allMonths = Array.from(Array(12), (_, index) =>
    moment(index + 1, "M").format("MMMM")
  );
  const [optionGroups, setOptionGroups] = useState({
    month: months,
    year: years,
  });

  const handleWheelPickerChange = (name: string, value: string) => {
    let updatedDateObject = {
      ...selectedMonthYear,
      [name]: value,
    };
    const valueMonthNum = parseInt(moment(value, "MMMM").format("M"));
    const selectedMonthNum = parseInt(
      moment(selectedMonthYear.month, "MMMM").format("M")
    );
    if (name === "month") {
      let selectedYearNum = parseInt(selectedMonthYear.year);

      if (valueMonthNum === 1 && selectedMonthNum === 12) {
        selectedYearNum = selectedYearNum + 1;
        updatedDateObject = {
          ...updatedDateObject,
          year: selectedYearNum.toString(),
        };
      } else if (valueMonthNum === 12 && selectedMonthNum === 1) {
        selectedYearNum = selectedYearNum - 1;
        updatedDateObject = {
          ...updatedDateObject,
          year: selectedYearNum.toString(),
        };
      }
    } else {
      if (
        value === moment().format("YYYY") &&
        selectedMonthNum < moment().month() + 1
      ) {
        updatedDateObject = {
          ...updatedDateObject,
          month: moment().format("MMMM"),
        };
      }
    }

    const updateMonthYear =
      updatedDateObject.year +
      "-" +
      moment(updatedDateObject.month, "MMMM").format("MM");
    const currentMonthYear =
      moment().isSame(updateMonthYear, "year") &&
      moment().isSame(updateMonthYear, "month");
    const currentYear = moment().isSame(updateMonthYear, "year");

    setSelectedMonthYear(updatedDateObject);

    if (currentYear) {
      setOptionGroups((prev) => ({
        ...prev,
        month: months,
      }));
    } else {
      setOptionGroups((prev) => ({
        ...prev,
        month: allMonths,
      }));
    }
    if (currentMonthYear) {
      setSelectedDate(moment());
      props.onSelectMonth && props.onSelectMonth(moment());
    } else {
      setSelectedDate(moment(updateMonthYear + "-01", "YYYY-MM-DD"));
      props.onSelectMonth &&
        props.onSelectMonth(moment(updateMonthYear + "-01", "YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    if (props.value) {
      const updateDate = moment(props.value);

      setSelectedDate(updateDate);
      setSelectedMonthYear({
        month: updateDate.format("MMMM"),
        year: updateDate.format("YYYY"),
      });
    }
  }, [props]);

  return (
    <Box className={styles.root}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateCalendar
          value={selectedDate}
          disableHighlightToday={props.disableHighlightToday}
          disablePast={props.disablePast}
          showDaysOutsideCurrentMonth
          className={styles.calendarContainer}
          loading={props.isLoading}
          dayOfWeekFormatter={(day: string) =>
            day.substring(0, 3).toUpperCase()
          }
          fixedWeekNumber={6}
          renderLoading={() => (
            <DayCalendarSkeleton className={styles.calendarSkeleton} />
          )}
          slots={{
            // eslint-disable-next-line
            day: ServerDay as any,
          }}
          slotProps={{
            day: {
              highlightedDays: highlightDayList,
              // eslint-disable-next-line
            } as any,
            leftArrowIcon: {
              className: styles.calendarArrow,
            },
            rightArrowIcon: {
              className: styles.calendarArrow,
            },
          }}
          onChange={(value: unknown) => {
            props.onSelectDate(value as Moment);
          }}
          onMonthChange={(date: unknown) => {
            handleWheelPickerChange(
              "month",
              moment(date as Moment).format("MMMM")
            );
          }}
          onViewChange={(view: DateView) => {
            setViewMode(view);
          }}
        />
      </LocalizationProvider>
      {viewMode !== "day" && (
        <Box className={styles.monthCalendarContainer}>
          <Fade in={true}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={styles.monthCalendarWrapper}
            >
              <Picker
                optionGroups={optionGroups}
                valueGroups={selectedMonthYear}
                onChange={handleWheelPickerChange}
                // wheel="normal"
                height={153}
                itemHeight={32}
              />
            </Grid>
          </Fade>
        </Box>
      )}
    </Box>
  );
});

export default Calendar;
