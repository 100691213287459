import { useEffect, useState } from 'react';

import moment from 'moment';

import { ObjectKey } from '../../interfaces/common-interface';

interface ReturnFunctionType {
  isDirty: boolean;
  formValue: FormValueType;
  handleInputFieldChange: (
    name: string,
    value: any,
    extraError?: Array<{ name: string; error: boolean }>
  ) => void;
  handleTextareaFieldChange: (name: string, value: any, limit?: number) => void;
  handleChangeDisplayLanguage: (selected: Array<string>) => void;
  handleToggleButtonChange: (name: string, checked: boolean) => void;
  checkValidation: () => boolean;
  convertFormValueToRequest: () => ObjectKey;
  // eslint-disable-next-line
  setInitFormValue: (data: FormValueType) => void;
}

interface FormValueSettingType {
  // eslint-disable-next-line
  value: any;
  isError: boolean;
  isRequired: boolean;
}

export interface FormValueType {
  [key: string]: FormValueSettingType;
}

export const useEventFormHook = (): ReturnFunctionType => {
  const nowDate = moment();
  const roundTime = 30 - (nowDate.minute() % 30);
  const roundedStartDate = nowDate.add(roundTime, 'minutes').format();
  const roundedEndDate = moment(roundedStartDate).add(1, 'hour').format();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<FormValueType>({
    cover: {
      value: null,
      isError: false,
      isRequired: true,
    },
    displayLanguage: {
      value: ['en'],
      isError: false,
      isRequired: true,
    },
    nameEN: {
      value: '',
      isError: false,
      isRequired: true,
    },
    descriptionEN: {
      value: '',
      isError: false,
      isRequired: true,
    },
    nameTC: {
      value: '',
      isError: false,
      isRequired: false,
    },
    descriptionTC: {
      value: '',
      isError: false,
      isRequired: false,
    },
    eventDate: {
      value: {
        startDate: roundedStartDate,
        endDate: roundedEndDate,
      },
      isError: false,
      isRequired: true,
    },
    location: {
      value: null,
      isError: false,
      isRequired: true,
    },
    isPublic: {
      value: null,
      isError: false,
      isRequired: true,
    },
    invitationScope: {
      value: {
        group: [],
        individuals: [],
      },
      isError: false,
      isRequired: false,
    },
    eventAdmin: {
      value: [],
      isError: false,
      isRequired: false,
    },
    externalLink: {
      value: false,
      isError: false,
      isRequired: false,
    },
    externalLinkUrl: {
      value: '',
      isError: false,
      isRequired: false,
    },
    additionalInformation: {
      value: '',
      isError: false,
      isRequired: false,
    },
    allowAttendanceWithQrCode: {
      value: false,
      isError: false,
      isRequired: false,
    },
    eventSurvey: {
      value: false,
      isError: false,
      isRequired: false,
    },
    selectSurvey: {
      value: null,
      isError: false,
      isRequired: false,
    },
    attachments: {
      value: [],
      isError: false,
      isRequired: false,
    },
    category: {
      value: null,
      isError: false,
      isRequired: true,
    },
    registrationPeriod: {
      value: {
        startDate: roundedStartDate,
        endDate: null,
      },
      isError: false,
      isRequired: true,
    },
    maxCapacity: {
      value: null,
      isError: false,
      isRequired: true,
    },
    maxCapacityLimit: {
      value: '',
      isError: false,
      isRequired: false,
    },
    sendReminder: {
      value: false,
      isError: false,
      isRequired: false,
    },
  });

  // useEffect(() => {
  //   console.log("formValue", formValue);
  // }, [formValue]);

  const handleInputFieldChange = (
    name: string,
    value: any,
    extraError?: Array<{ name: string; error: boolean }>
  ) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
      },
    };

    if (formValue[formName].isRequired) {
      let inValid = value === '' || !value;

      if (formName === 'registrationPeriod' && value.endDate === null) {
        inValid =
          moment(value.startDate).unix() >
          moment(formValue.eventDate.value.endDate).unix();
      } else if (formName === 'registrationPeriod' && value.endDate) {
        inValid =
          moment(value.endDate).unix() >
            moment(formValue.eventDate.value.endDate).unix() ||
          moment(value.startDate).unix() >
            moment(formValue.eventDate.value.endDate).unix();
      } else if (formName === 'eventDate') {
        inValid = moment().unix() > moment(value.endDate).unix();
      }
      updateFormValue = {
        [formName]: {
          ...updateFormValue[formName],
          isError: inValid,
        },
      };
    }

    if (formName === 'maxCapacity') {
      updateFormValue = {
        ...updateFormValue,
        ['maxCapacityLimit' as keyof typeof formValue]: {
          ...formValue.maxCapacityLimit,
          isRequired: value.value === 'LIMIT_TO',
        },
      };
    }

    if (formName === 'isPublic') {
      updateFormValue = {
        ...updateFormValue,
        ['invitationScope' as keyof typeof formValue]: {
          ...formValue.invitationScope,
          isRequired: value.id === 'private',
        },
      };
    }

    if (extraError) {
      extraError.forEach((item) => {
        console.log('extraError', item);
        updateFormValue = {
          ...updateFormValue,
          [item.name]: {
            ...updateFormValue[item.name],
            isError: item.error,
          },
        };
      });
    }
    // console.log('updateFormValue', updateFormValue);
    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleTextareaFieldChange = (
    name: string,
    value: any,
    limit?: number
  ) => {
    const formName = name as keyof typeof formValue;
    let inValid = formValue[formName].isRequired
      ? value === '' || !value
      : false;

    if (!inValid && limit) {
      inValid = value.length > limit;
    }

    const updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
        isError: inValid,
      },
    };

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleChangeDisplayLanguage = (selected: Array<string>) => {
    const haveEN = selected.some((lang: string) => lang.toLowerCase() === 'en');
    const haveTC = selected.some((lang: string) => lang.toLowerCase() === 'tc');

    const updateFormValue = {
      ...formValue,
      displayLanguage: {
        ...formValue.displayLanguage,
        value: selected,
      },
      nameEN: {
        ...formValue.nameEN,
        isRequired: haveEN,
      },
      descriptionEN: {
        ...formValue.descriptionEN,
        isRequired: haveEN,
      },
      nameTC: {
        ...formValue.nameTC,
        isRequired: haveTC,
      },
      descriptionTC: {
        ...formValue.descriptionTC,
        isRequired: haveTC,
      },
    };

    setFormValue(updateFormValue);
  };

  const handleToggleButtonChange = (name: string, checked: boolean) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: checked,
      },
    };

    if (formName === 'externalLink') {
      updateFormValue = {
        ...updateFormValue,
        ['externalLinkUrl' as keyof typeof formValue]: {
          ...formValue.externalLinkUrl,
          isRequired: checked,
        },
        ['additionalInformation' as keyof typeof formValue]: {
          ...formValue.additionalInformation,
          isRequired: checked,
        },
      };
    }

    if (formName === 'eventSurvey') {
      updateFormValue = {
        ...updateFormValue,
        ['selectSurvey' as keyof typeof formValue]: {
          ...formValue.selectSurvey,
          isRequired: checked,
        },
      };
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const checkValidation = () => {
    const requiredValue = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].isRequired)
    );

    let errorField: Array<string> = [];
    for (const [key, value] of Object.entries(requiredValue)) {
      if (!value.value) {
        errorField.push(key);
        if (key === 'maxCapacityLimit') {
          errorField.push('maxCapacity');
        }
      } else if (Array.isArray(value.value)) {
        if (value.value.length <= 0) {
          errorField.push(key);
        }
      } else if (typeof value.value === 'object') {
        if (key === 'invitationScope') {
          const isFill = Object.entries(value.value).some(
            ([key]) => value.value[key].length > 0
          );
          if (!isFill) errorField.push(key);
        } else if (key === 'eventDate') {
          const isFill = value.value.startDate && value.value.endDate;
          const isEndFill =
            moment().unix() < moment(value.value.endDate).unix();
          if (!isFill || !isEndFill) errorField.push(key);
        } else if (key === 'registrationPeriod') {
          const isFill =
            value.value.startDate <= formValue.eventDate.value.endDate;
          const isEndFill =
            !value.value.endDate ||
            value.value.endDate <= formValue.eventDate.value.endDate;
          if (!isFill || !isEndFill) errorField.push(key);
        } else {
          if (Object.keys(value.value).length <= 0) {
            errorField.push(key);
          }
        }
      }
    }

    const existErrorField = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].isError)
    );

    Object.keys(existErrorField).forEach((key: string) => {
      if (!errorField.includes(key)) {
        errorField.push(key);
      }
    });
    if (errorField.length > 0) {
      let errorFieldObj: FormValueType = formValue;
      errorField.forEach((key: string) => {
        errorFieldObj = {
          ...errorFieldObj,
          [key]: {
            ...errorFieldObj[key],
            isError: true,
          },
        };
      });
      setFormValue(errorFieldObj);
      return false;
    }

    return true;
  };

  const convertFormValueToRequest = () => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;
    const registrationPeriodEnd = getFieldValue('registrationPeriod').endDate;
    const haveCapacity =
      getFieldValue('maxCapacity').value === 'LIMIT_TO'
        ? getFieldValue('maxCapacityLimit')
        : null;

    const nameList = formValue.displayLanguage.value.map((lang: string) => ({
      lang: lang.toLowerCase(),
      value: getFieldValue('name' + lang.toLocaleUpperCase()),
    }));
    const descriptionList = formValue.displayLanguage.value.map(
      (lang: string) => ({
        lang: lang.toLowerCase(),
        value: getFieldValue('description' + lang.toLocaleUpperCase()),
      })
    );
    const inviteScopeRequest = Object.entries(
      getFieldValue('invitationScope')
    ).map(([key, value]) => ({
      scopeType: key === 'group' ? 'stores' : 'partners',
      value: (value as Array<ObjectKey>).map((item: ObjectKey) =>
        key === 'group' ? item.value : item.id
      ),
    }));

    const attachmentsRequest = getFieldValue('attachments').map(
      (item: ObjectKey) => {
        const fileDomain = item.data.split('//').pop().split('/')[0];
        return {
          attachmentName: item.fileName.split('.')[0],
          attachmentPath: item.data
            .split('//')
            .pop()
            .replace(fileDomain + '/', ''),
          attachmentExtension: item.fileName.split('.').pop(),
          attachmentUrl: item.data,
          attachmentSize: item.size,
        };
      }
    );

    return {
      eventName: nameList,
      description: descriptionList,
      eventStartTimestamp: moment(getFieldValue('eventDate').startDate).unix(),
      eventEndTimestamp: moment(getFieldValue('eventDate').endDate).unix(),
      eventLocation: getFieldValue('location').address,
      eventLocationId:
        getFieldValue('location').id === '-999'
          ? null
          : getFieldValue('location').id,
      eventCoverImage: getFieldValue('cover').data,
      inviteScope: inviteScopeRequest,
      eventAdmin: getFieldValue('eventAdmin').map(
        (admin: ObjectKey) => admin.id
      ),
      isExternalLink: getFieldValue('externalLink'),
      externalLinkURL: getFieldValue('externalLinkUrl'),
      externalLinkInfo: getFieldValue('additionalInformation'),
      isQRCodeAttend: getFieldValue('allowAttendanceWithQrCode'),
      isEventSurvey: getFieldValue('eventSurvey'),
      eventSurveyId: formValue.selectSurvey.value
        ? getFieldValue('selectSurvey').id
        : null,
      eventAttachments: attachmentsRequest,
      category: getFieldValue('category').value,
      registrationPeriodStart: moment(
        getFieldValue('registrationPeriod').startDate
      ).unix(),
      registrationPeriodEnd: registrationPeriodEnd
        ? moment(registrationPeriodEnd).unix()
        : null,
      maxCapacity: haveCapacity,
      isSendReminder: getFieldValue('sendReminder'),
      isPublic: getFieldValue('isPublic').id !== 'private',
      ...(getFieldValue('isPublic').id !== 'private' && {
        groupEventSharingOption:
          getFieldValue('isPublic').id === 'public'
            ? 'allPartners'
            : 'groupMember',
      }),
    };
  };

  const setInitFormValue = (data: FormValueType) => {
    setFormValue((prev) => ({
      ...prev,
      ...data,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  return {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    handleChangeDisplayLanguage,
    handleToggleButtonChange,
    checkValidation,
    convertFormValueToRequest,
    setInitFormValue,
  };
};
