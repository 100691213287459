import React, { FC, useEffect, useState, useRef, useCallback } from "react";
import styles from "./socialWallPostList.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  Snackbar,
  Alert,
  Slide,
  Dialog,
  Stack,
  IconButton,
  DialogContent,
  ListItem,
  List,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  SocialWallCard,
  ToggleButton,
  CustomTab,
  SocialWallCardSkeleton,
} from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import moment from "moment";
import theme from "../../theme";
import toHtml from "html-react-parser";
import { Scheduler } from "timers/promises";
import { TransitionProps } from "@mui/material/transitions";
import {
  SocailWallListRequestType,
  getReportedByList,
  getReportedPostCount,
  getReportedPostList,
  getScheduledPostList,
  getSocialWallConfig,
} from "../../services/social-wall";
import {
  SocialWallCardProps,
  postDetailsProps,
} from "../../interfaces/social-wall-interface";
import { convertDateTime } from "../../utility";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState, setUserState } from "../../reducers/user-slice";
interface ReportDetailType {
  _id: string;
  nickname: string;
  createByName: any;
  createTime: string;
  ReportDetails: string;
  userProfilePictureUrl: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function SocialWallPostList(props: { type: "reported" | "scheduled" }) {
  const { type } = props;
  const navigate = useNavigate();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [socialWallPermission, setSocialWallPermission] = useState<any>();

  let { id } = useParams();
  const [reportedPostList, setReportedPostList] = useState<
    Array<postDetailsProps>
  >([]);
  const [suspendedPostList, setSuspendedPostList] = useState<
    Array<postDetailsProps>
  >([]);
  const [reportedPostTotalCount, setReportedPostTotalCount] =
    useState<number>(0);
  const [suspendedPostTotalCount, setSuspendedPostTotalCount] =
    useState<number>(0);
  const [postList, setpostList] = useState<Array<postDetailsProps>>([]);
  const reportedTabData = [
    {
      value: "reported",
      display:
        t("socialWall.reportedPost.reported") +
        " (" +
        reportedPostTotalCount +
        ")",
      disable: false,
    },
    {
      value: "suspended",
      display:
        t("socialWall.reportedPost.suspended") +
        " (" +
        suspendedPostTotalCount +
        ")",
      disable: false,
    },
  ];
  const scheduledTabData = [
    {
      value: "all",
      display: t("socialWall.scheduledPost.all"),
      disable: userState.permission?.SocialWallAdmin ? false : true,
    },
    {
      value: "my",
      display: t("socialWall.scheduledPost.createdByMe"),
      disable: false,
    },
  ];
  const [activeTab, setActiveTab] = useState<string>(
    type === "reported"
      ? reportedTabData[0].value
      : userState.permission?.SocialWallAdmin
      ? scheduledTabData[0].value
      : scheduledTabData[1].value
  );
  const [totalCount, setTotalCount] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isReportDetailOpen, setReportDetailOpen] = useState(false);
  const [getReportDetailData, setReportDetailData] = useState<
    ReportDetailType[]
  >(null!);

  const getSnackbar = JSON.parse(sessionStorage.getItem("snackbar") as string);
  const [isSnackbarShow, setSnackbarShow] = useState(
    getSnackbar ? getSnackbar.isShow : false
  );

  //for infinite scroll
  const [hasMore, setHasMore] = useState(true);
  const [listParams, setListParams] = useState<SocailWallListRequestType>({
    page: 1,
    tab: activeTab,
    pageSize: 10,
    sort: "createTime",
    by: "ASC",
  });
  const removeNullFromArray = (array: any[]) => {
    return array.filter((item) => item !== null);
  };

  const getList = useCallback(async () => {
    let active = true;
    setIsLoading(true);
    setHasMore(true);
    try {
      let response: any;
      if (type === "reported") {
        response = await getReportedPostList(listParams);
      } else if (type === "scheduled") {
        response = await getScheduledPostList({
          ...listParams,
          sort: "scheduleStartTime",
        });
      }
      // const response = await getReportedPostList(listParams);
      if (response?.status === 200 && active) {
        if (type === "reported") {
          if (listParams.tab === reportedTabData[0].value) {
            setReportedPostTotalCount(response.data.totalCount);
          } else if (listParams.tab === reportedTabData[1].value) {
            setSuspendedPostTotalCount(response.data.totalCount);
          }
        } else if (type === "scheduled") {
          if (listParams.tab === scheduledTabData[0].value) {
            setTotalCount(response.data.totalCount);
          }
          if (
            !userState.permission?.SocialWallAdmin &&
            listParams.tab === scheduledTabData[1].value
          ) {
            setTotalCount(response.data.totalCount);
          }
        }

        setpostList((prev) => [
          ...(response?.data?.posts
            ? response.data.posts.map((item: SocialWallCardProps) => ({
                ...item,
                attachments: item?.attachments
                  ? removeNullFromArray(item.attachments)
                  : [],
                reportBy: item?.reportBy
                  ? removeNullFromArray(item.reportBy)
                  : [],
              }))
            : []),
        ]);
        setIsLoading(false);

        if (response.data.totalPage === listParams.page) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setIsLoading(false);
        setHasMore(false);
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  function handleChangeTab(active: string) {
    setActiveTab(active);
  }

  useEffect(() => {
    setIsLoading(true);

    const getConfig = async () => {
      const response = await getSocialWallConfig();
      setSocialWallPermission(response.data);
    };
    getConfig();

    if (type === "reported") {
      getReportedPostCount({
        ...listParams,
        tab: reportedTabData[1].value,
      }).then((res) => {
        setSuspendedPostTotalCount(res.data.totalCount);
      });
    }
  }, []);
  useEffect(() => {
    setpostList([]);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      tab: activeTab,
    }));
  }, [activeTab]);

  function handleCloseMsgBar() {
    localStorage.setItem(
      "snackbar",
      JSON.stringify({ isShow: false, type: getSnackbar.type })
    );
    setSnackbarShow(false);
  }

  const CloseButton = (props: { onClick: () => void }) => {
    return (
      <IconButton
        id="closeSelectPopupButton"
        className={styles.closeSelectPopupButton}
        disableRipple
        onClick={props.onClick}
      >
        <img src="/assets/images/close_btn.svg" alt="" />
      </IconButton>
    );
  };

  const snackbarText = (type: string) => {
    if (type === "resolve") {
      return t("socialWall.reportedPost.resolveSuccessfully");
    } else if (type === "keep") {
      return t("socialWall.reportedPost.keepSuccessfully");
    } else if (type === "suspend") {
      return t("socialWall.reportedPost.suspendSuccessfully");
    } else if (type === "restore") {
      return t("socialWall.reportedPost.restoreSuccessfully");
    } else if (type === "reported-delete" || type === "scheduled-delete") {
      return t("socialWall.reportedPost.deleteSuccessfully");
    }
  };

  const handleClosePopup = () => {
    setReportDetailOpen(false);
  };

  const getReportDetail = (id: string) => {
    // call api use id??
    getReportedByList(id).then((res) => {
      setReportDetailData(res.data.postReportedInfo);
      setReportDetailOpen(true);
    });
  };

  const ReportDetailCard = (props: { data: ReportDetailType }) => {
    const { data } = props;
    return (
      <ListItem disablePadding>
        <div className="reportDetailCard">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            className="cardTop"
          >
            <Avatar
              alt={data.createByName.userName}
              src={data.userProfilePictureUrl}
            />
            <span>{data.createByName.userName}</span>
          </Stack>
          <Grid
            container
            className="cardDetails"
            justifyContent="space-between"
          >
            <Grid item xs={4} className="cardTitle">
              {t("socialWall.reportedPost.reportTime")}
            </Grid>
            <Grid item xs={8} className="cardContent">
              {convertDateTime(data.createTime)}
            </Grid>
            <Grid item xs={4} className="cardTitle">
              {t("socialWall.reportedPost.reason")}
            </Grid>
            <Grid item xs={8} className="cardContent">
              {data.ReportDetails}
            </Grid>
          </Grid>
        </div>
      </ListItem>
    );
  };
  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };
  useEffect(() => {
    //handle refresh page permission checking
    if (type !== "reported") {
      setActiveTab(
        userState.permission?.SocialWallAdmin
          ? scheduledTabData[0].value
          : scheduledTabData[1].value
      );
    }
  }, [userState.permission?.SocialWallAdmin]);
  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <>
      <Header
        title={
          type === "scheduled"
            ? t("socialWall.scheduledPost.title")
            : t("socialWall.review")
        }
        children={
          type === "scheduled" ? (
            <></>
          ) : (
            <img
              src="/assets/images/history_icon.svg"
              onClick={() => {
                navigate("/social-wall/reported-post/log");
              }}
            />
          )
        }
        enableBackButton
        disableBottomBorder
        closeButtonNavigation="/social-wall"
      />

      <Grid item xs className={styles.postContainer} id="postListContainer">
        {type === "scheduled" && (
          <Typography variant="subtitle1" className={styles.scheduledPostTitle}>
            {t("socialWall.scheduledPost.title")} ({totalCount})
          </Typography>
        )}
        {type === "scheduled" && totalCount === 0 ? (
          ""
        ) : (
          <CustomTab
            data={type === "reported" ? reportedTabData : scheduledTabData}
            type={type === "reported" ? "fullWidth" : ""}
            activeTab={activeTab}
            onTabClick={handleChangeTab}
          />
        )}
        {isLoading && (
          <Box sx={{ padding: 2 }}>
            <SocialWallCardSkeleton />
          </Box>
        )}
        {postList.length > 0 && (
          <InfiniteScroll
            dataLength={postList.length} //This is important field to render the next data
            next={nextPage}
            hasMore={hasMore}
            loader={
              <Box sx={{ padding: 2 }}>
                <SocialWallCardSkeleton />
              </Box>
            }
            scrollableTarget="postListContainer"
          >
            {postList.map((data: any, index: number) => (
              <Box sx={{ marginTop: "8px" }} key={index}>
                <SocialWallCard
                  {...data}
                  userProfilePictureUrl={
                    data?.createByData?.userProfilePictureUrl ??
                    data?.userProfilePictureUrl
                  }
                  id={data._id}
                  size="large"
                  actionBtnType={type === "reported" ? "admin" : "scheduled"}
                  // handleGetReportDetail={getReportDetail}
                  disableLike={
                    Boolean(userState.permission?.SocialWallAdmin)
                      ? !socialWallPermission?.like.admin
                      : !socialWallPermission?.like.all
                  }
                  disableComment={
                    Boolean(userState.permission?.SocialWallAdmin)
                      ? !socialWallPermission?.comment.admin
                      : !socialWallPermission?.comment.all
                  }
                />
              </Box>
            ))}
          </InfiniteScroll>
        )}
        {!isLoading && postList.length === 0 && (
          <Box className={styles.noPostContainer}>
            <img src="/assets/images/empty.png" alt="" />
            <Typography variant="h3">
              {type === "reported"
                ? t("socialWall.reportedPost.noPost")
                : t("socialWall.scheduledPost.noPost")}
            </Typography>
            <Typography>
              {type === "reported"
                ? t("socialWall.reportedPost.noPostMsg")
                : t("socialWall.scheduledPost.noPostMsg")}
            </Typography>
          </Box>
        )}
      </Grid>

      {isSnackbarShow && (
        <Snackbar
          open={isSnackbarShow}
          autoHideDuration={6000}
          onClose={handleCloseMsgBar}
        >
          <Alert severity="success">{snackbarText(getSnackbar.type)}</Alert>
        </Snackbar>
      )}

      {getReportDetailData && (
        <Dialog
          id="reportedPostDetails"
          open={isReportDetailOpen}
          onClose={handleClosePopup}
          fullScreen
          TransitionComponent={Transition}
          PaperProps={{
            sx: {
              overflow: "hidden",
            },
          }}
        >
          <Grid item xs className={styles.reportDetailPopup}>
            <Stack flexWrap="nowrap" className="reportDetailContainer">
              <Grid container alignItems="center">
                <Grid item xs="auto">
                  <CloseButton onClick={handleClosePopup} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h4" sx={{ fontSize: "16px" }}>
                    {t("socialWall.reportedPost.reportBy")} (
                    {getReportDetailData.length})
                  </Typography>
                </Grid>
              </Grid>
              <DialogContent className="reportListContainer">
                <List className="reportList" disablePadding>
                  {getReportDetailData.map((item, index) => {
                    return <ReportDetailCard data={item} key={index} />;
                  })}
                </List>
              </DialogContent>
            </Stack>
          </Grid>
        </Dialog>
      )}
    </>
  );
}

export default SocialWallPostList;
