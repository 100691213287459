const MuiChip = {
  styleOverrides: {
    root: {
      fontSize: "14px",
      lineHeight: "16.8px",
      "&.Mui-disabled": {
        opacity: 1,
        backgroundColor: "var(--mui-palette-black-black026)",
        color: "var(--mui-palette-white-opacity100)",
      },
    },
    colorDefault: {
      backgroundColor: "var(--mui-palette-customGrey-natural)",
      color: "var(--mui-palette-black-black058)",
    },
    colorPrimary: {
      backgroundColor: "var(--mui-palette-green-light)",
      color: "var(--mui-palette-green-starbucks)",
      '&:hover': {
        color: "white",
      }
    },
    colorSecondary: {
      backgroundColor: "var(--mui-palette-customGrey-ceramic)",
      color: "var(--mui-palette-green-house)",
      '&:hover': {
        color: "white",
      }
    },
    sizeMedium: {
      height: "30px",
    },
    sizeSmall: {
      height: "30px",
      fontSize: "12px",
      lineHeight: "30px",
    },
    deleteIcon: {
      margin: "0 12px 0 -4px",
    },
    deleteIconSmall: {
      margin: "0 8px 0 -4px",
    },
  },
};

export default MuiChip;
