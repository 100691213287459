import {
  FC,
  HTMLInputTypeAttribute,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Popup, ToggleButton } from '../../components';
import AutoCompleteField from '../../components/auto-complete-field';
import Header from '../../components/header/header';
import InputField from '../../components/input-field';
import SelectAdminCard from '../../components/select-admin-card';

import {
  CardListType,
  GreenApronCardProps,
  SendCardRequestType,
} from '../../interfaces/green-apron-card-interface';
import styles from './greenApronCard.module.scss';

import 'swiper/css';
import 'swiper/css/pagination';

import LoadingButton from '@mui/lab/LoadingButton';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';

import DateTimePickerField from '../../components/datetime-picker-field';

import { ObjectKey } from '../../interfaces/common-interface';
import { UserState } from '../../reducers/user-slice';
import { getStaffFilterList, getStaffList } from '../../services/common';
import {
  getCardDetail,
  getCardsConfig,
  sendCard,
  updateSendCard,
} from '../../services/green-apron-card';
import { RootState } from '../../store/store';
import { convertStaffList, getDropdownListOnStore } from '../../utility';
import {
  emailValidation,
  FormValueType,
  useFormHook,
} from '../../utility/useFormHook';
import GreenApronCardContext from './greenApronCardProvider';

const GreenApronCardSend: FC = () => {
  const { t } = useTranslation();
  const {
    list,
    selectedCategory,
    setSelectedCategory,
    selectedId,
    setSelectedId,
    sendValue,
    setSendValue,
    sendEmailList,
    setSendEmailList,
  } = useContext(GreenApronCardContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(selectedCategory);
  const [initSelectedCardId, setInitSelectedCardId] = useState(selectedId);
  const [enableViaEmail, setEnableViaEmail] = useState<Boolean>(true);
  const [senderList, setSenderList] = useState<Array<ObjectKey>>([]);
  const [selectedList, setSelectedList] = useState<Array<GreenApronCardProps>>(
    []
  );
  const [swiperHeight, setSwiperHeight] = useState<number | undefined>(
    undefined
  );
  const [receiverError, setReceiverError] = useState<string>(null!);
  const swiperRef = useRef<HTMLDivElement | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [emailList, setEmailList] = useState<
    { receiverName: string; receiverEmail: string }[]
  >([]);
  const [isEmailPopupOpen, setEmailPopupOpen] = useState<boolean>(false);
  const [addEmailValue, setAddEmailValue] = useState<{
    name: { value: string; isError: boolean };
    email: { value: string; isError: boolean };
  }>({
    name: { value: '', isError: false },
    email: { value: '', isError: false },
  });
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const { id } = useParams();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const nowDate = moment();
  const roundTime = 30 - (nowDate.minute() % 30);
  const roundedStartDate = nowDate.add(roundTime, 'minutes').format();
  const contentLimit = 200;
  const sendDateList = [
    {
      id: 'NOW',
      name: t('greenApronCard.sendNow'),
      disableDateTime: true,
    },
    {
      id: 'NOT_NOW',
      name: t('greenApronCard.scheduleSendDate'),
      disableDateTime: false,
    },
  ];
  const defaultFormValue: FormValueType = {
    id: {
      value: '',
      isError: false,
      isRequired: false,
    },
    card: {
      value: '',
      isError: false,
      isRequired: true,
    },
    content: {
      value: '',
      isError: false,
      isRequired: true,
    },
    receiver: {
      value: [],
      isError: false,
      isRequired: true,
    },
    sender: {
      value: null,
      isError: false,
      isRequired: true,
    },
    sendDate: {
      value: sendDateList[0],
      isError: false,
      isRequired: true,
    },
    sendDateSchedule: {
      value: roundedStartDate,
      isError: false,
      isRequired: false,
    },
    sendViaEmail: {
      value: false,
      isError: false,
      isRequired: false,
    },
    // email: {
    //   value: [],
    //   isError: false,
    //   isRequired: false,
    //   type: "email" as HTMLInputTypeAttribute,
    // },
  };

  const {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    setInitFormValue,
    checkValidation,
  } = useFormHook({
    value: defaultFormValue,
  });

  useEffect(() => {
    let active = true;

    const getConfig = async () => {
      try {
        const response = await getCardsConfig();
        const { config } = response.data;
        if (active && config && config.isEnable !== enableViaEmail) {
          setEnableViaEmail(config.isEnable);
        }
      } catch (error) {
        throw error;
      }
    };

    const getDetail = async (cardId: string) => {
      try {
        const response = await getCardDetail(cardId);
        const data = response.data.greenApronCardDetail;
        console.log('data', data);
        if (!data) {
          navigate('/green-apron-card/selection');
        }
        if (active) {
          const senderDefaultValue = {
            id: userState.partnerId as string,
            name: userState.name,
            avatar: userState.avatar,
            department: '',
            jobTitle: '',
          };
          // console.log("formValue", formValue);
          const initValue: FormValueType = {
            id: {
              ...formValue.id,
              value: data._id,
            },
            card: {
              ...formValue.card,
              value: data.cardLibraryId,
            },
            content: {
              ...formValue.content,
              value: data.senderMessage ?? '',
            },
            receiver: {
              ...formValue.receiver,
              isRequired: data.receiverEmail ? false : true,
              value: data.receiverId
                ? [
                    {
                      id: data.receiverId,
                      name: data.receiver,
                      avatar: '',
                    },
                  ]
                : [],
            },
            sender: {
              ...formValue.sender,
              value: senderDefaultValue,
            },
            sendDate: {
              ...formValue.sendDate,
              value: data.sendTime ? sendDateList[1] : sendDateList[0],
            },
            sendDateSchedule: {
              ...formValue.sendDateSchedule,
              value: moment.unix(data.sendTime).format(),
            },
            sendViaEmail: {
              ...formValue.sendViaEmail,
              value: data.receiverEmail ? true : false,
            },
            // email: {
            //   ...formValue.email,
            //   value: [...data.receiverEmail],
            // },
          };
          setEmailList(
            data.receiverEmail
              ? [
                  {
                    receiverName: data.receiverEmailName,
                    receiverEmail: data.receiverEmail,
                  },
                ]
              : []
          );
          setSelectedId(data.cardLibraryId);
          setInitFormValue(initValue, true);
        }
      } catch (error) {
        navigate('/green-apron-card/selection');
        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      // if (!sendValue) {
      //   console.log("no sendValue");
      //   getDetail(id);
      // }
      getDetail(id);
    } else {
      setIsLoading(false);
    }

    getConfig();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const senderDefaultValue = {
      id: userState.partnerId as string,
      name: userState.name,
      avatar: userState.avatar,
      department: '',
      jobTitle: '',
    };
    setSenderList([senderDefaultValue]);
    setInitFormValue({
      ...formValue,
      sender: {
        ...formValue.sender,
        value: senderDefaultValue,
      },
    });
  }, [userState]);

  useEffect(() => {
    if (sendValue) {
      setInitFormValue(sendValue, true);
      setSendValue(null);
      setEmailList(sendEmailList!);
      setSendEmailList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendValue, sendEmailList]);

  useEffect(() => {
    if (list.length > 0) {
      if (selectedCategory === '' || selectedId === '') {
        setActiveSection(list[0].id);
        setInitSelectedCardId(list[0].data[0].id);
      }
    }
  }, [list, selectedCategory, selectedId]);

  useEffect(() => {
    const categoryItem = findCategoryData(activeSection);
    if (categoryItem) {
      if (initSelectedCardId === '') {
        setInitSelectedCardId(categoryItem.data[0].id);
      }
      setSelectedList(categoryItem.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  const findCategoryData = (id: string) => {
    return list.find((section: CardListType) => section.id === id);
  };

  const handleTabClick = (sectionId: string) => {
    setActiveSection(sectionId);
    setInitSelectedCardId('');
    setSelectedList([]);
  };

  const convertFormValueToRequest = (): SendCardRequestType => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;
    const date =
      getFieldValue('sendDate').id === 'NOW'
        ? moment().format()
        : getFieldValue('sendDateSchedule');
    return {
      cardLibraryId: getFieldValue('card'),
      senderMessage: getFieldValue('content'),
      receiverIds: [...getFieldValue('receiver').map((item: any) => item.id)],
      // receiverIds: [getFieldValue("receiver").id],
      sendTime: moment(date).unix(),
      isSendNow: getFieldValue('sendDate').id === 'NOW',
      receiverEmails: getFieldValue('sendViaEmail') ? [...emailList] : [],
    };
  };

  const handleSubmit = async () => {
    // console.log("emailList", emailList);
    // console.log("convertFormValueToRequest", convertFormValueToRequest());
    // console.log("formValue", formValue);
    // handle add email
    const isValid = checkValidation();
    const isEmailValid = formValue.sendViaEmail.value
      ? emailList.length > 0
      : true;
    // console.log("isEmailValid", isEmailValid);
    const isSendToSelf = convertFormValueToRequest().receiverIds.includes(
      userState.partnerId as string
    );
    if (isSendToSelf) {
      setReceiverError(t('greenApronCard.sendCardToYourSelfError'));
    }
    if (!isEmailValid) {
      setIsEmailError(true);
    } else {
      setIsEmailError(false);
    }
    if (isValid && !isSendToSelf && isEmailValid) {
      setIsSending(true);
      const request = convertFormValueToRequest();
      try {
        let response: AxiosResponse;
        if (id) {
          response = await updateSendCard({
            ...request,
            id: id,
          });
        } else {
          response = await sendCard(request);
        }
        if (response.status === 200) {
          if (formValue.sendDate.value.id === 'NOW') {
            setSendValue(null);
            navigate('/green-apron-card?send=success');
          } else {
            navigate('/green-apron-card?send=schedule', { state: formValue });
          }
        }
      } catch (error) {
        navigate('/green-apron-card?send=failed', { state: formValue });
        throw error;
      } finally {
        setIsSending(false);
      }
    }
  };

  const getCardDefaultContent = (id: string) => {
    const selectedCard = selectedList.find(
      (card: GreenApronCardProps) => card.id === id
    );
    if (!selectedCard) return '';

    return selectedCard.description;
  };

  const handlePreview = () => {
    const isValid = checkValidation();
    const isSendToSelf = convertFormValueToRequest().receiverIds.includes(
      userState.partnerId as string
    );
    const isEmailValid = formValue.sendViaEmail.value
      ? emailList.length > 0
      : true;

    if (isSendToSelf) {
      setReceiverError(t('greenApronCard.sendCardToYourSelfError'));
    }
    if (!isEmailValid) {
      setIsEmailError(true);
    } else {
      setIsEmailError(false);
    }
    if (isValid && !isSendToSelf && isEmailValid) {
      setSendValue(formValue);
      setSendEmailList(emailList);
      setSelectedCategory(activeSection);
      setSelectedId(formValue.card.value);
      navigate('/green-apron-card/send/preview');
    }
  };

  const handleAddEmail = () => {
    if (addEmailValue.name.value === '' && addEmailValue.email.value === '') {
      setAddEmailValue({
        name: { value: '', isError: true },
        email: { value: '', isError: true },
      });
    } else {
      if (addEmailValue.name.value === '') {
        setAddEmailValue({
          ...addEmailValue,
          name: { value: '', isError: true },
        });
      } else if (addEmailValue.email.value === '') {
        setAddEmailValue({
          ...addEmailValue,
          email: { value: '', isError: true },
        });
      } else {
        if (!addEmailValue.name.isError && !addEmailValue.email.isError) {
          setEmailList([
            ...emailList,
            {
              receiverName: addEmailValue.name.value,
              receiverEmail: addEmailValue.email.value,
            },
          ]);
          setEmailPopupOpen(false);
          setAddEmailValue({
            name: { value: '', isError: false },
            email: { value: '', isError: false },
          });
          setIsEmailError(false);
        }
      }
    }
    // setEmailList
    // setEmailPopupOpen
    // setAddEmailValue
  };

  const handleDeleteEmail = (
    item: { receiverName: string; receiverEmail: string },
    index: number
  ) => {
    setEmailList(emailList.filter((item, i) => i !== index));
  };

  return (
    <>
      <Header
        enableBackButton
        disableBottomBorder
        title={t('greenApronCard.sendCard')}
        closeButtonNavigation="/green-apron-card"
      >
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs="auto">
            <LoadingButton
              disabled={!isDirty}
              disableRipple
              loading={isSending}
              className={styles.sendButton}
              variant="text"
              onClick={handleSubmit}
            >
              {t('greenApronCard.send')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Header>
      {isLoading ? (
        <Grid item xs container alignItems="center" justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container wrap="nowrap" className={styles.tabsList}>
            {list.map((section: CardListType, index: number) => (
              <Grid
                key={index}
                item
                xs="auto"
                id={`${section.id}_Tab`}
                className={`${styles.tabs} ${
                  activeSection === section.id ? 'active' : ''
                }`}
                onClick={() => handleTabClick(section.id)}
              >
                {section.name}
              </Grid>
            ))}
          </Grid>
          <Grid item xs className={styles.sendContainer}>
            <Box
              ref={swiperRef}
              className={styles.swiperContainer}
              sx={{ height: swiperHeight }}
            >
              {selectedList.length > 0 && (
                <Swiper
                  slidesPerView={'auto'}
                  centeredSlides={true}
                  spaceBetween={16}
                  autoHeight={true}
                  pagination={{
                    type: 'fraction',
                  }}
                  modules={[Pagination]}
                  onInit={(swiper) => {
                    let targetIndex = 0;
                    if (initSelectedCardId) {
                      targetIndex = selectedList
                        .map((card: GreenApronCardProps) => card.id)
                        .indexOf(initSelectedCardId);
                    }
                    swiper.slideTo(targetIndex);
                    if (targetIndex === 0) {
                      if (formValue.card.value === '') {
                        setInitFormValue({
                          ...formValue,
                          card: {
                            ...formValue.card,
                            value: selectedList[targetIndex].id,
                          },
                        });
                      } else {
                        handleInputFieldChange(
                          'card',
                          selectedList[targetIndex].id
                        );
                      }
                    }
                    setSwiperHeight(swiperRef.current?.clientHeight);
                  }}
                  onActiveIndexChange={(swiper) => {
                    handleInputFieldChange(
                      'card',
                      selectedList[swiper.activeIndex].id
                    );
                  }}
                >
                  {selectedList.map(
                    (card: GreenApronCardProps, index: number) => (
                      <SwiperSlide key={index}>
                        <Box className={styles.swiperItem}>
                          <img src={card.url} alt={card.name} />
                        </Box>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              )}
            </Box>
            <Box className={styles.sendFormSection}>
              <Box className={styles.formField}>
                <Typography variant="body1">
                  {getCardDefaultContent(formValue.card.value)}
                </Typography>
              </Box>
              <Box className={styles.formField}>
                <InputField
                  id="content"
                  name="content"
                  placeholder={t('greenApronCard.saySomething')}
                  value={formValue.content.value}
                  error={formValue.content.isError}
                  required={formValue.content.isRequired}
                  multiline
                  helperText={
                    <Typography variant="body2" align="right">
                      {`${formValue.content.value.length}/${contentLimit}`}
                    </Typography>
                  }
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleTextareaFieldChange(name, value, contentLimit);
                  }}
                />
              </Box>
            </Box>
            <Box className={styles.sendFormSection}>
              <Box className={styles.formField}>
                <AutoCompleteField
                  id="receiver"
                  name="receiver"
                  label={t('greenApronCard.to')}
                  popupLabel={t('greenApronCard.selectRecipient')}
                  multiple
                  disableLabel
                  value={formValue.receiver.value}
                  error={receiverError !== null || formValue.receiver.isError}
                  required={formValue.receiver.isRequired}
                  startAdornment={
                    <img src="/assets/images/eventIcon_user_plus.svg" alt="" />
                  }
                  getOptionValue={(value) => value.id}
                  getOptionLabel={(value) => value.name}
                  onChange={(value) => {
                    // console.log("receiver");
                    setReceiverError(null!);
                    handleInputFieldChange('receiver', value);
                  }}
                  optionsItem={(option, id, classes) => (
                    <SelectAdminCard {...option} id={id} classes={classes} />
                  )}
                  disableSearchCancel
                  listFetch={getStaffList}
                  listFetchKey="staffList"
                  filterFetch={getStaffFilterList}
                  convertResponse={convertStaffList}
                  helperText={receiverError ? receiverError : ''}
                  isEdit={id ? true : false}
                />
              </Box>
              <Box
                className={`${styles.formField} ${styles.formDisabledField}`}
              >
                <AutoCompleteField
                  id="sender"
                  name="sender"
                  label={t('greenApronCard.to')}
                  popupLabel={t('greenApronCard.selectSender')}
                  disableLabel
                  disableActions
                  disableSearchCancel
                  value={formValue.sender.value}
                  error={formValue.sender.isError}
                  required={formValue.sender.isRequired}
                  options={senderList}
                  disabled
                  startAdornment={
                    <img src="/assets/images/user_pen_alt.svg" alt="" />
                  }
                  getOptionValue={(value) => value.id}
                  getOptionLabel={(value) => value.name}
                  onChange={(value) => {
                    handleInputFieldChange('sender', value);
                  }}
                  optionsItem={(option, id, classes) => (
                    <SelectAdminCard {...option} id={id} classes={classes} />
                  )}
                />
              </Box>
              <Box className={styles.formField}>
                <DateTimePickerField
                  id="sendDate"
                  name="sendDate"
                  label={t('greenApronCard.sendDate')}
                  startDateLabel="Send"
                  startDate={formValue.sendDateSchedule.value}
                  endDate={null}
                  error={formValue.sendDate.isError}
                  required={formValue.sendDate.isRequired}
                  startAdornment={
                    <img src="/assets/images/createPostIcon_clock.svg" alt="" />
                  }
                  // eslint-disable-next-line
                  onChange={(value: any) => {
                    handleInputFieldChange('sendDate', value.value, [
                      {
                        name: 'sendDateSchedule',
                        required: value.value === 'NOT_NOW',
                      },
                    ]);
                    handleInputFieldChange('sendDateSchedule', value.startDate);
                  }}
                  value={formValue.sendDate.value}
                  getOptionValue={(value) => value.id}
                  getOptionLabel={(value) => value.name}
                  disableDateTimePicker={(value) =>
                    !value || value.id === 'NOW'
                  }
                  options={sendDateList}
                  disableEndDateControl
                />
              </Box>
              {enableViaEmail && (
                <>
                  <Box className={styles.formToggleField}>
                    <ToggleButton
                      label={t('greenApronCard.sendViaEmail')}
                      checked={formValue.sendViaEmail.value}
                      setChecked={(checked: boolean) => {
                        // console.log("checked", checked);
                        handleInputFieldChange('sendViaEmail', checked);
                        setInitFormValue({
                          ...formValue,
                          sendViaEmail: {
                            ...formValue.sendViaEmail,
                            value: checked,
                          },
                          receiver: {
                            ...formValue.receiver,
                            isError: false,
                            isRequired: !checked,
                          },
                        });
                      }}
                    />
                  </Box>
                  {formValue.sendViaEmail.value && (
                    <Box className={styles.formField}>
                      {/* <InputField
                        id="email"
                        name="email"
                        type={formValue.email.type}
                        placeholder={t("greenApronCard.sendViaEmailAddress")}
                        value={formValue.email.value}
                        error={formValue.email.isError}
                        required={formValue.email.isRequired}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          handleInputFieldChange(name, value);
                        }}
                      /> */}
                      <Box className={styles.emailContainer}>
                        <button
                          onClick={() => {
                            setEmailPopupOpen(true);
                          }}
                        >
                          +
                        </button>
                        <div
                          className={`${styles.emailList}${
                            isEmailError ? ` ${styles.error}` : ''
                          }`}
                        >
                          {emailList.length > 0 &&
                            emailList.map(
                              (
                                item: {
                                  receiverName: string;
                                  receiverEmail: string;
                                },
                                index: number
                              ) => (
                                <Chip
                                  key={index}
                                  label={item.receiverName}
                                  onDelete={() =>
                                    handleDeleteEmail(item, index)
                                  }
                                  deleteIcon={
                                    <img
                                      src="/assets/images/close_btn_green.svg"
                                      alt="close"
                                    />
                                  }
                                  className={styles.emailTag}
                                />
                              )
                            )}
                        </div>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box
              className={`${styles.sendFormSection} ${styles.previewActions}`}
            >
              <Button
                className={styles.previewButton}
                disabled={!isDirty}
                onClick={handlePreview}
              >
                {isDirty ? (
                  <img src="/assets/images/eye_active.svg" alt="" />
                ) : (
                  <img src="/assets/images/eye.svg" alt="" />
                )}
                {t('greenApronCard.preview')}
              </Button>
            </Box>
          </Grid>
        </>
      )}
      <Popup
        id="addEmailPopup"
        isOpen={isEmailPopupOpen}
        setIsOpen={(isOpen) => setEmailPopupOpen(false)}
        title={<>Please type email address.</>}
        content={
          <div style={{ padding: '0 16px' }}>
            <InputField
              id="addEmailName"
              name="addEmailName"
              type="text"
              placeholder={t('greenApronCard.to')}
              error={addEmailValue.name.isError}
              value={addEmailValue.name.value}
              required={true}
              onChange={(e) => {
                const { name, value } = e.target;
                setAddEmailValue({
                  ...addEmailValue,
                  name: { value: value, isError: value === '' ? true : false },
                });
              }}
            />
            <InputField
              id="addEmailAddress"
              name="addEmailAddress"
              type="email"
              placeholder={t('greenApronCard.sendViaEmailAddress')}
              error={addEmailValue.email.isError}
              value={addEmailValue.email.value}
              required={true}
              onChange={(e) => {
                const { name, value } = e.target;
                setAddEmailValue({
                  ...addEmailValue,
                  email: {
                    value: value,
                    isError: value !== '' ? !emailValidation(value) : false,
                  },
                });
              }}
            />
          </div>
        }
        onClickConfirm={handleAddEmail}
      />
    </>
  );
};

export default GreenApronCardSend;
