import React, { useEffect, useRef, useState } from 'react';

// import SelectorField, { SelectorFieldProps } from "../selector-field";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Paper,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

import { ObjectKey } from '../../interfaces/common-interface';
import InputField, { InputFieldProps } from '../input-field';
import RoundButton from '../round-button';
import styles from './select-field-container.module.scss';

interface SelectFieldContainerProps extends InputFieldProps {
  // eslint-disable-next-line
  type?: string;
  error?: boolean;
  value: any;
  popupLabel?: string;
  confirmLabel?: string;
  // eslint-disable-next-line
  selectedValue: any;
  selectedList?: JSX.Element;
  disableLabel?: boolean;
  disableActions?: boolean;
  multiple?: boolean;
  useLeftArrowButton?: boolean;
  children: React.ReactNode;
  onConfirmSelected: (callback: () => void) => void;
  onClear?: () => void;
  onCancel: () => void;
  handleOpen?: boolean;
  disableSelectedCountOnBtn?: boolean;
  onClickField?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SelectFieldContainer = React.forwardRef<
  HTMLParagraphElement,
  SelectFieldContainerProps
>((props, multipleValueRef) => {
  const {
    type,
    popupLabel,
    confirmLabel,
    selectedValue,
    selectedList,
    disableLabel,
    disableActions,
    multiple,
    useLeftArrowButton,
    children,
    onConfirmSelected,
    onClear,
    onCancel,
    handleOpen,
    disableSelectedCountOnBtn,
    onClickField,
    ...inputProps
  } = props;
  // console.log('inputProps', inputProps);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const containerRef = useRef(null);
  // console.log("handleOpen", handleOpen);
  const hiddenLabel =
    disableLabel && inputProps.value && inputProps.value !== '';
  // console.log("selectedValue", selectedValue);
  useEffect(() => {
    handleOpen && setOpen(handleOpen);
  }, [handleOpen]);

  let endAdornmentArrow = {};
  if (!disableLabel) {
    endAdornmentArrow = {
      endAdornment:
        type === 'selector' ? (
          inputProps.error ? (
            <img src="/assets/images/chevron_down_red.svg" alt="" />
          ) : (
            <img src="/assets/images/chevron_down.svg" alt="" />
          )
        ) : (
          <img src="/assets/images/chevron_right.svg" alt="" />
        ),
    };
  }

  const CloseButton = (props: {
    type: 'close' | 'arrow';
    onClick: () => void;
  }) => {
    const iconPath =
      props.type === 'arrow'
        ? '/assets/images/arrow_left.svg'
        : '/assets/images/close_btn.svg';

    return (
      <IconButton
        id="closeSelectPopupButton"
        className={styles.closeSelectPopupButton}
        disableRipple
        onClick={props.onClick}
      >
        <img src={iconPath} alt="" />
      </IconButton>
    );
  };

  const handleOpenPopup = () => {
    if (inputProps.disabled) return false;
    onClickField && onClickField();
    setOpen(true);
  };

  const handleClosePopup = () => {
    onCancel();
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirmSelected(() => setOpen(false));
  };

  const handleCloseSelectedContainer = () => {
    setChecked((prev) => !prev);
  };

  const confirmButtonLabel = (label?: string) => {
    if (label) return label;
    if (!multiple || disableSelectedCountOnBtn) return t('general.confirm');

    if (selectedValue) {
      if (Array.isArray(selectedValue) && selectedValue.length > 0) {
        return `${t('general.done')} (${selectedValue.length})`;
      } else {
        let count = 0;
        Object.keys(selectedValue).forEach((key) => {
          count =
            count +
            selectedValue[key].filter(
              (value: ObjectKey) => value.children === undefined
            ).length;
        });
        return `${t('general.done')} (${count})`;
      }
    } else {
      return t('general.done');
    }
  };

  useEffect(() => {
    if (disableActions && selectedValue && !multiple) {
      handleConfirm();
    }
    // eslint-disable-next-line
  }, [selectedValue]);
  // console.log(type);
  const displayOutput = () => {
    if (type === 'selector') {
      return (
        <InputField
          {...inputProps}
          onChange={(e) => e.preventDefault()}
          onClick={handleOpenPopup}
          selectorField
          label={hiddenLabel ? undefined : inputProps.label}
          {...endAdornmentArrow}
        />
      );
    } else if (type === 'tag') {
      return (
        <Stack className={styles.tagFieldActions}>
          <Grid container alignItems="center" onClick={handleOpenPopup}>
            {!hiddenLabel && (
              <Grid item xs>
                <Typography variant="body1" color="black.opacity38">
                  {inputProps.label}
                </Typography>
              </Grid>
            )}
            <Grid item xs="auto">
              <img src="/assets/images/chevron_right.svg" alt="" />
            </Grid>
          </Grid>
          {selectedValue && selectedValue.length > 0 && (
            <Stack direction="row" flexWrap="wrap" gap="8px">
              {selectedValue.map((item: string, index: number) => {
                return (
                  <Chip
                    key={index}
                    label={item}
                    color="primary"
                    className={styles.profileSectionItemTag}
                  />
                );
              })}
            </Stack>
          )}
        </Stack>
      );
    } else if (type === 'hidden') {
      //if type hidden will not return any thing
    } else {
      return (
        <InputField
          {...inputProps}
          onChange={(e) => e.preventDefault()}
          onClick={handleOpenPopup}
          error={inputProps.error}
          selectField
          label={hiddenLabel ? undefined : inputProps.label}
          {...endAdornmentArrow}
        />
      );
    }
  };

  return (
    <>
      {displayOutput()}

      <Dialog
        id={props.id}
        open={open}
        onClose={handleClosePopup}
        fullScreen
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            overflow: 'hidden',
          },
        }}
      >
        <Grid
          item
          xs
          className={styles.selectPopupWrapper}
          data-open-selected={checked}
          ref={containerRef}
        >
          {open && (
            <Stack flexWrap="nowrap" className={styles.selectOptionsContainer}>
              <Grid
                container
                alignItems="center"
                className={styles.selectPopupHeader}
              >
                <Grid item xs="auto">
                  <CloseButton
                    type={useLeftArrowButton || multiple ? 'arrow' : 'close'}
                    onClick={handleClosePopup}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h3">
                    {popupLabel ? popupLabel : inputProps.label}
                  </Typography>
                </Grid>
                {multiple && onClear && (
                  <Grid item xs="auto">
                    <Link
                      component="button"
                      variant="button"
                      textTransform="capitalize"
                      underline="none"
                      onClick={onClear}
                    >
                      {t('general.clear')}
                    </Link>
                  </Grid>
                )}
                {multiple && disableActions && (
                  <Grid item xs="auto">
                    <Link
                      component="button"
                      variant="button"
                      textTransform="capitalize"
                      underline="none"
                      onClick={handleConfirm}
                    >
                      {t('general.save')}
                    </Link>
                  </Grid>
                )}
              </Grid>
              <DialogContent>{children}</DialogContent>
            </Stack>
          )}
          {selectedValue && selectedList && (
            <Slide direction="up" in={checked} container={containerRef.current}>
              <Paper className={styles.selectedOptionsContainer} elevation={0}>
                <Grid
                  container
                  alignItems="center"
                  className={styles.selectPopupHeader}
                >
                  <Grid item xs="auto">
                    <CloseButton
                      type="close"
                      onClick={handleCloseSelectedContainer}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h3">
                      {`Selected ${inputProps.label}`}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Link
                      component="button"
                      variant="button"
                      textTransform="capitalize"
                      underline="none"
                      onClick={onClear}
                    >
                      {t('general.clear')}
                    </Link>
                  </Grid>
                </Grid>
                <DialogContent>{selectedList}</DialogContent>
              </Paper>
            </Slide>
          )}
        </Grid>
        {!disableActions && (
          <DialogActions className={styles.selectPopupActions}>
            {multiple && (
              <Grid
                item
                xs
                className={styles.confirmMultipleValue}
                onClick={handleCloseSelectedContainer}
              >
                <Typography
                  ref={multipleValueRef}
                  variant="body2"
                  textOverflow="ellipsis"
                >
                  {inputProps?.value}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs="auto"
              sx={{
                width: multiple && !disableSelectedCountOnBtn ? 'auto' : '100%',
              }}
            >
              <RoundButton
                id="confirmSelectedOptionsButton"
                disabled={
                  !selectedValue ||
                  inputProps.disabled ||
                  selectedValue.length <= 0
                }
                // fullWidth={!multiple}
                onClick={handleConfirm}
              >
                {confirmButtonLabel(confirmLabel)}
              </RoundButton>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
});

export default SelectFieldContainer;
