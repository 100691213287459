import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListSubheader,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styles from "./myCalendar.module.scss";
import Header from "../../components/header/header";
import { ObjectKey } from "../../interfaces/common-interface";
import moment, { Moment } from "moment";
import Calendar from "../../components/calendar/calendar";
import {
  EventListRequestType,
  getCalendarEventList,
  getCalendarPartnerTeam,
  searchCalendarEvents,
  searchCalendarPartnerTeam,
} from "../../services/event";
import {
  convertDate,
  convertDateTime,
  convertDateWithWeekAndFullDate,
  convertTime,
  getApiDataByLang,
  getMSTokenSilent,
} from "../../utility";
import { NoResults, Popup } from "../../components";
import SearchField from "../../components/search-field";
import { PaginationParamsType } from "../../services/common";
import InfiniteScroll from "react-infinite-scroll-component";
import parse from "html-react-parser";
import { useMsal } from "@azure/msal-react";

interface TabType {
  id: string;
  label: string;
  value: string;
}

interface ListType {
  id: string;
  title: string;
  description: string;
  startDate: number;
  endDate: number;
}
const isTWN = process.env.REACT_APP_LOCATION === "TWN";
const MS_TOKEN = localStorage.getItem("MS_TOKEN");

const MyCalendar: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const [displayMode, setDisplayMode] = useState<"list" | "calendar">(
    "calendar"
  );
  const [filterList, setFilterList] = useState<Array<ListType>>([]);
  const [eventList, setEventList] = useState<Array<ListType>>([]);
  const [eventDayList, setEventDayList] = useState<Array<number>>([]);
  const [searchData, setSearchData] = useState<Array<ListType>>([]);

  const [selectDate, setSelectDate] = useState<Moment>(moment());
  const [listParams, setListParams] = useState<EventListRequestType>({
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("M")),
    type: "Registered,Checked-In",
  });
  const [searchListParams, setSearchListParams] =
    useState<PaginationParamsType>({
      page: 1,
      pageSize: 10,
      search: "",
      type: "Registered,Checked-In",
    });
  const [hasMore, setHasMore] = useState(true);
  const { instance, accounts } = useMsal();

  // const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
  //   setIsLoading(true);
  //   setEventDayList([0]);
  //   setEventList([]);
  //   setFilterList([]);
  //   setListParams((prev) => ({
  //     ...prev,
  //     type: newValue,
  //   }));
  // };

  const handleChangeSearchTab = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setIsSearching(true);
    setHasMore(true);
    setSearchData([]);
    setSearchListParams((prev) => ({
      ...prev,
      type: newValue,
    }));
  };

  function handleSelectedDateChange(date: Moment) {
    let currentDateEventList: Array<ListType> = [];

    eventList.forEach((event: ListType) => {
      if (
        date.isSame(moment.unix(event.startDate).format(), "day") ||
        date.isSame(moment.unix(event.endDate).format(), "day") ||
        date.isBetween(
          moment.unix(event.startDate).format(),
          moment.unix(event.endDate).format(),
          undefined,
          "[]"
        )
      ) {
        currentDateEventList.push(event);
      }
    });

    setSelectDate(date);
    setFilterList(currentDateEventList);
  }

  function handleActiveDateChange(date: Moment) {
    setIsLoading(true);
    setEventDayList([0]);
    setEventList([]);
    setFilterList([]);
    setSelectDate(date);
    setListParams((prev) => ({
      ...prev,
      month: parseInt(moment(date).format("M")),
      year: parseInt(moment(date).format("YYYY")),
    }));
  }

  const switchToToday = () => {
    if (
      parseInt(moment().format("M")) !== listParams.month ||
      parseInt(moment().format("YYYY")) !== listParams.year
    ) {
      setIsLoading(true);
      setSelectDate(moment());
      setListParams((prev) => ({
        ...prev,
        month: parseInt(moment().format("M")),
        year: parseInt(moment().format("YYYY")),
      }));
    } else {
      handleSelectedDateChange(moment());
    }
  };

  const handleClickEvent = (id: string, data?: ObjectKey) => {
    if (data?.isMS) {
      navigate(`/calendar/${id}`, { state: data });
    } else {
      navigate(`/event/${id}`);
    }
  };

  function convertEvent(event: ObjectKey, isMsTeams?: boolean) {
    // console.log("event", event);
    if (isMsTeams) {
      return {
        id: event.calendarId,
        title:
          typeof event.subject === "string"
            ? event.subject
            : getApiDataByLang(event.subject),
        description:
          typeof event.content === "string"
            ? event.content
            : getApiDataByLang(event.content),
        startDate: event.startTime,
        endDate: event.endTime,
        isMS: event.isMS,
        isOnlineMeeting: event.isOnlineMeeting,
        location: event.location,
      };
    } else {
      return {
        id: event._id,
        title:
          typeof event.eventName === "string"
            ? event.eventName
            : getApiDataByLang(event.eventName),
        description:
          typeof event.description === "string"
            ? event.description
            : getApiDataByLang(event.description),
        startDate: event.eventStartTimestamp,
        endDate: event.eventEndTimestamp,
      };
    }
  }

  const getList = useCallback(async () => {
    let active = true;

    try {
      // eslint-disable-next-line
      const response = isTWN
        ? await getCalendarEventList(listParams)
        : MS_TOKEN && (await getCalendarPartnerTeam(listParams, MS_TOKEN));

      // console.log("response", response);
      if (response && response.status === 200) {
        if (active) {
          let haveEventDayList: Array<number> = [0];
          let currentDateEventList: Array<ListType> = [];

          let events = isTWN ? response.data.events : response.data.calendars;
          const convertEvents = events.map((event: ObjectKey) =>
            convertEvent(event, !isTWN)
          );

          convertEvents.forEach((event: ListType) => {
            const eventStartTimes = moment.unix(event.startDate).format();
            const eventEndTimes = moment.unix(event.endDate).format();
            const isSameMonth = moment(eventStartTimes).isSame(
              eventEndTimes,
              "month"
            );

            if (isSameMonth) {
              if (moment(eventStartTimes).isSame(eventEndTimes, "day")) {
                // case: 7 / 15 - 7 / 15;
                const newDay = parseInt(moment(eventStartTimes).format("D"));
                if (!haveEventDayList.includes(newDay)) {
                  haveEventDayList.push(newDay);
                }
              } else {
                // case: 7 / 14 - 7 / 18;
                const startDate = parseInt(moment(eventStartTimes).format("D"));
                const endDate = parseInt(moment(eventEndTimes).format("D"));
                for (let i = startDate; i <= endDate; i++) {
                  if (!haveEventDayList.includes(i)) {
                    haveEventDayList.push(i);
                  }
                }
              }
            } else if (
              parseInt(moment(eventStartTimes).format("M")) === listParams.month
            ) {
              // case: 7 / 15 - 8 / 15;
              const startDate = parseInt(moment(eventStartTimes).format("D"));
              const endDate = parseInt(
                moment(eventStartTimes).endOf("month").format("D")
              );
              for (let i = startDate; i <= endDate; i++) {
                if (!haveEventDayList.includes(i)) {
                  haveEventDayList.push(i);
                }
              }
            } else if (
              parseInt(moment(eventEndTimes).format("M")) === listParams.month
            ) {
              // case: 6 / 15 - 7 / 15;
              const startDate = parseInt(
                moment(eventEndTimes).startOf("month").format("D")
              );
              const endDate = parseInt(moment(eventEndTimes).format("D"));
              for (let i = startDate; i <= endDate; i++) {
                if (!haveEventDayList.includes(i)) {
                  haveEventDayList.push(i);
                }
              }
            } else {
              // case: 6 / 15 - 8 / 15;
              const startDate = 1;
              const endDate = parseInt(moment().endOf("month").format("D"));
              for (let i = startDate; i <= endDate; i++) {
                if (!haveEventDayList.includes(i)) {
                  haveEventDayList.push(i);
                }
              }
            }

            if (
              selectDate.isSame(eventStartTimes, "day") ||
              selectDate.isSame(eventEndTimes, "day") ||
              selectDate.isBetween(
                eventStartTimes,
                eventEndTimes,
                undefined,
                "[]"
              )
            ) {
              currentDateEventList.push(event);
            }
          });

          setEventDayList(haveEventDayList);
          setEventList(convertEvents);
          setFilterList(currentDateEventList);
        }
      }
      setIsLoading(false);
      // eslint-disable-next-line
    } catch (error: any) {
      // TODO: remove status code 6000 and 4005 handler after backend fixed
      if (
        error.response.data.status === 6000 ||
        error.response.data.status === 4083 ||
        error.response.data.status === 4005
      ) {
        getMSTokenSilent(accounts, instance, `/calendar`, () => {
          getList();
        });
      } else {
        setIsLoading(false);
        throw error;
      }
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  useEffect(() => {
    getList();
  }, [getList]);

  const calendarMode = () => (
    <>
      <Box className={styles.calendarWrapper}>
        <Calendar
          activeMonth={listParams.month}
          activeYear={listParams.year}
          value={selectDate}
          highlightDayList={eventDayList}
          disableHighlightToday
          disablePast
          isLoading={isLoading}
          onSelectDate={(date: Moment) => handleSelectedDateChange(date)}
          onSelectMonth={(date: Moment) => handleActiveDateChange(date)}
        />
      </Box>
      <Grid
        container
        direction="column"
        item
        xs
        className={styles.calendarContainer}
      >
        <List className={styles.calendarEventList}>
          {convertListOrder(filterList)}
        </List>
      </Grid>
    </>
  );

  const listMode = () => {
    return (
      <List className={styles.calendarEventList}>
        {convertListOrder(eventList, true)}
      </List>
    );
  };

  const convertListOrder = (list: Array<ListType>, showSubHeader?: boolean) => {
    let eventListByDate: ObjectKey[] = [];

    list.forEach((event: ListType) => {
      const startDateMoment = moment.unix(event.startDate);
      const eventDate = startDateMoment.format("YYYY-MM-DD");
      const eventIndex = eventListByDate.findIndex(
        (item: ObjectKey) => item.date === eventDate
      );

      if (eventIndex > -1) {
        eventListByDate[eventIndex].list.push(event);
      } else {
        eventListByDate.push({
          date: eventDate,
          displayDate: convertDateWithWeekAndFullDate(event.startDate),
          list: [event],
        });
      }
    });

    return (
      <>
        {isSearching && (
          <>
            {showSubHeader && (
              <ListSubheader disableSticky className={styles.calendarEventDate}>
                <Skeleton variant="text" />
              </ListSubheader>
            )}
          </>
        )}

        {eventListByDate.map((item: ObjectKey, index: number) => (
          <React.Fragment key={index}>
            {showSubHeader && (
              <ListSubheader disableSticky className={styles.calendarEventDate}>
                <Typography variant="h5">{item.displayDate}</Typography>
              </ListSubheader>
            )}
            {item.list.map((event: ListType, eventIndex: number) => (
              <CalendarItem
                key={eventIndex}
                {...event}
                onClick={() => handleClickEvent(event.id, event)}
              />
            ))}
          </React.Fragment>
        ))}
      </>
    );
  };

  const LoadingSkeleton = () => {
    return (
      <Skeleton
        variant="rounded"
        height={62}
        // className={styles.calendarEventItem}
      />
    );
  };

  const handleResetField = () => {
    if (!isSearching) {
      setIsSearching(true);
      setSearchData([]);
      setSearchListParams((prev) => ({
        ...prev,
        page: 1,
        pageSize: 20,
        search: "",
      }));
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("handleValueChange");
    if (popupOpen) {
      const { value } = e.target;
      setIsSearching(true);
      setSearchData([]);
      setSearchListParams((prev) => ({
        ...prev,
        page: 1,
        search: value,
      }));
    }
  };

  const handleOpenPopup = () => {
    setIsSearching(true);
    setSearchData([]);
    setPopupOpen(true);
    setSearchListParams((prev) => ({
      ...prev,
      page: 1,
      search: "",
      type: "Registered,Checked-In",
    }));
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setIsSearching(false);
  };

  const searchList = useCallback(async () => {
    // console.log("searchList");
    let active = true;
    setIsSearching(true);
    setHasMore(true);

    try {
      const response = isTWN
        ? await searchCalendarEvents(searchListParams)
        : MS_TOKEN &&
          (await searchCalendarPartnerTeam(searchListParams, MS_TOKEN));
      // console.log("searchList", response);
      if (response && response.status === 200) {
        let events = isTWN ? response.data.events : response.data.calendars;
        if (events && events.length > 0) {
          if (active) {
            const convertEvents = events.map((event: ObjectKey) =>
              convertEvent(event, !isTWN)
            );
            setSearchData((prev) => [...prev, ...convertEvents]);
            if (
              searchListParams.pageSize &&
              events.length < searchListParams.pageSize
            ) {
              setHasMore(false);
            }
          }
        } else {
          setHasMore(false);
        }
      }
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      throw error;
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchListParams]);

  useEffect(() => {
    if (popupOpen) {
      searchList();
    }
  }, [searchList, popupOpen]);

  const toolbarList = [
    {
      label: "Today",
      icon: "/assets/images/calendar-today.svg",
      action: switchToToday,
      className: "TodayButton",
    },
    {
      label: "Mode",
      icon:
        displayMode === "list"
          ? "/assets/images/list-ul-alt-2.svg"
          : "/assets/images/files_list.svg",
      action: () =>
        displayMode === "calendar"
          ? setDisplayMode("list")
          : setDisplayMode("calendar"),
      className: "",
    },
    {
      label: "Search",
      icon: "/assets/images/toolbar_search_black.svg",
      action: handleOpenPopup,
      className: "",
    },
  ];

  // let tabList: Array<TabType> = [];

  // if (process.env.REACT_APP_LOCATION !== "TWN") {
  //   tabList = [
  //     {
  //       id: "registeredEventsTab",
  //       label: t("myCalendar.registeredEvents"),
  //       value: "Registered,Checked-In",
  //     },
  //     {
  //       id: "msTeamsTab",
  //       label: t("myCalendar.msTeams"),
  //       value: "msTeams",
  //     },
  //   ];
  // }

  const nextPage = () => {
    if (!isLoading) {
      setSearchListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  return (
    <>
      <Header
        enableBackButton
        disableBottomBorder
        title={t("myCalendar.title")}
        closeButtonFunction={() => {
          // console.log("closeButtonFunction");
          const prevPage = sessionStorage.getItem("prevPage") ?? null;
          if (prevPage) {
            window.location.href = prevPage;
          } else {
            navigate(-1);
          }
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          className={styles.toolsContainer}
        >
          {toolbarList.map((item, index) => (
            <Grid key={index} item xs="auto" className={styles.toolsItem}>
              <IconButton aria-label={item.label} onClick={item.action}>
                <img
                  src={item.icon}
                  alt={item.label}
                  className={styles[`calendar${item.className}`]}
                />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Header>
      {/* {process.env.REACT_APP_LOCATION !== "TWN" && (
        <Tabs
          value={listParams.type}
          className={styles.calendarTabSection}
          onChange={handleChangeTab}
        >
          {tabList.map((item, index) => (
            <Tab {...item} key={index} className={styles.calendarTab} />
          ))}
        </Tabs>
      )} */}
      <Stack
        className={styles.calendar}
        data-list-type={listParams.type}
        data-display-mode={displayMode}
      >
        {displayMode === "calendar" ? calendarMode() : listMode()}
      </Stack>
      <Popup
        id="searchMyEventPopup"
        isOpen={popupOpen}
        content={
          <Stack
            className={`${styles.popupContainer} ${styles.calendar}`}
            data-list-type={searchListParams.type}
          >
            <SearchField
              id="search"
              name="search"
              placeholder={t("general.search")}
              value={searchListParams.search ?? ""}
              onChange={handleValueChange}
              onReset={handleResetField}
              onClose={handleClosePopup}
            />
            {/* {tabList.length > 0 && (
              <Tabs
                value={searchListParams.type}
                className={styles.calendarTabSection}
                onChange={handleChangeSearchTab}
              >
                {tabList.map((item: TabType, index: number) => (
                  <Tab {...item} key={index} className={styles.calendarTab} />
                ))}
              </Tabs>
            )} */}
            <Grid
              id="searchListsContainer"
              item
              xs
              className={styles.popupContent}
            >
              {!isLoading && !hasMore && searchData.length === 0 && (
                <NoResults resultsType="SEARCH" />
              )}
              <List className={styles.calendarEventList}>
                <InfiniteScroll
                  dataLength={searchData.length} //This is important field to render the next data
                  next={nextPage}
                  hasMore={hasMore}
                  loader={<LoadingSkeleton />}
                  scrollableTarget="searchListsContainer"
                >
                  {convertListOrder(searchData, true)}
                </InfiniteScroll>
              </List>
            </Grid>
          </Stack>
        }
        disableActions
        fullScreen
        setIsOpen={(isClose) => setPopupOpen(isClose)}
      />
    </>
  );
};

interface CalendarItemProps extends ListType {
  onClick: () => void;
  isMS?: boolean;
}

const CalendarItem: FC<CalendarItemProps> = (props) => {
  // const spinDescription = () => {
  //   const dom = document.createElement("div");
  //   dom.innerHTML = props.description;
  //   // return dom.getElementsByTagName("p").item(0)?.innerHTML ?? "";
  //   return "";
  // };
  // console.log("endDate", props.endDate);
  const isSameDay = convertDate(props.startDate) === convertDate(props.endDate);
  const isPast = moment.unix(props.endDate).isBefore(moment());
  return (
    <ListItemButton
      className={`${styles.calendarEventItem}${
        isPast ? ` ${styles.past}` : ""
      }`}
      {...(props.isMS && { "data-list-type": "msTeams" })}
      {...(!isPast && { onClick: props.onClick })}
    >
      <Grid container alignItems="center" flexWrap="nowrap" gap={2}>
        <Grid item xs="auto" className={styles.calenderIcon}>
          <img
            src={`/assets/images/calender_${
              props.isMS ? "teams" : "starbucks"
            }.svg`}
            alt=""
          />
        </Grid>
        <Grid item xs className={styles.calendarEventItemInfo}>
          <Typography variant="h5">{props.title}</Typography>
          <Grid
            container
            alignItems="center"
            flexWrap="nowrap"
            className={styles.calendarEventItemTime}
          >
            <div
              className={styles.calendarDateItem}
              {...(!isSameDay && { style: { textAlign: "center" } })}
            >
              {!isSameDay && (
                <Typography variant="body1" style={{ fontSize: "12px" }}>
                  {convertDate(props.startDate)}
                </Typography>
              )}
              <Typography variant="body1">
                {convertTime(props.startDate)}
              </Typography>
            </div>
            <div
              className={styles.calendarDateItem}
              {...(!isSameDay && { style: { textAlign: "center" } })}
            >
              {!isSameDay && (
                <Typography variant="body1" style={{ fontSize: "12px" }}>
                  {convertDate(props.endDate)}
                </Typography>
              )}
              <Typography variant="body1">
                {convertTime(props.endDate)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </ListItemButton>
  );
};

export default MyCalendar;
