import React, { FC, useEffect, useRef, useState } from "react";
import InputField, { InputFieldProps } from "../input-field";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Radio,
  IconButton,
  Typography,
  Box,
  Slide,
  Dialog,
  Grid,
} from "@mui/material";
import styles from "./survey-field.module.scss";
import SelectFieldContainer from "../select-field-container";
import { containsObject, getApiDataByLang } from "../../utility";
import { useSelectHook } from "../select-field/useSelectHook";
import SearchField from "../search-field";
import { TransitionProps } from "@mui/material/transitions";
import SurveyTemplate from "../../pages/survey/surveyTemplate";
import SurveyCreate from "../../pages/survey/surveyCreate";
import { ObjectKey } from "../../interfaces/common-interface";

export interface SurveyFieldProps
  extends Omit<InputFieldProps, "value" | "multiline"> {
  startAdornment?: React.ReactNode;
  value: ObjectKey;
  multiple?: boolean;
  disableLabel?: boolean;
  error?: boolean;
  onRemove?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SurveyField: FC<SurveyFieldProps> = (props) => {
  const { multiple, disableLabel, onRemove, ...inputProps } = props;
  // const {
  //   multipleValueDisplayRef,
  //   selectedValue,
  //   enableOtherField,
  //   handleSelectOption,
  //   handleReset,
  //   displayValue,
  //   handleClear,
  // } = useSelectHook({
  //   value,
  //   multiple
  // });

  // const handleConfirmSelected = () => {
  //   handleSelectOption(selectedValue, multiple);
  //   containerProps.onChange(selectedValue);
  // };

  const hiddenLabel = disableLabel && inputProps.value;
  const containerRef = useRef(null);
  const [surveyMode, setSurveyMode] = useState("select");
  const [templateId, setTemplateId] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpenPopup = () => {
    if (inputProps.disabled) return false;
    if (inputProps.value) {
      setSurveyMode("edit");
    } else {
      setSurveyMode("select");
    }
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
    setSurveyMode("clear");
  };

  const handleCreateTemplate = (id?: string) => {
    setTemplateId(id ?? "");
    setSurveyMode("create");
  };

  const handleSurveyCreated = (id: string, name: ObjectKey) => {
    setSurveyMode("clear");
    setOpen(false);
    props.onChange({ id, name });
  };

  const surveyContent = () => {
    switch (surveyMode) {
      case "select":
        return (
          <SurveyTemplate
            onClickCreate={handleCreateTemplate}
            onBack={handleClosePopup}
          />
        );
      case "create":
        return (
          <SurveyCreate
            mode="create"
            onBack={() => setSurveyMode("select")}
            submitCallback={handleSurveyCreated}
            id={templateId}
          />
        );
      case "edit":
        return (
          <SurveyCreate
            mode="edit"
            onBack={handleClosePopup}
            submitCallback={handleSurveyCreated}
            id={inputProps.value && inputProps.value.id}
          />
        );
      default:
        return null;
    }
  };

  const displayValue = () => {
    if (!inputProps.value) return "";

    if (typeof inputProps.value === "string") {
      return inputProps.value;
    } else {
      return getApiDataByLang(inputProps.value.name);
    }
  };

  return (
    <>
      <InputField
        {...inputProps}
        value={displayValue()}
        onChange={(e) => e.preventDefault()}
        onClick={handleOpenPopup}
        onChipClick={onRemove}
        error={inputProps.error}
        label={hiddenLabel ? undefined : inputProps.label}
        endAdornment={<img src="/assets/images/chevron_right.svg" alt="" />}
      />
      <Dialog
        id={props.id}
        open={open}
        onClose={handleClosePopup}
        fullScreen
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            overflow: "hidden",
          },
        }}
      >
        <Grid
          container
          item
          xs
          flexDirection="column"
          className={styles.popupWrapper}
          ref={containerRef}
          sx={{
            overflow: "hidden",
          }}
        >
          {surveyContent()}
        </Grid>
      </Dialog>
    </>
  );
};

export default SurveyField;
