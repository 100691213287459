import * as React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { EventCardBaseType } from '../../interfaces/event-interface';
import { convertDateTime } from '../../utility';
import CategoryTag from '../category-tag/category-tag';
import styles from './event-card.module.scss';

interface EventCardProps extends EventCardBaseType {
  size?: 'small' | 'large';
  onClick: (id: string) => void;
}

export default function EventCard(props: EventCardProps) {
  const { size = 'small', ...cardsProps } = props;

  const { t } = useTranslation();

  return (
    <Card
      className={`${styles.cardWrapper} ${styles['cardSize--' + size]}`}
      onClick={() => props.onClick(props.id)}
      data-event-day={moment(cardsProps.startsFrom).format('YYYY-MM-DD')}
    >
      <CardMedia
        className={styles.cardThumbnail}
        image={cardsProps.imageUrl}
        title={cardsProps.name}
      >
        <Box className={styles.dateContainer}>
          <Typography className={styles.date} variant="h4">
            {moment(cardsProps.startsFrom).format('DD')}
          </Typography>
          <Typography className={styles.month} variant="caption">
            {moment(cardsProps.startsFrom).format('MMM')}
          </Typography>
        </Box>
        {cardsProps.category && (
          <Box className={styles.typeTag}>
            <CategoryTag category={cardsProps.category}></CategoryTag>
          </Box>
        )}
      </CardMedia>
      <CardContent className={styles.cardContent}>
        <Box className={styles.eventDetails}>
          <Typography
            className={styles.eventTitle}
            variant={size === 'large' ? 'subtitle1' : 'body1'}
          >
            {cardsProps.name}
          </Typography>
          <Grid
            container
            alignItems="center"
            columnGap={1}
            wrap="nowrap"
            className={styles.timeContainer}
          >
            <Grid item xs="auto">
              <img src="/assets/images/eventIcon_clock_two.svg" alt="" />
            </Grid>
            <Grid item xs>
              <Typography variant={size === 'large' ? 'body1' : 'body2'}>
                {`${convertDateTime(cardsProps.startsFrom)} - ${convertDateTime(
                  cardsProps.endsAt
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={styles.statusTag} data-status={cardsProps.status}>
          <Typography variant="body2">
            {t('event.status.' + cardsProps.status)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
