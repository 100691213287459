import { createBrowserRouter } from "react-router-dom";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { ChannelList } from "./ChannelList";
import { Channel } from "./Channel";
import { ChannelSettings } from "./ChannelSettings";
import { MessageSearch } from "./MessageSearch";

type Props = {};

const ChatRouter = (props: Props) => {
  const { channelUrl } = useParams();
  const pathname = window.location.pathname;

  if (pathname.endsWith("/settings")) {
    return <ChannelSettings />;
  } else if (pathname.endsWith("/search")) {
    return <MessageSearch />;
  } else if (channelUrl) {
    return <Channel />;
  }
  return <ChannelList />;
};

export default ChatRouter;
