import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./greenApronCard.module.scss";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Skeleton,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Header from "../../components/header/header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  RepliedType,
  GreenApronCardDetailProps,
} from "../../interfaces/green-apron-card-interface";
import { convertDateWithFullMonth, convertUserProfile } from "../../utility";
import moment from "moment";
import {
  getCardDetail,
  replyCard,
  resendCard,
  sendCard,
} from "../../services/green-apron-card";
import GreenApronCardContext from "./greenApronCardProvider";
import { FormValueType, useGreenCardFormHook } from "./useGreenCardFormHook";
import { getStaffDetail } from "../../services/common";
import { UserState } from "../../reducers/user-slice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const GreenApronCardDetail: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [cardDetail, setCardDetail] = useState<GreenApronCardDetailProps>(
    null!
  );
  const [existReplyObject, setExistReplyObject] = useState<RepliedType | null>(
    null
  );
  const [isReplied, setIsReplied] = useState(false);
  const [isReplyMode, setIsReplyMode] = useState(false);

  const [replyMsgSent, setReplyMsgSent] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const { setSelectedId, setSendValue } = useContext(GreenApronCardContext);
  const { formValue, convertResponseToFormValue, convertFormValueToRequest } =
    useGreenCardFormHook();

  useEffect(() => {
    let active = true;

    const getDetail = async (cardId: string) => {
      try {
        const response = await getCardDetail(cardId);
        const data = response.data.greenApronCardDetail;
        if (!data) {
          navigate("/green-apron-card?status=notFound");
        }

        if (active) {
          let repliedObject: RepliedType | null = null;
          if (data.cardReplyBy) {
            repliedObject = {
              name: data.cardReplyByName.userName ?? data.cardReplyByName.name,
              avatar: data.cardReplyBy.userProfilePictureUrl,
              msg: data.cardReplyMessage,
              date: data.cardReplyTime,
            };
          }
          // console.log("data", data);
          const detail: GreenApronCardDetailProps = {
            id: data._id,
            receiver: {
              id: data.receiverId,
              name: data.receiver,
              avatar: "",
            },
            sender: {
              id: data.senderId,
              name: data.sender,
              avatar: "",
            },
            description: data.senderMessage ?? "",
            sendDate: data.sendTime,
            cardInfo: {
              id: data.cardLibraryId,
              name: "",
              description: data.cardDefaultMessage,
              url: data.imageUrl,
            },
            isReplied: Boolean(data.cardReplyMessage),
            status: data.status,
            receiverEmails:
              data.receiverEmail && data.receiverEmail !== ""
                ? [
                    {
                      receiverName: data.receiverEmailName,
                      receiverEmail: data.receiverEmail,
                    },
                  ]
                : [],
            repliedObject,
          };
          setCardDetail(detail);
          if (data.status === "FAILED") {
            convertResponseToFormValue(detail);
          }
          if (
            location.pathname.split("/").pop() === "reply" &&
            !repliedObject
          ) {
            setIsReplyMode(true);
          }
          setIsReplied(detail.isReplied);
          setExistReplyObject(repliedObject ?? null);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        navigate("/green-apron-card?status=notFound");
        throw error;
      }
    };

    if (id) {
      if (isLoading) getDetail(id);
    } else {
      navigate("/green-apron-card");
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const repliedDate =
    existReplyObject &&
    convertDateWithFullMonth(moment.unix(existReplyObject.date).format());

  const FailedMsgBar = () => {
    if (!cardDetail || cardDetail.status !== "FAILED") return null;

    return (
      <Grid container alignItems="center" className={styles.detailFailSection}>
        <Grid item xs="auto">
          <img src="/assets/images/greenApronCard_status_failed.svg" alt="" />
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("greenApronCard.sendCardFailedMsg")}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Button
            id="resendCardButton"
            disableRipple
            className={styles.detailResendButton}
            disabled={isSending}
            onClick={handleResendCard}
          >
            {t("greenApronCard.resend")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const sentDate = () => {
    if (cardDetail.status !== "SCHEDULED")
      return convertDateWithFullMonth(cardDetail.sendDate);

    return (
      <>
        <img src="/assets/images/greenApronCard_status_scheduled.svg" alt="" />
        {t("greenApronCard.thisCardWillBeSentAt", {
          date: convertDateWithFullMonth(cardDetail.sendDate),
        })}
      </>
    );
  };

  const ReplyMsgBox = () => {
    if (!isReplied || !existReplyObject) return null;

    return (
      <Card className={styles.detailRepliedBox}>
        <CardHeader
          avatar={
            <Avatar
              src={existReplyObject.avatar}
              alt=""
              className={styles.avatar}
            />
          }
          title={existReplyObject.name}
          titleTypographyProps={{
            variant: "body1",
          }}
          className={styles.header}
        />
        <CardContent className={styles.content}>
          <Typography variant="subtitle2">{existReplyObject.msg}</Typography>
          {repliedDate && (
            <Typography variant="caption">{repliedDate}</Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  const handleSubmitReplyMsg = async (msg: string) => {
    if (!id || msg === "") return false;

    const request = {
      cardId: id,
      replyMessage: msg,
    };
    setIsSending(true);

    try {
      const response = await replyCard(request);
      if (response.status === 200) {
        const { greenApronCard } = response.data;
        setReplyMsgSent(true);
        setIsReplyMode(false);
        setIsReplied(true);
        const replyTarget = await getStaffDetail(
          greenApronCard.cardReplyMessage[0].createBy
        );
        const replyTargetProfile = convertUserProfile(
          replyTarget.data.userProfile
        );
        setExistReplyObject({
          name: replyTargetProfile.name,
          avatar: replyTargetProfile.avatar ?? "",
          msg: msg,
          date: moment().unix(),
        });
      }
      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      throw error;
    }
  };

  const handleClickEditButton = () => {
    convertResponseToFormValue(cardDetail, (data: FormValueType) => {
      setSendValue(data);
      setSelectedId(cardDetail.cardInfo.id);
      navigate(`/green-apron-card/send/${cardDetail.id}`);
    });
  };

  const handleResendCard = async () => {
    setIsSending(true);
    // const request = convertFormValueToRequest();
    // console.log("cardDetail", cardDetail);
    try {
      const response = await resendCard(cardDetail.id);
      // const response = await sendCard({ ...request, isSendNow: true });
      if (response.status === 200) {
        navigate("/green-apron-card?send=success");
      } else {
        navigate("/green-apron-card/send");
      }
    } catch (error) {
      navigate("/green-apron-card/send");
    } finally {
      setSendValue(null);
      setIsSending(false);
    }
  };

  if (!isLoading && !cardDetail) {
    navigate("/green-apron-card?status=notFound");
    return null;
  }

  return (
    <Grid item xs className={styles.detailContainer}>
      <Header
        enableBackButton
        colouredBackground
        disableBottomBorder
        title=""
        closeButtonNavigation="/green-apron-card/sent"
      >
        {cardDetail && cardDetail.status !== "RECEIVED" && (
          <Button
            id="editGreenApronCardButton"
            disableRipple
            className={styles.editButton}
            onClick={handleClickEditButton}
          >
            {t("general.edit")}
          </Button>
        )}
      </Header>
      <Box className={styles.detailContent}>
        <FailedMsgBar />
        <Box className={styles.detailCard}>
          <Box className={styles.detailCardToTarget}>
            <Typography variant="body1">{t("greenApronCard.to")}</Typography>
            <Typography variant="subtitle1">
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={140}
                  className={styles.detailCardSkeleton}
                />
              ) : (
                cardDetail.receiver.name
              )}
            </Typography>
          </Box>
          <Box className={styles.detailCardInfo}>
            <Box className={styles.detailCardImage}>
              {isLoading ? (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height="auto"
                  className={styles.detailCardImageSkeleton}
                />
              ) : (
                <img
                  src={cardDetail.cardInfo.url}
                  alt={cardDetail.cardInfo.name}
                />
              )}
            </Box>
            <Box className={styles.detailCardDefaultMsg}>
              <Typography variant="body1">
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  cardDetail.cardInfo.description
                )}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.detailCardDescription}>
            <Typography variant="subtitle2">
              {isLoading ? <Skeleton variant="text" /> : cardDetail.description}
            </Typography>
          </Box>
          <Box className={styles.detailCardFromTarget}>
            <Typography variant="subtitle2">
              {t("greenApronCard.from")}
            </Typography>
            <Typography variant="subtitle1">
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={140}
                  className={styles.detailCardSkeleton}
                />
              ) : (
                cardDetail.sender.name
              )}
            </Typography>
            <Typography variant="body1" className={styles.date}>
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={100}
                  className={styles.detailCardSkeleton}
                />
              ) : (
                sentDate()
              )}
            </Typography>
          </Box>
        </Box>
        <ReplyMsgBox />
        {isReplyMode && !replyMsgSent && (
          <ReplyFieldBox
            isSending={isSending}
            onSubmit={handleSubmitReplyMsg}
          />
        )}
      </Box>
    </Grid>
  );
};

interface ReplyFieldBoxProps {
  isSending: boolean;
  onSubmit: (msg: string) => void;
}

const ReplyFieldBox: FC<ReplyFieldBoxProps> = (props) => {
  const { t } = useTranslation();
  const [replyMsg, setReplyMsg] = useState("");
  const [replyMsgError, setReplyMsgError] = useState(false);
  const replyMsgLimit = 100;
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );

  const handleReplyMsgChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setReplyMsg(value);
    setReplyMsgError(value.length > replyMsgLimit);
  };

  return (
    <Grid container className={styles.detailRepliedField}>
      <Grid item xs="auto">
        <Avatar src={userState.avatar ?? ""} alt="" className={styles.avatar} />
      </Grid>
      <Grid item xs>
        <Box
          className={`${styles.textareaField} ${replyMsgError && styles.error}`}
        >
          <TextareaAutosize
            id="replyMsg"
            name="replyMsg"
            placeholder={t("greenApronCard.replyMsgPlaceholder")}
            value={replyMsg}
            onChange={handleReplyMsgChange}
          />
          {replyMsg !== "" && (
            <Typography variant="body2" align="right">
              {`${replyMsg.length} / ${replyMsgLimit}`}
            </Typography>
          )}
        </Box>
      </Grid>
      {replyMsg !== "" && (
        <Grid item xs="auto">
          <Button
            id="submitRepliedMsgButton"
            disableRipple
            type="submit"
            disabled={replyMsgError}
            className={styles.submitButton}
            onClick={() => (replyMsgError ? false : props.onSubmit(replyMsg))}
          >
            {props.isSending ? (
              <CircularProgress size="16px" />
            ) : (
              t("greenApronCard.send")
            )}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default GreenApronCardDetail;
