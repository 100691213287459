import { FC, useEffect, useState } from "react";
import styles from "./me.module.scss";
import {
  Grid,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  Radio,
  Divider,
} from "@mui/material";
import Header from "../../components/header/header";
import { useTranslation } from "react-i18next";
import InputField from "../../components/input-field";
import DropdownField from "../../components/dropdown-field";
import UploadFileField from "../../components/upload-file-field";
import { formatFileSize, getDropdownListOnStore } from "../../utility";
import { RoundButton } from "../../components";
import { FormValueType, useFormHook } from "../../utility/useFormHook";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState } from "../../reducers/user-slice";
import { ObjectKey } from "../../interfaces/common-interface";
import {
  getAreaCode,
  getIssueType,
  postFeedback,
  uploadFeedbackImage,
} from "../../services/user";

const MeContactUs: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const descriptionLimit = 100;
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [dropdownList, setDropdownList] = useState<{
    [key: string]: Array<any>;
  }>({
    areaCodeList: [],
    issueList: [],
  });
  const [loadedDropdownList, setLoadedDropdownList] = useState(false);
  const defaultFormValue: FormValueType = {
    name: {
      value: userState.name,
      isError: false,
      isRequired: true,
    },
    areaCode: {
      value: null,
      isError: false,
      isRequired: false,
      type: "areaCode",
    },
    phone: {
      value: "",
      isError: false,
      isRequired: false,
      type: "tel",
    },
    email: {
      value: "",
      isError: false,
      isRequired: false,
      type: "email",
    },
    issue: {
      value: null,
      isError: false,
      isRequired: true,
    },
    description: {
      value: "",
      isError: false,
      isRequired: true,
    },
    uploadPhotos: {
      value: [],
      isError: false,
      isRequired: false,
    },
  };
  const {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    checkValidation,
    setInitFormValue,
  } = useFormHook({
    value: defaultFormValue,
  });

  const handleFileFieldChange = async (name: string, value: File[] | null) => {
    if (!value) return false;

    try {
      const response = await uploadFeedbackImage({
        image: value,
      });
      if (response.status === 200) {
        const imagesList = response.data.feedbackFormLogImage;
        // eslint-disable-next-line
        let imagesValueList: any[] = [];
        imagesList.forEach((item: ObjectKey, index: number) => {
          const imageValue: ObjectKey = {
            id: (formValue[name].value.length + index).toString(),
            fileName: item.thumbPath.split("/").pop(),
            type: value[index].type,
            data: item.thumbUrl,
            size: value[index].size,
            requestData: item,
          };
          imagesValueList.push(imageValue);
        });

        const combineValue = [...formValue[name].value, ...imagesValueList].map(
          (item: ObjectKey, index: number) => ({
            ...item,
            id: index.toString(),
          })
        );

        handleInputFieldChange(name, combineValue);
      }
    } catch (error) {
      throw error;
    }
  };

  const convertFormValueToRequest = () => {
    const getFieldValue = (key: keyof FormValueType) => formValue[key].value;
    const phoneNumber =
      getFieldValue("phone") !== ""
        ? `${getFieldValue("areaCode").code}${getFieldValue("phone")}`
        : "";
    return {
      name: getFieldValue("name"),
      phoneNo: phoneNumber,
      email: getFieldValue("email"),
      issue: getFieldValue("issue")._id,
      comment: getFieldValue("description"),
      images: getFieldValue("uploadPhotos").map(
        (item: ObjectKey) => item.requestData
      ),
    };
  };

  const handleSubmit = async () => {
    const isValid = checkValidation();
    if (isValid && !isLoading) {
      setIsLoading(true);
      const request = convertFormValueToRequest();

      try {
        const response = await postFeedback(request);
        if (response.status === 200) {
          navigate("/me?sent");
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const getDropdownList = async () => {
      try {
        const issueList = await getDropdownListOnStore(
          "issueType",
          "feedbackIssueType",
          getIssueType
        );
        const areaCodeList = await getDropdownListOnStore(
          "areaCode",
          "feedbackAreaCode",
          getAreaCode
        );

        setDropdownList({
          areaCodeList,
          issueList,
        });

        setLoadedDropdownList(true);
      } catch (error) {
        throw error;
      }
    };

    getDropdownList();
  }, []);

  useEffect(() => {
    if (loadedDropdownList) {
      let currentAreaCode = "+886";
      if (process.env.REACT_APP_LOCATION === "PHL") {
        currentAreaCode = "+63";
      }

      const initAreaCodeOption = dropdownList.areaCodeList.find(
        (areaCode: ObjectKey) => areaCode.code === currentAreaCode
      );
      setInitFormValue({
        ...formValue,
        name: {
          ...formValue.name,
          value: userState.name,
        },
        areaCode: {
          ...formValue.areaCode,
          value: initAreaCodeOption,
        },
      });
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedDropdownList, userState]);

  return (
    <>
      <Header title={t("myProfile.contactUs.title")} enableBackButton />
      <Grid item xs className={styles.formContainer}>
        <Box className={styles.formField}>
          <Typography variant="h5" color="primary">
            {t("myProfile.contactUs.description")}
          </Typography>
        </Box>
        <Box className={styles.formField}>
          <InputField
            id="name"
            name="name"
            placeholder={t("myProfile.contactUs.yourName")}
            value={formValue.name.value}
            error={formValue.name.isError}
            required={formValue.name.isRequired}
            onChange={(e) => {
              const { name, value } = e.target;
              handleInputFieldChange(name, value);
            }}
          />
        </Box>
        <Box className={styles.formField}>
          <InputField
            id="phone"
            name="phone"
            placeholder={t("myProfile.contactUs.phoneNumber")}
            value={formValue.phone.value}
            error={formValue.phone.isError}
            required={formValue.phone.isRequired}
            onChange={(e) => {
              const { name, value } = e.target;
              handleInputFieldChange(name, value, [
                {
                  name: "phone",
                  required: value !== "",
                },
                {
                  name: "areaCode",
                  required: value !== "",
                },
              ]);
            }}
            startAdornment={
              <Grid container>
                <Grid item xs="auto">
                  <DropdownField
                    id="areaCode"
                    name="areaCode"
                    label={t("myProfile.contactUs.areaCode")}
                    disableLabel
                    value={formValue.areaCode.value}
                    options={dropdownList.areaCodeList}
                    getOptionValue={(value) => value._id}
                    getOptionLabel={(value) => value.code}
                    onChange={(value) => {
                      handleInputFieldChange("areaCode", value);
                    }}
                    optionsItem={(option, id, classes, isSelected) => (
                      <AreaCodeItem
                        id={id}
                        name={option.area}
                        value={option.code}
                        classes={classes}
                        isSelected={isSelected}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="auto" className={styles.formFieldDivider}>
                  <Divider orientation="vertical" />
                </Grid>
              </Grid>
            }
          />
        </Box>
        <Box className={styles.formField}>
          <InputField
            id="email"
            name="email"
            placeholder={t("myProfile.contactUs.email")}
            value={formValue.email.value}
            error={formValue.email.isError}
            required={formValue.email.isRequired}
            onChange={(e) => {
              const { name, value } = e.target;
              handleInputFieldChange(name, value, [
                {
                  name: "email",
                  required: value !== "",
                },
              ]);
            }}
          />
        </Box>
        <Box className={styles.formField}>
          <DropdownField
            id="issue"
            name="issue"
            label={t("myProfile.contactUs.issue")}
            disableLabel
            value={formValue.issue.value}
            error={formValue.issue.isError}
            required={formValue.issue.isRequired}
            options={dropdownList.issueList}
            getOptionValue={(value) => value._id}
            getOptionLabel={(value) => value.name}
            onChange={(value) => {
              handleInputFieldChange("issue", value);
            }}
          />
        </Box>
        <Box className={styles.formField}>
          <InputField
            id="description"
            name="description"
            placeholder={t("myProfile.contactUs.issueDescription")}
            value={formValue.description.value}
            error={formValue.description.isError}
            required={formValue.description.isRequired}
            multiline
            helperText={
              <Typography variant="body2" align="right">
                {`${formValue.description.value.length}/${descriptionLimit}`}
              </Typography>
            }
            onChange={(e) => {
              const { name, value } = e.target;
              handleTextareaFieldChange(name, value, descriptionLimit);
            }}
          />
        </Box>
        <Box className={styles.formField}>
          <UploadFileField
            id="uploadPhotos"
            name="uploadPhotos"
            placeholder={t("myProfile.contactUs.uploadPhotos")}
            helperText={t("myProfile.contactUs.uploadPhotosDescription", {
              limit: 10,
              size: formatFileSize(5 * 1000 * 1000),
            })}
            accept="image/*"
            inputFieldType="input"
            limitCount={10}
            limitSize={5 * 1000 * 1000}
            multiple
            value={formValue.uploadPhotos.value}
            error={formValue.uploadPhotos.isError}
            required={formValue.uploadPhotos.isRequired}
            onChange={(files) => {
              handleFileFieldChange("uploadPhotos", files as File[]);
            }}
            onRemove={(files) => {
              handleInputFieldChange("uploadPhotos", files);
            }}
          />
        </Box>
        <Box className={styles.formButton}>
          <RoundButton
            id="submitButton"
            type="submit"
            disabled={!isDirty}
            onClick={handleSubmit}
          >
            {isLoading ? "Loading..." : t("myProfile.contactUs.submit")}
          </RoundButton>
        </Box>
      </Grid>
    </>
  );
};

interface AreaCodeItemProps {
  id: string;
  name: string;
  value: string;
  classes?: string;
  isSelected?: boolean;
}

const AreaCodeItem: FC<AreaCodeItemProps> = (props) => {
  return (
    <>
      <Box>
        <Typography color="text.primary">{props.value}</Typography>
      </Box>
      <ListItemText
        primary={props.name}
        primaryTypographyProps={{ color: "text.primary" }}
      />
      <ListItemIcon>
        <Radio
          disableRipple
          checked={props.isSelected}
          value={props.value}
          inputProps={{ "aria-labelledby": props.id }}
          tabIndex={-1}
        />
      </ListItemIcon>
    </>
  );
};

export default MeContactUs;
