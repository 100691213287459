import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  Grid,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RoundButton, Popup, Checkbox } from "../../components";
import styles from "./login.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getPermission,
  postUserLoginTW,
  setAuthorizationToken,
} from "../../services/api-services";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import { getUserInfo } from "../../services/user";
import { useDispatch } from "react-redux";
import { UserState, setUserState } from "../../reducers/user-slice";
import { convertUserProfile } from "../../utility";
import InputField from "../../components/input-field";
import { getChatConfig } from "../../services/homepage";

function Login() {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  // const [isFirstLogin, setIsFirstLogin] = useState<boolean>(
  //   searchParams.get("FirstLogIn") ? true : false
  // );
  const [loginFailStatus, setLoginFailStatus] = useState({
    open: false,
    message: "",
  });

  const location = process.env.REACT_APP_LOCATION;
  const [partnerId, setPartnerId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    if (searchParams.get("status")) {
      if (searchParams.get("status") === "4083") {
        setLoginFailStatus({
          open: true,
          message: t("login.loginFail4083"),
        });
      } else {
        setLoginFailStatus({
          open: true,
          message: t("login.loginFail"),
        });
      }
      window.history.replaceState(null, "", "/login");
    }

    if (
      searchParams.get("initLocation") &&
      searchParams.get("initLocation") !== "/login"
    ) {
      const pathname = searchParams.get("initLocation");
      setRedirectPath(pathname);
      window.history.replaceState(null, "", "/login");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [redirectPath]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      login();
    }
  };

  const login = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (location === "TWN") {
        await postUserLoginTW({
          grant_type: "password",
          userId: partnerId
            ? partnerId
            : (document.getElementById("partnerIdInput") as HTMLInputElement)
                .value,
          password: password
            ? password
            : (document.getElementById("passwordInput") as HTMLInputElement)
                .value,
        })
          .then(async (response) => {
            // localStorage.setItem("ACCESS_TOKEN", response.data.access_token);
            // localStorage.setItem("REFRESH_TOKEN", response.data.refresh_token);
            setAuthorizationToken(response.data.access_token);
            await getInfo({
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token,
              isAcceptedTnc: response.data.isAcceptedTnc,
            });

            // setTimeout(() => {
            //   setIsLoading(false);
            // }, 100);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(true);
          });
      } else {
        if (window.location.hostname === "localhost") {
          // navigate(`/login?request=testLocalhost`);
          window.location.href = `/login?request=testLocalhost`;
        } else {
          instance.loginRedirect(loginRequest).catch((e) => {
            console.error("loginRedirect", e);
          });
        }
      }
    }
  };

  const getInfo = async (token: {
    accessToken: string;
    refreshToken: string;
    isAcceptedTnc: boolean;
  }) => {
    try {
      const permissionResponse = await getPermission();
      const response = await getUserInfo();
      const chatConfigResponse = await getChatConfig();
      const { userProfile } = response.data;
      let userInfo: UserState = convertUserProfile(userProfile) as UserState;

      userInfo = {
        ...userInfo,
        permission: permissionResponse.data,
        chat: chatConfigResponse.data,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken ?? "",
        isAcceptedTnc: token.isAcceptedTnc,
      };
      dispatch(setUserState(userInfo));
      handleRememberMe();
      setIsLoading(false);
      if (token.isAcceptedTnc) {
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate(`/home`);
        }
      } else {
        navigate(`/first-login`);
      }
    } catch (error) {
      setAuthorizationToken("");
      setError(true);
      setIsLoading(false);
    }
  };

  const handleEnterID = (event: any) => {
    setPartnerId(event.target.value);
    if (event.target && event.target.value.length !== 0 && password) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  const handleEnterPW = (event: any) => {
    setPassword(event.target.value);
    if (event.target && event.target.value.length !== 0 && partnerId) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  // function for remember me function to save partnerIdInput value (use high security method)
  const handleRememberMe = () => {
    if (isRememberMe) {
      localStorage.setItem("partnerId", partnerId);
    } else {
      localStorage.removeItem("partnerId");
    }
  };

  useEffect(() => {
    const rememberPartnerId = localStorage.getItem("partnerId");
    if (rememberPartnerId) {
      setPartnerId(rememberPartnerId);
      setIsRememberMe(true);
    }
  }, [localStorage]);

  return (
    <>
      <Box className={styles.pageWrapper}>
        <img
          className={styles.logo}
          src="/assets/images/starbucks_logo.png"
          alt=""
        />
        <Box className={styles.text}>
          <Typography variant="h1">
            {t("login.welcomeMsg1")}
            <br />
            <b>{t("login.welcomeMsg2")}</b>
            <br />
            <b>{t("login.welcomeMsg3")}</b>
          </Typography>
        </Box>
        {location === "TWN" && (
          <Box className={styles.formWrapper}>
            <Box className={styles.InputContainer}>
              <InputField
                id="partnerIdInput"
                name="partnerIdInput"
                placeholder={t("login.partnerId")}
                value={partnerId}
                error={error ? true : false}
                required
                onChange={handleEnterID}
              />
              <InputField
                id="passwordInput"
                name="passwordInput"
                placeholder={t("login.password")}
                helperText={error ? t("login.invalidIDPassword") : ""}
                value={password}
                error={error ? true : false}
                required
                type={showPassword ? "text" : "password"}
                onChange={handleEnterPW}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <img src="/assets/images/eye_slash.svg" />
                      ) : (
                        <img src="/assets/images/eye.svg" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Grid container className={styles.functionContainer}>
                <Grid item xs="auto">
                  <Checkbox
                    text={t("login.remember")}
                    checked={isRememberMe}
                    setChecked={(checked: boolean) => {
                      setIsRememberMe(checked);
                    }}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    variant="button"
                    className={styles.forgotPw}
                    onClick={() => setOpenPopup(true)}
                  >
                    {t("login.forgot")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <RoundButton disabled={isEmpty ? true : false} onClick={login}>
              {isLoading ? (
                <CircularProgress color="inherit" size="16px" />
              ) : (
                t("login.login")
              )}
            </RoundButton>
          </Box>
        )}
        {(location === "PHL" || location === "HK") && (
          <RoundButton onClick={login}>{t("login.loginSSO")}</RoundButton>
        )}
      </Box>
      <Snackbar
        open={loginFailStatus.open}
        autoHideDuration={6000}
        onClose={() =>
          setLoginFailStatus((prev) => ({
            ...prev,
            open: false,
          }))
        }
      >
        <Alert severity="error">{loginFailStatus.message}</Alert>
      </Snackbar>
      <Popup
        isOpen={openPopup}
        setIsOpen={setOpenPopup}
        title={t("login.forgotPwPopup") as string}
      />
    </>
  );
}

export default Login;
