import React from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomNavMenu, Popup, RoundButton } from '../../components';

import {
  createFolder,
  deleteFiles,
  deleteFolder,
  updateFiles,
  updateFolder,
} from '../../services/files';
import { checkDateIsNotTimestamp } from '../../utility';
import styles from './handlePopup.module.scss';
import { File } from './makeData';

type Props = {
  isOpen: boolean;
  setIsOpen: (close: boolean) => void;
  detail: { type: string; content?: any; folder?: any };
  onClickRename: (detail: File) => void;
  onClickNewFolder: () => void;
  onClickDetails: (detail: File) => void;
  onClickDelete: (detail: File) => void;
  onChangeFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChanged: (value: any) => void;
  isAdmin: boolean;
};

const HandlePopup = (props: Props) => {
  const {
    isOpen,
    setIsOpen,
    detail,
    onClickRename,
    onClickNewFolder,
    onClickDetails,
    onClickDelete,
    onChangeFileUpload,
    onChanged,
    isAdmin,
  } = props;

  const { t } = useTranslation();
  const isPHL = process.env.REACT_APP_LOCATION === 'PHL';

  const PopupTitle = () => {
    // console.log("detail", detail);
    let title = <span>{t('files.actions.create')}</span>;
    if (detail.type === 'input') {
      title = detail.content ? (
        <span>{t('files.actions.rename')}</span>
      ) : (
        <span>{t('files.actions.newFolder')}</span>
      );
    } else if (detail.type === 'more') {
      title = (
        <div className={styles.title}>
          <img
            src={`/assets/images/files/file_${detail.content.file.type}.svg`}
            alt={detail.content.file.type}
          />
          <span>{detail.content.file.name}</span>
        </div>
      );
    } else if (detail.type === 'details') {
      title = (
        <div className={styles.title}>
          <img
            src="/assets/images/close_btn.svg"
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <span>{t('files.actions.details')}</span>
        </div>
      );
    } else if (detail.type === 'delete') {
      title = (
        <span>
          {detail.content.file.type === 'folder'
            ? t('files.removeFolder')
            : t('files.removeFile')}
        </span>
      );
    } else if (detail.type === 'upload') {
      title = <span>{t('files.actions.leavePage')}</span>;
    }
    return (
      <div className={styles.popupHeader}>
        {title}
        {detail.type !== 'input' &&
          detail.type !== 'details' &&
          detail.type !== 'delete' && (
            <img
              src="/assets/images/close_btn.svg"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          )}
      </div>
    );
  };
  const convertDateTime = (date: string | number) => {
    const momentDate = checkDateIsNotTimestamp(date);
    return moment(momentDate).format('YYYY/MM/DD HH:mm:ss');
  };
  // console.log('detail', detail);
  const PopupContent = () => {
    const navigate = useNavigate();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
      if (inputRef.current) {
        setValue(inputRef.current.value);
      }
    }, [inputRef]);

    let content = (
      <div className={styles.contentList}>
        <button
          type="button"
          onClick={() => {
            setIsOpen(false);
            onClickNewFolder();
          }}
        >
          <img
            src="/assets/images/files/new_folder.svg"
            alt={t('files.actions.newFolder')}
          />
          {t('files.actions.newFolder')}
        </button>
        <button type="button">
          <img
            src="/assets/images/files/upload_files.svg"
            alt={t('files.actions.uploadFiles')}
          />
          {t('files.actions.uploadFiles')}
          <input
            type="file"
            name="fileUpload"
            id="fileUpload"
            onChange={onChangeFileUpload}
          />
        </button>
      </div>
    );
    if (detail.type === 'input') {
      // const name = detail.content && detail.content.file.name.split(".")[0];
      // const extension =
      //   detail.content && detail.content.file.name.split(".")[1];
      content = (
        <>
          <div className={styles.contentInput}>
            <div className={styles.inputControl}>
              <input
                type="text"
                placeholder={t('files.placeholder.name')}
                defaultValue={''}
                // defaultValue={detail.content.file.name}
                ref={inputRef}
                onChange={(e) => {
                  if (inputRef.current) {
                    setValue(e.target.value);
                  }
                }}
              />
              {value.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    if (inputRef.current) {
                      inputRef.current.value = '';
                      setValue('');
                    }
                  }}
                >
                  <img src="/assets/images/files/input_reset.svg" alt="" />
                </button>
              )}
            </div>
            {detail?.content?.file.extension &&
              detail?.content?.file.extension !== 'folder' && (
                <div className={styles.extension}>
                  .{detail.content.file.extension}
                </div>
              )}
          </div>
          <div className={styles.inputAction}>
            <RoundButton
              className={styles.cancel}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('files.actions.cancel')}
            </RoundButton>
            <RoundButton
              className={styles.confirm}
              disabled={value.length > 0 ? false : true}
              onClick={() => {
                setIsOpen(false);
                if (detail.content) {
                  if (detail.content.file.type === 'folder') {
                    updateFolder(detail.content.id, {
                      name: inputRef.current?.value.trim(),
                    })
                      .then((response: any) => {
                        // console.log("updateFolder", response);
                        sessionStorage.setItem(
                          'snackbar',
                          JSON.stringify({
                            open: true,
                            type: 'success',
                            message: t('files.renameFolderSuccess'),
                          })
                        );
                        window.location.reload();
                      })
                      .catch((error) => {});
                  } else {
                    updateFiles(detail.content.id, {
                      name: inputRef.current?.value.trim(),
                    })
                      .then((response: any) => {
                        sessionStorage.setItem(
                          'snackbar',
                          JSON.stringify({
                            open: true,
                            type: 'success',
                            message: t('files.renameFileSuccess'),
                          })
                        );
                        // console.log("updateFiles", response);
                        window.location.reload();
                      })
                      .catch((error) => {});
                  }
                } else {
                  createFolder({
                    isRootFolder:
                      detail.folder === null || detail.folder?.path === ''
                        ? true
                        : false,
                    name: inputRef.current?.value.trim(),
                    parentFolderId:
                      detail.folder === null || detail.folder?.path === ''
                        ? ''
                        : detail.folder?._id,
                  })
                    .then((response: any) => {
                      console.log('response', response);
                      sessionStorage.setItem(
                        'snackbar',
                        JSON.stringify({
                          open: true,
                          type: 'success',
                          message: t('files.newFolderSuccess'),
                        })
                      );
                      onChanged(response.data.folder._id);
                      // navigate(`/files/${response.data.folder._id}`);
                      window.location.href = `/files/${response.data.folder._id}`;
                    })
                    .catch((error) => {});
                }
              }}
            >
              {detail.content
                ? t('files.actions.rename')
                : t('files.actions.create')}
            </RoundButton>
          </div>
        </>
      );
    } else if (detail.type === 'more') {
      content = (
        <div className={styles.contentList}>
          <button
            type="button"
            onClick={() => {
              setIsOpen(false);
              onClickDetails(detail.content);
            }}
          >
            <img src="/assets/images/files/info_button.svg" alt="info" />
            {t('files.actions.details')}
          </button>
          {isAdmin && !isPHL && (
            <button
              type="button"
              onClick={() => {
                setIsOpen(false);
                onClickRename(detail.content);
              }}
            >
              <img src="/assets/images/files/rename_button.svg" alt="rename" />
              {t('files.actions.rename')}
            </button>
          )}
          {isAdmin && !isPHL && (
            <button
              type="button"
              onClick={() => {
                setIsOpen(false);
                onClickDelete(detail.content);
              }}
            >
              <img src="/assets/images/files/remove_button.svg" alt="remove" />
              {t('files.actions.remove')}
            </button>
          )}
        </div>
      );
    } else if (detail.type === 'details') {
      content = (
        <div className={styles.contentDetails}>
          <div className={styles.detailsHeader}>
            {(detail.content.file.type === 'image' ||
              detail.content.file.type === 'video') && (
              <div className={styles.mediaContent}>
                {detail.content.file.type === 'image' ? (
                  <img src={detail.content.file.image} alt="" />
                ) : (
                  <video
                    src={`${detail.content.file.video}#t=0.001`}
                    controls
                  ></video>
                )}
              </div>
            )}

            <div className={styles.title}>
              <img
                src={`/assets/images/files/file_${detail.content.file.type}.svg`}
                alt={detail.content.file.type}
              />
              <span>{detail.content.file.name}</span>
            </div>
          </div>
          <div className={styles.detailsContent}>
            <h5>{t('files.details.information')}</h5>
            <ul className={styles.detailsList}>
              <li>
                <label>{t('files.details.type')}</label>
                <span>{detail.content.file.type}</span>
              </li>
              <li>
                <label>{t('files.details.size')}</label>
                <span>{detail.content.file.size}</span>
              </li>
              <li>
                <label>{t('files.details.lastUpdateDate')}</label>
                <span>{convertDateTime(detail.content.lastUpdateDate)}</span>
              </li>
              <li>
                <label>{t('files.details.lastUpdateBy')}</label>
                <span>{detail.content.lastUpdateBy}</span>
              </li>
              <li>
                <label>{t('files.details.createDate')}</label>
                <span>{convertDateTime(detail.content.createDate)}</span>
              </li>
              <li>
                <label>{t('files.details.createBy')}</label>
                <span>{detail.content.createBy}</span>
              </li>
              <li>
                <label>{t('files.details.location')}</label>
                <div className={styles.location}>
                  {detail.content.file.location
                    .substring(1)
                    .split('/')
                    .map((item: string, index: number) => {
                      return <span key={index}>{item}</span>;
                    })}
                </div>
              </li>
            </ul>
          </div>
        </div>
      );
    } else if (detail.type === 'delete') {
      content = (
        <>
          <div className={styles.contentDesc}>
            <span>{`${
              detail.content.file.type === 'folder'
                ? t('files.removeFolderDescription')
                : t('files.removeFileDescription')
            }`}</span>
          </div>
          <div className={styles.inputAction}>
            <RoundButton
              className={styles.cancel}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('files.actions.cancel')}
            </RoundButton>
            <RoundButton
              className={styles.remove}
              onClick={() => {
                setIsOpen(false);
                if (detail.content.file.type === 'folder') {
                  deleteFolder(detail.content.id)
                    .then((response: any) => {
                      sessionStorage.setItem(
                        'snackbar',
                        JSON.stringify({
                          open: true,
                          type: 'success',
                          message: t('files.removeFolderSuccess'),
                        })
                      );
                      response.data.folder &&
                        response.data.folder.isDeleted &&
                        onChanged(response.data.folder.parentFolderId);
                    })
                    .catch((error) => {});
                } else {
                  deleteFiles(detail.content.id)
                    .then((response: any) => {
                      // console.log("data", data);
                      sessionStorage.setItem(
                        'snackbar',
                        JSON.stringify({
                          open: true,
                          type: 'success',
                          message: t('files.removeFileSuccess'),
                        })
                      );
                      response.data.files &&
                        response.data.files.isDeleted &&
                        onChanged(response.data.files.folderId);
                    })
                    .catch((error) => {});
                }
              }}
            >
              {t('files.actions.remove')}
            </RoundButton>
          </div>
        </>
      );
    } else if (detail.type === 'upload') {
      content = (
        <>
          <div className={styles.contentDesc}>
            <span>{t('files.actions.leavePageDescription')}</span>
          </div>
          <div className={styles.inputAction}>
            <RoundButton
              className={styles.cancel}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('files.actions.cancel')}
            </RoundButton>
            <RoundButton
              className={styles.remove}
              onClick={() => {
                setIsOpen(false);
                window.location.href = detail.content;
              }}
            >
              {t('files.actions.leave')}
            </RoundButton>
          </div>
        </>
      );
    }
    return <>{content}</>;
  };
  return (
    <Popup
      id={styles.filePopup}
      isOpen={isOpen}
      setIsOpen={(close: boolean) => setIsOpen(close)}
      title={<PopupTitle />}
      content={<PopupContent />}
      disableActions={true}
      fullScreen={detail.type === 'details'}
    />
  );
};

export default HandlePopup;
