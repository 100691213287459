import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./socialWallSuspendedPostDetail.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SocialWallCard, ToggleButton } from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import moment from "moment";
import theme from "../../theme";
import toHtml from "html-react-parser";
import { convertDateTimeWithDivider } from "../../utility";
import {
  getReportedPostDetails,
  getReportedPostLogDetail,
  getSocialWallConfig,
} from "../../services/social-wall";
import { SocialWallCardProps } from "../../interfaces/social-wall-interface";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { UserState } from "../../reducers/user-slice";

const SocialWallSuspendedPostDetail: FC = () => {
  const navigate = useNavigate();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [socialWallPermission, setSocialWallPermission] = useState<any>();
  let { id } = useParams();
  const [data, setData] = useState<any>([]);
  const location = useLocation();
  const isLogDetail = location.pathname.indexOf("/log-detail/") !== -1;
  const pageTitle = isLogDetail
    ? t("socialWall.logDetails")
    : t("socialWall.postDetails");

  const statusText = {
    report: t("socialWall.reportedPost.reported"),
    keep: t("socialWall.reportedPost.kept"),
    suspend: t("socialWall.reportedPost.suspended"),
    delete: t("socialWall.reportedPost.deleted"),
    resolve: t("socialWall.reportedPost.resolved"),
    deleted: t("socialWall.reportedPost.deleted"),
    recover: t("socialWall.reportedPost.restored"),
  };

  useEffect(() => {
    const getConfig = async () => {
      const response = await getSocialWallConfig();
      setSocialWallPermission(response.data);
    };
    getConfig();

    if (isLogDetail) {
      getReportedPostLogDetail(id as string)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.error("err", err);
        });
    } else {
      getReportedPostDetails(id as string)
        .then((res) => {
          setData(res.data.post);
        })
        .catch((err) => {
          console.error("err", err);
        });
    }
  }, []);

  return (
    <>
      <Header
        title={pageTitle}
        enableBackButton
        disableBottomBorder
        closeButtonNavigation="/social-wall/reported-post"
      />
      {data && (
        <>
          <Box>
            <SocialWallCard
              {...data}
              id={data._id}
              size="large"
              actionBtnType={isLogDetail ? "adminRead" : "admin"}
              fixedBtn={true}
              // handleGetReportDetail={}
              disableLike={
                Boolean(userState.permission?.SocialWallAdmin)
                  ? !socialWallPermission?.like.admin
                  : !socialWallPermission?.like.all
              }
              disableComment={
                Boolean(userState.permission?.SocialWallAdmin)
                  ? !socialWallPermission?.comment.admin
                  : !socialWallPermission?.comment.all
              }
            />
          </Box>
          <Box className={styles.postCommentContainer}>
            {data.reportStatusLog?.map((item: any, index: number) => (
              <Box className={styles.commentContainer} key={index}>
                <Box className={styles.row}>
                  <Box className={styles.avatarWrapper}>
                    <Avatar
                      sx={{ width: "32px", height: "32px" }}
                      src={item.userProfilePictureUrl ?? ""}
                    />
                  </Box>
                  <Box className={styles.commentWrapper}>
                    <Box className={styles.contentContainer}>
                      <Typography className={styles.title} variant="body1">
                        {t("socialWall.reportedPost.log", {
                          user:
                            item.lastUpdateByName?.userName ??
                            item.lastUpdateByName?.name,
                          action:
                            item.statusLog === "pending"
                              ? statusText.report
                              : (item.statusLog === "keep"
                                  ? statusText.keep
                                  : item.statusLog === "suspend"
                                  ? statusText.suspend
                                  : item.statusLog === "delete"
                                  ? statusText.delete
                                  : item.statusLog === "resolve"
                                  ? statusText.resolve
                                  : item.statusLog === "deleted"
                                  ? statusText.deleted
                                  : item.statusLog === "recover"
                                  ? statusText.recover
                                  : item.statusLog
                                ).toLowerCase(),
                        })}
                      </Typography>
                      <Typography className={styles.details} variant="body2">
                        {item.content}
                      </Typography>
                      <Box className={styles.timestamp}>
                        <img src="/assets/images/clock_icon_grey.svg" alt="" />
                        <Typography variant="body2">
                          {item.lastUpdateTime
                            ? convertDateTimeWithDivider(item.lastUpdateTime)
                            : "---"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default SocialWallSuspendedPostDetail;
