const MuiDayCalendar = {
  styleOverrides: {
    header: {
      width: "100%",
      justifyContent: "space-between",
    },
    weekDayLabel: {
      color: "var(--mui-palette-black-black058)",
      fontWeight: 400,
      lineHeight: "13px",
      fontSize: 11,
      opacity: 0.5,
    },
    slideTransition: {
      minHeight: "220px",
      "& > *": {
        width: "100%",
      },
    },
    weekContainer: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
};

export default MuiDayCalendar;
