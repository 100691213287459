import { Scheduler } from 'timers/promises';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Grid,
  Skeleton,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import toHtml from 'html-react-parser';
import { t } from 'i18next';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomTab, SocialWallCard, ToggleButton } from '../../components';
import Header from '../../components/header/header';

import {
  getReportedPostLogList,
  SocailWallListRequestType,
} from '../../services/social-wall';
import theme from '../../theme';
import {
  convertDate,
  convertDateTime,
  convertTime,
  dateTimeFormat,
} from '../../utility';
import styles from './socialWallReportLog.module.scss';

interface logType {
  _id: string;
  lastUpdateBy: string;
  lastUpdateByName: any;
  lastUpdateTime: number;
  reportStatus:
    | 'keep'
    | 'suspend'
    | 'restore'
    | 'recover'
    | 'delete'
    | 'resolve';
}
const ReportedPostLogItem: FC<{ logData: logType }> = (props) => {
  const navigate = useNavigate();

  return (
    <Box
      className={styles.itemContainer}
      onClick={() =>
        navigate(`/social-wall/reported-post/log-detail/${props.logData._id}`)
      }
    >
      <Box className={styles.itemHeader}>
        <Typography variant="body1">POST ID #{props.logData._id}</Typography>
        <Typography
          variant="caption"
          className={`${styles.statusTag} ${props.logData.reportStatus}`}
        >
          {t(
            `socialWall.reportedPost.${
              props.logData.reportStatus === 'recover'
                ? 'restored'
                : props.logData.reportStatus
            }`
          )}
        </Typography>
      </Box>
      <Box className={styles.itemContent}>
        <Typography className={styles.label} variant="body2">
          {t('socialWall.reportedPost.lastUpdate')}
        </Typography>

        <Typography className={styles.content} variant="body2">
          {/* 23 Jan, 2023 | 02:00 PM */}
          {convertDateTime(props.logData.lastUpdateTime)}
        </Typography>

        <Typography className={styles.label} variant="body2">
          {t('socialWall.reportedPost.lastUpdateBy')}
        </Typography>

        <Typography className={styles.content} variant="body2">
          {props.logData.lastUpdateByName?.userName ??
            props.logData.lastUpdateByName?.name}
        </Typography>
      </Box>
    </Box>
  );
};
function SocialWallReportLog(props: any) {
  // const { data } = props;

  const reportedTabData = [
    {
      value: 'all',
      display: t('socialWall.reportedPost.all'),
    },
    {
      value: 'keep',
      display: t('socialWall.reportedPost.kept'),
    },
    {
      value: 'suspended',
      display: t('socialWall.reportedPost.suspend'),
    },
    {
      value: 'recovered',
      display: t('socialWall.reportedPost.restored'),
    },
    // {
    //   value: "delete",
    //   display: t("socialWall.reportedPost.deleted"),
    // },
    {
      value: 'resolved',
      display: t('socialWall.reportedPost.resolved'),
    },
  ];

  const [activeTab, setActiveTab] = useState<string>(reportedTabData[0].value);
  const [comment, setComment] = useState<string>('');
  // const [data, setData] = useState<Array<logType>>([]);
  const [postList, setpostList] = useState<Array<logType>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 10,
    }
  );
  const [listParams, setListParams] = useState<SocailWallListRequestType>({
    page: apiPagination.page,
    pageSize: apiPagination.pageSize,
    sort: 'createTime',
    by: 'DESC',
    tab: reportedTabData[0].value,
  });
  const [hasMore, setHasMore] = useState(true);

  function handleChangeTab(active: string) {
    setpostList([]);
    setActiveTab(active);
  }
  // useEffect(() => {
  //   getReportedPostLogList(listParams).then((res) => {
  //     setData(res.data.posts);
  //   });
  // }, []);

  useEffect(() => {
    // console.log("update event list", activeTab);
    // var displayList: any[] | ((prevState: logType[]) => logType[]);
    // if (activeTab === "all") {
    //   displayList = data.filter((item) => item.reportStatus !== "delete");
    // } else {
    //   displayList = data.filter((item) => item.reportStatus === activeTab);
    // }

    // setIsLoading(true);
    // setTimeout(() => {
    // setpostList(displayList);
    // setIsLoading(false);
    // }, 2000);
    setListParams((prev) => ({
      ...prev,
      page: 1,
      tab: activeTab,
    }));
    setApiPagination({ page: 1 });
  }, [activeTab]);

  const getList = useCallback(async () => {
    let active = true;
    setIsLoading(true);

    try {
      const response = await getReportedPostLogList(listParams);

      if (response.status === 200 && active) {
        // console.log("response", response);
        setpostList((prev) => [...prev, ...response.data.posts]);

        setIsLoading(false);
        if (response.data.totalPage === listParams.page) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setIsLoading(false);
        setHasMore(false);
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  useEffect(() => {
    getList();
  }, [getList]);

  async function nextPage() {
    if (!isLoading) {
      try {
        const nextListParams = {
          ...listParams,
          page: apiPagination.page === 1 ? 2 : apiPagination.page,
        };
        // console.log("apiPagination", apiPagination, nextListParams);
        const response = await getReportedPostLogList(nextListParams);
        if (response.status === 200) {
          // console.log("response next", response);
          setpostList((prev) => [...prev, ...response.data.posts]);

          setIsLoading(false);
          if (apiPagination.page >= response.data.totalPage) {
            setHasMore(false);
          } else {
            setApiPagination({ page: apiPagination.page + 1 });
          }
        } else {
          setIsLoading(false);
          setHasMore(false);
        }
      } catch (error) {}
    }
  }
  // const nextPage = () => {
  //   if (!isLoading) {
  //     console.log("listParams", listParams);
  //     setListParams((prev) => ({
  //       ...prev,
  //       page: prev.page ? prev.page + 1 : 1,
  //     }));
  //   }
  // };

  return (
    <>
      <Header
        title={t('socialWall.reportedPost.reportedLog')}
        enableBackButton
        disableBottomBorder
        closeButtonNavigation="/social-wall/reported-post"
      />

      <Grid item xs className={styles.postContainer} id="postListContainer">
        <CustomTab
          data={reportedTabData}
          activeTab={activeTab}
          onTabClick={handleChangeTab}
        />
        {isLoading && (
          <Box sx={{ padding: 2 }}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Box>
        )}
        {!isLoading ? (
          postList.length > 0 ? (
            <InfiniteScroll
              dataLength={postList.length} //This is important field to render the next data
              next={nextPage}
              hasMore={hasMore}
              loader={
                <Box sx={{ padding: 2 }}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={40}
                  />
                </Box>
              }
              // scrollableTarget="postListContainer"
            >
              {postList.map((data: any, index: number) => (
                <Box className={styles.recordContainer} key={index}>
                  <ReportedPostLogItem logData={data} />
                </Box>
              ))}
            </InfiniteScroll>
          ) : (
            <Box className={styles.noPostContainer}>
              <img src="/assets/images/empty.png" alt="" />
              <Typography variant="h3">
                {t('socialWall.reportedPost.noPost')}
              </Typography>
              <Typography>{t('socialWall.reportedPost.noPostMsg')}</Typography>
            </Box>
          )
        ) : (
          ''
        )}
      </Grid>
    </>
  );
}

export default SocialWallReportLog;
