import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { UserState } from "../../../reducers/user-slice";
import { getSocialWallConfig } from "../../../services/social-wall";

interface ReturnFunctionType {
  socialWallPermission: any;
  userState: UserState;
}

const usePermissionHook = (): ReturnFunctionType => {
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [socialWallPermission, setSocialWallPermission] = useState<any>();

  useEffect(() => {
    const getConfig = async () => {
      try {
        const response = await getSocialWallConfig();
        // console.log(response.data);
        setSocialWallPermission(response.data);
      } catch (error) {
        throw error;
      }
    };

    getConfig();
  }, []);

  return {
    socialWallPermission,
    userState,
  };
};

export default usePermissionHook;
