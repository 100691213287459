import React, { FC, useEffect, useState, useRef } from "react";
import styles from "./rewardDetail.module.scss";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  TextareaAutosize,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { Popup, RoundButton, ShortcutMenu } from "../../components";
import Header from "../../components/header/header";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { convertDate, removeApiCacheSession } from "../../utility";
import toHtml from "html-react-parser";
import {
  getMyRewardsDetail,
  getRankingRewardsDetails,
  postCollectMyRewards,
} from "../../services/leaderboard";

function RewardDetail(props: { redeemable: boolean }) {
  const { redeemable } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [filter, setFilter] = useState(Array<any>);
  const [open, setOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    if (redeemable) {
      getMyRewardsDetail(id as string).then((response) => {
        setData(response.data.reward);
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      });
    } else {
      getRankingRewardsDetails(id as string).then((response) => {
        setData({
          ...response.data[0].prizeLists.find((item: any) => item._id === id),
          endDate: response.data[0].endDate,
          startDate: response.data[0].startDate,
          rewardCampaignName: response.data[0].rewardCampaignName,
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      });
    }
  }, []);

  return (
    <>
      <Box className={styles.headerContainer}>
        <Header
          title={t("leaderboard.rewardDetails")}
          enableBackButton
          disableBottomBorder
        ></Header>
      </Box>

      {!isLoading && data && (
        <Grid
          item
          xs
          className={`${styles.contentContainer} ${
            data.isDelivered
              ? data.isReceived
                ? "collected"
                : "onTheWay"
              : "pending"
          }`}
        >
          <Stack gap="8px">
            <Stack gap="0px">
              <img src={data.imageUrl} style={{ width: "100%" }} />
              <Box className={styles.contentWrapper}>
                <Stack gap="16px">
                  <Stack direction="row">
                    <Typography variant="h4">{data.prizeName}</Typography>
                    {redeemable && (
                      <Typography
                        variant="subtitle2"
                        className={`${styles.statusTag} ${
                          data.isDelivered
                            ? data.isReceived
                              ? "collected"
                              : "onTheWay"
                            : "pending"
                        }`}
                      >
                        {t(
                          `leaderboard.status.${
                            data.isDelivered
                              ? data.isReceived
                                ? "collected"
                                : "onTheWay"
                              : "pending"
                          }`
                        )}
                      </Typography>
                    )}
                  </Stack>
                  <Stack gap="8px">
                    <Typography variant="body1">
                      {data.rewardCampaignName}
                    </Typography>
                    <Typography variant="body1">
                      {convertDate(data.startDate, false, true)} -{" "}
                      {convertDate(data.endDate, false, true)}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            <Box className={styles.contentWrapper}>
              <Typography variant="subtitle2" className={styles.subTitle}>
                {t("leaderboard.keyFeatures")}
              </Typography>
              <Typography>{data.description}</Typography>
            </Box>
            <Box className={styles.contentWrapper}>
              <Typography variant="subtitle2" className={styles.subTitle}>
                {t("leaderboard.howToRedeem")}
              </Typography>
              <Typography>{data.howToRedeem}</Typography>
            </Box>
          </Stack>
        </Grid>
      )}

      {redeemable && data && !data.isReceived && data.isDelivered && (
        <Stack className={styles.confirmBtnWrapper} gap="12px">
          <Stack direction="row" gap="6px" className={styles.confirmNotice}>
            <img src="/assets/images/lightbulb_icon.svg" />
            <Typography variant="body2">
              {t("leaderboard.collectRewardsReminder")}
            </Typography>
          </Stack>

          <RoundButton
            id="confirmSelectedOptionsButton"
            // fullWidth={!multiple}
            onClick={() => {
              setOpen(true);
              removeApiCacheSession("getMyRewardsList");
            }}
          >
            {t("leaderboard.iHaveCollectedTheRewards")}
          </RoundButton>
        </Stack>
      )}

      <Popup
        isOpen={open}
        setIsOpen={(close: boolean) => setOpen(close)}
        title={t("leaderboard.confirmCollected")}
        content={t("leaderboard.confirmCollectedMsg")}
        onClickCancel={() => {
          setOpen(false);
        }}
        confirmBtnText={t("general.confirm")}
        onClickConfirm={() => {
          postCollectMyRewards(id as string).then((response) => {
            if (response.status === 200) {
              navigate("/leaderboard/my-rewards");
            }
          });
        }}
      />
    </>
  );
}
export default RewardDetail;
