import React, { FC } from "react";
import styles from "./invited-group-icon.module.scss";
import { Box } from "@mui/material";

export type InvitedGroup = "operation" | "district" | "store";

interface InvitedGroupIconProps {
  type?: InvitedGroup;
}

const InvitedGroupIcon: FC<InvitedGroupIconProps> = (props) => {
  const groupType = props.type ?? "operation";

  return (
    <Box className={styles.groupIcon} data-group={groupType}>
      <img src="/assets/images/invited_group_icon.svg" alt="" />
    </Box>
  );
};

export default InvitedGroupIcon;
