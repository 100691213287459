import { useState, useEffect, HTMLInputTypeAttribute } from "react";
import { ObjectKey } from "../interfaces/common-interface";

interface useFormHookType {
  value: FormValueType;
}

export interface ReturnFunctionType {
  isDirty: boolean;
  formValue: FormValueType;
  handleInputFieldChange: (
    name: string,
    value: any,
    changeRequired?: Array<{ name: string; required: boolean }>
  ) => void;
  handleTextareaFieldChange: (
    name: string,
    value: any,
    limit?: number,
    changeRequired?: Array<{ name: string; required: boolean }>
  ) => void;
  checkValidation: () => boolean;
  setInitFormValue: (data: FormValueType, isRecoverData?: boolean) => void;
}

interface FormValueSettingType {
  // eslint-disable-next-line
  value: any;
  isError: boolean;
  isRequired: boolean;
  type?: HTMLInputTypeAttribute;
}

export interface FormValueType {
  [key: string]: FormValueSettingType;
}

export const emailValidation = (value: string) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(value);
};

export const useFormHook = ({
  value = {},
}: useFormHookType): ReturnFunctionType => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<FormValueType>(value);

  // useEffect(() => {
  //   console.log("formValue", formValue);
  // }, [formValue]);

  const phoneValidation = (areaCode: string, phoneNumber: string) => {
    const hkPhoneWithoutAreaCodeRegex = /^\d{8}$/;
    const phPhoneWithoutAreaCodeRegex = /^\d{10}$/;
    const twPhoneWithoutAreaCodeRegex = /^\d{9,10}$/;

    if (areaCode === "+63") {
      return phPhoneWithoutAreaCodeRegex.test(phoneNumber);
    } else if (areaCode === "+852") {
      return hkPhoneWithoutAreaCodeRegex.test(phoneNumber);
    } else if (areaCode === "+886") {
      return twPhoneWithoutAreaCodeRegex.test(phoneNumber);
    } else {
      return true;
    }
  };

  const contactNoValidation = (phoneNumber: string) => {
    const contactNoRegex = /^\+?\d{1,}$/;
    return contactNoRegex.test(phoneNumber);
  };

  const handleInputFieldChange = (
    name: string,
    value: any,
    changeRequired?: Array<{ name: string; required: boolean }>
  ) => {
    const formName = name as keyof typeof formValue;
    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
      },
    };

    if (changeRequired) {
      changeRequired.forEach((item: ObjectKey) => {
        const target = updateFormValue[item.name]
          ? updateFormValue[item.name]
          : formValue[item.name];
        updateFormValue = {
          ...updateFormValue,
          [item.name]: {
            ...target,
            isRequired: item.required,
          },
        };
      });
    }

    if (
      formValue[formName].isRequired ||
      (updateFormValue[formName] && updateFormValue[formName].isRequired)
    ) {
      if (formValue[formName].type) {
        switch (formValue[formName].type) {
          case "email":
            updateFormValue = {
              ...updateFormValue,
              [formName]: {
                ...updateFormValue[formName],
                isError: value !== "" ? !emailValidation(value) : false,
              },
            };
            break;
          case "tel":
            const withCode = Object.keys(formValue).find(
              (key: string) => formValue[key].type === "areaCode"
            );
            let telInValid = value !== "" ? !contactNoValidation(value) : false;
            if (withCode) {
              telInValid =
                value !== ""
                  ? !phoneValidation(formValue[withCode].value.code, value)
                  : false;

              updateFormValue = {
                ...updateFormValue,
                [withCode]: {
                  ...formValue[withCode],
                  isError: false,
                },
              };
            }
            updateFormValue = {
              ...updateFormValue,
              [formName]: {
                ...updateFormValue[formName],
                isError: telInValid,
              },
            };
            break;
          case "areaCode":
            const telKey = Object.keys(formValue).find(
              (key: string) => formValue[key].type === "tel"
            );
            updateFormValue = {
              ...updateFormValue,
              [formName]: {
                ...updateFormValue[formName],
                isError: false,
              },
            };
            if (telKey) {
              updateFormValue = {
                ...updateFormValue,
                [telKey]: {
                  ...formValue[telKey],
                  isError:
                    value !== ""
                      ? !phoneValidation(value.code, formValue[telKey].value)
                      : false,
                },
              };
            }
            break;
        }
      } else {
        let inValid = value === "" || !value;

        updateFormValue = {
          [formName]: {
            ...updateFormValue[formName],
            isError: inValid,
          },
        };
      }
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleTextareaFieldChange = (
    name: string,
    value: any,
    limit?: number,
    changeRequired?: Array<{ name: string; required: boolean }>
  ) => {
    const formName = name as keyof typeof formValue;
    let inValid = formValue[formName].isRequired
      ? value === "" || !value
      : false;

    if (!inValid && limit) {
      inValid = value.length > limit;
    }

    let updateFormValue = {
      [formName]: {
        ...formValue[formName],
        value: value,
        isError: inValid,
      },
    };

    if (changeRequired) {
      changeRequired.forEach((item: ObjectKey) => {
        updateFormValue = {
          ...updateFormValue,
          [item.name]: {
            ...formValue[item.name],
            isRequired: item.required,
          },
        };
      });
    }

    setFormValue((prev) => ({
      ...prev,
      ...updateFormValue,
    }));

    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const checkValidation = () => {
    const typeRequired = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].type)
    );
    const requiredValue = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].isRequired)
    );
    let errorField: Array<string> = [];
    for (const [key, value] of Object.entries(typeRequired)) {
      if (errorField.includes(key)) continue;
      if (value.isRequired) {
        switch (value.type) {
          case "email":
            if (!emailValidation(value.value)) {
              errorField.push(key);
            }
            break;
          case "tel":
            const withCode = Object.keys(formValue).find(
              (key: string) => formValue[key].type === "areaCode"
            );
            let telInValid = !contactNoValidation(value.value);
            if (withCode) {
              telInValid = !phoneValidation(
                formValue[withCode].value.code,
                value.value
              );
            }
            if (telInValid) {
              errorField.push(key);
            }
            break;
          case "areaCode":
            const telKey = Object.keys(formValue).find(
              (key: string) => formValue[key].type === "tel"
            );
            if (
              telKey &&
              !phoneValidation(value.value.code, formValue[telKey].value)
            ) {
              errorField.push(key);
            }
            break;
        }
      }
    }

    for (const [key, value] of Object.entries(requiredValue)) {
      if (errorField.includes(key)) continue;
      if (!value.value) {
        errorField.push(key);
      } else if (Array.isArray(value.value)) {
        if (value.value.length <= 0) {
          errorField.push(key);
        }
      } else if (typeof value.value === "object") {
        if (Object.keys(value.value).length <= 0) {
          errorField.push(key);
        }
      }
    }

    const existErrorField = Object.fromEntries(
      Object.entries(formValue).filter(([key]) => formValue[key].isError)
    );

    Object.keys(existErrorField).forEach((key: string) => {
      if (!errorField.includes(key)) {
        errorField.push(key);
      }
    });

    if (errorField.length > 0) {
      let errorFieldObj: FormValueType = formValue;
      errorField.forEach((key: string) => {
        errorFieldObj = {
          ...errorFieldObj,
          [key]: {
            ...errorFieldObj[key],
            isError: true,
          },
        };
      });
      setFormValue(errorFieldObj);
      return false;
    }

    return true;
  };

  const setInitFormValue = (data: FormValueType, isRecoverData?: boolean) => {
    setFormValue((prev) => ({
      ...prev,
      ...data,
    }));

    if (isRecoverData !== undefined && !isDirty) {
      setIsDirty(true);
    }
  };

  return {
    isDirty,
    formValue,
    handleInputFieldChange,
    handleTextareaFieldChange,
    checkValidation,
    setInitFormValue,
  };
};
