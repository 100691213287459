import { RefObject, useRef, useState, useEffect } from "react";
import { containsObject } from "../../utility";
import { InputFieldProps } from "../input-field";

interface useSelectHookProps {
  // eslint-disable-next-line
  value: any;
  multiple?: boolean;
  // eslint-disable-next-line
  getOptionValue?: (value: any) => string;
  // eslint-disable-next-line
  getOptionLabel?: (value: any) => string;
  getOptionDisplay?: (value: any) => string;
  otherFieldValue?: string;
  otherFieldProps?: InputFieldProps;
  disableDateTimePicker?: (value: any) => boolean;
}

interface ReturnFunctionType {
  multipleValueDisplayRef: RefObject<HTMLParagraphElement>;
  selectedValue: any;
  enableOtherField: boolean;
  handleSelectOption: (option: any, isMultiple?: boolean) => void;
  handleReset: () => void;
  displayValue: () => string;
  handleClear: () => void;
  isDisabledDateTimePicker: boolean;
}

export const useSelectHook = (
  props: useSelectHookProps
): ReturnFunctionType => {
  const {
    value,
    multiple,
    getOptionValue,
    getOptionLabel,
    getOptionDisplay,
    otherFieldValue,
    otherFieldProps,
    disableDateTimePicker,
  } = props;

  const otherValid = otherFieldValue ?? "other";
  const multipleValueDisplayRef = useRef<HTMLParagraphElement>(null);
  // eslint-disable-next-line
  const handleShowDateTimePicker = (value: any): string => {
    return disableDateTimePicker ? disableDateTimePicker(value) : value;
  };

  const [selectedValue, setSelectedValue] = useState<any>(value);
  const [enableOtherField, setEnableOtherField] = useState(
    checkInitValueIsOther()
  );

  const [isDisabledDateTimePicker, setIsDisabledDateTimePicker] = useState<any>(
    handleShowDateTimePicker(value)
  );
  // useEffect(() => {
  //   console.log("selectedValue", selectedValue);
  // }, [selectedValue]);

  function outputValue(value: any): string {
    return getOptionValue && value ? getOptionValue(value) : value;
  }

  function outputLabel(value: any) {
    return getOptionLabel && value ? getOptionLabel(value) : value;
  }

  function outputDisplayLabel(value: any) {
    if (getOptionDisplay) return getOptionDisplay(value);

    let output = getOptionLabel ? getOptionLabel(value) : value;
    // console.log("output", [output, value]);
    if (outputValue(value) === otherValid) {
      output = `${output} - ${otherFieldProps ? otherFieldProps.value : ""}`;
    }
    return output;
  }

  const checkOptionExistInArray = (option: any, existOption: Array<any>) => {
    if (containsObject(option, existOption)) {
      const removeSelectedValue = existOption.filter(
        // eslint-disable-next-line
        (value: any) => outputValue(value) !== outputValue(option)
      );
      return removeSelectedValue;
    } else {
      return [...existOption, option];
    }
  };

  function checkInitValueIsOther() {
    if (Array.isArray(value)) {
      return value.some((selected) => outputValue(selected) === otherValid);
    } else {
      return outputValue(value) === otherValid;
    }
  }

  // eslint-disable-next-line
  const handleSelectOption = (option: any, isMultiple?: boolean) => {
    if (isMultiple) {
      let newSelectedValue = selectedValue;

      if (Array.isArray(option)) {
        option.forEach((newOption: any) => {
          newSelectedValue = checkOptionExistInArray(
            newOption,
            newSelectedValue
          );
        });
      } else {
        newSelectedValue = checkOptionExistInArray(option, newSelectedValue);
      }

      setSelectedValue(newSelectedValue);

      if (multipleValueDisplayRef.current) {
        if (newSelectedValue.length <= 0) {
          multipleValueDisplayRef.current.innerHTML = "";
        } else {
          multipleValueDisplayRef.current.innerHTML =
            convertArrayValueToString(newSelectedValue);
        }
      }
    } else {
      setIsDisabledDateTimePicker(handleShowDateTimePicker(option));
      setSelectedValue(option);
      setEnableOtherField(outputValue(option) === otherValid);
    }
  };

  const handleReset = () => {
    setSelectedValue(value);
    setEnableOtherField(checkInitValueIsOther());
  };

  // eslint-disable-next-line
  const convertArrayValueToString = (value: Array<any>) => {
    // eslint-disable-next-line
    const outputValueArray = value.map((item: any) => {
      let output = outputLabel(item);
      if (outputValue(item) === otherValid) {
        output = `${output} - ${
          otherFieldProps ? outputValue(otherFieldProps) : ""
        }`;
      }
      return output;
    });
    return outputValueArray.join(", ");
  };

  const displayValue = () => {
    var output;
    if (multiple) {
      if (value.length > 0) {
        output = convertArrayValueToString(value);
      }
    } else if (disableDateTimePicker && !isDisabledDateTimePicker) {
      output = "";
    } else {
      if (value) {
        output = outputDisplayLabel(value);
        // output = getOptionLabel ? getOptionLabel(value) : value;
        // console.log("output", [output, value]);
        // if (outputValue(value) === otherValid) {
        //   output = `${output} - ${
        //     otherFieldProps ? otherFieldProps.value : ""
        //   }`;
        // }
        // return output;
      }
    }
    return output ? output : "";
  };

  const handleClear = () => {
    // console.log(multiple, selectedValue);
    setSelectedValue(multiple ? [] : null);
    if (multipleValueDisplayRef.current) {
      multipleValueDisplayRef.current.innerHTML = "";
    }
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return {
    multipleValueDisplayRef,
    selectedValue,
    enableOtherField,
    handleSelectOption,
    handleReset,
    displayValue,
    handleClear,
    isDisabledDateTimePicker,
  };
};
