import { FC, useState } from 'react';

import {
  Alert,
  Box,
  Chip,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ObjectKey } from '../../interfaces/common-interface';
import InputField, { InputFieldProps } from '../input-field';
import Popup from '../popup';
import SelectFieldContainer from '../select-field-container';
import { useSelectHook } from '../select-field/useSelectHook';
import styles from './tag-select-field.module.scss';

export interface TagSelectFieldProps
  extends Omit<InputFieldProps, 'value' | 'multiline'> {
  startAdornment?: React.ReactNode;
  value: Array<string>;
  popupLabel?: string;
  options: Array<ObjectKey>;
  isOptionReverse?: boolean;
  multiple?: boolean;
  disableLabel?: boolean;
  error?: boolean;

  title?: string;
  count?: number;
  freeText?: boolean;
}

const TagSelectField: FC<TagSelectFieldProps> = (props) => {
  const { t } = useTranslation();
  const {
    startAdornment,
    value,
    options,
    isOptionReverse = false,
    multiple,
    disableLabel,
    error,
    title,
    count,
    freeText,
    ...containerProps
  } = props;
  const { selectedValue, handleSelectOption, handleReset } = useSelectHook({
    value,
    multiple,
  });
  const [warningBar, setWarningBar] = useState(false);
  const [freeTextPopup, setFreeTextPopup] = useState({
    open: false,
    value: '',
    error: false,
  });
  const freeTextLimit = 20;

  const handleConfirmSelected = (callback: () => void) => {
    containerProps.onChange(selectedValue);
    callback();
  };

  const Option = (props: {
    id: string;
    option: string;
    type?: 'selected' | 'option';
    onClick: () => void;
  }) => {
    const { type = 'option' } = props;
    let isSelected = false;
    if (selectedValue) {
      if (multiple) {
        isSelected = selectedValue.find(
          (selected: string) => selected === props.option
        );
      } else {
        isSelected = selectedValue === props.option;
      }
    }

    let chipClickOption = {};

    if (type === 'selected') {
      chipClickOption = {
        deleteIcon: <img src="/assets/images/xmark-active.svg" alt="remove" />,
        onDelete: props.onClick,
      };
    } else {
      chipClickOption = {
        onClick: props.onClick,
      };
    }

    return (
      <Chip
        id={props.id}
        color={isSelected ? 'primary' : 'secondary'}
        label={props.option}
        {...chipClickOption}
      />
    );
  };

  const handleChipClick = (option: string) => {
    if (!count || !Array.isArray(selectedValue))
      handleSelectOption(option, multiple);

    if (count) {
      if (selectedValue.length < count) {
        handleSelectOption(option, multiple);
      } else {
        setWarningBar(true);
      }
    } else {
      handleSelectOption(option, multiple);
    }
  };

  const handleOpenFreeTextPopup = () => {
    // if (count && selectedValue.length >= count) {
    //   setWarningBar(true);
    // } else {
    setFreeTextPopup((prev) => ({
      ...prev,
      open: true,
    }));
    // }
  };

  const handleAddFreeTextChip = () => {
    if (freeTextPopup.error) return false;
    handleSelectOption(freeTextPopup.value, multiple);
    handleCloseFreeTextPopup();
  };

  const handleCloseFreeTextPopup = () => {
    setFreeTextPopup((prev) => ({
      ...prev,
      open: false,
      value: '',
      error: false,
    }));
  };

  return (
    <SelectFieldContainer
      {...containerProps}
      disableLabel={disableLabel}
      multiple={multiple ? true : false}
      value=""
      required
      selectedValue={selectedValue}
      onConfirmSelected={handleConfirmSelected}
      onCancel={handleReset}
      type="tag"
      error={error}
      startAdornment={startAdornment}
      disableSelectedCountOnBtn
      disableActions
    >
      <Stack className={styles.root}>
        <Box className={styles.container}>
          {title || count ? (
            <Grid container className={styles.header}>
              {title && (
                <Grid item xs>
                  <Typography variant="subtitle1">{title}</Typography>
                </Grid>
              )}
              {count && (
                <Grid item xs="auto">
                  <Typography variant="body1">
                    {selectedValue ? selectedValue.length : '0'}
                    {'/'}
                    {count}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : null}
          <Box className={styles.chipSection}>
            <Stack direction="row" flexWrap="wrap" className={styles.chipList}>
              {freeText && (
                <Chip
                  label={
                    <img
                      src="/assets/images/plus_white.svg"
                      alt=""
                      style={{ display: 'block' }}
                    />
                  }
                  className={styles.chipFreeText}
                  disabled={Boolean(count && selectedValue.length >= count)}
                  clickable
                  onClick={handleOpenFreeTextPopup}
                />
              )}
              {selectedValue.map((value: string, index: number) => (
                <Option
                  key={index}
                  id={`selected_${index}`}
                  option={value}
                  type="selected"
                  onClick={() => {
                    handleSelectOption(value, multiple);
                  }}
                />
              ))}
            </Stack>
          </Box>
          {options.map((category: ObjectKey, index: number) => (
            <Box key={index} className={styles.chipSection}>
              <Typography
                variant="body1"
                className={styles.chipTitle}
                color="inherit"
              >
                {category.name}
              </Typography>
              <Stack
                direction="row"
                flexWrap="wrap"
                className={styles.chipList}
              >
                {category.options.map((option: string, subIndex: number) => (
                  <Option
                    key={subIndex}
                    id={`option_${index}`}
                    option={option}
                    onClick={() => {
                      handleChipClick(option);
                    }}
                  />
                ))}
              </Stack>
            </Box>
          ))}
        </Box>
        <Popup
          isOpen={freeTextPopup.open}
          title={containerProps.label}
          content={
            <Box className={styles.freeTextPopupContent}>
              <InputField
                id="freeText"
                name="freeText"
                placeholder={t('myProfile.editProfile.enterHere')}
                value={freeTextPopup.value}
                error={freeTextPopup.error}
                required
                onChange={(e) => {
                  const { value } = e.target;
                  setFreeTextPopup((prev) => ({
                    ...prev,
                    value: value,
                    error: value.length > freeTextLimit,
                  }));
                }}
                helperText={
                  <Typography variant="body2" align="right">
                    {`${freeTextPopup.value.length}/${freeTextLimit}`}
                  </Typography>
                }
              />
            </Box>
          }
          setIsOpen={(isOpen: boolean) => handleCloseFreeTextPopup()}
          confirmBtnText={t('general.done')}
          onClickConfirm={handleAddFreeTextChip}
          isDisabled={freeTextPopup.value === '' || freeTextPopup.error}
        />
        <Snackbar
          open={warningBar}
          autoHideDuration={6000}
          onClose={() => setWarningBar(false)}
          className={styles.warningBar}
        >
          <Alert severity="warning">
            {t('myProfile.editProfile.maxSelect', { limit: count })}
          </Alert>
        </Snackbar>
      </Stack>
    </SelectFieldContainer>
  );
};

export default TagSelectField;
