import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./eventSearch.module.scss";
import {
  Box,
  Breadcrumbs,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchField from "../../components/search-field";
import CategoryTag from "../../components/category-tag/category-tag";
import {
  convertDate,
  convertTime,
  convertDateTime,
  getApiDataByLang,
} from "../../utility";
import { EventCardBaseType } from "../../interfaces/event-interface";
import { EventSearchType, searchEvents } from "../../services/event";
import { ObjectKey } from "../../interfaces/common-interface";
import moment from "moment";
import NoResults from "../../components/no-results";
import { PaginationParamsType } from "../../services/common";
import InfiniteScroll from "react-infinite-scroll-component";
export interface EventSearchProps {
  value?: any;
  onChange?: (e: any) => void;
}

const EventSearch: FC<EventSearchProps> = (props) => {
  const { groupId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [result, setResult] = useState<Array<EventCardBaseType>>([]);
  const [searchParams] = useSearchParams();
  const [listParams, setListParams] = useState<EventSearchType>({
    page: 1,
    pageSize: 20,
    search: "",
    type: "Open,Upcoming,Registered,Checked-In,Fulled",
    ...(groupId && { partnerNetworkGroupId: groupId }),
  });
  const [hasMore, setHasMore] = useState(true);
  const [delaySearchThread, setDelaySearchThread] =
    useState<NodeJS.Timeout | null>(null);

  const handleResetField = () => {
    if (!isLoading) {
      setResult([]);
      setListParams((prev) => ({ ...prev, page: 1, search: "" }));
    }
  };

  const handleCloseChange = () => {
    if (props.onChange) {
      props.onChange({});
    } else {
      navigate(groupId ? `/partner-network/event/${groupId}` : "/event");
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let searchParams = "";
    if (value.length > 0) {
      searchParams = "?search=" + value;
    }
    window.history.replaceState(null, "", `/event/search${searchParams}`);
    setListParams((prev) => ({ ...prev, page: 1, search: value }));
    setResult([]);
  };

  const findEvents = useCallback(async () => {
    let active = true;
    setIsLoading(true);
    setHasMore(true);

    try {
      const response = await searchEvents(listParams);
      const events = response.data && response.data.events;

      if (response.status === 200) {
        if (events && events.length > 0) {
          const convertEvents = events.map((event: ObjectKey) => {
            return {
              id: event._id,
              partnerNetworkGroupId: event.partnerNetworkGroupId ?? null,
              name:
                typeof event.eventName === "string"
                  ? event.eventName
                  : getApiDataByLang(event.eventName),
              imageUrl: event.eventCoverImage,
              startsFrom: moment.unix(event.eventStartTimestamp).format(),
              endsAt: moment.unix(event.eventEndTimestamp).format(),
              category: event.category
                ? {
                    id: event.category._id,
                    name: getApiDataByLang(event.category.categoryName),
                    bgColor: event.category.color,
                  }
                : null,
              status: "",
              location:
                event.eventLocationId &&
                event.eventLocation &&
                typeof event.eventLocation === "object"
                  ? {
                      id: event.eventLocation._id,
                      name: event.eventLocation.storeName,
                      address: event.eventLocation.address,
                    }
                  : {
                      id: "",
                      name: event.eventLocation,
                      address: "",
                    },
            };
          });

          if (active) {
            setResult((prev) => [...prev, ...convertEvents]);
            if (response.data.totalPage === listParams.page) {
              setHasMore(false);
            }
          }
        } else {
          setHasMore(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }

    return () => {
      active = false;
    };
  }, [listParams]);

  useEffect(() => {
    if (delaySearchThread) {
      clearTimeout(delaySearchThread);
    }
    const threadId = setTimeout(() => {
      findEvents();
    }, 2000);
    setDelaySearchThread(threadId);
  }, [findEvents]);

  useEffect(() => {
    if (searchParams.get("search")) {
      const value = searchParams.get("search") as string;
      // setSearchValue(value);
      setListParams((prev) => ({ ...prev, page: 1, search: value }));
    }
  }, [searchParams]);

  const nextPage = () => {
    if (!isLoading) {
      setListParams((prev) => ({
        ...prev,
        page: prev.page ? prev.page + 1 : 1,
      }));
    }
  };

  return (
    <>
      <SearchField
        id="search"
        name="search"
        placeholder={t("general.search")}
        value={listParams.search ?? ""}
        onChange={handleValueChange}
        onReset={handleResetField}
        onClose={handleCloseChange}
      />
      <Grid id="searchListsContainer" item xs className={styles.searchResult}>
        {!isLoading && !hasMore && result.length === 0 && (
          <NoResults resultsType="SEARCH" />
        )}
        <InfiniteScroll
          dataLength={result.length} //This is important field to render the next data
          next={nextPage}
          hasMore={hasMore}
          loader={<EventResultCardSkeleton />}
          scrollableTarget="searchListsContainer"
        >
          {result.map((event: EventCardBaseType, index: number) => {
            // console.log("event", event);
            return (
              <Grid
                key={index}
                container
                alignItems="center"
                className={styles.eventResultCard}
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(event);
                  } else {
                    navigate(
                      event.partnerNetworkGroupId
                        ? `/partner-network/event/${event.partnerNetworkGroupId}/${event.id}`
                        : `/event/${event.id}`
                    );
                  }
                }}
              >
                <Grid item className={styles.eventResultImage}>
                  <img src={event.imageUrl} alt="" />
                </Grid>
                <Grid item xs className={styles.eventResultContent}>
                  <Stack spacing={0.5}>
                    {event.category && (
                      <Box className={styles.eventResultCategory}>
                        <CategoryTag category={event.category}></CategoryTag>
                      </Box>
                    )}
                    <Box className={styles.eventResultTitle}>
                      <Typography variant="body1">{event.name}</Typography>
                    </Box>
                    <Box className={styles.eventResultDate}>
                      <Breadcrumbs separator="|" aria-label="breadcrumb">
                        <Typography variant="body2">
                          {convertDateTime(event.startsFrom)}
                        </Typography>
                        {/* <Typography variant="body2">
                          {convertDate(event.startsFrom)}
                        </Typography>
                        <Typography variant="body2">
                          {convertTime(event.startsFrom)}
                        </Typography> */}
                      </Breadcrumbs>
                    </Box>
                    <Grid
                      container
                      alignItems="flex-start"
                      wrap="nowrap"
                      className={styles.eventResultLocation}
                    >
                      <img
                        src="/assets/images/eventIcon_location_pin.svg"
                        alt=""
                      />
                      <Typography variant="body2">
                        {event.location.name}
                      </Typography>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </InfiniteScroll>
      </Grid>
    </>
  );
};

const EventResultCardSkeleton: FC = () => {
  return (
    <Grid container alignItems="center" className={styles.eventResultCard}>
      <Grid item xs="auto">
        <Skeleton
          className={styles.eventResultImage}
          animation="wave"
          variant="rectangular"
        />
      </Grid>
      <Grid item xs className={styles.eventResultContent}>
        <Stack spacing={0.5}>
          <Skeleton variant="text" animation="wave" height={20} width="40%" />
          <Skeleton variant="text" animation="wave" height={36} width="100%" />
          <Skeleton variant="text" animation="wave" height={16} width="55%" />
          <Skeleton variant="text" animation="wave" height={16} width="100%" />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default EventSearch;
