import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AxiosPromise } from 'axios';
import imageCompression from 'browser-image-compression';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import { optionItemProps, OptionsListType } from '../components/multi-filter';

import { ObjectKey } from '../interfaces/common-interface';
import i18n from '../languages';
import { mimeTypeMap } from '../pages/files/mimeType';
import { setDropdownList } from '../reducers/dropdownList-slice';
import {
  clearUserState,
  updateMsToken,
  UserState,
} from '../reducers/user-slice';
import {
  postUserLogout,
  setAuthorizationToken,
} from '../services/api-services';
import {
  getInvitationGroupList,
  PaginationParamsType,
} from '../services/common';
import { store } from '../store/store';

export const phDateFormat = 'MMM DD, YYYY';
export const phDateFormatWithoutYear = 'MMM DD';
export const phTimeFormat = 'hh:mm A';
export const tcDateFormat = 'MMMDo';
export const tcDateFormatWithYear = 'YYYY年MMMDo';
export const tcTimeFormat = 'Ah:mm';

export const dateFormat = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear;
  return 'DD MMM, YY';
};
export const monthDateFormat = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear;
  return 'MMMM DD, YYYY';
};
export const monthDateFormatWithoutYear = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormatWithoutYear;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormat;
  return 'MMMM DD';
};
export const dateNumberFormat = (isReverse?: boolean) => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear;
  return isReverse ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
};
export const dateFormatWithFullYear = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear + 'dddd';
  return 'DD MMM, YYYY';
};
export const dateFormatWithFullMonth = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear + 'dddd';
  return 'MMMM DD, YYYY';
};
export const dateFormatWithoutYear = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormat;
  return 'DD MMM';
};
export const dateFormatWithWeek = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear + 'dddd';
  return 'dddd, DD MMM, YY';
};
export const dateFormatWithWeekAndFullDate = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phDateFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear + 'dddd';
  return 'dddd, DD MMMM, YYYY';
};
export const timeFormat = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL') return phTimeFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcTimeFormat;
  return 'hh:mm A';
};
export const dateTimeFormat = () => {
  if (process.env.REACT_APP_LOCATION === 'PHL')
    return phDateFormat + ' ' + phTimeFormat;
  if (
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK'
  )
    return tcDateFormatWithYear + ', ' + tcTimeFormat;
  return 'DD MMM, YY hh:mm A';
};

function convertTimestampToDate(timestamp: number): string {
  return moment.unix(timestamp).format();
}

export function checkDateIsNotTimestamp(date: string | number): string {
  if (typeof date === 'number') {
    return convertTimestampToDate(date);
  }

  return date;
}

export function convertDate(
  date: string | number,
  withoutYear?: boolean,
  fullyear?: boolean
) {
  const outputDateFormat = withoutYear
    ? dateFormatWithoutYear
    : fullyear
      ? dateFormatWithFullYear
      : dateFormat;
  const momentDate = checkDateIsNotTimestamp(date);
  return moment(momentDate).format(outputDateFormat());
}

export function convertPeriod(startDate: string, endDate: string) {
  const currentStartDate = checkDateIsNotTimestamp(startDate);
  const currentEndDate = checkDateIsNotTimestamp(endDate);
  const isSameYear = moment(currentStartDate).isSame(currentEndDate, 'year');
  const isSameDay = moment(currentStartDate).isSame(currentEndDate, 'day');

  const isTC =
    sessionStorage.getItem('lang') === 'zhTW' ||
    sessionStorage.getItem('lang') === 'zhHK';
  if (isSameYear) {
    if (isSameDay) {
      return `${moment(currentEndDate).format(monthDateFormat())}`;
    } else {
      return !isTC
        ? `${moment(currentStartDate).format(
            monthDateFormatWithoutYear()
          )} - ${moment(currentEndDate).format(monthDateFormat())}`
        : `${moment(currentStartDate).format(monthDateFormat())} - ${moment(
            currentEndDate
          ).format(monthDateFormat())}`;
    }
  } else {
    return `${moment(currentStartDate).format(monthDateFormat())} - ${moment(
      currentEndDate
    ).format(monthDateFormat())}`;
  }
}
export function convertNumberDate(date: string | number, isReverse?: boolean) {
  const momentDate = checkDateIsNotTimestamp(date);
  return moment(momentDate).format(dateNumberFormat(isReverse));
}

export function convertDateWithFullMonth(date: string | number) {
  const momentDate = checkDateIsNotTimestamp(date);
  return moment(momentDate).format(dateFormatWithFullMonth());
}

export function convertDateWithWeek(date: string | number) {
  const momentDate = checkDateIsNotTimestamp(date);
  return moment(momentDate).format(dateFormatWithWeek());
}

export function convertDateWithWeekAndFullDate(date: string | number) {
  const momentDate = checkDateIsNotTimestamp(date);
  return moment(momentDate).format(dateFormatWithWeekAndFullDate());
}

export function convertTime(date: string | number) {
  const momentDate = checkDateIsNotTimestamp(date);
  return moment(momentDate).format(timeFormat());
}

export function convertDateTime(date: string | number) {
  const momentDate = checkDateIsNotTimestamp(date);
  return moment(momentDate).format(dateTimeFormat());
}

export function convertDateTimeWithDivider(date: string | number) {
  const momentDate = checkDateIsNotTimestamp(date);
  return (
    moment(momentDate).format(dateFormat()) +
    '|' +
    moment(momentDate).format(timeFormat())
  );
}

export function checkExpiration(date: string) {
  return moment().isAfter(date);
}

// eslint-disable-next-line
export function containsObject(obj: ObjectKey, list: Array<ObjectKey>) {
  let x;

  for (x in list) {
    let isEqual = true;
    for (let prop in obj) {
      if (list[x][prop] !== obj[prop]) {
        isEqual = false;
        break;
      }
    }
    if (isEqual) {
      return isEqual;
    }
  }

  return false;
}

export function formatFileSize(bytes: number, decimalPoint?: number) {
  if (bytes === 0) return '0 Bytes';
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// Fail on DEV, don't use
// eslint-disable-next-line
export async function getBase64FromUrl(url: string, callback?: any) {
  return new Promise((resolve) => {
    fetch(url).then(async (response) => {
      const fileName = response.url.split('/').pop() as string;
      const contentType = response.headers.get('content-type');
      const blob = await response.blob();
      const file = new File([blob], fileName, {
        type: contentType ?? undefined,
      });
      const reader = new FileReader();
      reader.onloadend = function () {
        let readerResult = reader.result as string;
        if (file.type.split('/')[0] !== 'image') {
          readerResult = '';
        }
        const returnData = {
          id: '',
          fileName: file.name,
          type: file.type,
          data: readerResult,
          size: file.size,
        };
        if (callback) {
          callback(returnData);
        } else {
          resolve(returnData);
        }
      };
      reader.readAsDataURL(file);
    });
  });
}

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve({ data: { base64: reader.result } });
      }
    };
    reader.onerror = (error) => reject(error);
  });
};

export function getApiDataByLang(data: any) {
  if (typeof data === 'string') {
    return data;
  } else if (data.length === 1) {
    return data[0].value;
  } else if (data.length === 0) {
    return '';
  } else {
    const currentLang = i18n.use(LanguageDetector).language;
    if (currentLang.includes('zhTW') || currentLang.includes('zhHK')) {
      return data.find((item: any) => item.lang.toLowerCase() === 'tc').value;
    } else {
      return data.find((item: any) => item.lang.toLowerCase() === 'en').value;
    }
  }
}
export async function getDropdownListOnStore(
  storeKey: string,
  responseKey: string,
  fetchFn: (params?: ObjectKey) => AxiosPromise,
  params?: ObjectKey
) {
  const dropdownList = store.getState().dropdownListState;
  const targetList = dropdownList[storeKey as keyof typeof dropdownList];
  if (!targetList) {
    try {
      const response = await fetchFn(params);
      const returnList: Array<ObjectKey> = response.data[responseKey];
      store.dispatch(
        setDropdownList({
          key: storeKey,
          list: returnList,
        })
      );
      return returnList;
    } catch (error) {
      return [];
    }
  } else {
    return targetList;
  }
}

export function findMultiLvObjectByValue(
  value: string,
  array: Array<any>,
  key: string
): any | null {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return array[i];
    }
    if (array[i].children) {
      const result = findMultiLvObjectByValue(value, array[i].children, key);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export const getInvitationScopeGroupList = (responseList: Array<ObjectKey>) => {
  let invitationGroupLv: Array<string> = [];

  if (process.env.REACT_APP_LOCATION === 'TWN') {
    invitationGroupLv = ['area', 'store'];
    return responseList.map((area: ObjectKey) => {
      let areaObject: ObjectKey = {
        value: area.districtId,
        label: area.name,
      };
      if (area[invitationGroupLv[0]].length > 0) {
        areaObject = {
          ...areaObject,
          children: area[invitationGroupLv[0]].map((district: ObjectKey) => {
            let districtObject: ObjectKey = {
              value: district.areaId,
              label: district.name,
              // operationValue: area.districtId,
            };
            if (district[invitationGroupLv[1]].length > 0) {
              districtObject = {
                ...districtObject,
                children: district[invitationGroupLv[1]].map(
                  (store: ObjectKey) => ({
                    value: store.storeId,
                    label: store.storeName,
                    // operationValue: area.districtId,
                    // districtValue: district.areaId,
                  })
                ),
              };
            }
            return districtObject;
          }),
        };
      }
      return areaObject;
    });
  } else {
    invitationGroupLv = ['district', 'store'];
    return responseList.map((area: ObjectKey) => {
      let areaObject: ObjectKey = {
        value: area.areaId,
        label: area.name,
      };
      if (area[invitationGroupLv[0]].length > 0) {
        areaObject = {
          ...areaObject,
          children: area[invitationGroupLv[0]].map((district: ObjectKey) => {
            let districtObject: ObjectKey = {
              value: district.districtId,
              label: district.name,
              // operationValue: area._id,
            };
            if (district[invitationGroupLv[1]].length > 0) {
              districtObject = {
                ...districtObject,
                children: district[invitationGroupLv[1]].map(
                  (store: ObjectKey) => ({
                    value: store._id,
                    label: store.storeName,
                    // operationValue: area._id,
                    // districtValue: district.id,
                  })
                ),
              };
            }
            return districtObject;
          }),
        };
      }
      return areaObject;
    });
  }
};

export const convertStaffList = (response: Array<ObjectKey>) => {
  // console.log("convertStaffList", response);
  if (process.env.REACT_APP_LOCATION === 'TWN') {
    return response?.map((item: ObjectKey) => ({
      id: item.partnerId,
      name: item.userName,
      avatar: item.profilePath,
      department: item.departmentName,
      jobTitle: item.positionName,
    }));
  } else {
    return response?.map((item: ObjectKey) => ({
      id: item.id ?? item.partnerId,
      name:
        item?.userName ??
        item?.displayName ??
        item?.partnerName?.userName ??
        item?.partnerName?.name ??
        item?.nickname ??
        '-',
      avatar: item.profilePath,
      department: item.departmentName ?? item.function,
      jobTitle: item.positionName ?? item.jobTitle,
    }));
  }
};

export const convertStoreList = (response: Array<ObjectKey>) => {
  if (process.env.REACT_APP_LOCATION === 'TWN') {
    return response.map((item: ObjectKey) => ({
      id: item._id,
      name: item.storeName,
      address: item.DMArea + ', ' + item.OMArea,
    }));
  } else {
    return response.map((item: ObjectKey) => ({
      id: item._id,
      name: item.displayName ?? item.storeName,
      address:
        (item.district ? item.district : '-') +
        ' ' +
        (item.area ? item.area : '-'),
    }));
  }
};

export const convertUserProfile = (data: ObjectKey, state?: ObjectKey) => {
  const targetState = state || store.getState().userState;
  let sortedAttribute: ObjectKey[] = [];
  if (data.profileAttribute && data.profileAttribute.length > 0) {
    sortedAttribute = data.profileAttribute.sort(
      (p1: ObjectKey, p2: ObjectKey) =>
        p1.sequence > p2.sequence ? 1 : p1.sequence < p2.sequence ? -1 : 0
    );
  }

  return {
    ...targetState,
    id: data._id,
    partnerId: data.partnerId,
    email: data.email,
    name: data.name,
    firstName: data.firstName,
    nickname: data.nickname,
    avatar: data.userProfilePictureUrl ?? data.userProfilePicture,
    banner: data.userCoverPhotoUrl ?? data.userCoverPhoto,
    function: data.function,
    jobTitle: data.jobTitle,
    status: data.status,

    contactNo: data.contactNo,
    languages: data.languages,
    profileAttribute: sortedAttribute,
    area: data.area,
    district: data.district,
    store: data.store,
    pronouns: data.pronouns,

    isFirstLogin: true,
  };
};

export const nth = (n: number) => {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
};

export const convertInvitationScopeGroupData = (
  groupData: Array<ObjectKey>
) => {
  let invitationGroupLv: Array<string> = [];

  if (process.env.REACT_APP_LOCATION === 'TWN') {
    invitationGroupLv = ['area', 'store'];

    return groupData.map((data: ObjectKey) => {
      if (!data) return {};
      if (data.districtId) {
        return {
          value: data.districtId,
          label: data.name,
          children: data[invitationGroupLv[0]].map((district: ObjectKey) => ({
            value: district.areaId,
            label: district.name,
            children: district[invitationGroupLv[1]].map(
              (store: ObjectKey) => ({
                value: store.storeId,
                label: store.storeName,
              })
            ),
          })),
        };
      } else if (data.areaId) {
        return {
          value: data.areaId,
          label: data.name,
          children: data[invitationGroupLv[1]].map((store: ObjectKey) => ({
            value: store.storeId,
            label: store.storeName,
          })),
        };
      } else {
        return {
          value: data.storeId,
          label: data.storeName,
        };
      }
    });
  } else {
    invitationGroupLv = ['district', 'store'];

    return groupData.map((data: ObjectKey) => {
      if (!data) return {};
      if (data.areaId) {
        return {
          value: data.areaId,
          label: data.name,
          children: data[invitationGroupLv[0]].map((district: ObjectKey) => ({
            value: district.districtId,
            label: district.name,
            children: district[invitationGroupLv[1]].map(
              (store: ObjectKey) => ({
                value: store._id,
                label: store.storeName,
              })
            ),
          })),
        };
      } else if (data.districtId) {
        return {
          value: data.districtId,
          label: data.name,
          children: data[invitationGroupLv[1]].map((store: ObjectKey) => ({
            value: store._id,
            label: store.storeName,
          })),
        };
      } else {
        return {
          value: data._id,
          label: data.storeName,
        };
      }
    });
  }
};

export function getContentTypeFromExtension(fileExtension: string): string {
  // Convert the file extension to lowercase and check if it exists in the map
  const lowercasedExtension = fileExtension.toLowerCase();
  if (mimeTypeMap.hasOwnProperty(lowercasedExtension)) {
    return mimeTypeMap[lowercasedExtension];
  }

  // If the file extension is not found in the map, return a default content type
  return 'application/octet-stream';
}

export const getMSTokenSilent = async (
  accounts: any,
  instance: any,
  redirect: string,
  callback?: () => void
) => {
  const accessTokenRequest = {
    scopes: ['user.read'],
    account: accounts[0],
  };
  // console.log("accounts", JSON.stringify(accounts));
  // console.log("accounts", accounts);
  try {
    const accessTokenResponse =
      await instance.acquireTokenSilent(accessTokenRequest);
    let accessToken = accessTokenResponse.accessToken;
    if (callback) {
      store.dispatch(updateMsToken(accessToken));
      callback();
    } else {
      window.location.href = redirect;
    }
  } catch (error) {
    console.log('getMSTokenSilent error stringify', JSON.stringify(error));
    console.log('getMSTokenSilent error', error);
    if (error instanceof InteractionRequiredAuthError) {
      instance.acquireTokenRedirect(accessTokenRequest);
    } else {
      postUserLogout();
      handleLogoutAction('/login?status=4083');
    }
  }
};

export const handleLogoutAction = (redirectLink?: string) => {
  document.cookie = `Authorization=Bearer ${localStorage.getItem(
    'ACCESS_TOKEN'
  )};domain=${process.env.REACT_APP_API_SUBDOMAIN};path=/;`;
  document.cookie = `Source=webapp;domain=${process.env.REACT_APP_API_SUBDOMAIN};path=/;`;

  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('REFRESH_TOKEN');
  localStorage.removeItem('MS_TOKEN');
  localStorage.removeItem('IS_ACCEPTED_TNC');
  localStorage.removeItem('isRefresh');
  localStorage.removeItem('PARTNER_ID');
  localStorage.removeItem('SSO_REQUEST');
  localStorage.removeItem('SendBirdUserId');
  sessionStorage.clear();
  setAuthorizationToken('');
  store.dispatch(clearUserState());
  window.location.href = redirectLink ?? '/login';
};

export const setParamsToUrl = (
  params: PaginationParamsType
): URLSearchParams => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key: string) => {
    const value = params[key as keyof PaginationParamsType];

    if (!value) {
      urlParams.delete(key);
    } else if (typeof value === 'string') {
      urlParams.set(key, value);
    } else if (typeof value === 'number') {
      urlParams.set(key, value.toString());
    } else if (typeof value === 'object') {
      if (Array.isArray(value) && value.length > 0) {
        const filterParams = value.map((filter: ObjectKey) => {
          return (
            filter.key +
            '-' +
            filter.selected
              .map((selected: ObjectKey) => selected.value)
              .join(',')
          );
        });
        urlParams.set(key, filterParams.join('--'));
      } else {
        urlParams.delete(key);
      }
    }
  });

  return urlParams;
};

export const splitParamsFromUrl = (
  defaultParams: PaginationParamsType,
  searchParams: string
): PaginationParamsType => {
  const paramsArray = searchParams.replace('?', '').split('&');
  let newParamsObj = defaultParams;
  paramsArray.forEach((value: string) => {
    const valueArr = value.split('=');
    if (valueArr[0] === 'filter') {
      const filterArray = valueArr[1].split('--').map((filterValue: string) => {
        const filterValueArr = filterValue.split('-');
        const selectedArray = decodeURIComponent(filterValueArr[1]).split(',');
        return {
          key: filterValueArr[0],
          selected:
            selectedArray.length === 1 && selectedArray[0] === ''
              ? []
              : selectedArray,
        };
      });
      newParamsObj = {
        ...newParamsObj,
        [valueArr[0]]: filterArray,
      };
    } else if (valueArr[0] === 'page' || valueArr[0] === 'pageSize') {
      newParamsObj = {
        ...newParamsObj,
        // [valueArr[0]]: parseInt(valueArr[1]),
      };
    } else {
      newParamsObj = {
        ...newParamsObj,
        [valueArr[0]]: decodeURIComponent(valueArr[1]),
      };
    }
  });

  return newParamsObj;
};

export const splitFilterParamsFromUrl = (
  paramsString: string,
  options: Array<OptionsListType>
) => {
  const filterArray = paramsString.split('--').map((filterValue: string) => {
    const filterValueArr = filterValue.split('-');
    const selectedArray = decodeURIComponent(filterValueArr[1]).split(',');
    return {
      key: filterValueArr[0],
      selected:
        selectedArray.length === 1 && selectedArray[0] === ''
          ? []
          : selectedArray,
    };
  });

  if (filterArray && filterArray.length > 0) {
    let filterParamsArray: any = [];
    filterArray.forEach((filterColumn: ObjectKey) => {
      const filterColumnKey = filterColumn.key;
      const filterListByKey = options.find((filter: ObjectKey) => {
        return filter.key === filterColumnKey;
      });
      let selectedList: ObjectKey[] = [];
      if (filterListByKey) {
        selectedList = filterColumn.selected
          .map((id: string) => {
            return filterListByKey.options.find(
              (item: optionItemProps) => item.value === id
            );
          })
          .filter((item: ObjectKey | undefined) => item);
      }
      filterParamsArray = [
        ...filterParamsArray,
        {
          key: filterColumnKey,
          selected: selectedList,
        },
      ];
    });
    return filterParamsArray;
  } else {
    return [];
  }
};

export const removeApiCacheSession = (key: string) => {
  Object.keys(sessionStorage)
    .filter((item) => item.indexOf(key) > -1)
    .forEach((item) => sessionStorage.removeItem(item));
};

export const compressFile = async (file: File, maxImageSize: number = 0.5) => {
  const options = {
    maxSizeMB: maxImageSize,
    maxWidthOrHeight: 1920,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    const convertedFile = new File([compressedFile], compressedFile.name, {
      type: compressedFile.type,
    });
    return convertedFile;
  } catch (err) {
    // console.log("compressFile: ", err);
    return file;
  }
};

export const compressFileList = async (
  fileList: File[],
  maxImageSize: number = 0.5,
  maxWidth: number = 1920
) => {
  const compressedFileList = await Promise.all(
    fileList.map(async (file) => {
      const options = {
        maxSizeMB: maxImageSize,
        maxWidthOrHeight: maxWidth,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        const convertedFile = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type,
        });
        return convertedFile;
      } catch (err) {
        // console.log("compressFileList: ", err);
        return file;
      }
    })
  );
  return compressedFileList;
};

export const addThousandSeparator = (number: number | string) => {
  const formattedNumber =
    number &&
    number.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  return formattedNumber ?? ' - ';
};
