import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import type {} from "@mui/material/themeCssVarsAugmentation";
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
interface ToggleButtonProps {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  setChecked: (checked: boolean) => void;
}

function ToggleButton(props: ToggleButtonProps) {
  const { label, checked, disabled, setChecked } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const Root = styled("span")(
    ({ theme }) =>
      `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 51px;
    height: 30px;
    margin: 0px;
    cursor: pointer;
  
    &.${switchUnstyledClasses.disabled} {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    & .${switchUnstyledClasses.track} {
      background: ${theme.vars.palette.black.opacity12};
      border-radius: 27px;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  
    & .${switchUnstyledClasses.thumb} {
      display: block;
      width: 27px;
      height: 27px;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      background-color: #fff;
      position: relative;
      
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 120ms;
      
      box-shadow: 0px 3px 1px 0px #0000000F, 0px 3px 8px 0px #00000026;
    }
  
    &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
      background-color: ${theme.vars.palette.black.opacity12};
      box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }
  
    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 22px;
        top: 2px;
        background-color: #fff;
      }
  
      .${switchUnstyledClasses.track} {
        background:${theme.vars.palette.green.starbucks};
      }
    }
  
    & .${switchUnstyledClasses.input} {
      cursor: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }
    `
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {label && (
        <Typography
          variant="body1"
          sx={{
            color: "#9E9E9E",
          }}
        >
          {label}
        </Typography>
      )}
      <SwitchUnstyled
        component={Root}
        checked={checked ? true : false}
        disabled={disabled}
        onChange={handleChange}
      />
    </Box>
  );
}

export default ToggleButton;
