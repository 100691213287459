import React, { useState } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./social-wall-card.module.scss";

interface AccountItemListProps {
  padding?: string;
  isComment?: boolean;
}

const SocialWallCardSkeleton = (props: AccountItemListProps) => {
  const { padding, isComment = false } = props;
  const { t } = useTranslation();
  return (
    <Box
      className={styles.itemWrapper}
      sx={{ padding: padding ? padding : "16px" }}
    >
      <Stack direction="row" gap="8px" alignItems="center">
        <Skeleton
          width={isComment ? 32 : 40}
          height={isComment ? 32 : 40}
          animation="wave"
          variant="circular"
          sx={{ flex: "0 0 auto" }}
        />
        {isComment ? (
          <Skeleton variant="text" animation="wave" height={45} width="100%" />
        ) : (
          <>
            <Box className={styles.menuItemDetail}>
              <Skeleton
                variant="text"
                animation="wave"
                height={20}
                width={200}
              />
              <Skeleton
                variant="text"
                animation="wave"
                height={15}
                width={200}
              />
            </Box>
          </>
        )}
      </Stack>
      {isComment ? (
        <Stack direction="row" gap="8px" alignItems="flex-start">
          <Skeleton
            width={32}
            height={32}
            animation="wave"
            variant="circular"
            sx={{ flex: "0 0 auto" }}
          />
          <Box sx={{ width: "100%" }}>
            <Skeleton variant="text" animation="wave" height={30} width={150} />
            <Skeleton
              variant="text"
              animation="wave"
              height={70}
              width="100%"
            />
          </Box>
        </Stack>
      ) : (
        <Skeleton variant="text" animation="wave" height={100} width="100%" />
      )}
    </Box>
  );
};

export default SocialWallCardSkeleton;
