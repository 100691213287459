import { AxiosPromise, AxiosResponse } from "axios";
import { instance, searchInstance } from "./api-services";
import { ObjectKey } from "../interfaces/common-interface";
import { PaginationParamsType } from "./common";
import { compressFileList, removeApiCacheSession } from "../utility";

const servicePath = "/event-roster-and-schedule/";
// const storageMsToken = localStorage.getItem("MS_TOKEN");

export interface EventListRequestType {
  month: number;
  year: number;
  type?: string;
  eventType?: string;
  registerType?: string;
  isAdmin?: true;
  partnerNetworkGroupId?: string;
}

export interface RegisterEventRequestType {
  eventId: string;
  // msAccessToken?: string;
}

export const getEventListSummary = (
  params: EventListRequestType
): AxiosPromise =>
  instance({
    method: "GET",
    id: `getEventList-summary${
      params.partnerNetworkGroupId ? `-${params.partnerNetworkGroupId}` : ""
    }}`,
    url: `${servicePath}/summary`,
    params: params,
  });

export const getEventList = (params: EventListRequestType): AxiosPromise =>
  instance({
    method: "GET",
    id: `getEventList-${params.month}-${params.year}-${params.type}-${
      params.eventType
    }-${params.registerType}-${params.isAdmin}${
      params.partnerNetworkGroupId ? `-${params.partnerNetworkGroupId}` : ""
    }`,
    cache: { ttl: 1 * 60 * 1000 },
    url: `${servicePath}/list`,
    params: params,
  });

export interface EventSearchType extends PaginationParamsType {
  partnerNetworkGroupId?: string;
}

export const searchEvents = (params?: EventSearchType): AxiosPromise =>
  searchInstance({
    method: "GET",
    cache: false,
    url: `${servicePath}/search`,
    params,
  });

export const getEventCategory = (): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    url: `${servicePath}/eventCategoryList`,
  });

export const getEventDetail = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getEventList-details-${id}`,
    cache: { ttl: 1 * 60 * 1000 },
    url: `${servicePath}/${id}`,
    params: {
      isMultiLang: process.env.REACT_APP_LOCATION !== "PHL",
    },
  });

export const getEventAttendees = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getEventList-attendees-${id}`,
    cache: { ttl: 1 * 60 * 1000 },
    url: `${servicePath}/${id}/attendees`,
  });

export const getEventAttachments = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    id: `getEventList-attachments-${id}`,
    cache: { ttl: 1 * 60 * 1000 },
    url: `${servicePath}/${id}/attachments`,
  });

export const registerEvent = (
  data: RegisterEventRequestType,
  msToken?: string
): AxiosPromise => {
  removeApiCacheSession("getEventList-");
  return instance({
    method: "POST",
    headers: {
      MSToken: msToken,
    },
    url: `${servicePath}/register`,
    data,
  });
};

export const unregisterEvent = (id: string, msToken?: string): AxiosPromise => {
  removeApiCacheSession("getEventList-");
  return instance({
    method: "PUT",
    headers: {
      MSToken: msToken,
    },
    url: `${servicePath}/unregister/${id}`,
  });
};

export const getQrCode = (id: string): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    url: `${servicePath}/eventQr/${id}`,
  });

export const verifyQrCode = (data: { qrCode: string }): AxiosPromise =>
  instance({
    method: "POST",
    url: `${servicePath}/verifyQr`,
    data,
  });

export const createEvent = (data: ObjectKey): AxiosPromise => {
  removeApiCacheSession("getEventList-");
  return instance({
    method: "POST",
    cache: {
      update: {
        getEventListSummary: "delete",
      },
    },
    url: `${servicePath}/create`,
    data,
  });
};

export const updateEvent = (data: {
  id: string;
  request: ObjectKey;
}): AxiosPromise => {
  removeApiCacheSession("getEventList-");
  return instance({
    method: "PUT",
    cache: {
      update: {
        getEventListSummary: "delete",
      },
    },
    url: `${servicePath}/${data.id}`,
    data: data.request,
  });
};

export const deleteEvent = (id: string): AxiosPromise => {
  removeApiCacheSession("getEventList-");
  return instance({
    method: "DELETE",
    cache: {
      update: {
        getEventListSummary: "delete",
      },
    },
    url: `${servicePath}/${id}`,
  });
};

export const uploadCoverImage = async (data: {
  image: File;
}): Promise<AxiosResponse> => {
  const formData = new FormData();

  try {
    const fileList = await compressFileList([data.image], 0.5, 1280);
    fileList.forEach((item: File | undefined) => {
      if (item) formData.append("image", item, encodeURIComponent(item.name));
    });
  } catch (error) {
    console.log("compressFileList error:", error);
  }

  return instance({
    method: "POST",
    url: `${servicePath}/coverImage`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const uploadAttachments = (data: { file: File[] }): AxiosPromise => {
  const formData = new FormData();
  data.file.forEach((item: File) => {
    formData.append("file[]", item);
  });

  return instance({
    method: "POST",
    url: `${servicePath}/attachments`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const getCalendarEventList = (
  params: EventListRequestType,
  msToken?: string
): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    headers: {
      MSToken: msToken,
    },
    url: `${servicePath}/calendar/${
      params.type === "msTeams"
        ? process.env.REACT_APP_LOCATION === "PHL"
          ? "ph/"
          : "hk/"
        : ""
    }partner?page=1&pageSize=1000`.replace("//", "/"),
    params,
  });

export const getCalendarPartnerTeam = (
  params: EventListRequestType,
  msToken: string
): AxiosPromise =>
  instance({
    method: "GET",
    cache: false,
    headers: {
      MSToken: msToken,
    },
    url: `${servicePath}/calendar/partnerTeam?page=1&pageSize=1000`.replace(
      "//",
      "/"
    ),
    params,
  });

export const searchCalendarEvents = (
  params?: PaginationParamsType
): AxiosPromise =>
  searchInstance({
    method: "GET",
    cache: false,
    url: `${servicePath}/calendar/partner`.replace("//", "/"),
    params,
  });

export const searchCalendarPartnerTeam = (
  params?: PaginationParamsType,
  msToken?: string
): AxiosPromise =>
  searchInstance({
    method: "GET",
    cache: false,
    headers: {
      MSToken: msToken,
    },
    url: `${servicePath}/calendar/partnerTeam`.replace("//", "/"),
    params,
  });
