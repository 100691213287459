import React, { useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import styles from "./account-item.module.scss";

interface AccountItemListProps {
  padding?: string;
}

const AccountItemSkeleton = (props: AccountItemListProps) => {
  const { padding } = props;
  const { t } = useTranslation();
  return (
    <Box
      className={styles.itemWrapper}
      sx={{ padding: padding ? padding : "16px" }}
    >
      <Box>
        <Skeleton width={40} height={40} animation="wave" variant="circular" />
      </Box>

      <Box className={styles.menuItemDetail}>
        <Skeleton variant="text" animation="wave" height={20} width={200} />
        <Skeleton variant="text" animation="wave" height={15} width={200} />
      </Box>
    </Box>
  );
};

export default AccountItemSkeleton;
